import React from "react";
import { IonLoading } from "@ionic/react";

const Loading: React.FC<any> = ({ isLoading, message }) => {
  return (
    <IonLoading
      isOpen={isLoading}
      backdropDismiss={true}
      message={message || 'Cargando...'}
      duration={10000}
      showBackdrop={false}
    />
  )
}

export default Loading;
