import React from 'react';
import { useHistory } from 'react-router';
import { getPlatforms, IonAlert, IonAvatar, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { arrowDownCircleOutline, briefcaseOutline, calendarOutline, call, callOutline, checkmarkOutline, chevronBack, chevronDown, close, constructOutline, createOutline, documentTextOutline, homeOutline, laptopOutline, libraryOutline, personCircleOutline, personOutline, star, starHalf, starOutline } from 'ionicons/icons';

// Component
import Loading from '../../../components/loading';
import BackButton from '../../../components/BackButton';

// Services
import api from '../../../services/api'
import auth from '../../../services/auth';

// Context
import { UserContext } from '../../../context/UserContext';


// Utils
import { customBadges } from '../../../utils/customBadges';
import Tabs from '../../../components/Tabs';
import Header from '../../../components/desktop/Header';
import ReactStars from 'react-stars';
import formatDate from '../../../utils/formatDate';
import MobileHeader from '../../../components/MobileHeader';
import { ToastContext } from '../../../context/ToastContext';

const ShowFreelancer: React.FC = (props: any) => {

  const platform: any = getPlatforms();

  const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext)
  const { setToast, setToastMessage } = React.useContext(ToastContext);

  const [freelancer, setFreelancer] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<any>(true);

  const [favoriteChange, setFavoriteChange] = React.useState<any>(false);
  const [favoriteChangeLoading, setFavoriteChangeLoading] = React.useState<any>(false);


  const router = useHistory();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const config = await auth.getHeaderConfig()
        const res = await api.get(`/user/${props.match.params.freelancerID}`, config)
        setFreelancer(res.data)
        setIsLoading(false)


        setFavoriteChangeLoading(false)
        setFavoriteChange(false)
      } catch (error) {
        console.log("Erro no FreelancerShow:", error?.response?.data);
        return;
      }
    } fetchData()
  }, [router.location, favoriteChange])

  async function handleFavoriteFreelancer() {
    try {
      setFavoriteChangeLoading(true)
      const config = await auth.getHeaderConfig()
      const res = await api.patch(`/user/${props.match.params.freelancerID}/favorite`, null, config)
      setFavoriteChange(true)
      setToastMessage(freelancer.freelancer.favorite ? "Autónomo eliminado de favoritos!" : "Autónomo agregado de favoritos!");
      setToast(true)
    } catch (error) {
      console.log("Erro no FavoriteFreelancerShow:", error?.response?.data);
      return;
    }
  }


  if (isLoading) return <Loading isLoading={isLoading} />

  else if (!freelancer) return <></>

  else if (platform == 'desktop') return (

    <IonContent className="freelancer-job-desktop">

      <Header currentPath={props.match.url} />

      <main className="container">
        <IonGrid fixed className="content-wrapper">
          <IonRow className='ion-margin-top'>

            <IonCol size="8">
              <IonCard className='ion-no-padding'>

                <IonCardContent style={{ paddingBottom: '0' }}>
                  <IonRow style={{ marginBottom: '1em' }}>

                    <IonCol size='3'>
                      <IonAvatar style={{ border: "2px solid #00F1A9", marginRight: '1rem', height: "9rem", width: "9rem" }}>
                        <img src={freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                      </IonAvatar>
                    </IonCol>
                    <IonCol>

                      <div style={{ fontSize: '1.1rem', marginRight: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ width: "100%", display: 'flex', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                              <div>
                                <p style={{ margin: 0, fontSize: '36px', color: '#2D2D2D' }}>{freelancer.name}</p>
                                <div className="d-flex align-items-center" style={{ margin: '0.5rem 0' }}>
                                  <IonIcon style={{ fontSize: "20px", padding: 0 }} icon="/assets/email.svg" />
                                  <span className="d-block ml-2 p-0">{freelancer?.email}</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <ReactStars
                                    count={5}
                                    value={freelancer?.freelancer?.rating?.value}
                                    size={20}
                                    edit={false}
                                    color2={'#ffd700'}
                                  />
                                  <p style={{ margin: "0 0 0 5px", fontSize: '15px' }}>{freelancer?.freelancer?.rating?.value}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ fontSize: "1.2rem", fontWeight: 300 }} className="w-100 mt-4 d-flex justify-content-start" >
                        Disponibilidad de trabajo:
                      </div>

                      <div className="w-100 mt-4 mb-5 d-flex justify-content-start" >
                        {freelancer?.freelancer?.workPreference?.remote ?
                          <div style={{
                            fontSize: "1rem", fontWeight: 300, backgroundColor: "#DAE6EB",
                            borderRadius: "1rem", margin: "0 0.2rem", padding: "0.4rem 1.3rem", color: "black", textAlign: "center"
                          }} >Remoto</div>
                          : <></>}

                        {freelancer?.freelancer?.workPreference?.presential ?
                          <div style={{
                            fontSize: "1", fontWeight: 300, backgroundColor: "#DAE6EB",
                            borderRadius: "1rem", margin: "0 0.2rem", padding: "0.4rem 1.3rem", color: "black", textAlign: "center"
                          }} >
                            Presencial
                          </div>
                          : <></>
                        }
                      </div>

                      {/* fecha de registro */}
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
                        <IonIcon style={{ fontSize: "1.4rem" }} icon="/assets/registered_green.svg" />
                        <IonText color='dark' style={{ marginLeft: '0.8rem', fontWeight: 300 }}>Fecha de registro</IonText>
                      </div>
                      <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>{freelancer.createdAt ? formatDate(freelancer.createdAt) : "No informado"}</p>

                      {/* dirección */}

                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                        <IonIcon style={{ fontSize: "1.4rem" }} icon="/assets/location_green.svg" />
                        <IonText color='dark' style={{ marginLeft: '0.8rem', fontWeight: 300 }}>Dirección</IonText>
                      </div>
                      <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>{freelancer.freelancer?.address ? freelancer.freelancer.address : "No informado"}</p>

                      {/* <IonButton style={{ fontSize: "1.2rem", width: "13rem", fontWeight: 400, position: "absolute", right: 0, bottom: 0 }} shape="round" color='primary' onClick={(e) => { e.stopPropagation(); router.push('/client/new-job', { customJob: true, freelancer: freelancer }); }}>Contratar</IonButton> */}
                      <button onClick={(e) => { e.stopPropagation(); router.push('/client/new-job', { customJob: true, freelancer: freelancer }); }} style={{ position: 'absolute', bottom: 0, right: 0, borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                        <p style={{ margin: '0', fontSize: '17px' }}>Contratar</p>
                      </button>

                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>

            </IonCol>
            <IonCol size="4">
              <IonCard className="ion-no-padding" style={{ height: '100%' }} >
                <IonCardContent style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
                  <div style={{ flex: 1 }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                      <IonText color='dark' style={{ fontWeight: 500, fontSize: "1.2rem" }}>Resumen</IonText>
                    </div>
                    <p style={{ fontSize: '1em', marginBottom: '1em' }}>{freelancer.freelancer?.resume ? freelancer.freelancer.resume : "No informado"}</p>


                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
                      <IonIcon color='dark' icon={briefcaseOutline} />
                      <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Servicios</IonText>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                      <IonLabel color='dark' style={{ fontWeight: 400 }}> {freelancer.freelancer?.jobs?.length > 0 ? freelancer.freelancer.jobs.filter((job: any) => job.status === 'finished').length : 0} </IonLabel>
                      <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>Concluídos</p>
                    </div>
                  </div>


                  {/* <div className="px-4 mt-3">
                    <IonButton style={{ fontSize: "1.2rem", padding: 0, fontWeight: 400 }} expand="block" shape="round" color='secondary'
                      onClick={() => router.push(`/messages`, { socketId: (freelancer._id + user._id) })}
                    >Abre el chat</IonButton>
                  </div> */}

                  <button onClick={() => router.push(`/messages`, { socketId: (freelancer._id + user._id) })} style={{ margin: '0 auto', borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                    <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                  </button>

                </IonCardContent>

              </IonCard>
            </IonCol>
            <IonCol size="12">
              <IonCard className="mt-3 ion-no-padding" >

                <IonCardContent>


                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                    <IonIcon style={{ fontSize: "1.8rem" }} color="primary" icon="/assets/about_me.svg" />
                    <IonText color='dark' style={{ fontSize: "1.2rem", marginLeft: '0.8rem', fontWeight: 300 }}>Sobre mi</IonText>
                  </div>
                  <p style={{ fontSize: '1em', margin: '1rem 0' }}>{freelancer.freelancer?.about ? freelancer.freelancer.about : "No informado"}</p>


                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>

                    <IonText color='dark' style={{ fontSize: "1.2rem", fontWeight: 300 }}>Habilidades</IonText>
                  </div>

                  {
                    freelancer.freelancer?.skills && freelancer.freelancer?.skills?.length
                      ? freelancer.freelancer.skills.map((skill: any, index: any) => (
                        <React.Fragment key={index}>
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
                            <IonIcon color='dark' icon={checkmarkOutline} />
                            <p style={{ fontSize: '1rem', marginLeft: '0.4rem' }}>{skill}</p>
                          </div>
                        </React.Fragment>

                      ))
                      : <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>No informado</p>
                  }

                </IonCardContent>

              </IonCard>



            </IonCol>
          </IonRow>
        </IonGrid>
      </main>
      <Loading isLoading={favoriteChangeLoading} />
      {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}
    </IonContent >
  )

  else return (
    <IonPage>
      <IonHeader>
        <MobileHeader title='Detalhes do Freelancer' backButton={true} />
      </IonHeader>

      <IonContent color="medium" >
        <div className="d-flex justify-content-center" style={{ height: '14rem', zIndex: 0, backgroundColor: '#7A82EA', padding: '2rem 0' }} >
          <div style={{ display: 'flex', width: '100%', position: 'absolute', top: 0, left: 0, zIndex: 0, backgroundColor: '#7A82EA', padding: '1rem' }} >
            <div style={{ display: 'flex', gap: '0.2rem', cursor: 'pointer', zIndex: 2 }}
              // onClick={() => router.push('/client/freelancers')}
              onClick={() => router.goBack()}
            >
              <IonIcon color='light' icon={chevronBack} />
              <p style={{ margin: '0', fontSize: '12px', color: '#FFFFFF' }}>Volver</p>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-start align-items-center" style={{ zIndex: 1 }}>
            <div className="d-flex flex-column justify-content-start align-items-center py-3" style={{ zIndex: 1 }}>
              <p className="pt-3" style={{ fontSize: "1.2rem", textAlign: "center", color: "#FFFFFF" }}>Autónomo</p>
            </div>
          </div>
        </div>
        <IonCard className='ion-no-padding ion-margin' style={{ marginTop: "-6rem" }}>
          <IonCardContent style={{ paddingBottom: '0' }}>
            <IonRow style={{ marginBottom: '1em' }}>
              <IonCol>

                <div style={{ fontSize: '1.1rem', marginRight: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ width: "100%", display: 'flex', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IonAvatar style={{ border: "2px solid #00F1A9", marginRight: '10px', height: "4rem", width: "4rem" }}>
                        <img src={freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                      </IonAvatar>
                      <div>

                        <div>
                          <p style={{ margin: 0, fontSize: '18px' }}>{freelancer.name}</p>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ReactStars
                              count={5}
                              value={freelancer?.freelancer?.rating?.value}
                              size={16}
                              edit={false}
                              color2={'#ffd700'}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <IonIcon style={{ fontSize: "1rem", padding: 0 }} icon="/assets/email.svg" />
                          <span className="d-block ml-2 p-0">{freelancer?.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ fontSize: "1.2rem", fontWeight: 300 }} className="w-100 mt-4 d-flex justify-content-center" >
                  Disponibilidad de trabajo:
                </div>

                <div className="w-100 mt-4 mb-5 d-flex justify-content-center" >
                  {freelancer?.freelancer?.workPreference?.remote ?
                    <div style={{
                      fontSize: "1rem", fontWeight: 300, backgroundColor: "#DAE6EB",
                      borderRadius: "1rem", margin: "0 0.2rem", padding: "0.4rem 1.3rem", color: "black", textAlign: "center"
                    }} >Remoto</div>
                    : <></>}

                  {freelancer?.freelancer?.workPreference?.presential ?
                    <div style={{
                      fontSize: "1", fontWeight: 300, backgroundColor: "#DAE6EB",
                      borderRadius: "1rem", margin: "0 0.2rem", padding: "0.4rem 1.3rem", color: "black", textAlign: "center"
                    }} >
                      Presencial
                    </div>
                    : <></>
                  }
                </div>

                {/* fecha de registro */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
                  <IonIcon style={{ fontSize: "1.4rem" }} icon="/assets/registered_green.svg" />
                  <IonText color='dark' style={{ marginLeft: '0.8rem', fontWeight: 300 }}>Fecha de registro</IonText>
                </div>
                <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>{freelancer.createdAt ? formatDate(freelancer.createdAt) : "No informado"}</p>

                {/* dirección */}

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                  <IonIcon style={{ fontSize: "1.4rem" }} icon="/assets/location_green.svg" />
                  <IonText color='dark' style={{ marginLeft: '0.8rem', fontWeight: 300 }}>Dirección</IonText>
                </div>
                <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>{freelancer.freelancer?.address ? freelancer.freelancer.address : "No informado"}</p>

                {/* Contratar */}
                <div className="px-4 mt-5">
                  <IonButton style={{ fontSize: "1.2rem", padding: 0, fontWeight: 400 }} expand="block" shape="round" color='primary' className='mb-3' onClick={(e) => { e.stopPropagation(); router.push('/client/new-job', { customJob: true, freelancer: freelancer }); }}>Contratar</IonButton>
                </div>
              </IonCol>
            </IonRow>


            {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
              <IonIcon color='dark' icon={briefcaseOutline} />
              <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Servicios</IonText>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
              <IonLabel color='dark' style={{ fontWeight: 400 }}> {freelancer.freelancer?.jobs?.length > 0 ? freelancer.freelancer.jobs.filter((job: any) => job.status === 'finished').length : 0} </IonLabel>
              <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>Concluídos</p>
            </div> */}

          </IonCardContent>
        </IonCard>

        <IonCard className="mt-3 ion-no-padding ion-margin" >

          <IonCardContent>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
              <IonText color='dark' style={{ fontWeight: 500, fontSize: "1.2rem" }}>Resumen</IonText>
            </div>
            <p style={{ fontSize: '1em', marginBottom: '1em' }}>{freelancer.freelancer?.resume ? freelancer.freelancer.resume : "No informado"}</p>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
              <IonIcon color='dark' icon={briefcaseOutline} />
              <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Servicios</IonText>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
              <IonLabel color='dark' style={{ fontWeight: 400 }}> {freelancer.freelancer?.jobs?.length > 0 ? freelancer.freelancer.jobs.filter((job: any) => job.status === 'finished').length : 0} </IonLabel>
              <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>Concluídos</p>
            </div>



            <div className="px-4 mt-5">
              <IonButton style={{ fontSize: "1.2rem", padding: 0, fontWeight: 400 }} expand="block" shape="round" color='secondary' className='mb-3'
                onClick={() => router.push(`/chat/${freelancer._id + user._id}`)}
              >Abre el chat</IonButton>
            </div>
          </IonCardContent>

        </IonCard>

        <IonCard className="mt-3 ion-no-padding ion-margin" >

          <IonCardContent>


            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
              <IonIcon style={{ fontSize: "1.8rem" }} color="primary" icon="/assets/about_me.svg" />
              <IonText color='dark' style={{ fontSize: "1.2rem", marginLeft: '0.8rem', fontWeight: 300 }}>Sobre mi</IonText>
            </div>
            <p style={{ fontSize: '1em', margin: '1rem 0' }}>{freelancer.freelancer?.about ? freelancer.freelancer.about : "No informado"}</p>


            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>

              <IonText color='dark' style={{ fontSize: "1.2rem", fontWeight: 300 }}>Habilidades</IonText>
            </div>

            {
              freelancer.freelancer?.skills && freelancer.freelancer?.skills?.length
                ? freelancer.freelancer.skills.map((skill: any, index: any) => (
                  <React.Fragment key={index}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
                      <IonIcon color='dark' icon={checkmarkOutline} />
                      <p style={{ fontSize: '1rem', marginLeft: '0.4rem' }}>{skill}</p>
                    </div>
                  </React.Fragment>

                ))
                : <p style={{ fontSize: '1rem', marginBottom: '1rem' }}>No informado</p>
            }

          </IonCardContent>

        </IonCard>




      </IonContent>

      <IonFooter>
        <Tabs currentPath={props.match.url} />
      </IonFooter>
      <Loading isLoading={favoriteChangeLoading} />

    </IonPage >
  )
}
export default ShowFreelancer;