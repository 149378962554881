import { getPlatforms, IonAvatar, IonButton, IonCard, IonCardContent, IonCardHeader, IonContent, IonFooter, IonIcon, IonInput, IonItem, IonPage, IonRow, IonText } from '@ionic/react';
import { send } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { io } from "socket.io-client";
import Header from '../../components/desktop/Header';
import Loading from '../../components/loading';
import MobileHeader from '../../components/MobileHeader';
import Tabs from '../../components/Tabs';
import { UserContext } from '../../context/UserContext';
import api from '../../services/api';
import formatDateMessage from '../../utils/formatDateMessage';
import './styles.scss';



// const socket = io("http://localhost:3050")
const socket = io(process.env.REACT_APP_PROD_API || "https://contadoor.herokuapp.com/", { autoConnect: false });
// const socket = io("https://contadoor.herokuapp.com/");

export default function Messages(props: any) {

  const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext);

  const router = useHistory()

  const platform: any = getPlatforms();

  const chatForm = React.useRef<any>(null);
  const messagesEnd = React.useRef<any>(null);

  const [messageSelected, setMessageSelected] = React.useState<any>(false)
  const [searchText, setSearchText] = React.useState<any>('');
  const [socketId, setSocketId] = React.useState<any>(props?.location?.state?.socketId || false)
  const [userReceiver, setUserReceiver] = React.useState<any>(false)
  const [chat, setChat] = React.useState<any>(false)

  const messages = user.messages || []

  const messagesToShow = messages && searchText.length > 0
    ? messages.filter((message: any) => (
      user.type == 'freelancer'
        ? message.client.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        : message.freelancer.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
    ))
    : messages

  if (props?.location?.state?.socketId && !messageSelected && !isUserLoading) {
    const message = messages?.find((message: any) => message?.socketId === props?.location?.state?.socketId)
    if (message) {
      setSocketId(message?.socketId);
      setUserReceiver(user.type == 'freelancer' ? message?.client : message?.freelancer);
      setMessageSelected(message)
    }
  }

  React.useEffect(() => {
    async function fetchMessages() {
      let getChat: any = await api.get(`/message/${socketId}`)
      setChat(getChat.data)
      setUserReceiver(user.type == 'freelancer' ? getChat.data?.client : getChat.data?.freelancer)
    }
    if (socketId && user) fetchMessages()
  }, [
    socketId,
  ])

  React.useEffect(() => {
    async function joinSocket() {

      socket.off(socketId);
      socket.disconnect()

      socket.connect()
      socket.emit("createChat", socketId)
      socket.on(socketId, (message: any) => {
        console.log('socket on message!')
        setChat((currentChat: any) => ({
          messages: [...currentChat.messages, message]
        }))
      })
    }
    if (socketId && user) joinSocket()
    return () => {
      socket.off(socketId);
      socket.disconnect()
      setChat(false)
    }
  }, [messageSelected])

  // Salva histórico para cada mensagem digitada
  React.useEffect(() => {
    async function updateMessages() {
      await api.patch(`/message/${socketId}`, { messages: chat?.messages })
      await messagesEnd?.current?.scrollIntoView()
    }
    if (socketId && user && chat) updateMessages()
  }, [chat])


  // Create user.message for 1st time
  React.useEffect(() => {
    async function initMessages() {
      await api.patch(`/message/${socketId}`, { messages: chat?.messages })
      setUpdateUserContext((state: any) => !state)
    }
    const message = messages?.find((message: any) => message?.socketId === props?.location?.state?.socketId)
    if (socketId && !message) initMessages()
  }, [])


  async function handleSubmit(e: any) {
    e.preventDefault();
    let message = {
      text: e.target.message.value,
      user: user,
      date: new Date().toString(),
      freelancer: socketId.substr(0, 24),
      client: socketId.substr(24, 48)
    }

    socket.emit(socketId, message)

    chatForm?.current?.reset();
  }

  if (isUserLoading || !user) return <></>

  if (platform == "desktop") return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard" style={{ overflow: 'hidden', flex: 1 }}>
        <main style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', gap: '0.5rem', width: '100%', height: '260px', position: 'absolute', top: 0, left: 0, zIndex: 0, backgroundColor: '#7A82EA', padding: '2rem' }} />
          <div style={{ display: 'flex', width: '60%', height: '755px', justifyContent: 'center', alignItems: 'center' }} className="mt-1">
            <div style={{ width: '370px', minWidth: '370px', height: '100%', backgroundColor: 'transparent', borderRadius: '10px', padding: '1rem' }}>
              <IonCard style={{ position: 'relative', width: "100%", height: '100%', backgroundColor: '#FFFFFF', border: '1px solid #E3E3E3', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', zIndex: 3 }} className="ion-margin-none ion-padding-bottom">
                <IonCardContent className='scroll' style={{ position: 'relative', width: "100%", height: '100%', overflowY: 'scroll' }}>
                  <p style={{ marginBottom: '1.5rem', color: '#000000', fontSize: '24px' }}>Mensajes</p>
                  <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#F5F5F5', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, marginBottom: '1rem' }}>
                    <input style={{ flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar por nombre.." />
                    <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
                  </div>
                  {messagesToShow?.map((message: any) => (
                    <div key={message?._id} style={{ minHeight: '76px', display: 'flex', alignItems: 'center', gap: '0.5rem', padding: '0 1rem', margin: '0 -1.5rem', backgroundColor: messageSelected?._id === message._id ? '#00CFB415' : '', borderRadius: '8px' }} onClick={() => { setSocketId(message?.socketId); setUserReceiver(user.type == 'freelancer' ? message?.client : message?.freelancer); setMessageSelected(message) }}>
                      <IonAvatar style={{ marginRight: '.8rem', width: '60px', height: '60px' }}>
                        <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }}
                          src={
                            user.type == 'freelancer' ?
                              message?.client?.image :
                              message?.freelancer?.image
                          } />
                      </IonAvatar>
                      <IonText style={{ fontSize: '1rem', color: '#2D2D2D', flex: 1 }} className="ion-text-wrap">
                        <div className="ion-padding-vertical">
                          <strong>
                            {
                              user.type == 'freelancer' ?
                                message?.client?.name :
                                message?.freelancer?.name
                            }
                          </strong><br />
                          {message?.messages?.[message?.messages?.length - 1]?.text}
                        </div>
                      </IonText>
                    </div>
                  ))}
                </IonCardContent>
              </IonCard>
            </div>

            {/*  */}

            <div style={{ minWidth: '100%', height: '100%', borderRadius: '10px', padding: '1rem' }}>
              <IonCard className="ion-margin-none" style={{ position: 'relative', width: "100%", height: '100%', backgroundColor: '#EAEAEA', border: '1px solid #E3E3E3', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', zIndex: 1, marginLeft: '-45px' }} >
                <IonCardContent className='ion-no-padding scroll' style={{ position: 'relative', height: '100%', paddingLeft: '2rem', paddingRight: '1rem', paddingTop: '90px', paddingBottom: '75px', overflowY: 'scroll' }}>
                  {
                    userReceiver ?
                      <IonCardHeader style={{ position: 'fixed', top: 0, left: 0, display: 'flex', alignItems: 'center', height: '70px', width: '100%', backgroundColor: '#FFFFFF', borderRadius: '10px', paddingLeft: '1.8rem', zIndex: 2, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 8px' }}>
                        <IonAvatar style={{ marginRight: '.8rem' }}>
                          <img style={{ padding: '.3rem', borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} src={userReceiver?.image} />
                        </IonAvatar>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <p className="ion-no-margin"><strong>{userReceiver.name}</strong></p>
                        </div>
                      </IonCardHeader>
                      : <></>
                  }
                  <div>
                    {
                      userReceiver
                        ? chat ?
                          chat?.messages && chat?.messages?.map((message: any, index: any) =>
                            <div key={index} style={{ display: 'flex', marginBottom: '1.5rem', alignItems: 'flex-end' }}>
                              {
                                message?.user?._id == user._id ?
                                  <>
                                    <div style={{ flex: 1 }}>
                                      <IonRow className={`justify-content-end`}>
                                        <IonCard className="ion-text-right" key={message} color="primary"
                                          style={{ padding: '10px 25px', borderRadius: '35px 35px 0 35px' }}
                                        >
                                          <p style={{ fontSize: '16px', margin: 0, color: '#ffffff' }}>{message?.text}</p>
                                          <p style={{ fontSize: '14px', margin: 0, marginTop: "0.2rem", color: '#ffffff60' }}>{formatDateMessage(message?.date)}</p>
                                        </IonCard>
                                      </IonRow>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                      <IonAvatar style={{ marginLeft: '14px', width: '40px', height: '40px' }}>
                                        <img src={message?.user?.image} />
                                      </IonAvatar>
                                    </div>
                                  </>
                                  : <>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                      <IonAvatar style={{ marginRight: '14px', width: '40px', height: '40px' }}>
                                        <img src={message?.user?.image} />
                                      </IonAvatar>
                                    </div>
                                    <div style={{ flex: 1 }}>
                                      <IonRow className="justify-content-start">
                                        <IonCard key={message} style={{ padding: '10px 25px 5px 25px', borderRadius: '35px 35px 35px 0px' }}>
                                          <p style={{ fontSize: '16px', margin: 0, marginTop: "0.2rem", color: '#000000' }}>{message?.text}</p>
                                          <p style={{ fontSize: '14px', margin: 0, color: '#00000060' }}>{formatDateMessage(message?.date)}</p>
                                        </IonCard>
                                      </IonRow>
                                    </div>
                                  </>
                              }
                            </div>
                          )
                          : <Loading isLoading={!chat} />
                        : <p >Selecione um contato para abrir a conversa..</p>
                    }

                    <div ref={messagesEnd} />

                  </div>

                  {
                    userReceiver ?
                      <IonFooter style={{ position: 'fixed', bottom: 0, right: 0, paddingLeft: '0.8rem', zIndex: 2 }}>
                        <IonCard style={{ width: "100%", height: '75%', backgroundColor: '#EAEAEA', borderRadius: '14px', padding: '15px 15px' }}>
                          <form ref={chatForm} onSubmit={handleSubmit}>
                            <IonItem lines="none" style={{ borderRadius: '14px' }}>
                              <IonInput style={{ fontSize: '16px', color: '#969696' }} required name="message" placeholder="Escribe aquí tu mensaje.."></IonInput>
                              <IonButton type="submit" style={{ borderRadius: '50%' }}>
                                <IonIcon slot="end" icon={send} />
                              </IonButton>
                            </IonItem>
                          </form>
                        </IonCard>
                      </IonFooter>
                      : <></>
                  }
                </IonCardContent>
              </IonCard>
            </div>
          </div>
        </main>
      </IonContent>
    </div>
  )

  else return (
    <IonPage className='chat-mobile-page'>
      {/* <IonHeader> */}
      <MobileHeader />
      {/* </IonHeader> */}
      <IonContent className="ion-padding">

        <IonCard style={{ width: "100%", height: "100%", backgroundColor: '#F9FAFC', overflow: 'scroll' }} className="ion-text-center">
          <IonCardContent>

            <p className='ion-text-left' style={{ fontSize: '21px', color: '#000000', fontWeight: 500, marginBottom: '16px' }}>Mensajes</p>

            <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#F5F5F5', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <input style={{ border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText}
                onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar por nombre.." />
              <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
            </div>

            {/* <div style={{ overflow: 'scroll' }}> */}
            {messagesToShow?.length > 0
              ? <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '16px' }} >
                {messagesToShow?.map((message: any, index: any) => (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '1rem 0' }} onClick={() => router.push(`/chat/${message.freelancer._id + message.client._id}`)} key={index}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                      <IonAvatar style={{ marginRight: '16px', width: '60px', height: '60px' }}>
                        <img src={
                          user.type == 'freelancer' ?
                            message?.client?.image :
                            message?.freelancer?.image
                        } />
                      </IonAvatar>
                      <div>
                        <p className='ion-text-left' style={{ fontSize: '16px', color: '#2D2D2D', fontWeight: 600 }}>{user?.type == 'freelancer' ? message?.client?.name : message?.freelancer?.name}</p>
                        {/* <p className='ion-text-left' style={{ fontSize: '14px', color: '#2D2D2D', fontWeight: 400 }}>{message?.messages[message?.messages?.length - 1]?.text?.length < 140 ? message?.messages[message?.messages?.length - 1]?.text : message?.messages?.[message?.messages?.length - 1]?.text?.slice(0, 20) + '...'}</p> */}
                        <p className='ion-text-left' style={{ fontSize: '14px', color: '#2D2D2D', fontWeight: 400 }}>{message?.messages?.[message?.messages?.length - 1]?.text?.length > 0 ? message?.messages[message?.messages?.length - 1]?.text?.length < 140 ? message?.messages[message?.messages?.length - 1]?.text : message?.messages?.[message?.messages?.length - 1]?.text?.slice(0, 20) + '...' : ''}</p>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                      <p style={{ fontSize: '15px', color: '#969696' }}>{formatDateMessage(message?.messages?.[message?.messages?.length - 1]?.date)}</p>
                    </div>
                  </div>
                ))}
              </div>

              : <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 className="ion-text-center ion-padding ion-align-self-center">Ningúna menssagem acá...</h4>
              </div>
            }
            {/* </div> */}

          </IonCardContent>
        </IonCard>

        {/* <IonRow>
               <IonCol>

                  {user && user.messages?.length > 0
                     ? <IonList lines="full">
                        {user.messages?.map((message: any, index: any) => (
                           <IonItem onClick={() => handleMessageClick(message)} key={index} detail>
                              <IonAvatar style={{ marginRight: '.8rem' }}>
                                 <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }}
                                    src={
                                       user.type == 'freelancer' ?
                                          message?.client?.image :
                                          message?.freelancer?.image
                                    } />
                              </IonAvatar>
                              <IonText style={{ fontSize: '1rem' }} className="ion-text-wrap">
                                 <div className="ion-padding-vertical">
                                    <strong>
                                       {
                                          user.type == 'freelancer' ?
                                             message?.client?.name :
                                             message?.freelancer?.name
                                       }
                                    </strong><br />
                                    {message?.messages[message?.messages?.length - 1]?.text}
                                 </div>
                              </IonText>
                           </IonItem>
                        ))}
                     </IonList>

                     : <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningúna menssagem acá...</h4>
                     </div>
                  }

               </IonCol>
            </IonRow> */}

      </IonContent>

      <IonFooter>
        <Tabs currentPath={props.match.url} />
      </IonFooter>

    </IonPage>
  )
}
