import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

async function setItem(key: any, value: any) {
  // localStorage.setItem('chave', 12345)
  await Storage.set({ key: key, value: value });
}

async function getItem(key: any) {
  //'key-name',
  const { value } = await Storage.get({ key: key });
  return value;
}

async function removeItem(key: any) {
  //key: 'key-name',
  await Storage.remove({ key: key });
}

async function keys() {
  const { keys } = await Storage.keys();
  return keys
}

async function clear() {
  await Storage.clear();
}

export default { setItem, getItem, removeItem, keys, clear }