import { getPlatforms, IonAvatar, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonText, IonThumbnail, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { analytics, star, starHalf, starOutline } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';
// import BackButton from '../../components/BackButton';
import Tabs from '../../../components/Tabs';
import Loading from '../../../components/loading';
import api from '../../../services/api';
import auth from '../../../services/auth';
import Header from '../../../components/desktop/Header';
import ReactStars from 'react-stars'

import './styles.scss'
import formatDate from '../../../utils/formatDate';
import MobileHeader from '../../../components/MobileHeader';

const Freelancers: React.FC = (props: any) => {

  const router = useHistory();

  const [freelancers, setFreelancers] = React.useState<any>([])
  const [isLoading, setIsLoading] = React.useState<any>(true)
  const [searchText, setSearchText] = React.useState('');
  const [workPreferenceSelect, setWorkPreferenceSelect] = React.useState<any>("any");
  const [jobTypeSelect, setJobTypeSelect] = React.useState<any>("any");

  const platform: any = getPlatforms();

  // Filtro 1 - Work Preference
  let freelancersToShow = freelancers
    ? freelancers.filter((freelancer: any) => {
      if (workPreferenceSelect === 'any') return freelancer.freelancer.workPreference.remote || freelancer.freelancer.workPreference.presential
      else if (workPreferenceSelect === 'remote') return freelancer.freelancer.workPreference.remote
      else if (workPreferenceSelect === 'presential') return freelancer.freelancer.workPreference.presential
    })
    : null

  // Filtro 2 - Search Bar
  freelancersToShow = searchText.length > 0
    ? freelancers.filter((freelancer: any) => (
      freelancer.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
      freelancer.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
    ))
    : freelancersToShow

  React.useEffect(() => {
    async function fetchData() {
      try {
        const config = await auth.getHeaderConfig();
        const res = await api.get(`/user?type=freelancer`, config)
        setFreelancers(res.data)
        setIsLoading(false)
      } catch (error) {
        console.log("Erro no FreelancerIndex:", error?.response?.message);
        return;
      }
      finally {
        setIsLoading(false)
      }
    } fetchData()
  }, [router.location])

  function handleName(name: any) {
    const a = name.split(' ');
    const b = a.shift();
    return b + " " + a.map((word: any) => word[0] + '.').join(' ')
  }

  if (isLoading) return <Loading isLoading={isLoading} />

  else if (platform == 'desktop') return (

    <>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          <div style={{ display: 'flex' }} className="mt-1">
            <div style={{ width: '370px', minWidth: '370px', backgroundColor: 'transparent', borderRadius: '10px', padding: '1rem' }}>
              <IonRow className="ion-no-padding">
                <IonCard style={{ width: "100%", backgroundColor: '#3B3FB6', border: '1px solid #E3E3E3' }} className="ion-margin-none ion-padding-bottom">
                  <IonCardContent>
                    <p style={{ marginBottom: '1.5rem', color: '#FFFFFF', fontSize: '24px' }}>Freelancers</p>
                    {/* <p style={{ marginTop: '1.5rem', color: '#FFFFFF' }}>Tipos de servicios</p>
                    <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: '1rem' }}>
                      <select value={jobTypeSelect} onChange={(e: any) => setJobTypeSelect(e.target.value!)} style={{ height: '25px', flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }}>
                        <option value="any">Todos</option>
                        <option value="auditoria">Auditoría</option>
                        <option value="analise">Análisis</option>
                        <option value="pericia">Pericia</option>
                        <option value="fiscalizacao">Inspección</option>
                        <option value="consultoria">Consultoría</option>
                      </select>
                    </div> */}
                    <p style={{ marginTop: '1.5rem', color: '#FFFFFF' }}>Forma de trabajo</p>
                    <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: '1rem' }}>
                      <select value={workPreferenceSelect} onChange={(e: any) => setWorkPreferenceSelect(e.target.value!)} style={{ height: '25px', flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }}>
                        <option value="any">Todos</option>
                        <option value="presential">Presencial</option>
                        <option value="remote">Remoto</option>
                      </select>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonRow>
            </div>

            {/*  */}

            <div style={{ width: '100%', backgroundColor: 'trasparent', borderRadius: '10px', padding: '1rem', marginBottom: '4rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                <p className='ion-margin-vertical' style={{ fontSize: '15px', color: '#3B3FB6' }}>Encontrado {freelancersToShow?.length} freelancer(s)</p>
                <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                  <input style={{ flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar servicio por nombre o descripción.." />
                  <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
                </div>
              </div>
              {freelancersToShow?.length === 0
                ? <></>
                : freelancersToShow?.map((freelancer: any, index: any) => (
                  <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-margin-top ion-no-padding">
                    <IonCardContent style={{ padding: '2rem' }}>
                      <div className='ion-no-margin ion-no-padding' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem' }}>
                        <div className='ion-no-margin ion-no-padding' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <IonAvatar style={{ border: "2px solid #7A82EA", height: "5.5rem", width: "5.5rem" }}>
                              <img src={freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                            </IonAvatar>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <ReactStars
                              count={5}
                              value={freelancer?.freelancer?.rating?.value}
                              size={22}
                              edit={false}
                              color2={'#ffd700'}
                            />
                          </div>
                        </div>
                        <div className='ion-no-margin' style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: '1rem' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flex: 1 }}>
                              <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0" }}>{freelancer.name}</p>
                              <div className="d-flex align-items-center">
                                <IonIcon style={{ fontSize: "16px", padding: 0 }} icon="/assets/email.svg" />
                                <span className="d-block ml-2 p-0">{freelancer?.email}</span>
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                              <p style={{ fontSize: '16px', color: '#463E3E', margin: '16px 0' }}>{freelancer.freelancer?.about?.length < 140 ? freelancer.freelancer?.about : freelancer.freelancer?.about?.slice(0, 140) + '...'}</p>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                    <img height='15px' src='/assets/registered_green.svg' />
                                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Registrado en:</strong></p>
                                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{freelancer.createdAt ? formatDate(freelancer.createdAt) : "No informado"}</p>
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                    <img height='15px' src='/assets/bandeja-de-entrada.svg' />
                                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Proyectos terminados:</strong></p>
                                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{freelancer.freelancer?.jobs?.length > 0 ? freelancer.freelancer.jobs.filter((job: any) => job.status === 'finished').length : 0}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="w-100 d-flex justify-content-start" style={{ marginTop: '1rem' }}>
                                {freelancer?.freelancer?.workPreference?.remote ?
                                  <div style={{
                                    fontSize: "14px", fontWeight: 300, backgroundColor: "#DAE6EB",
                                    borderRadius: "1rem", margin: "0 0.2rem", padding: "0.4rem 1.3rem", color: "black", textAlign: "center"
                                  }}>Remoto</div>
                                  : <></>}
                                {freelancer?.freelancer?.workPreference?.presential ?
                                  <div style={{
                                    fontSize: "14px", fontWeight: 300, backgroundColor: "#DAE6EB",
                                    borderRadius: "1rem", margin: "0 0.2rem", padding: "0.4rem 1.3rem", color: "black", textAlign: "center"
                                  }}>Presencial</div>
                                  : <></>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='ion-no-margin ion-no-padding' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                          <button onClick={(e) => { e.stopPropagation(); router.push(`/client/freelancers/${freelancer._id}`) }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Ver detalles</p>
                          </button>
                          <button onClick={(e) => { e.stopPropagation(); router.push('/client/new-job', { customJob: true, freelancer: freelancer }); }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Contratar</p>
                          </button>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                ))
              }
            </div>
          </div>
        </main>

      </IonContent>
    </>
  )

  else return (
    <IonPage>
      <IonHeader >
        <MobileHeader title='Freelancers' backButton={false} />
        {/* <IonToolbar color="primary">
          <IonSegment onIonChange={(e: any) => setWorkTypeSelect(e.detail.value)} value={workTypeSelect}>
            <IonSegmentButton value='all'>Todos</IonSegmentButton>
            <IonSegmentButton value='presential'>Presencial</IonSegmentButton>
            <IonSegmentButton value='remote'>Remoto</IonSegmentButton>
          </IonSegment>
        </IonToolbar> */}

        {/* <IonToolbar style={{ padding: '0' }}>
          <IonSearchbar style={{ padding: '0' }} value={searchText} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Buscar autónomos por nombre o email.." />
        </IonToolbar> */}

      </IonHeader>
      <IonContent color="medium" className="ion-padding">

        <div style={{ borderRadius: '6px', padding: '10px 20px ', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <input style={{ border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText}
            onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar freelancers..." />
          <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
        </div>

        {/* <IonRow className="my-3">
          <IonCol >
            <IonText color="dark">Autónomos encontrados: {freelancersFilter.length}</IonText>
          </IonCol>
        </IonRow> */}

        <p className='ion-margin-vertical' style={{ fontSize: '15px', color: '#3B3FB6' }}>Freelancers encontrados: {freelancersToShow?.length}</p>

        {
          freelancersToShow.length === 0
            ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningún freelancer acá...</h4>
              <div>
                <p>Si tiene problemas para mostrar los autónomos, verifique lo siguiente:</p>
                <ul>
                  <li>Filtros aplicados</li>
                  <li>Conexión a Internet</li>
                  <li>Conexión al servidor Contadoor</li>
                </ul>
              </div>
            </div>
            : freelancersToShow.map((freelancer: any, index: any) => (
              <IonCard className="mb-3 pb-3" key={index} >
                <IonRow>
                  <IonCol>
                    <IonCardContent className="ion-padding">
                      <IonRow className='mb-1'>

                        <IonCol >
                          <div style={{ fontSize: '1.1rem', marginRight: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: "100%", display: 'flex', alignItems: 'start', justifyContent: 'space-between', flexDirection: 'column' }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <IonAvatar style={{ border: "2px solid #00F1A9", marginRight: '10px', height: "4rem", width: "4rem" }}>
                                  <img src={freelancer.image || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFvTcn5FrkzwRL1mBlRgMpNE7mIelcbKJiWg&usqp=CAU"} />
                                </IonAvatar>
                                <div>

                                  <div>
                                    <p style={{ margin: 0, fontSize: '18px' }}>{handleName(freelancer.name)}</p>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <ReactStars
                                        count={5}
                                        value={freelancer?.freelancer?.rating?.value}
                                        size={16}
                                        edit={false}
                                        color2={'#ffd700'}
                                      />
                                    </div>
                                  </div>

                                  <div className="d-flex align-items-center">
                                    <IonIcon style={{ fontSize: "1rem", padding: 0 }} icon="/assets/email.svg" />
                                    <span className="d-block ml-2 p-0">{freelancer?.email}</span>
                                  </div>

                                </div>
                              </div>
                            </div>


                            {freelancer.freelancer?.about ? <div className="my-3">
                              <p style={{ fontSize: "1rem" }}>{freelancer?.freelancer?.about || ""} </p>
                            </div>
                              : <></>
                            }
                            <IonRow className="w-100 mt-1">
                              <IonCol size="6" className="text-center d-flex align-items-center flex-column">
                                <IonIcon className="d-block " style={{ fontSize: "2rem", backgroundColor: "#fff" }} icon="/assets/laptop_green.svg" />
                                <strong>Registrado en:</strong>
                                <p>10 de enero de 2021</p>
                              </IonCol>
                              <IonCol size="6" className="text-center d-flex align-items-center flex-column">
                                <IonIcon className="d-block " style={{ fontSize: "2rem", backgroundColor: "#fff" }} icon="/assets/projects.svg" />
                                <strong>Registrado en:</strong>
                                <p>10 de enero de 2021</p>
                              </IonCol>
                            </IonRow>


                            <div className="w-100 mt-4 d-flex justify-content-center" >


                              {freelancer?.freelancer?.workPreference?.remote ?
                                <div style={{
                                  fontSize: "0.85rem", fontWeight: 300, backgroundColor: "#DAE6EB",
                                  borderRadius: "1rem", margin: "0 0.2rem", padding: "0.2rem 1rem", color: "black", textAlign: "center"
                                }} >Remoto</div>
                                : <></>}

                              {freelancer?.freelancer?.workPreference?.presential ?
                                <div style={{
                                  fontSize: "0.85rem", fontWeight: 300, backgroundColor: "#DAE6EB",
                                  borderRadius: "1rem", margin: "0 0.2rem", padding: "0.2rem 1rem", color: "black", textAlign: "center"
                                }} >
                                  Presencial
                                </div>
                                : <></>
                              }
                            </div>
                          </div>
                        </IonCol>
                      </IonRow>

                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem', marginTop: '2rem' }}>
                        <button onClick={() => router.push(`/client/freelancers/${freelancer._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Ver detalles</p>
                        </button>
                        <button onClick={() => router.push('/client/new-job', { customJob: true, freelancer: freelancer })} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#00D3B3', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Contratar</p>
                        </button>
                      </div>

                    </IonCardContent>
                  </IonCol>
                </IonRow>

              </IonCard>
            ))
        }
      </IonContent >
      <IonFooter>
        <Tabs currentPath={props.match.url} />
      </IonFooter>
    </IonPage >
  )
};

export default Freelancers;