import { IonAvatar, IonBadge, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonFooter, IonHeader, IonItem, IonPage, IonRow, IonSegment, IonSegmentButton, IonRefresher, IonPopover, IonRefresherContent, IonText, IonThumbnail, IonTitle, IonToolbar, useIonViewDidEnter, IonButton, IonIcon, IonGrid, IonChip, IonLabel, IonSelect, IonSelectOption, IonSearchbar, IonCheckbox } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import React, { } from 'react';
import { useHistory } from 'react-router-dom'
import { getPlatforms } from '@ionic/react';

// Components
import Loading from '../../../components/loading';
import BackButton from '../../../components/BackButton';
import Tabs from '../../../components/Tabs'

// Services
import api from '../../../services/api'
import { customBadges } from '../../../utils/customBadges';

// Context
import { UserContext } from '../../../context/UserContext';
import Refresher from '../../../components/Refresher/Index'

// Util
import formatDate from '../../../utils/formatDate';
import { addDays } from 'date-fns'

import './index.scss'
import Header from '../../../components/desktop/Header';
import MobileHeader from '../../../components/MobileHeader';
import { chevronDown } from 'ionicons/icons';


const AvJob: React.FC = (props: any) => {

  const platform: any = getPlatforms();
  const router = useHistory();

  const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext);

  const [jobSegment, setJobSegment] = React.useState<any>("all");
  const [jobSubSegment, setJobSubSegment] = React.useState<any>("open");

  const [workPreferenceSelect, setWorkPreferenceSelect] = React.useState<any>("any");
  const [jobTypeSelect, setJobTypeSelect] = React.useState<any>("any");

  const [myJobsOnly, setMyJobsOnly] = React.useState<any>(false);

  const [searchText, setSearchText] = React.useState('');

  // let jobsToShow = user ? myJobsOnly ? user.freelancer?.jobs.filter((job: any) => job.acceptedProposal || job.status === 'private') : platform.includes('mobile') ? user.freelancer?.jobs.filter((job: any) => job.status === 'open') : user.freelancer?.jobs : null
  let jobsToShow = user?.freelancer?.jobs

  jobsToShow = user ? jobSegment === 'all' ? jobsToShow : jobsToShow?.filter((job: any) => {

    let status = job.status

    if (status === 'waiting-payment' || status === 'private' || status === 'freelancer-finished' || status === 'client-require-job-cancel' || status === 'moderation') status = 'pending'
    else if (status === 'working' || status === 'client-refused') status = 'working'
    else if (status === 'job-finished' || status === 'job-cancelled') status = 'finished'

    return status === jobSegment

  }) : null

  // jobsToShow = platform.includes('mobile') ? jobSubSegment === 'open' ? jobsToShow?.filter((job: any) => job.status === 'open') : jobSubSegment === 'my' ? jobsToShow?.filter((job: any) => job.acceptedProposal) : jobsToShow : jobsToShow
  jobsToShow = jobTypeSelect === 'any' ? jobsToShow : jobsToShow?.filter((job: any) => job.type === jobTypeSelect)
  jobsToShow = workPreferenceSelect === 'any' ? jobsToShow : jobsToShow?.filter((job: any) => job.workPreference === workPreferenceSelect)

  // jobsToShow = jobsToShow?.filter((job:any) => !(job.status === 'open' && job.proposed === true) )

  jobsToShow = searchText?.length > 0
    ? jobsToShow?.filter((job: any) => (
      job.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
      job.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      // job.type.toLowerCase().includes(searchText.toLowerCase())
    ))
    : jobsToShow

  if (isUserLoading || !jobsToShow) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (
    <>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          <div style={{ display: 'flex' }} className="mt-1">
            <div style={{ width: '370px', minWidth: '370px', backgroundColor: 'transparent', borderRadius: '10px', padding: '1rem' }}>
              <IonRow className="ion-no-padding">
                <IonCard style={{ width: "100%", backgroundColor: '#3B3FB6', border: '1px solid #E3E3E3' }} className="ion-margin-none ion-padding-bottom">
                  <IonCardContent>
                    <p style={{ marginBottom: '1.5rem', color: '#FFFFFF', fontSize: '24px' }}>Servicios</p>
                    <p style={{ marginTop: '1.5rem', color: '#FFFFFF' }}>Estado de servicios</p>
                    <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: '1rem' }}>
                      <select value={jobSegment} onChange={(e: any) => setJobSegment(e.target.value!)} style={{ height: '25px', flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }}>
                        <option value="all">Todos</option>
                        {!myJobsOnly ? <option value="open">Abiertos</option> : <></>}
                        <option value="pending">Pendientes</option>
                        <option value="working">Trabajando</option>
                        <option value="finished">Terminados</option>
                      </select>
                    </div>
                    <p style={{ marginTop: '1.5rem', color: '#FFFFFF' }}>Tipos de servicios</p>
                    <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: '1rem' }}>
                      <select value={jobTypeSelect} onChange={(e: any) => setJobTypeSelect(e.target.value!)} style={{ height: '25px', flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }}>
                        <option value="any">Todos</option>
                        <option value="auditoria">Auditoría</option>
                        <option value="analise">Análisis</option>
                        <option value="pericia">Pericia</option>
                        <option value="fiscalizacao">Inspección</option>
                        <option value="consultoria">Consultoría</option>
                      </select>
                    </div>
                    <p style={{ marginTop: '1.5rem', color: '#FFFFFF' }}>Forma de trabajo</p>
                    <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: '1rem' }}>
                      <select value={workPreferenceSelect} onChange={(e: any) => setWorkPreferenceSelect(e.target.value!)} style={{ height: '25px', flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }}>
                        <option value="any">Todos</option>
                        <option value="presential">Presencial</option>
                        <option value="remote">Remoto</option>
                      </select>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonRow>
            </div>

            {/*  */}

            <div style={{ width: '100%', backgroundColor: 'trasparent', borderRadius: '10px', padding: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                <p className='ion-margin-vertical' style={{ fontSize: '15px', color: '#3B3FB6' }}>Encontrado {jobsToShow?.length} servicio(s)</p>
                <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                  <input style={{ flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar servicio por nombre o descripción.." />
                  <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
                </div>
              </div>
              {jobsToShow?.length === 0
                ? <div style={{ height: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                  <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningún servicio acá...</h4>
                  <div>
                    <p>Si tiene problemas para mostrar los servicios, verifique lo siguiente:</p>
                    <ul>
                      <li>Filtros aplicados</li>
                      <li>Conexión a Internet</li>
                      <li>Conexión al servidor Contadoor</li>
                    </ul>
                  </div>
                </div>
                : jobsToShow?.map((job: any, index: any) => (
                  <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-margin-top ion-no-padding">
                    <IonCardContent style={{ padding: '2rem' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flex: 1 }}>
                          <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0" }}>{job.title}</p>
                          <IonChip className='ion-text-center' style={{
                            backgroundColor:
                              job.status === 'open' ? '#00CFB4' :
                                job.status === 'private' ? '#ffc409' :
                                  job.status === 'working' ? '#ffc409' :
                                    job.status === 'waiting-payment' ? '#ffc409' :
                                      job.status === 'freelancer-finished' ? '#2fdf75' :
                                        job.status === 'client-refused' ? '#ff4961' :
                                          job.status === 'client-require-job-cancel' ? '#ffd534' :
                                            job.status === 'moderation' ? '#ffc409' :
                                              job.status === 'job-cancelled' ? '#989aa2' :
                                                job.status === 'job-finished' ? '#989aa2' : '',
                            color: '#F9FAFC',
                            fontSize: '16px',
                            padding: '10px 30px',
                            margin: '0',
                            cursor: 'default',
                          }}>{customBadges(job, user)}</IonChip>
                        </div>
                        <p style={{ fontSize: '16px', color: '#969696', margin: '8px 0' }}>Atualizado el {formatDate(job.updatedAt)}</p>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <p style={{ fontSize: '16px', color: '#2D2D2D', margin: '20px 0' }}>Descripción: {job.description.length < 140 ? job.description : job.description.slice(0, 140) + '...'}</p>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/portfolio.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{
                                  job.type === "auditoria" ? "Auditoría" :
                                    job.type === "analise" ? "Análisis" :
                                      job.type === "pericia" ? "Pericia" :
                                        job.type === "fiscalizacao" ? "Inspección" :
                                          job.type === "consultoria" ? "Consultoría" : ''
                                }</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/Grupo 5269.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Plazo deseado:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/laptop.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button onClick={() => router.push(`/freelancer/jobs/${job._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Ver más</p>
                        </button>
                      </div>
                      <div style={{ display: 'flex', justifyContent: job.proposed ? 'space-between' : 'flex-end', alignItems: 'center', gap: '16px', marginTop: '16px' }}>
                        {job.status === 'open'
                          ? job.proposed
                            ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                              <img height='15px' src='/assets/check.svg' />
                              <p style={{ fontSize: '14px', margin: 0 }}>Propuesta enviada</p>
                            </div>
                            : <></>
                          : <></>
                        }
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                          <img src='/assets/adjunto-archivo.svg' />
                          <p style={{ fontSize: '16px', margin: 0 }}>{job.attachments?.length} archivo adjunto</p>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                ))
              }
            </div>
          </div>
        </main>

        {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}

      </IonContent>
    </>
  )

  else return (
    <IonPage>
      {/* <IonHeader> */}
      <MobileHeader />
      {/* <IonToolbar color='primary'>
          <IonSegment onIonChange={(e: any) => { setJobSubSegment(e.detail.value); setMyJobsOnly(e.detail.value === 'open' ? false : true) }} value={jobSubSegment}>
            <IonSegmentButton value='open'>Servicios Abiertos</IonSegmentButton>
            <IonSegmentButton value='my'>Mis Servicios</IonSegmentButton>
          </IonSegment>
        </IonToolbar>
        <IonToolbar style={{ padding: '0' }}>
          <IonSearchbar style={{ padding: '0' }} value={searchText} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Procura servicios por nome ou descripción.." />
        </IonToolbar> */}
      {/* <IonToolbar>
          <IonButton expand='block' onClick={() => setShowPopover(!showPopover)}>Filtrar <IonIcon icon={menuOutline} /></IonButton>
          <IonPopover
          isOpen={showPopover}
          onDidDismiss={() => setShowPopover(!showPopover)}
          >
          <IonButton color={aColor}>Todos</IonButton>
          <IonButton color={rColor}>Remoto</IonButton>
          <IonButton color={lColor}>Local</IonButton>
          </IonPopover>
        </IonToolbar> */}
      {/* </IonHeader> */}
      <IonContent color="medium" className="ion-padding">
        {/* {
          myJobsOnly ?
            <IonSegment onIonChange={(e: any) => setJobSegment(e.detail.value)} value={jobSegment}>
              <IonSegmentButton value='all'>Todos</IonSegmentButton>
              <IonSegmentButton value='pending'>Pendientes</IonSegmentButton>
              <IonSegmentButton value='working'>Trabajando</IonSegmentButton>
              <IonSegmentButton value='finished'>Terminados</IonSegmentButton>
            </IonSegment>

            : <></>
        } */}
        {/* <Refresher setUpdateContext={setUpdateUserContext} /> */}

        <p style={{ fontSize: '21px', color: '#000000', fontWeight: 500 }}>Servicios</p>

        <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <input style={{ border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar servicio.." />
          <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
        </div>

        <p className='ion-margin-vertical' style={{ fontSize: '15px', color: '#3B3FB6' }}>Encontrado {jobsToShow?.length} servicio(s)</p>


        {jobsToShow?.length === 0
          ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningún servicio acá...</h4>
            <div>
              <p>Si tiene problemas para mostrar los autónomos, verifique lo siguiente:</p>
              <ul>
                <li>Filtros aplicados</li>
                <li>Conexión a Internet</li>
                <li>Conexión al servidor Contadoor</li>
              </ul>
            </div>
          </div>
          : jobsToShow?.map((job: any, index: any) => (

            <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-text-center ion-margin-top ion-padding-vertical">
              <IonCardContent>
                <p style={{ fontSize: "16px", color: "#2D2D2D", margin: '0', fontWeight: 600 }}>{job.title}</p>
                <IonChip className='ion-text-center ion-margin-vertical' style={{
                  backgroundColor:
                    job.status === 'open' ? '#00CFB4' :
                      job.status === 'private' ? '#ffc409' :
                        job.status === 'working' ? '#ffc409' :
                          job.status === 'waiting-payment' ? '#ffc409' :
                            job.status === 'freelancer-finished' ? '#2fdf75' :
                              job.status === 'client-refused' ? '#ff4961' :
                                job.status === 'client-require-job-cancel' ? '#ffd534' :
                                  job.status === 'moderation' ? '#ffc409' :
                                    job.status === 'job-cancelled' ? '#989aa2' :
                                      job.status === 'job-finished' ? '#989aa2' : '',
                  color: '#F9FAFC',
                  fontSize: '16px',
                  padding: '10px 30px',
                  margin: '16px 0',
                }}>{customBadges(job, user)}</IonChip>

                <p style={{ fontSize: '15px', color: '#969696', marginBottom: '16px' }}>Atualizado el {formatDate(job.updatedAt)}</p>

                <p style={{ fontSize: '15px', color: '#2D2D2D', margin: '20px 0' }}>{job.description.length < 140 ? job.description : job.description.slice(0, 140) + '...'}</p>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '32px 0' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <img height='30px' src='/assets/portfolio.svg' />
                    </div>
                    <div style={{ marginTop: '12px' }}>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio</p>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{
                        job.type === "auditoria" ? "Auditoría" :
                          job.type === "analise" ? "Análisis" :
                            job.type === "pericia" ? "Pericia" :
                              job.type === "fiscalizacao" ? "Inspección" :
                                job.type === "consultoria" ? "Consultoría" : ''
                      }</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <img height='30px' src='/assets/Grupo 5269.svg' />
                    </div>
                    <div style={{ marginTop: '12px' }}>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Plazo deseado</p>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <img height='30px' src='/assets/laptop.svg' />
                    </div>
                    <div style={{ marginTop: '12px' }}>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo</p>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                    </div>
                  </div>
                </div>

                <div className='ion-margin-vertical' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                  {job.status === 'open'
                    ? job.proposed
                      ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                        <img height='15px' src='/assets/check.svg' />
                        <p style={{ fontSize: '14px', margin: 0 }}>Propuesta enviada</p>
                      </div>
                      : <></>
                    : <></>
                  }
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                    <img src='/assets/adjunto-archivo.svg' />
                    <p style={{ fontSize: '14px', margin: 0 }}>{job.attachments?.length} archivo adjunto</p>
                  </div>
                </div>

                <button onClick={() => router.push(`/freelancer/jobs/${job._id}`)} style={{ marginTop: '16px', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                  <p style={{ margin: '0', fontSize: '17px' }}>Ver más</p>
                </button>
              </IonCardContent>
            </IonCard>

            // <IonCard className='ion-no-padding' key={index} onClick={() => router.push(`/freelancer/jobs/${job._id}`)}>
            //   <IonRow>
            //     <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
            //       <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{formatDate(job.updatedAt)}</strong></p>
            //     </IonCol>
            //     <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
            //       display: 'flex',
            //       justifyContent: 'center',
            //       alignItems: 'center',
            //       backgroundColor:
            //         job.status === 'open' ? '#6DECAE40' :
            //           job.status === 'private' ? '#ffc40940' :
            //             job.status === 'working' ? '#ffc40940' :
            //               job.status === 'waiting-payment' ? '#ffc40940' :
            //                 job.status === 'freelancer-finished' ? '#2fdf7540' :
            //                   job.status === 'client-refused' ? '#ff496140' :
            //                     job.status === 'client-require-job-cancel' ? '#ffd53440' :
            //                       job.status === 'moderation' ? '#ffc40940' :
            //                         job.status === 'job-cancelled' ? '#989aa240' :
            //                           job.status === 'job-finished' ? '#989aa240' : ''
            //     }}>
            //       {customBadges(job, user)}
            //     </IonCol>
            //   </IonRow>
            //   <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

            //   <IonCardContent>
            //     <h1><strong>{job.title}</strong></h1>
            //     <p style={{ fontSize: '0.7em' }}>{
            //       job.type === "auditoria" ? "Auditoría".toUpperCase() :
            //         job.type === "analise" ? "Análisis".toUpperCase() :
            //           job.type === "pericia" ? "Pericia".toUpperCase() :
            //             job.type === "fiscalizacao" ? "Inspección".toUpperCase() :
            //               job.type === "consultoria" ? "Consultoría".toUpperCase() : ''
            //     } | {job.workPreference === 'remote' ? 'REMOTO' : 'PRESENCIAL'}</p>
            //     <p style={{ fontSize: '0.8em', marginTop: '1.1em' }} >{job.description.length < 140 ? job.description : job.description.slice(0, 140) + '...'}</p>
            //   </IonCardContent>
            //   <IonFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            //     {job.status === 'open' ? job.proposed ? <p style={{ fontSize: '0.7em' }}>Propuesta enviada.</p> : <p style={{ fontSize: '0.7em' }}>Disponible para recibir propuestas.</p> : null}
            //     {job.status === 'private' ? job.proposed ? <p style={{ fontSize: '0.7em' }}>Ya ha enviado su propuesta. En espera por la respuesta del cliente.</p> : <p style={{ fontSize: '0.7em' }}>Servicio privado enviado por un cliente. En espera por tu propuesta.</p> : null}
            //     {job.status === 'waiting-payment' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El cliente aceptó su propuesta!</span> El trabajo comienza después de la confirmación del pago.</p> : null}
            //     {job.status === 'working' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Pago confirmado!</span> El autónomo debe terminar el servicio hasta {job.acceptedProposal.createdAt ? formatDate(addDays(job.acceptedProposal.createdAt, job.acceptedProposal.deadline)) : 'a ser definido..'}</p> : null}
            //     {job.status === 'freelancer-finished' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Terminaste el servicio!</span> En espera por la confirmación del cliente.</p> : null}
            //     {job.status === 'client-refused' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>La entrega fue rechazada.</span> El cliente rechazó la finalización del servicio y está esperando una nueva finalización.</p> : null}
            //     {job.status === 'job-finished' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El servicio esta terminado!</span></p> : null}
            //     {job.status === 'job-cancelled' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El servicio ha sido cancelado!</span></p> : null}
            //     {job.status === 'moderation' ? <p style={{ fontSize: '0.7em', padding: '0 1rem' }}><span style={{ color: '#ED6060' }}>¡Servicio en proceso de disputa!</span> A la espera de la moderación del equipo de Contadoor.</p> : null}
            //     {job.status === 'client-require-job-cancel' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>Cancelación de servicio solicitada por el cliente. </span> En aguardo de su decisión.</p> : null}

            //   </IonFooter>
            // </IonCard>
          ))
        }

      </IonContent>
      <IonFooter color="dark">
        <Tabs currentPath={props.match.url} />
      </IonFooter>
    </IonPage>
  )
};
export default AvJob;