import { getPlatforms, IonAlert, IonAvatar, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonText, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import { alertCircleOutline, arrowDownCircleOutline, attachOutline, briefcaseOutline, calendarOutline, cashOutline, checkmarkOutline, chevronBack, chevronDown, createOutline, documentTextOutline, laptopOutline, trashOutline } from 'ionicons/icons';
import React from 'react';

// Services
import auth from '../../../services/auth'

// Components
import Tabs from '../../../components/Tabs';
import Loading from '../../../components/loading';
import { useHistory } from 'react-router-dom';

// Context
import { UserContext } from '../../../context/UserContext';

// Utils
import formatDate from '../../../utils/formatDate';
import BackButton from '../../../components/BackButton';
import api from '../../../services/api';
import { customBadges } from '../../../utils/customBadges';
import Header from '../../../components/desktop/Header';
import MobileHeader from '../../../components/MobileHeader';


const MyProposal: React.FC = (props: any) => {

  const router = useHistory()
  const platform: any = getPlatforms();

  const [showModal, setShowModal] = React.useState<any>(false)
  const [cancelProposalConf, setCancelProposalConf] = React.useState(false);

  const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext)

  const proposal = user ? user?.freelancer?.proposals?.find((proposal: any) => proposal._id === props.match.params.proposalID) : null

  const proposalJob = user && proposal ? user.freelancer.jobs.find((job: any) => job._id === proposal.job._id) : null

  async function handleEditProposal(e: any) {
    e.preventDefault()

    const { description, deadline, price } = e.target;

    try {
      const config = await auth.getHeaderConfig()
      await api.patch(`/proposal/${proposal._id}`, {
        description: description.value,
        deadline: deadline.value,
        price: price.value,
        status: "pending"
      }, config)

      // setIsSucessAlertOpen(true);
      setUpdateUserContext((previous: any) => !previous);
      setShowModal(false);
      router.push('/freelancer/proposals', { setPending: true })
    } catch (error) {
      console.log("Erro no handleProposal:", error?.response?.data)
      return;
    }
  }

  async function handleDeleteProposal() {
    try {
      const config = await auth.getHeaderConfig()
      await api.delete(`/proposal/${proposal._id}`, config);
      setUpdateUserContext((previous: any) => !previous);
      return router.push('/freelancer/proposals')


    } catch (error) {
      // setToastMessage("Erro en eliminar servicio, intenta de nuevo..");
      // setToast(true)
      console.log("Erro no handleDeleteJob:", error?.response?.data)
      return;
    }
  }

  if (isUserLoading || !proposal) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (

    <>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          <div style={{ display: 'flex', gap: '0.5rem', width: '100%', height: '218px', position: 'absolute', top: 0, left: 0, zIndex: 0, backgroundColor: '#7A82EA', padding: '2rem' }} >
            <div style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer' }} onClick={() => router.push('/freelancer/proposals')}>
              <IonIcon size='large' color='light' icon={chevronBack} />
              <p style={{ margin: '0', fontSize: '14px', color: '#FFFFFF', marginTop: '0.4rem' }}>Atrás</p>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1 }} className="mt-1">
            <div style={{ display: 'flex', alignItems: 'center', zIndex: 1 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', padding: '2rem 0', zIndex: 1 }}>
                <p style={{ fontSize: "24px", color: "#FFFFFF", margin: "0" }}>Propuesta para: {proposalJob?.title}</p>
                <IonChip className='ion-text-center' style={{
                  backgroundColor:
                    proposal?.status === 'accepted' ? '#6DECAE' :
                      proposal?.status === 'pending' ? '#ffc409' :
                        proposal?.status === 'declined' ? '#ff4961' : '',
                  color: '#F9FAFC',
                  fontSize: '16px',
                  padding: '10px 30px',
                  margin: '0',
                  cursor: 'default',
                }}>{proposal?.status === 'pending' ? 'Pendiente' :
                  proposal?.status === 'accepted' ? 'Aceptada' :
                    proposal?.status === 'declined' ? 'Rechazada' : ''}</IonChip>
              </div>
            </div>

            <div style={{ width: '65%' }}>
              <IonCard style={{ width: "100%", backgroundColor: '#F9FAFC', padding: '2rem' }} className="ion-margin-top">
                <IonCardContent style={{ padding: '2rem' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <p style={{ fontSize: '14px', color: '#969696', margin: '0' }}>Atualizado el {formatDate(proposal.updatedAt)}</p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <p style={{ fontSize: '16px', color: '#2D2D2D', margin: '2rem 0' }}><strong>Descripción: </strong>{proposal.description.length < 140 ? proposal.description : proposal.description.slice(0, 140) + '...'}</p>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '2rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img width='20px' src='/assets/portfolio.svg' />
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Precio:</strong></p>
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{proposal.price}usd</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img width='20px' src='/assets/Grupo 5269.svg' />
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Plazo:</strong></p>
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{proposal.deadline} dias</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img width='20px' src='/assets/laptop.svg' />
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Forma de trabajo:</strong></p>
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{proposalJob?.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1.5rem' }}>
                    {
                      proposalJob?.status === 'open' || proposalJob?.status === 'private'
                        ? <>
                          <button onClick={() => router.push(`/freelancer/jobs/${proposalJob?._id}`)} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Ver servicio</p>
                          </button>
                          <button onClick={() => setShowModal(true)} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Editar propuesta</p>
                          </button>
                          <button onClick={() => setCancelProposalConf(true)} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Cancelar propuesta</p>
                          </button>
                        </>
                        : <>
                          <button onClick={() => router.push(`/freelancer/jobs/${proposalJob?._id}`)} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Ver servicio</p>
                          </button>
                        </>
                    }
                    <button onClick={() => router.push(`/messages`, { socketId: (user._id + proposalJob.client) })} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                      <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                    </button>
                  </div>
                </IonCardContent>
              </IonCard>
            </div>
          </div>
        </main>


        <IonModal isOpen={showModal} swipeToClose={true} onDidDismiss={() => setShowModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonButton color='light' size="small" fill="clear" onClick={() => setShowModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Propuesta</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color='medium'>


            <form onSubmit={(e: any) => handleEditProposal(e)}>
              <IonCard style={{ paddingBottom: '20px', paddingTop: '5px' }} className='ion-no-padding'>
                <IonList>
                  <IonItem>
                    <IonLabel position='floating'>Descripción</IonLabel>
                    <IonTextarea
                      value={proposal.description}
                      className='ion-no-padding'
                      name='description'
                      required={true}
                      maxlength={100}
                      rows={1}
                    />
                  </IonItem>
                  <IonItem className='ion-align-items-center'>
                    <IonLabel position='floating'>Plazo</IonLabel>
                    <IonInput
                      value={proposal.deadline}
                      className='ion-no-padding'
                      name='deadline'
                      type='number'
                      inputMode="numeric"
                      required={true}
                      maxlength={3}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position='floating'>Precio</IonLabel>
                    <IonInput
                      value={proposal.price}
                      name='price'
                      type='number'
                      inputMode="numeric"
                      required={true}
                    />
                  </IonItem>
                </IonList>
              </IonCard>
              <IonButton type='submit' color='primary' className="ion-margin" expand="block">Salvar propuesta</IonButton>
            </form>
          </IonContent>
        </IonModal>
        <IonAlert
          isOpen={cancelProposalConf}
          onDidDismiss={() => setCancelProposalConf(false)}
          message='¿Seguro que quieres cancelar la propuesta atual?'
          buttons={[{ text: 'Si', handler: handleDeleteProposal }, 'No']}
        />

        {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}

      </IonContent>
    </>

    // <IonContent className="freelancer-proposal-desktop">

    //   <Header currentPath={props.match.url} />

    //   <main>
    //     <IonGrid fixed className="content-wrapper">
    //       {
    //         proposal
    //           ? <IonCard className='ion-no-padding'>
    //             <IonRow>
    //               <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
    //                 <p style={{ fontSize: '.8em', margin: '0.5rem 0' }}>Atualizado en <strong>{formatDate(proposal.updatedAt)}</strong></p>
    //               </IonCol>
    //               <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //                 backgroundColor:
    //                   proposal?.status === 'accepted' ? '#6DECAE' :
    //                     proposal?.status === 'pending' ? '#ffc409' :
    //                       proposal?.status === 'declined' ? '#ff4961' : ''
    //               }}>
    //                 {proposal?.status === 'pending' ? 'Pendiente' :
    //                   proposal?.status === 'accepted' ? 'Aceptada' :
    //                     proposal?.status === 'declined' ? 'Rechazada' : ''}
    //               </IonCol>
    //             </IonRow>
    //             <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //             <IonCardContent style={{ paddingRight: '0' }}>
    //               <IonRow>
    //                 <IonCol size='9'>
    //                   <IonRow>
    //                     <IonCol>
    //                       <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.3em' }}>
    //                         <IonIcon color='dark' icon={briefcaseOutline} />
    //                         <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Servicio</IonText>
    //                       </div>
    //                       <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{proposalJob.title}</p>

    //                     </IonCol>
    //                     <IonCol>
    //                       <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                         <IonIcon color='dark' icon={calendarOutline} />
    //                         <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Plazo</IonText>
    //                       </div>
    //                       <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{proposal.deadline} dias</p>
    //                     </IonCol>
    //                     <IonCol>
    //                       <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                         <IonIcon color='dark' icon={cashOutline} />
    //                         <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Precio</IonText>
    //                       </div>
    //                       <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{proposal.price} usd</p>
    //                     </IonCol>

    //                     <IonCol size='12'>
    //                       <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.3em' }}>
    //                         <IonIcon color='dark' icon={documentTextOutline} />
    //                         <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Descripción</IonText>
    //                       </div>
    //                       <p style={{ fontSize: '1em', marginBottom: '0.3em', marginLeft: '0.5em' }}>{proposal.description}</p>
    //                     </IonCol>

    //                   </IonRow>
    //                 </IonCol>

    //                 {proposalJob?.status === 'open' || proposalJob?.status === 'private'
    //                   ? <>
    //                     <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>

    //                       <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/freelancer/jobs/${proposalJob?._id}`)}>
    //                         Ver servicio
    //                       </IonButton>
    //                       <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => setShowModal(true)}>
    //                         Editar propuesta
    //                       </IonButton>
    //                       <IonButton color='danger' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => setCancelProposalConf(true)}>
    //                         Cancelar propuesta
    //                       </IonButton>
    //                     </IonCol>
    //                   </>
    //                   : <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                     <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/freelancer/jobs/${proposalJob?._id}`)}>
    //                       Ver servicio
    //                     </IonButton>
    //                     <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/chat/${user._id + proposalJob.client}`)}>
    //                       Abre el chat
    //                     </IonButton>
    //                   </IonCol>
    //                 }
    //               </IonRow>
    //             </IonCardContent>
    //           </IonCard>
    //           : <></>
    //       }



    //     </IonGrid>


    //     <IonModal isOpen={showModal} swipeToClose={true} onDidDismiss={() => setShowModal(false)}>
    //       <IonHeader>
    //         <IonToolbar color='primary'>
    //           <IonButton color='light' size="small" fill="clear" onClick={() => setShowModal(false)}>
    //             <IonIcon slot='start' icon={chevronDown} />
    //           </IonButton>
    //           <IonTitle>Propuesta</IonTitle>
    //         </IonToolbar>
    //       </IonHeader>
    //       <IonContent color='medium'>


    //         <form onSubmit={(e: any) => handleEditProposal(e)}>
    //           <IonCard style={{ paddingBottom: '20px', paddingTop: '5px' }} className='ion-no-padding'>
    //             <IonList>
    //               <IonItem>
    //                 <IonLabel position='floating'>Descripción</IonLabel>
    //                 <IonTextarea
    //                   value={proposal.description}
    //                   className='ion-no-padding'
    //                   name='description'
    //                   required={true}
    //                   maxlength={100}
    //                   rows={1}
    //                 />
    //               </IonItem>
    //               <IonItem className='ion-align-items-center'>
    //                 <IonLabel position='floating'>Plazo</IonLabel>
    //                 <IonInput
    //                   value={proposal.deadline}
    //                   className='ion-no-padding'
    //                   name='deadline'
    //                   type='number'
    //                   inputMode="numeric"
    //                   required={true}
    //                   maxlength={3}
    //                 />
    //               </IonItem>
    //               <IonItem>
    //                 <IonLabel position='floating'>Precio</IonLabel>
    //                 <IonInput
    //                   value={proposal.price}
    //                   name='price'
    //                   type='number'
    //                   inputMode="numeric"
    //                   required={true}
    //                 />
    //               </IonItem>
    //             </IonList>
    //           </IonCard>
    //           <IonButton type='submit' color='primary' className="ion-margin" expand="block">Salvar propuesta</IonButton>
    //         </form>
    //       </IonContent>
    //     </IonModal>
    //   </main>
    //   <IonAlert
    //     isOpen={cancelProposalConf}
    //     onDidDismiss={() => setCancelProposalConf(false)}
    //     message='¿Seguro que quieres cancelar la propuesta atual?'
    //     buttons={[{ text: 'Si', handler: handleDeleteProposal }, 'No']}
    //   />
    // </IonContent >
  )

  else return (


    <IonPage>
      <IonHeader>
        <MobileHeader title='Servicios disponibles' backButton={true} />
      </IonHeader>
      <IonContent color='medium' >

        <div className="d-flex justify-content-center" style={{ height: '14rem', zIndex: 0, backgroundColor: '#7A82EA', padding: '2rem 0' }} >
          <div style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer' }} >

            <div style={{ display: 'flex', width: '100%', position: 'absolute', top: 0, left: 0, zIndex: 0, backgroundColor: '#7A82EA', padding: '1rem' }} >
              <div style={{ display: 'flex', gap: '0.2rem', cursor: 'pointer', zIndex: 2 }}
                // onClick={() => router.push('/freelancer/proposals')}
                onClick={() => router.goBack()}
              >
                <IonIcon color='light' icon={chevronBack} />
                <p style={{ margin: '0', fontSize: '12px', color: '#FFFFFF' }}>Volver</p>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center" style={{ zIndex: 1 }}>
              <div
                className="d-flex flex-column justify-content-center align-items-center py-3"
                style={{ zIndex: 1 }}>

                <div className="d-flex algin-items-center justify-content-center">
                  <p className="mb-2 ml-2" style={{ fontSize: "1.2rem", textAlign: "center", color: "#FFFFFF", margin: "0" }}>Propuesta para: {proposalJob?.title}</p>
                </div>

                <IonChip className='ion-text-center' style={{
                  backgroundColor:
                    proposal?.status === 'accepted' ? '#6DECAE' :
                      proposal?.status === 'pending' ? '#ffc409' :
                        proposal?.status === 'declined' ? '#ff4961' : '',
                  color: '#F9FAFC',
                  fontSize: '16px',
                  padding: '10px 30px',
                  margin: '0',
                  cursor: 'default',
                }}>{proposal?.status === 'pending' ? 'Pendiente' :
                  proposal?.status === 'accepted' ? 'Aceptada' :
                    proposal?.status === 'declined' ? 'Rechazada' : ''}</IonChip>
              </div>
            </div>




          </div>
        </div>



        <IonCard className="ion-margin" style={{ marginTop: "-3rem" }}>
          <IonCardContent style={{ padding: '2rem' }}>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem', marginBottom: '1rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                <p style={{ fontSize: '14px', color: "#8D8A8A" }}>Atualizado el {proposal?.updatedAt ? formatDate(proposal?.updatedAt) : ''}</p>
              </div>
            </div>

            {/* <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
              <IonAvatar style={{ width: '50px', height: '50px' }}>
                <img src={user?.image || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFvTcn5FrkzwRL1mBlRgMpNE7mIelcbKJiWg&usqp=CAU"} />
              </IonAvatar>
              <p style={{ margin: 0, fontSize: '18px' }}>{user?.name}</p>
            </div> */}

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginTop: '1.5rem', gap: '1rem', width: '100%' }}>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '2rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                  <img width='20px' src='/assets/portfolio.svg' />
                  <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Precio:</strong></p>
                  <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>${proposal?.price}</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                  <img width='20px' src='/assets/Grupo 5269.svg' />
                  <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Plazo:</strong></p>
                  <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{proposal?.deadline} dias</p>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                  <img width='20px' src='/assets/laptop.svg' />
                  <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Descripción:</strong></p>
                  <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{proposal?.description.length < 50 ? proposal?.description : proposal?.description.slice(0, 50) + '...'}</p>
                </div>
              </div>

              <div style={{ width: '100%', margin: '1rem auto' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1.5rem' }}>
                  {
                    proposalJob?.status === 'open' || proposalJob?.status === 'private'
                      ? <>
                        <button onClick={() => router.push(`/freelancer/jobs/${proposalJob?._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Ver servicio</p>
                        </button>
                        <button onClick={() => setShowModal(true)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Editar propuesta</p>
                        </button>
                        <button onClick={() => setCancelProposalConf(true)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Cancelar propuesta</p>
                        </button>
                      </>
                      : <>
                        <button onClick={() => router.push(`/freelancer/jobs/${proposalJob?._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Ver servicio</p>
                        </button>
                      </>
                  }
                  <button onClick={() => router.push(`/messages`, { socketId: (user._id + proposalJob.client) })} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                    <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                  </button>
                </div>
              </div>
            </div>
          </IonCardContent>
        </IonCard>
      </IonContent>

      <IonModal isOpen={showModal} swipeToClose={true} onDidDismiss={() => setShowModal(false)}>
        <IonHeader>
          <IonToolbar color='primary'>

            <IonButton color='light' size="small" fill="clear" onClick={() => setShowModal(false)}>
              <IonIcon slot='start' icon={chevronDown} />
            </IonButton>
            <IonTitle>Edición de la propuesta</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent color='medium'>


          <form onSubmit={(e: any) => handleEditProposal(e)}>
            <IonCard className='ion-no-padding'>
              <IonList>
                <IonItem>
                  <IonLabel position='floating'>Descripción</IonLabel>
                  <IonTextarea
                    value={proposal?.description}
                    className='ion-no-padding'
                    name='description'
                    required={true}
                    maxlength={100}

                    rows={1}
                  />
                </IonItem>
                <IonItem className='ion-align-items-center'>
                  <IonLabel position='floating'>Plazo en días</IonLabel>
                  <IonInput
                    value={proposal?.deadline}
                    className='ion-no-padding'
                    name='deadline'
                    type='number'
                    inputMode="numeric"
                    required={true}
                    maxlength={3}
                  />
                </IonItem>
                <IonItem>
                  <IonLabel position='floating'>Precio</IonLabel>
                  <IonInput
                    value={proposal?.price}
                    name='price'
                    type='number'
                    inputMode="numeric"
                    required={true}
                  />
                </IonItem>
              </IonList>
              <div className="d-flex justify-content-center align-items-center mx-3 my-3">
                <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Salvar propuesta</button>
                <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setShowModal(false)}>Cancelar</button>
              </div>
            </IonCard>
          </form>
        </IonContent>
      </IonModal>

      <IonAlert
        isOpen={cancelProposalConf}
        onDidDismiss={() => setCancelProposalConf(false)}
        message='¿Seguro que quieres cancelar la propuesta atual?'
        buttons={[{ text: 'Si', handler: handleDeleteProposal }, 'No']}
      />

      <IonFooter color="dark">
        <Tabs currentPath={props.match.url} />
      </IonFooter>

    </IonPage>



    // <IonPage>
    //   <IonHeader>
    //     <MobileHeader title='Detalhes da propuesta' backButton={true} />
    //   </IonHeader>
    //   <IonContent color='medium'>
    //     {
    //       proposal
    //         ? <>
    //           <IonCard className='ion-no-padding'>
    //             <IonRow style={{ display: 'flex', justifyContent: (proposalJob?.status === 'open' || proposalJob?.status === 'private' ? 'space-between' : 'center') }}>
    //               {proposalJob?.status === 'open' || proposalJob?.status === 'private' ? <IonButton size='small' fill='clear' onClick={() => setShowModal(true)}><IonIcon icon={createOutline} /></IonButton> : <></>}
    //               <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{formatDate(proposal.updatedAt)}</strong></p>
    //               {proposalJob?.status === 'open' || proposalJob?.status === 'private' ? <IonButton size='small' fill='clear' color='danger' onClick={() => setCancelProposalConf(true)}><IonIcon icon={trashOutline} /></IonButton> : <></>}
    //             </IonRow>
    //             <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //             <IonCardContent>
    //               <IonRow>
    //                 <IonCol>
    //                   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.3em' }}>
    //                     <IonIcon color='dark' icon={documentTextOutline} />
    //                     <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Descripción</IonText>
    //                   </div>
    //                   <p style={{ fontSize: '1em', marginBottom: '0.3em', marginLeft: '0.5em' }}>{proposal.description}</p>
    //                 </IonCol>
    //               </IonRow>
    //               <IonRow style={{ display: 'flex', justifyContent: 'space-between' }}>
    //                 <IonCol>
    //                   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                     <IonIcon color='dark' icon={calendarOutline} />
    //                     <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Plazo</IonText>
    //                   </div>
    //                   <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{proposal.deadline} dias</p>
    //                 </IonCol>
    //                 <IonCol>
    //                   <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                     <IonIcon color='dark' icon={cashOutline} />
    //                     <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Precio</IonText>
    //                   </div>
    //                   <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{proposal.price} usd</p>
    //                 </IonCol>
    //               </IonRow>
    //             </IonCardContent>
    //           </IonCard>

    //           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>

    //             <IonIcon className="ion-text-center ion-padding-horizontal" icon={chevronDown} size='1rem' />
    //             <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
    //           </div>
    //           <IonCard className='ion-no-padding'>
    //             <IonRow>
    //               <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
    //                 <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{formatDate(proposalJob.updatedAt)}</strong></p>
    //               </IonCol>
    //               <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
    //                 display: 'flex',
    //                 justifyContent: 'center',
    //                 alignItems: 'center',
    //                 backgroundColor:
    //                   proposalJob?.status === 'open' ? '#6DECAE' :
    //                     proposalJob?.status === 'private' ? '#ffc409' :
    //                       proposalJob?.status === 'working' ? '#ffc409' :
    //                         proposalJob?.status === 'waiting-payment' ? '#ffc409' :
    //                           proposalJob?.status === 'freelancer-finished' ? '#2fdf75' :
    //                             proposalJob?.status === 'client-refused' ? '#ff4961' :
    //                               proposalJob?.status === 'client-require-job-cancel' ? '#ffd534' :
    //                                 proposalJob?.status === 'moderation' ? '#ffc409' :
    //                                   proposalJob?.status === 'job-cancelled' ? '#989aa2' :
    //                                     proposalJob?.status === 'job-finished' ? '#989aa2' : ''
    //               }}>
    //                 {customBadges(proposalJob, user)}
    //               </IonCol>
    //             </IonRow>
    //             <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //             <IonCardContent>
    //               <h1><strong>{proposalJob.title}</strong></h1>
    //               <p style={{ fontSize: '0.7em' }}>{proposalJob.type.toUpperCase()} | REMOTO</p>
    //               <p style={{ fontSize: '0.8em', marginTop: '1.1em' }}>{proposalJob.description}</p>
    //               <IonButton color='dark' size='small' expand='block' className='ion-margin-top' onClick={() => router.push(`/freelancer/jobs/${proposalJob?._id}`)}>Ver detalles del servicio</IonButton>
    //             </IonCardContent>
    //           </IonCard>

    //         </>
    //         : <></>
    //     }
    // </IonContent>
    //   <IonFooter>
    //     <Tabs currentPath={props.match.url} />
    //   </IonFooter>

    // <IonModal isOpen={showModal} swipeToClose={true} onDidDismiss={() => setShowModal(false)}>
    //   <IonHeader>
    //     <IonToolbar color='primary'>

    //       <IonButton color='light' size="small" fill="clear" onClick={() => setShowModal(false)}>
    //         <IonIcon slot='start' icon={chevronDown} />
    //       </IonButton>
    //       <IonTitle>Edición de la propuesta</IonTitle>
    //     </IonToolbar>
    //   </IonHeader>
    //   <IonContent color='medium'>


    //     <form onSubmit={(e: any) => handleEditProposal(e)}>
    //       <IonCard className='ion-no-padding'>
    //         <IonList>
    //           <IonItem>
    //             <IonLabel position='floating'>Descripción</IonLabel>
    //             <IonTextarea
    //               value={proposal?.description}
    //               className='ion-no-padding'
    //               name='description'
    //               required={true}
    //               maxlength={100}

    //               rows={1}
    //             />
    //           </IonItem>
    //           <IonItem className='ion-align-items-center'>
    //             <IonLabel position='floating'>Plazo en días</IonLabel>
    //             <IonInput
    //               value={proposal?.deadline}
    //               className='ion-no-padding'
    //               name='deadline'
    //               type='number'
    //               inputMode="numeric"
    //               required={true}
    //               maxlength={3}
    //             />
    //           </IonItem>
    //           <IonItem>
    //             <IonLabel position='floating'>Precio</IonLabel>
    //             <IonInput
    //               value={proposal?.price}
    //               name='price'
    //               type='number'
    //               inputMode="numeric"
    //               required={true}
    //             />
    //           </IonItem>
    //         </IonList>
    //         <div className="d-flex justify-content-center align-items-center mx-3 my-3">
    //           <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Salvar propuesta</button>
    //           <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setShowModal(false)}>Cancelar</button>
    //         </div>
    //       </IonCard>
    //     </form>
    //   </IonContent>
    // </IonModal>

    // <IonAlert
    //   isOpen={cancelProposalConf}
    //   onDidDismiss={() => setCancelProposalConf(false)}
    //   message='¿Seguro que quieres cancelar la propuesta atual?'
    //   buttons={[{ text: 'Si', handler: handleDeleteProposal }, 'No']}
    // />

    // </IonPage>
  )
};
export default MyProposal;