import React from "react";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonImg, IonLabel, IonModal, IonPage, IonRow, IonText, IonTextarea } from '@ionic/react';
import { useHistory } from 'react-router-dom';

// Services
import api from "../../../services/api";
import auth from '../../../services/auth'
// import { context } from "../../../services/context";

// Context
import { UserContext } from '../../../context/UserContext';

//Components
import Toast from '../../../components/Toast';

// Styles
import './index.css'
import Loading from "../../../components/loading";
import SucessAlert from "../../../components/Alert/SucessAlert";
import BackButton from "../../../components/BackButton";

const Index: React.FC = (props: any) => {

    const router = useHistory();

    const [isSucessAlertOpen, setIsSucessAlertOpen] = React.useState(false);

    const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext);

    const job = user ? user.client?.jobs?.find((job: any) => job._id === props.match.params.jobID) : null
    const proposal = props?.location?.state;

    async function handlePay() {
        try {
            const config = await auth.getHeaderConfig()
            await api.patch(`/job/${job._id}`, { status: 'working' }, config)
            setUpdateUserContext((previous: any) => !previous);
            setIsSucessAlertOpen(true)
            router.push(`/client/jobs/${job._id}`)
        } catch (error) {
            console.log("Erro no handlePay:", error?.response?.data)
            return;
        }
    }

    if (isUserLoading || !job || !proposal) return <Loading isLoading={isUserLoading} />

    else return (
        <IonPage id='page-subscribe'>
            <BackButton />
            <IonContent>
                <IonRow className='fullheight background ion-justify-content-center ion-align-items-center'>
                    <IonCol>
                        <IonRow>
                            <IonCol className='center'>
                                <h1>PAGO DE INSCRIPCIÓN</h1>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-no-padding'>
                            <IonCard className='card'>
                                <IonCardHeader>
                                    <h2>Lorem</h2>
                                    <h3>Lorem, ipsum.</h3>
                                </IonCardHeader>
                                <IonCardContent>
                                    <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae, quod ducimus aliquam cumque iure dolor adipisci, accusamus quidem obcaecati possimus deserunt perferendis nostrum. Ea, possimus!</h4>
                                </IonCardContent>
                                <IonRow className='ion-align-itens-center ion-justify-content-center ion-padding-bottom'>
                                    <IonChip outline color='dark'>
                                        <IonLabel>Badge 1</IonLabel>
                                    </IonChip>
                                    <IonChip outline color='dark'>
                                        <IonLabel>Badge 2</IonLabel>
                                    </IonChip>
                                    <IonChip outline color='dark'>
                                        <IonLabel>Badge 3</IonLabel>
                                    </IonChip>
                                    <IonChip outline color='dark'>
                                        <IonLabel>Badge 4</IonLabel>
                                    </IonChip>
                                </IonRow>
                            </IonCard>
                        </IonRow>
                        <IonRow >
                            <IonCol className='center'>
                                <IonCard className='sub-card'>
                                    <IonCardHeader>VALOR DO INGRESSO: <strong>R$220,00</strong></IonCardHeader>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonButton expand='block' color='light' className="ion-padding-horizontal" onClick={handlePay}>
                                    <IonText className='paypal'>PAY WITH PAYPAL</IonText>
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <IonRow className='ion-justify-content-center ion-align-items-center'>
                            <h5>PAGAMENTO ÚNICO</h5>
                        </IonRow>
                    </IonCol>
                </IonRow>

                <SucessAlert isOpen={isSucessAlertOpen} setIsOpen={setIsSucessAlertOpen} text="Pago realizado. El autónomo será notificado y empezará a trabajar." />

            </IonContent>
        </IonPage>
    )
}

export default Index;