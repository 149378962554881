import { IonCard, IonButton, IonCol, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonRow, IonText, IonTitle, IonToolbar, IonGrid } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { getPlatforms } from '@ionic/react';

//services
import api from '../../services/api';
import auth from '../../services/auth'

//components
import './index.scss'
import Toast from '../../components/Toast';

// context
import { UserContext } from '../../context/UserContext';
import { ToastContext } from '../../context/ToastContext';
import Header from '../../components/desktop/Header';


const Login: React.FC = (props: any) => {

  const platform: any = getPlatforms(); // returns ["iphone", "ios", "mobile", "mobileweb"] from an iPhone

  const loginForm = React.useRef<any>(null);

  const router = useHistory();

  // Clean-up Function
  React.useEffect(() => {
    return () => {
      // setEmail('')
      // setPassword('')
      loginForm?.current?.reset();
    }
  }, [router.location])

  const { setUser, setUpdateUserContext } = React.useContext(UserContext);
  const { setToast, setToastMessage } = React.useContext(ToastContext);

  // const [email, setEmail] = React.useState('');
  // const [password, setPassword] = React.useState('');

  // const [hideErr, setHideErr] = React.useState(false);
  // const [theError, settheError] = React.useState('');

  const handleLogin = async (e: any) => {

    e.preventDefault();

    const { email, password } = e.target

    // if (email == '' || password == '') {
    //   setToastMessage('Por favor, completa ambos campos')
    //   setToast(true)
    //   return;
    // }

    const loginData = {
      email: email.value,
      password: password.value,
    }

    try {
      const res = await api.post("/login", loginData)

      await auth.onSignIn(res?.data?.user?.tokens[0]?.token)
      setUser(res?.data?.user);
      setUpdateUserContext((previous: any) => !previous);

      const userType = res?.data?.user?.type

      if (userType == "freelancer") {
        router.push('/freelancer');
      } else if (userType == "client") {
        router.push('/client');
      }
      setToastMessage(`Bienvenido, ${res?.data?.user?.name}!`)
      setToast(true)
    }
    catch (error) {
      if (error) {
        await setToastMessage('Error de login, verifique el email y la contraseña.')
        await setToast(true)

        setTimeout(() => router.push('/landing'), 1000)

        return;
      }
    }
  }



  if (platform == 'desktop') return (
    <IonContent className="freelancer-job-desktop">

      <Header currentPath={props.match.url} />

      <main style={{ height: '100%' }}>
        <IonGrid fixed className="content-wrapper" style={{ height: '100%', width: '100%' }}>

          <IonRow>

            <IonCol size='4'></IonCol>
            <IonCol size="4" style={{ display: 'flex', alignItems: 'center', marginTop: '-70px' }}>
              <IonCard className="ion-padding ion-no-margin" style={{ width: '100%' }}>
                <h4 className="ion-text-center">Bienvenido a Contadoor!</h4>
                <form ref={loginForm} onSubmit={handleLogin}>
                  <IonItem>
                    <IonLabel position="floating">Email</IonLabel>
                    <IonInput
                      required
                      type="email"
                      name="email"
                    // value={email}
                    // onIonChange={(e: any) => {setEmail(e.detail.value)}}
                    >
                    </IonInput>
                  </IonItem>

                  <IonItem>
                    <IonLabel position="floating">Contraseña</IonLabel>
                    <IonInput
                      required
                      type="password"
                      name="password"
                    // value={password}
                    // onIonChange={(e: any) => {setPassword(e.detail.value)}}
                    >
                    </IonInput>
                  </IonItem>
                  {/* <IonRow className='ion-padding-horizontal'>
                    <IonCol className='ion-padding-horizontal'>
                      <IonText color='danger' hidden={hideErr} className='ion-text-center'>{theError}</IonText>
                    </IonCol>
                  </IonRow> */}

                  <IonButton type='submit' color='primary' expand='block' className='ion-padding-horizontal mt-4'>Iniciar sesión</IonButton>
                </form>
                <IonText>
                  <p className='ion-padding-horizontal mt-1'>No tienes una conta? <a onClick={() => router.push('/landing')}><span style={{ color: '#6C74E6', cursor: 'pointer', fontWeight: 600 }}>Registrate!</span></a></p>
                </IonText>
              </IonCard>
            </IonCol>
            <IonCol size='4'></IonCol>
          </IonRow>
        </IonGrid>
      </main>
    </IonContent>
  )


  else return (
    <IonPage className="login-page">

      <IonHeader>
        <IonToolbar color='primary'>
          <IonTitle>Iniciar sesión</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" class='center' fullscreen>
        <IonRow className="ion-align-items-center h-100">
          <IonCol size="12">
            <IonCard className="ion-padding ">


              <img style={{ width: "12.5rem" }} className="d-block mx-auto mb-4" src="/images/landing/logo.png" alt="Logotipo Contadoor" />
              <form ref={loginForm} onSubmit={handleLogin}>
                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput
                    required
                    type="email"
                    name="email"
                  // value={email}
                  // onIonChange={(e: any) => {setEmail(e.detail.value)}}
                  >
                  </IonInput>
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">Contraseña</IonLabel>
                  <IonInput
                    required
                    type="password"
                    name="password"
                  // value={password}
                  // onIonChange={(e: any) => {setPassword(e.detail.value)}}
                  >
                  </IonInput>
                </IonItem>
                {/* <IonRow className='ion-padding-horizontal'>
                <IonCol className='ion-padding-horizontal'>
                <IonText color='danger' hidden={hideErr} className='ion-text-center'>{theError}</IonText>
                </IonCol>
              </IonRow> */}

                <IonButton type='submit' color='primary' expand='block' className='ion-padding-horizontal mt-4'>Iniciar sesión</IonButton>

              </form>
              <IonText className="ion-text-center">
                <p>No tienes una conta? <a href='/landing'>Registrate!</a></p>
              </IonText>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  )
};
export default Login;