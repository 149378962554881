import {

  IonButton, IonContent, IonHeader, IonInput, IonItem,
  IonLabel, IonList, IonPage, IonTitle, IonToolbar,
  IonCheckbox,
  IonCard,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonText,
  getPlatforms,
  IonGrid

} from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import Header from '../../components/desktop/Header';
import Toast from '../../components/Toast';
import { ToastContext } from '../../context/ToastContext';
import { UserContext } from '../../context/UserContext';
import api from '../../services/api';
import auth from '../../services/auth';
import './index.css'

const queryString = require('query-string');

const Signup: React.FC = (props: any) => {


  const router = useHistory()

  const platform: any = getPlatforms();

  const { setUser, setUpdateUserContext } = React.useContext(UserContext);
  const { setToast, setToastMessage } = React.useContext(ToastContext);


  // const { type } = queryString.parse(props.location.search)

  const signupForm = React.useRef<any>(null);

  const [userType, setUserType] = useState<any>(props?.location?.state);
  const [checked, setChecked] = useState<any>(false);



  const handleSignup = async (e: any) => {
    e.preventDefault()

    if (!checked) {
      setToastMessage('Debe aceptar los términos de uso para crear su cuenta')
      setToast(true)
      return
    }

    let reg: any = {}
    if (userType === 'client') reg = {
      name: e.target.name.value,
      type: userType,
      adress: e.target.address.value, //dirección
      phone: e.target.phone.value,
      email: e.target.email.value,
      password: e.target.password.value,
    }
    else if (userType === 'freelancer') reg = {
      name: e.target.name.value,
      type: userType,
      adress: e.target.address.value, //dirección
      phone: e.target.phone.value,
      email: e.target.email.value,
      password: e.target.password.value,
      skill: e.target.skill.value,
      about: e.target.about.value,
      resume: e.target.resume.value,
      identity: e.target.identity.value,
      cpa: e.target.cpa.value,
    }

    if (reg.name && reg.type && reg.email && reg.password) {
      if (reg.email.includes('@') && reg.email.includes('.com')) {
        if (reg.password.length >= 3) {
          try {
            const res = await api.post('/user', reg)
            await auth.onSignIn(res?.data?.tokens[0]?.token)
            setUser(res?.data)
            setUpdateUserContext((previous: any) => !previous);
            setToastMessage('¡Registrado con éxito! Bienvenido a Contadoor.')
            setToast(true)
            router.push(`/${userType}`)
            signupForm.current.reset();
          } catch (error) {
            console.log("Erro no handleSignup:", error?.response?.data)
            setToastMessage('¡Error al registrarse! ¡Compruebe si la cuenta ya existe o inténtelo de nuevo más tarde!')
            setToast(true)
            return;
          }
        }
        else {
          return console.log("La contraseña debe tener al menos 3 caracteres");
        }
      }
      else {
        return console.log("Email inválido");
      }
    }
    else {
      return console.log("Rellene los campos obligatorios");
    }
  }


  if (platform == 'desktop') return (
    <IonContent className="freelancer-job-desktop">

      <Header currentPath={props.match.url} />

      <main style={{ height: '100%' }}>
        <IonGrid fixed className="content-wrapper" style={{ height: '100%', width: '100%' }}>
          <IonRow>

            <IonCol size='4'></IonCol>
            <IonCol size="4">
              <IonCard className="ion-padding ion-no-margin" style={{ width: '100%', margin: '2rem 0' }}>
                <form ref={signupForm} onSubmit={(e) => handleSignup(e)}>
                  {/* <IonList className='ion-alig-items-center ion-justify-content-center ion-padding-horizontal'> */}

                  <IonList>
                    <IonItem lines="none">
                      <IonText>Tipo de cuenta</IonText>
                    </IonItem>

                    <IonItem>
                      <IonSegment color="dark" onIonChange={(e: any) => { setUserType(e.detail.value) }} value={userType}>
                        <IonSegmentButton value="client">Cliente</IonSegmentButton>
                        <IonSegmentButton value="freelancer">Freelancer</IonSegmentButton>
                      </IonSegment>
                    </IonItem>

                    <IonItem>
                      <IonLabel position="floating">Nombre y apellido</IonLabel>
                      <IonInput name='name' type='text' required />
                    </IonItem>

                    <IonItem>
                      <IonLabel position="floating">Email</IonLabel>
                      <IonInput name='email' type='text' required />
                    </IonItem>

                    <IonItem>
                      <IonLabel position="floating">Teléfono</IonLabel>
                      <IonInput name='phone' type='text' required />
                    </IonItem>

                    <IonItem>
                      <IonLabel position="floating">Dirección</IonLabel>
                      <IonInput name='address' type='text' required />
                    </IonItem>

                    {
                      userType === 'freelancer' ?
                        <>
                          <IonItem>
                            <IonLabel position='floating'>Habilidades</IonLabel>
                            <IonInput name='skill' type='text' />
                          </IonItem>

                          <IonItem>
                            <IonLabel position='floating'>Sobre mi</IonLabel>
                            <IonInput name='about' type='text' />
                          </IonItem>

                          <IonItem>
                            <IonLabel position='floating'>Historia Laboral</IonLabel>
                            <IonInput name='resume' type='text' />
                          </IonItem>

                          <IonItem>
                            <IonLabel position='floating'>Cédula de Identificación</IonLabel>
                            <IonInput name='identity' type='text' />
                          </IonItem>

                          <IonItem>
                            <IonLabel position='floating'>Registro de Contador Autorizado</IonLabel>
                            <IonInput name='cpa' type='text' />
                          </IonItem>
                        </>
                        : <></>
                    }

                    <IonItem>
                      <IonLabel position="floating">Contraseña</IonLabel>
                      <IonInput name='password' type='password' required />
                    </IonItem>
                  </IonList>

                  <IonCol>
                    <IonItem lines="none">
                      <IonLabel class="ion-text-wrap">Yo leo y acepto las condiciones de uso</IonLabel>
                      <IonCheckbox slot="end" onIonChange={() => setChecked(!checked)} checked={checked} />
                    </IonItem>
                    <IonButton expand="block" className="ion-margin-top" type='submit'>REGISTRARSE</IonButton>
                  </IonCol>
                  <IonRow>
                  </IonRow>
                </form>
              </IonCard>
            </IonCol>
            <IonCol size='4'></IonCol>
          </IonRow>
        </IonGrid>
      </main>
      {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}
    </IonContent>
  )

  else return (
    <IonPage id='page-signup'>
      <IonHeader>
        <IonToolbar color='primary'>
          <BackButton />
          <IonTitle>Registro</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="medium">
        <IonRow>
          <IonCard className='ion-align-items-center ion-justify-content-center w-100'>
            <form ref={signupForm} onSubmit={(e) => handleSignup(e)}>
              {/* <IonList className='ion-alig-items-center ion-justify-content-center ion-padding-horizontal'> */}

              <IonList>
                <IonItem lines="none">
                  <IonText>Tipo de cuenta</IonText>
                </IonItem>

                <IonItem>
                  <IonSegment color="dark" onIonChange={(e: any) => { setUserType(e.detail.value) }} value={userType}>
                    <IonSegmentButton value="client">Cliente</IonSegmentButton>
                    <IonSegmentButton value="freelancer">Freelancer</IonSegmentButton>
                  </IonSegment>
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">Nombre y apellido</IonLabel>
                  <IonInput name='name' type='text' required />
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput name='email' type='text' required />
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">Teléfono</IonLabel>
                  <IonInput name='phone' type='text' required />
                </IonItem>

                <IonItem>
                  <IonLabel position="floating">Dirección</IonLabel>
                  <IonInput name='address' type='text' required />
                </IonItem>

                {
                  userType === 'freelancer' ?
                    <>
                      <IonItem>
                        <IonLabel position='floating'>Habilidades</IonLabel>
                        <IonInput name='skill' type='text' />
                      </IonItem>

                      <IonItem>
                        <IonLabel position='floating'>Sobre mi</IonLabel>
                        <IonInput name='about' type='text' />
                      </IonItem>

                      <IonItem>
                        <IonLabel position='floating'>Historia Laboral</IonLabel>
                        <IonInput name='resume' type='text' />
                      </IonItem>

                      <IonItem>
                        <IonLabel position='floating'>Cédula de Identificación</IonLabel>
                        <IonInput name='identity' type='text' />
                      </IonItem>

                      <IonItem>
                        <IonLabel position='floating'>Registro de Contador Autorizado</IonLabel>
                        <IonInput name='cpa' type='text' />
                      </IonItem>
                    </>
                    : <></>
                }

                <IonItem>
                  <IonLabel position="floating">Contraseña</IonLabel>
                  <IonInput name='password' type='password' required />
                </IonItem>
              </IonList>

              <IonCol>
                <IonItem lines="none">
                  <IonLabel class="ion-text-wrap">Yo leo y acepto las condiciones de uso</IonLabel>
                  <IonCheckbox slot="end" onIonChange={() => setChecked(!checked)} checked={checked} />
                </IonItem>
                <IonButton expand="block" className="ion-margin" type='submit'>REGISTRARSE</IonButton>
              </IonCol>
              <IonRow>
              </IonRow>
            </form>
          </IonCard>
        </IonRow>

        {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}
      </IonContent>
    </IonPage>
  )
};
export default Signup;