import React, { useState } from 'react';
import { IonToast, IonButton } from '@ionic/react';
import { ToastContext } from '../context/ToastContext';


// interface ContainerProps {
//    toast?: any;
//    setToast?: any;

//  }

const Toast: React.FC<any> = (props) => {

  const { position, color } = props;

  const { toast, setToast, toastMessage, setToastMessage } = React.useContext(ToastContext);

  return (
    <IonToast
      color={color || 'light'}
      position={position || 'top'}
      isOpen={toast}
      onDidDismiss={() => setToast(false)}
      message={toastMessage}
      duration={2000}
    />
  );
};
export default Toast