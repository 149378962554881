import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute: React.FC<any> = (props: any) => {

  const user = localStorage.getItem('_cap_token')
  if (user) {
    return <Route path={props.path} exact={props.exact} component={props.component} />
  }
  else return <Redirect to="/landing" />

  // if (props.user) {
  //   return <Route path={props.path} exact={props.exact} component={props.component} />
  // }
  // else return <Redirect to="/landing" />
};

export default PrivateRoute;