import React from 'react';
import { createContext, useEffect, useState } from 'react'

// import api from '../services/api';
import auth from '../services/auth';

export const UserContext = createContext<any>({})

export function UserProvider(props: any) {

  const [user, setUser] = useState<any>(false);
  const [updateUserContext, setUpdateUserContext] = useState<any>(false);
  const [isUserLoading, setIsUserLoading] = React.useState<any>(false);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState<any>(false);




  useEffect(() => {
    // let flag = true
    async function fetchData() {
      try {
        setIsUserLoading(true)
        const userData = await auth.getUser()
        setUser(userData)
        setLastUpdatedAt(new Date())
      } catch (error) {
        console.log("Erro:", error?.response?.data);
      }
      finally {
        setIsUserLoading(false)
      }
    } fetchData()
  }, [updateUserContext])

  return (
    <UserContext.Provider value={{
      user,
      isUserLoading,
      lastUpdatedAt,
      setUser,
      setIsUserLoading,
      setUpdateUserContext,
      setLastUpdatedAt
    }}>

      {props.children}
    </UserContext.Provider>
  )
}