import React from 'react'
import { IonPage, IonHeader, IonToolbar, IonLabel, IonTitle, IonContent, IonFooter, IonCard, IonCol, IonRow, IonText, IonCardContent, IonButton, IonCardHeader, IonCardTitle, IonCardSubtitle, getPlatforms, IonGrid, IonBadge, IonModal, IonIcon, IonAvatar, IonChip } from '@ionic/react'
import Tabs from '../../../components/Tabs';
import { UserContext } from '../../../context/UserContext';
import formatDate from '../../../utils/formatDate'
import Loading from '../../../components/loading';
import Header from '../../../components/desktop/Header';
import { useHistory } from 'react-router-dom'
import { customBadges } from '../../../utils/customBadges';
import formatDateHour from '../../../utils/formatDateHour';
import { chevronDown, walletOutline } from 'ionicons/icons';
import api from '../../../services/api';
import auth from '../../../services/auth';
import Toast from '../../../components/Toast';
import MobileHeader from '../../../components/MobileHeader';
import { ToastContext } from '../../../context/ToastContext';
import ReactStars from 'react-stars';


const FreelancerDashboard: React.FC = (props: any) => {

  const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext);
  const { setToast, setToastMessage } = React.useContext(ToastContext);

  const [withdrawModal, setWithdrawModal] = React.useState(false);

  const router = useHistory();
  const currentDate = new Date()

  const platform: any = getPlatforms();

  const acceptedProposals = user.freelancer?.proposals?.filter((proposal: any) => proposal.status === 'accepted')
  const myJobs = user.freelancer?.jobs?.filter((job: any) => job.status === 'working' || job.status === 'private')

  const handleWithdraw = async (e: any) => {

    e.preventDefault()

    const { withdrawAmount } = e.target;

    if (withdrawAmount.value > user?.freelancer?.wallet?.balance?.available) {
      setToastMessage('¡No es posible retirar el monto ingresado, saldo insuficiente!')
      setToast(true);
      return;
    }

    if (user?.bank?.name || user?.bank?.account || user?.bank?.accountType || user?.bank?.bank) {
      setToastMessage('¡Complete sus datos bancarios en la pantalla de perfil para realizar un retiro!')
      setToast(true);
      return;
    }

    const input = {
      amount: withdrawAmount.value,
    }

    try {
      const config = await auth.getHeaderConfig();
      await api.post("/withdraw", input, config);
      setUpdateUserContext((previous: any) => !previous);
      setWithdrawModal(false);
      setToastMessage('¡retiro exitoso!')
      setToast(true);
    } catch (error) {
      setToastMessage('No se pudo realizar el retiro. Vuelve a intentarlo más tarde.')
      setToast(true);
    }
  }


  // let incomingAmount = 0;
  // const paymentsIncoming = user.freelancer?.payments?.filter((payment: any) => !payment.available).map((payment: any) => incomingAmount = +payment.valueTofreelancer)

  if (isUserLoading || !user) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (
    <>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          {/* <IonGrid fixed className="content-wrapper"> */}
          <div style={{ display: 'flex' }} className="mt-1">
            <div style={{ width: '370px', minWidth: '370px', backgroundColor: 'transparent', borderRadius: '10px', padding: '1rem' }}>

              <IonRow className="ion-no-padding">
                <IonCard style={{ width: "100%", backgroundColor: '#F9FAFC', border: '1px solid #E3E3E3' }} className="ion-margin-none ion-padding">
                  {/* <IonCardContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}> */}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IonAvatar style={{ border: "2px solid #00F1A9", marginRight: '10px', width: '70px', height: '70px' }}>
                        <img src={user.image} />
                      </IonAvatar>
                      <div>
                        <p style={{ margin: 0, fontSize: '12px', color: '#969696' }}>Buenos dias</p>
                        <p style={{ margin: 0, fontSize: '18px' }}>{user.name}</p>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ReactStars
                            count={5}
                            value={user?.freelancer?.rating?.value}
                            size={20}
                            edit={false}
                            color2={'#ffd700'}
                          />
                          <p style={{ margin: "0 0 0 5px", fontSize: '15px' }}>{user?.freelancer?.rating?.value}</p>
                        </div>

                      </div>
                    </div>
                    <button style={{ borderRadius: '18px', color: '#00CFB4', backgroundColor: 'transparent', padding: '10px', fontSize: '13px', width: '76px', textDecoration: 'underline' }}>
                      <p style={{ margin: "0", fontSize: '13px' }} onClick={() => router.push('/profile')}>Ver perfil</p>
                    </button>
                  </div>
                  {/* </IonCardContent> */}
                </IonCard>
              </IonRow>

              <IonRow className="ion-no-padding ion-margin-vertical">
                <IonCard style={{ width: "100%", backgroundColor: '#3B3FB6', border: '1px solid #E3E3E3' }} className="ion-text-center ion-margin-none">
                  <IonCardContent>
                    <p style={{ fontSize: "15px", color: "#F9FAFC", margin: "8px 0", opacity: '0.56' }}>A liberar: ${user.freelancer?.wallet?.balance?.toRelease}</p>
                    <IonCardTitle style={{ fontSize: "20px", color: '#F9FAFC' }} color="light">Disponible: ${user.freelancer?.wallet.balance.available}</IonCardTitle>
                    <button style={{ borderRadius: '18px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '218px', height: '44px' }} className='ion-padding-horizontal mt-3' onClick={() => setWithdrawModal(true)}>
                      Retirar
                    </button>
                    <IonText style={{ fontSize: "15px", color: '#F9FAFC', cursor: 'pointer' }} className='d-block mt-3' onClick={() => router.push("/freelancer/withdraws")}>
                      Ver todos las transaciones
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonRow>

              <IonRow className="ion-no-padding">
                <IonCard style={{ width: "100%", backgroundColor: '#F9FAFC', border: '1px solid #E3E3E3' }} className="ion-text-center ion-margin-none ion-padding">
                  <IonCardContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src='/assets/contrato.svg' />
                        <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0 10px" }}>Propuestas</p>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', marginTop: '10px' }}>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Propuestas enviadas: {user.freelancer?.proposals?.length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Propuestas aceptadas: {user.freelancer?.proposals?.filter((proposal: any) => proposal.status === 'accepted').length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Propuestas rechazadas: {user.freelancer?.proposals?.filter((proposal: any) => proposal.status === 'declined').length}</p>
                      </div>
                    </div>
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src='/assets/cliente.svg' />
                        <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0 10px" }}>Servicios</p>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', marginTop: '10px' }}>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Servicios abiertos: {user.freelancer?.jobs?.filter((job: any) => job.status === 'open').length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Servicios en ejecución: {user.freelancer?.jobs?.filter((job: any) => job.status === 'working').length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Servicios finalizados: {user.freelancer?.jobs?.filter((job: any) => job.status === 'finished').length}</p>
                      </div>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonRow>

            </div>

            {/*  */}

            <div style={{ width: '100%', backgroundColor: 'trasparent', borderRadius: '10px', padding: '1rem' }}>
              <div className='ion-margin-vertical' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <IonChip style={{ backgroundColor: '#FFFFFF', color: '#969696', cursor: 'default' }}>{formatDate(currentDate)}</IonChip>
                {/* <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '133px', backgroundColor: '#00CFB4', borderRadius: '6px', color: '#F9FAFC', padding: '10px' }}>
                  <p style={{ margin: "0", fontSize: '16px' }}>Ordenar por</p>
                  <img src="/assets/Group 8435.svg" />
                </button> */}
              </div>

              {
                myJobs.length > 0
                  ? <>
                    <p className='ion-margin-vertical' style={{ fontSize: '24px' }}>Servicios en los que estoy trabajando</p>
                    {myJobs.map((job: any, index: any) => (
                      <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC', cursor: 'pointer' }} className="ion-margin-vertical ion-no-padding" onClick={() => router.push(`/freelancer/jobs/${job._id}`)}>
                        <IonCardContent style={{ padding: '2rem' }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0" }}>{job.title}</p>
                            <IonChip className='ion-text-center' style={{
                              backgroundColor:
                                job.status === 'open' ? '#00CFB4' :
                                  job.status === 'private' ? '#ffc409' :
                                    job.status === 'working' ? '#ffc409' :
                                      job.status === 'waiting-payment' ? '#ffc409' :
                                        job.status === 'freelancer-finished' ? '#2fdf75' :
                                          job.status === 'client-refused' ? '#ff4961' :
                                            job.status === 'client-require-job-cancel' ? '#ffd534' :
                                              job.status === 'moderation' ? '#ffc409' :
                                                job.status === 'job-cancelled' ? '#989aa2' :
                                                  job.status === 'job-finished' ? '#989aa2' : '',
                              color: '#F9FAFC',
                              fontSize: '16px',
                              padding: '10px 30px',
                              margin: '0',
                            }}>{customBadges(job, user)}</IonChip>
                          </div>

                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/portfolio.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{
                                  job.type === "auditoria" ? "Auditoría" :
                                    job.type === "analise" ? "Análisis" :
                                      job.type === "pericia" ? "Pericia" :
                                        job.type === "fiscalizacao" ? "Inspección" :
                                          job.type === "consultoria" ? "Consultoría" : ''
                                }</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/Grupo 5269.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Plazo deseado:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/laptop.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                              <img src='/assets/adjunto-archivo.svg' />
                              <p style={{ fontSize: "16px", color: '#616161', margin: "0" }}>{job.attachments?.length} archivo adjunto</p>
                            </div>
                          </div>


                        </IonCardContent>
                      </IonCard>
                    ))}
                  </>
                  : <>
                    <p className='ion-margin-vertical' style={{ fontSize: '24px' }}>Servicios disponíbles para trabajo</p>
                    {user.freelancer?.jobs?.map((job: any, index: any) => (
                      <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC', cursor: 'pointer' }} className="ion-margin-vertical ion-no-padding" onClick={() => router.push(`/freelancer/jobs/${job._id}`)}>
                        <IonCardContent style={{ padding: '2rem' }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0" }}>{job.title}</p>
                            <IonChip className='ion-text-center' style={{
                              backgroundColor:
                                job.status === 'open' ? '#00CFB4' :
                                  job.status === 'private' ? '#ffc409' :
                                    job.status === 'working' ? '#ffc409' :
                                      job.status === 'waiting-payment' ? '#ffc409' :
                                        job.status === 'freelancer-finished' ? '#2fdf75' :
                                          job.status === 'client-refused' ? '#ff4961' :
                                            job.status === 'client-require-job-cancel' ? '#ffd534' :
                                              job.status === 'moderation' ? '#ffc409' :
                                                job.status === 'job-cancelled' ? '#989aa2' :
                                                  job.status === 'job-finished' ? '#989aa2' : '',
                              color: '#F9FAFC',
                              fontSize: '16px',
                              padding: '10px 30px',
                              margin: '0',
                            }}>{customBadges(job, user)}</IonChip>
                          </div>

                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/portfolio.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{
                                  job.type === "auditoria" ? "Auditoría" :
                                    job.type === "analise" ? "Análisis" :
                                      job.type === "pericia" ? "Pericia" :
                                        job.type === "fiscalizacao" ? "Inspección" :
                                          job.type === "consultoria" ? "Consultoría" : ''
                                }</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/Grupo 5269.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Plazo deseado:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/laptop.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                              <img src='/assets/adjunto-archivo.svg' />
                              <p style={{ fontSize: "16px", color: '#616161', margin: "0" }}>{job.attachments?.length} archivo adjunto</p>
                            </div>
                          </div>


                        </IonCardContent>
                      </IonCard>
                    ))}
                  </>
              }
            </div>
          </div>
          {/* </IonGrid> */}
        </main>


        <IonModal isOpen={withdrawModal} swipeToClose={true} onDidDismiss={() => setWithdrawModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonButton color='light' size="small" fill="clear" onClick={() => setWithdrawModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Cantidad a retirar</IonTitle>
            </IonToolbar>
          </IonHeader>
          <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {/* <IonRow className="ion-align-items-center ion-justify-content-center fullheight ion-padding-horizontal"> */}
            <IonCard className='ion-no-padding'>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }} className='mb-4'>
                <IonIcon color='dark' icon={walletOutline} />
                <IonText color='dark' style={{ marginLeft: '0.4rem', fontWeight: 600 }}>Saldo disponible: <span>${user.freelancer?.wallet.balance.available}</span></IonText>
              </div>
              <form onSubmit={handleWithdraw}>
                <div className="form-group">
                  <IonText color='dark' style={{ fontWeight: 600 }}>Informar cuánto quieres retirar</IonText>
                  <input required type="number" min="0.01" step="0.01" inputMode="numeric" name="withdrawAmount" className="form-control" />
                </div>
                <div className="d-flex flex-direction-column justify-content-center align-items-center">
                  <button type="submit" className="m-1 btn btn-block btn-outline-success mt-3">Retirar</button>
                  <button type="button" className="m-1 btn btn-block btn-outline-danger mt-3" onClick={() => setWithdrawModal(false)}>Cancelar</button>
                </div>
              </form>
            </IonCard>
            {/* </IonRow> */}
          </div>
        </IonModal>

        {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}


      </IonContent>
    </>
  )

  else return (
    <IonPage>
      <MobileHeader />
      <IonContent color="medium" className="ion-padding mobile-dashboard">

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonAvatar style={{ border: "2px solid #00F1A9", marginRight: '10px', width: '70px', height: '70px' }}>
              <img src={user.image} />
            </IonAvatar>
            <div>
              <p style={{ margin: 0, fontSize: '18px' }}>{user.name}</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ReactStars
                  count={5}
                  value={user?.freelancer?.rating?.value}
                  size={20}
                  edit={false}
                  color2={'#ffd700'}
                />
                <p style={{ margin: "0 0 0 5px", fontSize: '15px' }}>{user?.freelancer?.rating?.value}</p>
              </div>

            </div>
          </div>
          <button style={{ borderRadius: '18px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '13px', width: '76px' }}>
            <p style={{ margin: "0", fontSize: '13px' }} onClick={() => router.push('/profile')}>Ver perfil</p>
          </button>
        </div>

        {/* <div className='ion-margin-vertical'>
          <p style={{ margin: "0", fontSize: '15px' }}>Propuestas enviadas esta semana: 10</p>
          <p style={{ margin: "0", fontSize: '15px' }}>Servicios en los que trabajo: 2</p>
        </div> */}

        <IonRow className="ion-no-padding ion-margin-vertical">
          <IonCard style={{ width: "100%", backgroundColor: '#3B3FB6', border: '1px solid #E3E3E3' }} className="ion-text-center ion-margin-none">
            <IonCardContent>
              <p style={{ fontSize: "15px", color: "#F9FAFC", margin: "8px 0", opacity: '0.56' }}>A liberar: ${user.freelancer?.wallet?.balance?.toRelease}</p>
              <IonCardTitle style={{ fontSize: "20px", color: '#F9FAFC' }} color="light">Disponible: ${user.freelancer?.wallet?.balance?.available}</IonCardTitle>
              <button style={{ borderRadius: '18px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '218px', height: '44px' }} className='ion-padding-horizontal mt-3' onClick={() => setWithdrawModal(true)}>
                Retirar
              </button>
              <IonText style={{ fontSize: "15px", color: '#F9FAFC' }} className='d-block mt-3' onClick={() => router.push("/freelancer/withdraws")}>
                Ver todos las transaciones
              </IonText>
            </IonCardContent>
          </IonCard>
        </IonRow>

        <IonRow className="ion-no-padding">
          <IonCard style={{ width: "100%", backgroundColor: '#F9FAFC', border: '1px solid #E3E3E3' }} className="ion-text-center ion-margin-none ion-padding">
            <IonCardContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src='/assets/contrato.svg' />
                  <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0 10px" }}>Propuestas</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', marginTop: '10px' }}>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Propuestas enviadas: {user.freelancer?.proposals?.length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Propuestas aceptadas: {user.freelancer?.proposals?.filter((proposal: any) => proposal.status === 'accepted').length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Propuestas rechazadas: {user.freelancer?.proposals?.filter((proposal: any) => proposal.status === 'declined').length}</p>
                </div>
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src='/assets/cliente.svg' />
                  <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0 10px" }}>Servicios</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', marginTop: '10px' }}>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Servicios abiertos: {user.freelancer?.jobs?.filter((job: any) => job.status === 'open').length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Servicios en ejecución: {user.freelancer?.jobs?.filter((job: any) => job.status === 'working').length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Servicios finalizados: {user.freelancer?.jobs?.filter((job: any) => job.status === 'finished').length}</p>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </IonRow>

        <div className='ion-margin-vertical' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <IonChip style={{ backgroundColor: '#FFFFFF', color: '#969696' }}>{formatDate(currentDate)}</IonChip>
          {/* {
            myJobs.length > 0
              ? <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '133px', backgroundColor: '#00CFB4', borderRadius: '6px', color: '#F9FAFC', padding: '10px' }}>
                <p style={{ margin: "0", fontSize: '16px' }}>Ordenar por</p>
                <img src="/assets/Group 8435.svg" />
              </button>
              : <></>
          } */}
        </div>

        {
          myJobs.length > 0
            ? <>
              <p className='ion-text-center ion-margin-vertical' style={{ fontSize: '19px' }}>Servicios en los que estoy trabajando</p>
              {myJobs.map((job: any, index: any) => (
                <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-text-center ion-margin-top ion-padding-vertical" onClick={() => router.push(`/freelancer/jobs/${job._id}`)}>
                  <IonCardContent>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <p style={{ fontSize: "17px", color: "#2D2D2D", margin: "0", fontWeight: 600 }}>{job.title}</p>
                    </div>
                    <IonChip className='ion-text-center ion-margin-vertical' style={{
                      backgroundColor:
                        job.status === 'open' ? '#00CFB4' :
                          job.status === 'private' ? '#ffc409' :
                            job.status === 'working' ? '#ffc409' :
                              job.status === 'waiting-payment' ? '#ffc409' :
                                job.status === 'freelancer-finished' ? '#2fdf75' :
                                  job.status === 'client-refused' ? '#ff4961' :
                                    job.status === 'client-require-job-cancel' ? '#ffd534' :
                                      job.status === 'moderation' ? '#ffc409' :
                                        job.status === 'job-cancelled' ? '#989aa2' :
                                          job.status === 'job-finished' ? '#989aa2' : '',
                      color: '#F9FAFC',
                      fontSize: '16px',
                      padding: '10px 30px',
                      margin: '16px 0',
                    }}>{customBadges(job, user)}</IonChip>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '32px', marginTop: '16px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/portfolio.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{
                            job.type === "auditoria" ? "Auditoría" :
                              job.type === "analise" ? "Análisis" :
                                job.type === "pericia" ? "Pericia" :
                                  job.type === "fiscalizacao" ? "Inspección" :
                                    job.type === "consultoria" ? "Consultoría" : ''
                          }</p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/Grupo 5269.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Plazo deseado</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/laptop.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                        </div>
                      </div>
                    </div>

                    <div className='ion-margin-top' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                      <img src='/assets/adjunto-archivo.svg' />
                      <p>{job.attachments?.length} archivo adjunto</p>
                    </div>

                  </IonCardContent>
                </IonCard>
              ))}
            </>
            : <>
              <p className='ion-text-center ion-margin-vertical' style={{ fontSize: '19px' }}>Servicios disponíbles para trabajo</p>
              {user.freelancer?.jobs?.map((job: any, index: any) => (
                <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-text-center ion-margin-top ion-padding-vertical" onClick={() => router.push(`/freelancer/jobs/${job._id}`)}>
                  <IonCardContent>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <p style={{ fontSize: "17px", color: "#2D2D2D", margin: "0", fontWeight: 600 }}>{job.title}</p>
                    </div>
                    <IonChip className='ion-text-center ion-margin-vertical' style={{
                      backgroundColor:
                        job.status === 'open' ? '#00CFB4' :
                          job.status === 'private' ? '#ffc409' :
                            job.status === 'working' ? '#ffc409' :
                              job.status === 'waiting-payment' ? '#ffc409' :
                                job.status === 'freelancer-finished' ? '#2fdf75' :
                                  job.status === 'client-refused' ? '#ff4961' :
                                    job.status === 'client-require-job-cancel' ? '#ffd534' :
                                      job.status === 'moderation' ? '#ffc409' :
                                        job.status === 'job-cancelled' ? '#989aa2' :
                                          job.status === 'job-finished' ? '#989aa2' : '',
                      color: '#F9FAFC',
                      fontSize: '16px',
                      padding: '10px 30px',
                      margin: '16px 0',
                    }}>{customBadges(job, user)}</IonChip>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '32px', marginTop: '16px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/portfolio.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{
                            job.type === "auditoria" ? "Auditoría" :
                              job.type === "analise" ? "Análisis" :
                                job.type === "pericia" ? "Pericia" :
                                  job.type === "fiscalizacao" ? "Inspección" :
                                    job.type === "consultoria" ? "Consultoría" : ''
                          }</p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/Grupo 5269.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Plazo deseado</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/laptop.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                        </div>
                      </div>
                    </div>

                    <div className='ion-margin-top' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                      <img src='/assets/adjunto-archivo.svg' />
                      <p>{job.attachments?.length} archivo adjunto</p>
                    </div>

                  </IonCardContent>
                </IonCard>
              ))}
            </>
        }
      </IonContent >
      <IonFooter>
        <Tabs currentPath={props.match.url} />
      </IonFooter>

      <IonModal isOpen={withdrawModal} swipeToClose={true} onDidDismiss={() => setWithdrawModal(false)}>
        <IonHeader>
          <IonToolbar color='primary'>
            <IonButton color='light' size="small" fill="clear" onClick={() => setWithdrawModal(false)}>
              <IonIcon slot='start' icon={chevronDown} />
            </IonButton>
            <IonTitle>Cantidad a retirar</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {/* <IonRow className="ion-align-items-center ion-justify-content-center fullheight ion-padding-horizontal"> */}
          <IonCard className='ion-no-padding'>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }} className='mb-4'>
              <IonIcon color='dark' icon={walletOutline} />
              <IonText color='dark' style={{ marginLeft: '0.4rem', fontWeight: 600 }}>Saldo disponible: <span>${user.freelancer?.wallet.balance.available}</span></IonText>
            </div>
            <form onSubmit={handleWithdraw}>
              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Informar cuánto quieres retirar</IonText>
                <input required type="number" min="0.01" step="0.01" inputMode="numeric" name="withdrawAmount" className="form-control" />
              </div>
              <div className="d-flex flex-direction-column justify-content-center align-items-center">
                <button type="submit" className="m-1 btn btn-block btn-outline-success mt-3">Retirar</button>
                <button type="button" className="m-1 btn btn-block btn-outline-danger mt-3" onClick={() => setWithdrawModal(false)}>Cancelar</button>
              </div>
            </form>
          </IonCard>
          {/* </IonRow> */}
        </div>
      </IonModal>

      {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}

    </IonPage >
  )
};

export default FreelancerDashboard;