import React from "react";
import { useHistory } from "react-router-dom";

import './index.css'

const MobileHeader: React.FC<any> = () => {

  const router = useHistory();

  return (
    <div className='mobile-header'>
      <img src="/assets/logo_contadoor.svg" onClick={() => router.push("/")}/>
      {/* <div className='header-menu'>
        <div className='header-option'>
          <img src="/assets/search.svg" />
        </div>
        <div style={{ position: 'relative' }} className='header-option'>
          <img src="/assets/Grupo 2906.svg" />
          <img style={{ position: 'absolute', top: "-6px", right: "-5px" }} src="/assets/Ellipse 181.svg" />
        </div>
        <div className='header-option'>
          <img src="/assets/bell.svg" />
        </div>
        <div className='header-option'>
          <img src="/assets/menu.svg" />
        </div>
      </div> */}
    </div>
  )
}

export default MobileHeader;
