import { getPlatforms, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonPage, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar, IonSelectOption, IonSelect, IonLabel, IonSearchbar, IonChip } from '@ionic/react';
import { briefcaseOutline, calendarOutline, cashOutline, documentTextOutline } from 'ionicons/icons';
import React from 'react';

import './index.scss'

// Services
import auth from '../../../services/auth'

// Components
import Tabs from '../../../components/Tabs';
import Loading from '../../../components/loading';
import { useHistory } from 'react-router-dom';

// Context
import { UserContext } from '../../../context/UserContext';

// Utils
import formatDate from '../../../utils/formatDate';
import Header from '../../../components/desktop/Header';
import { customBadges } from '../../../utils/customBadges';
import MobileHeader from '../../../components/MobileHeader';


const MyProposals: React.FC = (props: any) => {

  const router = useHistory()
  const platform: any = getPlatforms();

  const [proposalSegment, setProposalSegment] = React.useState<any>('all')
  const { user, isUserLoading } = React.useContext(UserContext)

  const [searchText, setSearchText] = React.useState('');

  let proposalToShow = user ? user.freelancer?.proposals?.filter((proposal: any) => {

    if (proposal.status == "declined" && proposal.job.status != 'open') return false
    else if (proposalSegment === 'all') return true
    else {
      let status = proposal.status
      return status === proposalSegment
    }
  }) : null

  proposalToShow = searchText.length > 0 ?
    proposalToShow?.filter((proposal: any) => (
      proposal.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
      proposal.job.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
    ))
    : proposalToShow

  if (props?.location?.state?.setPending) {
    setProposalSegment('pending')
    props.location.state = null
  }

  if (isUserLoading || !proposalToShow) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (
    <>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          <div style={{ display: 'flex' }} className="mt-1">
            <div style={{ width: '370px', minWidth: '370px', backgroundColor: 'transparent', borderRadius: '10px', padding: '1rem' }}>
              <IonRow className="ion-no-padding">
                <IonCard style={{ width: "100%", backgroundColor: '#3B3FB6', border: '1px solid #E3E3E3' }} className="ion-margin-none ion-padding-bottom">
                  <IonCardContent>
                    <p style={{ marginBottom: '1.5rem', color: '#FFFFFF', fontSize: '24px' }}>Propuestas</p>
                    <p style={{ marginTop: '1.5rem', color: '#FFFFFF' }}>Estado de propuestas</p>
                    <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: '1rem' }}>
                      <select value={proposalSegment} onChange={(e: any) => setProposalSegment(e.target.value!)} style={{ height: '25px', flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }}>
                        <option value="all">Todas</option>
                        <option value="pending">Pendientes</option>
                        <option value="accepted">Aceptadas</option>
                        <option value="declined">Rechazadas</option>
                      </select>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonRow>
            </div>

            {/*  */}

            <div style={{ width: '100%', backgroundColor: 'trasparent', borderRadius: '10px', padding: '1rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                <p className='ion-margin-vertical' style={{ fontSize: '15px', color: '#3B3FB6' }}>Encontrada {proposalToShow?.length} propuesta(s)</p>
                <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                  <input style={{ flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar propuesta por nombre, descripción o servicio.." />
                  <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
                </div>
              </div>
              {proposalToShow?.length === 0
                ? <div style={{ height: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                  <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningúna propuesta acá...</h4>
                  <div>
                    <p>Si tiene problemas para mostrar las propuestas, verifique lo siguiente:</p>
                    <ul>
                      <li>Filtros aplicados</li>
                      <li>Conexión a Internet</li>
                      <li>Conexión al servidor Contadoor</li>
                    </ul>
                  </div>
                </div>
                : proposalToShow?.map((proposal: any, index: any) => (
                  <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-margin-top ion-no-padding">
                    <IonCardContent style={{ padding: '2rem' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                          <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0" }}>Propuesta para: {proposal.job?.title}</p>
                          <IonChip className='ion-text-center' style={{
                            backgroundColor:
                              proposal.status === 'accepted' ? '#6DECAE' :
                                proposal.status === 'pending' ? '#ffc409' :
                                  proposal.status === 'declined' ? '#ff4961' : ''
                            ,
                            color: '#F9FAFC',
                            fontSize: '16px',
                            padding: '10px 30px',
                            margin: '0',
                            cursor: 'default',
                          }}>{proposal.status === 'pending' ? 'Pendiente' :
                            proposal.status === 'accepted' ? 'Aceptada' :
                              proposal.status === 'declined' ? 'Rechazada' : ''}</IonChip>
                        </div>
                        <p style={{ fontSize: '16px', color: '#969696', margin: '16px 0' }}>Atualizado el {formatDate(proposal.updatedAt)}</p>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <p style={{ fontSize: '16px', color: '#2D2D2D', margin: '20px 0' }}>Descripción: {proposal.description?.length < 200 ? proposal.description : proposal.description.slice(0, 200) + '...'}</p>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/portfolio.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Precio:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>${proposal.price}</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/Grupo 5269.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Plazo:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{proposal.deadline} días</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/laptop.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{proposal.job?.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button onClick={() => router.push(`/freelancer/proposals/${proposal._id}`)} style={{ marginTop: '16px', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Ver detalles</p>
                        </button>
                      </div>
                    </IonCardContent>
                  </IonCard>
                ))
              }
            </div>
          </div>
        </main>

        {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}

      </IonContent>
    </>





    // <IonContent className="freelancer-proposal-desktop">
    //   <Header currentPath={props.match.url} />
    //   <main>
    //     <IonGrid fixed className="content-wrapper">
    //       <IonRow className="ion-margin-top">
    //         <IonCol size='3'>
    //           <IonRow>
    //             <IonCol style={{ backgroundColor: 'white', borderRadius: '10px' }}>
    //               <IonRow>
    //                 <IonCol className='ion-padding'>

    //                   <div>
    //                     <small className="mb-2 d-block">Estado de propuestas</small>
    //                     <select value={proposalSegment} onChange={(e: any) => setProposalSegment(e.target.value!)} className="custom-select custom-select-lg mb-3 w-100">
    //                       <option value="all">Todas</option>
    //                       <option value="pending">Pendientes</option>
    //                       <option value="accepted">Aceptadas</option>
    //                       <option value="declined">Rechazadas</option>
    //                     </select>
    //                   </div>

    //                   <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

    //                   <div className='text-center mt-2'>
    //                     <small>Propuestas encontradas: {proposalToShow?.length}</small>
    //                   </div>

    //                 </IonCol>
    //               </IonRow>
    //             </IonCol>
    //           </IonRow>
    //         </IonCol>
    //         <IonCol size="9">

    //           <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Procura propuestas por descripción ou servicio.." />

    //           <div style={{ height: '80vh', overflowY: 'auto' }}>

    //             {
    //               !proposalToShow
    //                 ? <></>
    //                 : proposalToShow.length === 0
    //                   ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //                     <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningúna propuesta ahi..</h4>
    //                     <div>
    //                       <p>Si tiene problemas para mostrar las propuestas, verifique lo siguiente:</p>
    //                       <ul>
    //                         <li>Filtros aplicados</li>
    //                         <li>Conexión a Internet</li>
    //                         <li>Conexión com o servidor contadoor</li>
    //                       </ul>
    //                     </div>
    //                   </div>
    //                   : proposalToShow.map((proposal: any, index: any) => (
    //                     <IonCard className='ion-card-desktop' key={index} onClick={() => router.push(`/freelancer/proposals/${proposal._id}`)}>
    //                       <IonRow>
    //                         <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
    //                           <p style={{ fontSize: '.8em', margin: '0.5rem 0' }}>Atualizado en <strong>{formatDate(proposal.updatedAt)}</strong></p>
    //                         </IonCol>
    //                         <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
    //                           display: 'flex',
    //                           justifyContent: 'center',
    //                           alignItems: 'center',
    //                           backgroundColor:
    //                             proposal.status === 'accepted' ? '#6DECAE' :
    //                               proposal.status === 'pending' ? '#ffc409' :
    //                                 proposal.status === 'declined' ? '#ff4961' : ''
    //                         }}>
    //                           {proposal.status === 'pending' ? 'Pendiente' :
    //                             proposal.status === 'accepted' ? 'Aceptada' :
    //                               proposal.status === 'declined' ? 'Rechazada' : ''}
    //                         </IonCol>
    //                       </IonRow>

    //                       <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

    //                       <IonCardContent>
    //                         <IonRow>
    //                           <IonCol size="8">
    //                             <div className='d-flex'>
    //                               <div className="mr-4">
    //                                 <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                                   <IonIcon color='dark' icon={briefcaseOutline} />
    //                                   <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Servicio</IonText>
    //                                 </div>
    //                                 <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{proposal.job?.title}</p>
    //                               </div>
    //                             </div>
    //                             <div className="d-flex mt-3">
    //                               <div className="mr-4">
    //                                 <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                                   <IonIcon color='dark' icon={calendarOutline} />
    //                                   <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Plazo</IonText>
    //                                 </div>
    //                                 <p style={{ fontSize: '1em' }}>{proposal.deadline} dias</p>
    //                               </div>

    //                               <div>
    //                                 <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                                   <IonIcon color='dark' icon={cashOutline} />
    //                                   <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Precio</IonText>
    //                                 </div>
    //                                 <p style={{ fontSize: '1em' }}>{proposal.price} usd</p>
    //                               </div>
    //                             </div>
    //                           </IonCol>
    //                           <IonCol size="4">
    //                             <IonButton color='dark' className='mb-3' expand='block'>Ver detalles</IonButton>

    //                           </IonCol>

    //                           <IonCol size="12">
    //                             <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.3em' }}>
    //                               <IonIcon color='dark' icon={documentTextOutline} />
    //                               <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Descripción</IonText>
    //                             </div>
    //                             <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{proposal.description?.length < 200 ? proposal.description : proposal.description.slice(0, 200) + '...'}</p>
    //                           </IonCol>
    //                         </IonRow>
    //                       </IonCardContent>
    //                     </IonCard>
    //                   ))
    //             }
    //           </div>
    //         </IonCol>
    //       </IonRow>
    //     </IonGrid>
    //   </main>
    // </IonContent >
  )

  else return (
    <IonPage>
      {/* <IonHeader> */}
      <MobileHeader />
      {/* <IonToolbar color="primary">
          <IonSegment onIonChange={(e: any) => setProposalSegment(e.detail.value)} value={proposalSegment}>
            <IonSegmentButton value='pending'>Pendientes</IonSegmentButton>
            <IonSegmentButton value='accepted'>Aceptadas</IonSegmentButton>
            <IonSegmentButton value='declined'>Rechazadas</IonSegmentButton>
          </IonSegment>
        </IonToolbar>
        <IonToolbar style={{ padding: '0' }}>
          <IonSearchbar style={{ padding: '0' }} value={searchText} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Procura propuestas por descripción ou servicio.." />
        </IonToolbar> */}
      {/* </IonHeader> */}
      <IonContent color='medium' className="ion-padding">

        <p style={{ fontSize: '21px', color: '#000000', fontWeight: 500 }}>Propuestas</p>

        <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <input style={{ border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar propuesta.." />
          <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
        </div>

        <p className='ion-margin-vertical' style={{ fontSize: '15px', color: '#3B3FB6' }}>Encontrada {proposalToShow?.length} propuesta(s)</p>

        {
          !proposalToShow
            ? <></>
            : proposalToShow.length === 0
              ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningúna propuesta ahi..</h4>
                <div>
                  <p>Si tiene problemas para mostrar las propuestas, verifique lo siguiente:</p>
                  <ul>
                    <li>Filtros aplicados</li>
                    <li>Conexión a Internet</li>
                    <li>Conexíon al servidor Contadoor</li>
                  </ul>
                </div>
              </div>
              : proposalToShow.map((proposal: any, index: any) => (
                <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-text-center ion-margin-top ion-padding-vertical">
                  <IonCardContent>
                    <p style={{ fontSize: "16px", color: "#2D2D2D", margin: '0', fontWeight: 600 }}>{proposal.job.title}</p>
                    <IonChip className='ion-text-center ion-margin-vertical' style={{
                      backgroundColor:
                        proposal.status === 'accepted' ? '#6DECAE' :
                          proposal.status === 'pending' ? '#ffc409' :
                            proposal.status === 'declined' ? '#ff4961' : '',
                      color: '#F9FAFC',
                      fontSize: '16px',
                      padding: '10px 30px',
                      margin: '16px 0',
                    }}>{proposal.status === 'pending' ? 'Pendiente' :
                      proposal.status === 'accepted' ? 'Aceptada' :
                        proposal.status === 'declined' ? 'Rechazada' : ''}</IonChip>

                    <p style={{ fontSize: '15px', color: '#969696', marginBottom: '16px' }}>Atualizado el {formatDate(proposal.updatedAt)}</p>

                    <p style={{ fontSize: '15px', color: '#2D2D2D', margin: '20px 0' }}><strong >Descripción: </strong> {proposal.description.length < 140 ? proposal.description : proposal.description.slice(0, 140) + '...'}</p>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '32px 0' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/portfolio.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Precio de trabajo</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>${proposal.price}</p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/Grupo 5269.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Plazo</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{proposal.deadline} días</p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/laptop.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{proposal.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                        </div>
                      </div>
                    </div>
                    <button onClick={() => router.push(`/freelancer/proposals/${proposal._id}`)} style={{ marginTop: '16px', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                      <p style={{ margin: '0', fontSize: '17px' }}>Ver más</p>
                    </button>
                  </IonCardContent>
                </IonCard>

                // <IonCard className='ion-no-padding' key={index} onClick={() => router.push(`/freelancer/proposals/${proposal._id}`)}>
                //   <IonRow>
                //     <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
                //       <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{formatDate(proposal.updatedAt)}</strong></p>
                //     </IonCol>
                //     <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
                //       display: 'flex',
                //       justifyContent: 'center',
                //       alignItems: 'center',
                //       backgroundColor:
                //         proposal.status === 'accepted' ? '#6DECAE40' :
                //           proposal.status === 'pending' ? '#ffc40940' :
                //             proposal.status === 'declined' ? '#ff496140' : ''
                //     }}>
                //       {proposal.status === 'pending' ? 'Pendiente' :
                //         proposal.status === 'accepted' ? 'Aceptada' :
                //           proposal.status === 'declined' ? 'Rechazada' : ''}
                //     </IonCol>
                //   </IonRow>
                //   <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

                //   <IonCardContent>
                //     <IonRow>
                //       <IonCol>
                //         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.3em' }}>
                //           <IonIcon color='dark' icon={briefcaseOutline} />
                //           <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Servicio</IonText>
                //         </div>
                //         <p style={{ fontSize: '1em', marginBottom: '0.3em', marginLeft: '0.5em' }}>{proposal.job?.title}</p>
                //       </IonCol>
                //     </IonRow>
                //     <IonRow>
                //       <IonCol>
                //         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.3em' }}>
                //           <IonIcon color='dark' icon={documentTextOutline} />
                //           <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Descripción</IonText>
                //         </div>
                //         <p style={{ fontSize: '1em', marginBottom: '0.3em', marginLeft: '0.5em' }}>{proposal.description?.length < 200 ? proposal.description : proposal.description.slice(0, 200) + '...'}</p>
                //       </IonCol>
                //     </IonRow>
                //     <IonRow style={{ display: 'flex', justifyContent: 'space-between' }}>
                //       <IonCol>
                //         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                //           <IonIcon color='dark' icon={calendarOutline} />
                //           <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Plazo</IonText>
                //         </div>
                //         <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{proposal.deadline} dias</p>
                //       </IonCol>
                //       <IonCol>
                //         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                //           <IonIcon color='dark' icon={cashOutline} />
                //           <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Precio</IonText>
                //         </div>
                //         <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{proposal.price} usd</p>
                //       </IonCol>
                //     </IonRow>
                //   </IonCardContent>
                // </IonCard>
              ))
        }
      </IonContent>
      <IonFooter>
        <Tabs currentPath={props.match.url} />
      </IonFooter>
    </IonPage>
  )
};
export default MyProposals;