import { IonButton, IonCard, IonRow, IonCol, IonPage, IonFooter, IonContent, IonText, getPlatforms, IonGrid, IonAvatar, IonCardContent } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss'

// Contexts
import { UserContext } from '../../context/UserContext';

// Services
import auth from '../../services/auth'
import Header from '../../components/desktop/Header';



declare global {
  interface Window {
    pfClave: any;
  }
}



const Landing: React.FC = (props: any) => {
  const router = useHistory()

  // const { user } = React.useContext(UserContext);

  // React.useEffect(() => {
  //   if (user) {
  //     route.push(`/${user.type}`);
  //   }
  // }, [user])

  const platform: any = getPlatforms();



  if (platform == "desktop") return (

    <IonContent className="freelancer-job-desktop  h-100">

      {/* <Header currentPath={props.match.url} /> */}
      <main className="page-landing-desktop h-100 ">

        <IonGrid className="content-desktop p-0 d-flex justify-content-center align-items-center">

          <IonRow >


            <IonCol size="12" className="h-100 d-flex align-items-center flex-column justify-content-center">

              <img style={{ width: '190px', marginBottom: '3rem' }} className="img-fluid d-block" src="/images/landing/logo.png" alt="Logotipo Contadoor" />

              <IonCard style={{ width: '600px' }}>
                <IonCardContent>

                  <IonRow className="text-center">
                    <IonCol>

                      <h2>¿Quieres contratar un freelancer?</h2>

                      <IonButton onClick={() => router.push('/signup', 'client')} style={{ width: '80%', '--border-width': '3px', fontWeight: 600 }} expand="block" shape="round" color="primary">Contrata a un autónomo</IonButton>
                    </IonCol>


                    <IonCol>
                      <h2>¿Quieres trabajar como freelancer?</h2>

                      <IonButton onClick={() => router.push('/signup', 'freelancer')} style={{ width: '80%', '--border-width': '3px', fontWeight: 600 }} shape="round" expand="block" color="primary">Trabajar como autónomo</IonButton>

                    </IonCol>

                    <div style={{ width: '100%', backgroundColor: '#F6f6f6', height: '1px', margin: '1rem 0' }}></div>

                    <p className="d-block mx-auto">¿Ya estás registrado? <span style={{ color: '#6C74E6', cursor: 'pointer', fontWeight: 600 }} onClick={() => router.push('/login')} >Entrar</span></p>
                  </IonRow>
                </IonCardContent>
              </IonCard>

            </IonCol>
          </IonRow>


          {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'url(images/landing/bg_desktop.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center' }}>


            <div style={{ height: '100%', flexGrow: 8, backgroundColor: '', display: 'flex', flexDirection: 'column', justifyContent: 'start', paddingTop: '3rem', alignItems: 'center' }}>


 


            
            </div>

   

            <div style={{ height: '100%', flexGrow: 8, backgroundColor: '', display: 'flex', flexDirection: 'column', justifyContent: 'start', paddingTop: '3rem', alignItems: 'center' }}>
   
              <IonButton onClick={() => router.push('/signup', 'freelancer')} style={{ width: '80%', '--border-width': '3px', fontWeight: 600 }} expand="block" color="primary">SÉ UN FREELANCER</IonButton>
            </div>


          </div> */}
        </IonGrid>
      </main>
    </IonContent>
  )
  else return (
    <IonPage className="page-landing">

      <IonContent color="light" fullscreen>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>


          <div style={{ paddingTop: '2em', flexGrow: 8, backgroundColor: '#5E66E2', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* <IonButton routerLink="/signup?type=client" style={{ width: '80%', '--border-width': '3px', fontWeight: 900 }} fill="outline" expand="block" color="secondary">CONTRATA A UN FREELANCER</IonButton> */}
            <IonButton onClick={() => router.push('/signup', 'client')} style={{ width: '80%', '--border-width': '3px', fontWeight: 900 }} fill="outline" expand="block" color="secondary">CONTRATA A UN FREELANCER</IonButton>
            <img src="images/landing/client.png" />
          </div>

          <div style={{ flexGrow: 4, backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img style={{ width: '40%', padding: '.7em 0' }} src="images/landing/logo.png" />
          </div>

          <div style={{ paddingBottom: '2em', flexGrow: 8, backgroundColor: '#6DECAE', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            <img src="images/landing/freelancer.png" />
            {/* <IonButton routerLink="/signup?type=freelancer" style={{ width: '80%', '--border-width': '3px', fontWeight: 900 }} fill="outline" expand="block" color="primary">SÉ UN FREELANCER</IonButton> */}
            <IonButton onClick={() => router.push('/signup', 'freelancer')} style={{ width: '80%', '--border-width': '3px', fontWeight: 900 }} fill="outline" expand="block" color="primary">SÉ UN FREELANCER</IonButton>
          </div>

          <div style={{ flexGrow: 1, backgroundColor: 'white' }}>
            <IonButton onClick={() => router.push('/login')} fill="clear" color="primary" className="ion-text-center" >

              <p>¿Ya tienes una cuenta?<br /> <strong >Iniciar sesión</strong></p>
            </IonButton>
          </div>


          {/* <IonCol>
            <IonCard >
              <h2>Bienvenido a Contadoor</h2>
              <IonButton expand="block" color="primary" routerLink="/login">iniciar</IonButton>
            </IonCard>
          </IonCol> */}
        </div>
      </IonContent>


    </IonPage>
  )
};
export default Landing;