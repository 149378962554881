import { IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonRouterOutlet, IonRow, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { briefcase, cash, home, people, person, chatbubbles } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../context/UserContext';
import auth from '../services/auth';

const CustomTabs: React.FC<any> = ({ currentPath }) => {

  const { user } = React.useContext(UserContext);

  const router = useHistory()

  if (user?.type === "client") return (
    <IonRow style={{ background: '#3B3FB6', borderTop: '1px solid #CCCCCC', padding: '5px 0', textAlign: 'center' }}>

      <IonCol onClick={() => router.push('/client')} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath === "/client" ? "secondary" : "light"} size="large" icon={home} />
        {/* <IonLabel color={currentPath === "/client" ? "secondary" : "light"}><small>Dashboard</small></IonLabel> */}
      </IonCol>

      <IonCol onClick={() => router.push('/client/jobs')} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath.includes("/client/jobs") ? "secondary" : "light"} size="large" icon={briefcase} />
        {/* <IonLabel color={currentPath.includes("/client/jobs") ? "secondary" : "light"}><small>Servicios</small></IonLabel> */}
      </IonCol>

      <IonCol onClick={() => router.push('/client/freelancers')} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath.includes("/client/freelancers") ? "secondary" : "light"} size="large" icon={people} />
        {/* <IonLabel color={currentPath.includes("/client/freelancers") ? "secondary" : "light"}><small>Freelancers</small></IonLabel> */}
      </IonCol>

      <IonCol onClick={() => router.push("/messages")} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath === "/messages" ? "secondary" : "light"} size="large" icon={chatbubbles} />
        {/* <IonLabel color={currentPath === "/messages" ? "secondary" : "light"}><small>Mensajes</small></IonLabel> */}
      </IonCol>

      <IonCol onClick={() => router.push("/profile")} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath === "/profile" ? "secondary" : "light"} size="large" icon={person} />
        {/* <IonLabel color={currentPath === "/profile" ? "secondary" : "light"}><small>Perfil</small></IonLabel> */}
      </IonCol>


    </IonRow>
  );

  else if (user?.type === "freelancer") return (
    <IonRow style={{ background: '#3B3FB6', borderTop: '1px solid #CCCCCC', padding: '5px 0', textAlign: 'center' }}>

      <IonCol onClick={() => router.push('/freelancer')} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath === "/freelancer" ? "secondary" : "light"} size="large" icon={home} />
        {/* <IonLabel color={currentPath === "/freelancer" ? "secondary" : "light"}><small>Dashboard</small></IonLabel> */}
      </IonCol>

      <IonCol onClick={() => router.push('/freelancer/jobs')} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath.includes("/freelancer/jobs") ? "secondary" : "light"} size="large" icon={briefcase} />
        {/* <IonLabel color={currentPath.includes("/freelancer/jobs") ? "secondary" : "light"}><small>Servicios</small></IonLabel> */}
      </IonCol>

      <IonCol onClick={() => router.push('/freelancer/proposals')} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath.includes("/freelancer/proposals") ? "secondary" : "light"} size="large" icon={cash} />
        {/* <IonLabel color={currentPath.includes("/freelancer/proposals") ? "secondary" : "light"}><small>Propuestas</small></IonLabel> */}
      </IonCol>

      <IonCol onClick={() => router.push("/messages")} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath.includes("/messages") || currentPath.includes("/chat") ? "secondary" : "light"} size="large" icon={chatbubbles} />
        {/* <IonLabel color={currentPath.includes("/messages") || currentPath.includes("/chat") ? "secondary" : "light"}><small>Mensajes</small></IonLabel> */}
      </IonCol>

      <IonCol onClick={() => router.push("/profile")} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <IonIcon color={currentPath === "/profile" ? "secondary" : "light"} size="large" icon={person} />
        {/* <IonLabel color={currentPath === "/profile" ? "secondary" : "light"}><small>Perfil</small></IonLabel> */}
      </IonCol>

    </IonRow>
  );

  else return <></>
};

export default CustomTabs;