import React from 'react';
import { getPlatforms, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonPage, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar } from '@ionic/react';
import Loading from '../../../components/loading';
import Tabs from '../../../components/Tabs';
import { add, person } from 'ionicons/icons';
import { UserContext } from '../../../context/UserContext';
import { customBadges } from '../../../utils/customBadges';
import formatDate from '../../../utils/formatDate'
import { Router, useHistory } from 'react-router';
import Header from '../../../components/desktop/Header';
import Refresher from '../../../components/Refresher/Index';
import MobileHeader from '../../../components/MobileHeader';
import { addDays } from 'date-fns';

const MyJobsClient: React.FC = (props: any) => {

  const router = useHistory();
  const platform: any = getPlatforms();

  const { user, isUserLoading } = React.useContext(UserContext);

  const [jobSegment, setJobSegment] = React.useState<any>("all");
  const [searchText, setSearchText] = React.useState('');
  const [workPreferenceSelect, setWorkPreferenceSelect] = React.useState<any>("any");
  const [jobTypeSelect, setJobTypeSelect] = React.useState<any>("any");

  // Filtro 1 - status
  let myJobs = user ? jobSegment === 'all' ? user.client?.jobs : user.client?.jobs?.filter((job: any) => {

    let status = job.status

    if (status === 'freelancer-finished' || status === 'client-require-job-cancel' || status === 'client-refused') status = 'working'
    else if (status === 'waiting-payment' || status === 'private' || status === 'moderation') status = 'pending'
    else if (status === 'job-finished' || status === 'job-cancelled') status = 'finished'

    return status === jobSegment

  }) : null

  // Filtro 2 - jowork manner
  myJobs = jobTypeSelect === 'any' ? myJobs : myJobs.filter((job: any) => job.type === jobTypeSelect)
  myJobs = workPreferenceSelect === 'any' ? myJobs : myJobs.filter((job: any) => job.workPreference === workPreferenceSelect)

  // Filtro 3 - searchbar
  myJobs = searchText.length > 0
    ? myJobs.filter((job: any) => (
      job.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
      job.description.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) ||
      job.type.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(searchText.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
    ))
    : myJobs


  function handleName(name: any) {
    const a = name.split(' ');
    const b = a.shift();
    return b + " " + a.map((word: any) => word[0] + '.').join(' ')
  }

  if (isUserLoading || !myJobs) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (

    <>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          <div style={{ display: 'flex' }} className="mt-1">
            <div style={{ width: '370px', minWidth: '370px', backgroundColor: 'transparent', borderRadius: '10px', padding: '1rem' }}>
              <IonRow className="ion-no-padding">
                <IonCard style={{ width: "100%", backgroundColor: '#3B3FB6', border: '1px solid #E3E3E3' }} className="ion-margin-none ion-padding-bottom">
                  <IonCardContent>
                    <p style={{ marginBottom: '1.5rem', color: '#FFFFFF', fontSize: '24px' }}>Servicios</p>
                    <p style={{ marginTop: '1.5rem', color: '#FFFFFF' }}>Estado de servicios</p>
                    <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: '1rem' }}>
                      <select value={jobSegment} onChange={(e: any) => setJobSegment(e.target.value!)} style={{ height: '25px', flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }}>
                        <option value="all">Todos</option>
                        <option value="open">Abiertos</option>
                        <option value="pending">Pendientes</option>
                        <option value="working">Trabajando</option>
                        <option value="finished">Terminados</option>
                      </select>
                    </div>
                    <p style={{ marginTop: '1.5rem', color: '#FFFFFF' }}>Tipos de servicios</p>
                    <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: '1rem' }}>
                      <select value={jobTypeSelect} onChange={(e: any) => setJobTypeSelect(e.target.value!)} style={{ height: '25px', flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }}>
                        <option value="any">Todos</option>
                        <option value="auditoria">Auditoría</option>
                        <option value="analise">Análisis</option>
                        <option value="pericia">Pericia</option>
                        <option value="fiscalizacao">Inspección</option>
                        <option value="consultoria">Consultoría</option>
                      </select>
                    </div>
                    <p style={{ marginTop: '1.5rem', color: '#FFFFFF' }}>Forma de trabajo</p>
                    <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1, gap: '1rem' }}>
                      <select value={workPreferenceSelect} onChange={(e: any) => setWorkPreferenceSelect(e.target.value!)} style={{ height: '25px', flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }}>
                        <option value="any">Todos</option>
                        <option value="presential">Presencial</option>
                        <option value="remote">Remoto</option>
                      </select>
                    </div>

                    <IonButton style={{ marginTop: '1.5rem' }} color='dark' expand='block' onClick={() => router.push("/client/new-job", 0)}>Publica un proyecto</IonButton>
                  </IonCardContent>
                </IonCard>
              </IonRow>
            </div>

            {/*  */}

            <div style={{ width: '100%', backgroundColor: 'trasparent', borderRadius: '10px', padding: '1rem', marginBottom: '4rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                <p className='ion-margin-vertical' style={{ fontSize: '15px', color: '#3B3FB6' }}>Encontrado {myJobs?.length} servicio(s)</p>
                <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flex: 1 }}>
                  <input style={{ flex: 1, border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar servicio por nombre o descripción.." />
                  <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
                </div>
              </div>
              {myJobs?.length === 0
                ? <></>
                : myJobs?.map((job: any, index: any) => (
                  <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-margin-top ion-no-padding">
                    <IonCardContent style={{ padding: '2rem' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', flex: 1 }}>
                          <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0" }}>{job.title}</p>
                          <IonChip className='ion-text-center' style={{
                            backgroundColor:
                              job.status === 'open' ? '#00CFB4' :
                                job.status === 'private' ? '#ffc409' :
                                  job.status === 'working' ? '#ffc409' :
                                    job.status === 'waiting-payment' ? '#ffc409' :
                                      job.status === 'freelancer-finished' ? '#2fdf75' :
                                        job.status === 'client-refused' ? '#ff4961' :
                                          job.status === 'client-require-job-cancel' ? '#ffd534' :
                                            job.status === 'moderation' ? '#ffc409' :
                                              job.status === 'job-cancelled' ? '#989aa2' :
                                                job.status === 'job-finished' ? '#989aa2' : '',
                            color: '#F9FAFC',
                            fontSize: '16px',
                            padding: '10px 30px',
                            margin: '0',
                            cursor: 'default',
                          }}>{customBadges(job, user)}</IonChip>
                        </div>
                        <p style={{ fontSize: '16px', color: '#969696', margin: '8px 0' }}>Atualizado el {formatDate(job.updatedAt)}</p>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <p style={{ fontSize: '16px', color: '#2D2D2D', margin: '20px 0' }}>Descripción: {job.description.length < 140 ? job.description : job.description.slice(0, 140) + '...'}</p>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/portfolio.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{
                                  job.type === "auditoria" ? "Auditoría" :
                                    job.type === "analise" ? "Análisis" :
                                      job.type === "pericia" ? "Pericia" :
                                        job.type === "fiscalizacao" ? "Inspección" :
                                          job.type === "consultoria" ? "Consultoría" : ''
                                }</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/Grupo 5269.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Plazo deseado:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/laptop.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button onClick={() => router.push(`/client/jobs/${job._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Ver más</p>
                        </button>
                      </div>
                      <div style={{ display: 'flex', justifyContent: job.proposed ? 'space-between' : 'flex-end', alignItems: 'center', gap: '16px', marginTop: '16px' }}>
                        {job.status === 'open'
                          ? job.proposed
                            ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                              <img height='15px' src='/assets/check.svg' />
                              <p style={{ fontSize: '14px', margin: 0 }}>Propuesta enviada</p>
                            </div>
                            : <></>
                          : <></>
                        }
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                          <img src='/assets/adjunto-archivo.svg' />
                          <p style={{ fontSize: '16px', margin: 0 }}>{job.attachments?.length} archivo adjunto</p>
                        </div>
                      </div>
                    </IonCardContent>
                  </IonCard>
                ))
              }
            </div>
          </div>
        </main>

      </IonContent>
    </>

    // <IonContent className="freelancer-job-desktop">

    //   <Header currentPath={props.match.url} />

    //   <main>
    //     <IonGrid fixed className="content-wrapper">
    //       <IonRow className='ion-margin-top'>

    //         <IonCol size='3'>
    //           <IonRow>
    //             <IonCol style={{ backgroundColor: 'white', borderRadius: '10px' }}>
    //               <IonRow>
    //                 <IonCol className='ion-padding'>

    //                   <div>
    //                     <small className="mb-2 d-block">Estado del servicio</small>
    //                     <select value={jobSegment} onChange={(e: any) => setJobSegment(e.target.value!)} className="custom-select custom-select-lg mb-3 w-100">
    //                       <option value="all">Todos</option>
    //                       <option value="open">Abiertos</option>
    //                       <option value="pending">Pendientes</option>
    //                       <option value="working">Trabajando</option>
    //                       <option value="finished">Terminados</option>
    //                     </select>
    //                   </div>

    //                   <div>
    //                     <small className="mb-2 d-block">Tipo de servicio</small>
    //                     <select value={jobTypeSelect} onChange={(e: any) => setJobTypeSelect(e.target.value!)} className="custom-select custom-select-lg mb-3 w-100">
    //                       <option value="any">Todos</option>
    //                       <option value="auditoria">Auditoría</option>
    //                       <option value="analise">Análisis</option>
    //                       <option value="pericia">Pericia</option>
    //                       <option value="fiscalizacao">Inspección</option>
    //                       <option value="consultoria">Consultoría</option>
    //                     </select>
    //                   </div>

    //                   <div>
    //                     <small className="mb-2 d-block">Forma de trabalho</small>
    //                     <select value={workPreferenceSelect} onChange={(e: any) => setWorkPreferenceSelect(e.target.value!)} className="custom-select custom-select-lg mb-3 w-100">
    //                       <option value="any">Todos</option>
    //                       <option value="presential">Presencial</option>
    //                       <option value="remote">Remoto</option>
    //                     </select>
    //                   </div>

    //                   <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

    //                   <div className='text-center mt-2'>
    //                     <small>Servicios encontrados: {myJobs.length}</small>
    //                   </div>

    //                 </IonCol>
    //               </IonRow>
    //             </IonCol>
    //           </IonRow>
    //         </IonCol>
    //         <IonCol size='9'>

    //           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Procura servicios por nome ou descripción.." />
    //             <IonButton color='dark' onClick={() => router.push("/client/new-job", 0)}>Publica un proyecto</IonButton>
    //           </div>

    //           <div style={{ height: '80vh', overflowY: 'auto' }}>

    //             {
    //               myJobs?.length === 0
    //                 ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //                   <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningún servicio acá...</h4>
    //                   <div>
    //                     <p>Si tiene problemas para mostrar los servicios, verifique lo siguiente:</p>
    //                     <ul>
    //                       <li>Filtros aplicados</li>
    //                       <li>Conexión a Internet</li>
    //                       <li>Conexión com o servidor contadoor</li>
    //                     </ul>
    //                   </div>
    //                 </div>
    //                 : myJobs?.map((job: any, index: any) => (
    //                   <IonCard className="ion-card-desktop" key={index}>

    //                     <IonRow>
    //                       <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
    //                         <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{formatDate(job.updatedAt)}</strong></p>
    //                       </IonCol>
    //                       <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
    //                         display: 'flex',
    //                         justifyContent: 'center',
    //                         alignItems: 'center',
    //                         backgroundColor:
    //                           job.status === 'open' ? '#6DECAE40' :
    //                             job.status === 'private' ? '#ffc40940' :
    //                               job.status === 'working' ? '#ffc40940' :
    //                                 job.status === 'waiting-payment' ? '#ffc40940' :
    //                                   job.status === 'freelancer-finished' ? '#2fdf7540' :
    //                                     job.status === 'client-refused' ? '#ff496140' :
    //                                       job.status === 'client-require-job-cancel' ? '#ffd53440' :
    //                                         job.status === 'moderation' ? '#ffc40940' :
    //                                           job.status === 'job-cancelled' ? '#989aa240' :
    //                                             job.status === 'job-finished' ? '#989aa240' : ''
    //                       }}>
    //                         {customBadges(job, user)}
    //                       </IonCol>
    //                     </IonRow>
    //                     <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

    //                     <IonCardContent>
    //                       <IonRow>
    //                         <IonCol size='8'>
    //                           <div className='d-flex mb-3' style={{ justifyContent: 'space-between' }}>


    //                             <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    //                               <p style={{ fontSize: '1.2rem' }}><strong>{job.title}</strong></p>
    //                             </div>


    //                             <div style={{ fontSize: '1.1rem', margin: '0 1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start' }}>
    //                               <div className='d-flex'>
    //                                 <IonBadge style={{ margin: '0 0.1rem' }} color='dark'>{
    //                                   job.type === "auditoria" ? "Auditoría" :
    //                                     job.type === "analise" ? "Análisis" :
    //                                       job.type === "pericia" ? "Pericia" :
    //                                         job.type === "fiscalizacao" ? "Inspección" :
    //                                           job.type === "consultoria" ? "Consultoría" : ''
    //                                 }</IonBadge>
    //                                 <IonBadge style={{ margin: '0 0.1rem' }} color='dark'>{job.workPreference === 'remote' ? 'Remoto' : 'Presencial'}</IonBadge>
    //                               </div>
    //                             </div>
    //                           </div>


    //                           <p style={{ fontSize: '1rem', textAlign: 'justify', marginRight: '1rem', lineHeight: '1.5rem' }}>{job.description}</p>
    //                         </IonCol>
    //                         <IonCol className='ion-padding-horizontal'>
    //                           <IonButton color='dark' className='mb-3' expand='block' onClick={(e) => { e.stopPropagation(); router.push(`/client/jobs/${job._id}`) }}>Ver más</IonButton>

    //                           {job.status === 'open' ? <p style={{ fontSize: '0.7em' }}>{job.proposals.filter((proposal: any) => proposal.status === "pending").length + ` propuesta${job.proposals.length === 1 ? " recebida" : "s recebidas"}`}</p> : null}
    //                           {job.status === 'private' ? <p style={{ fontSize: '0.7em' }}>{job.proposals.length > 0 ? "¡El autónomo ha presentado su propuesta!" : "Servicio privado enviado al autónomo. Pendiente de propuesta."}</p> : null}
    //                           {job.status === 'waiting-payment' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Aceptaste una propuesta!</span> El servicio comienza después del pago.</p> : null}
    //                           {job.status === 'working' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Pago confirmado!</span> El trabajador autónomo debe finalizar el servicio hasta {job.acceptedProposal.createdAt ? formatDate(addDays(job.acceptedProposal.createdAt, job.acceptedProposal.deadline)) : 'a ser definido..'}</p> : null}
    //                           {job.status === 'freelancer-finished' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El autónomo terminó el trabajo!</span> Esperando tu confirmacion.</p> : null}
    //                           {job.status === 'client-refused' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Rechazaste la finalización del servicio!</span> Esperando nueva finalización por parte del autónomo.</p> : null}
    //                           {job.status === 'job-finished' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El servicio esta terminado!</span></p> : null}
    //                           {job.status === 'job-cancelled' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El servicio ha sido cancelado!</span></p> : null}
    //                           {job.status === 'moderation' ? <p style={{ fontSize: '0.7em', padding: '0 1rem' }}><span style={{ color: '#ED6060' }}>¡Servicio en proceso de disputa!</span> A la espera de la moderación del equipo de Contadoor.</p> : null}
    //                           {job.status === 'client-require-job-cancel' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Cancelación del servicio solicitada! </span>Esperando la decisión del autónomo.</p> : null}

    //                         </IonCol>
    //                       </IonRow>
    //                       <div style={{ display: 'flex', marginTop: '0.5rem' }}>
    //                         {job.requirements?.slice(0, 3).map((req: any, index: any) => (
    //                           <IonChip style={{ fontSize: '0.7rem' }} key={index}>{req}</IonChip>
    //                         ))}
    //                       </div>
    //                     </IonCardContent>
    //                   </IonCard>
    //                 ))}
    //           </div>
    //         </IonCol>
    //       </IonRow>
    //     </IonGrid>
    //   </main>
    // </IonContent >
  )

  else return (

    <IonPage>
      {/* <IonHeader> */}
      <MobileHeader />
      {/* <IonToolbar color='primary'>
        <IonSegment onIonChange={(e: any) => { setJobSubSegment(e.detail.value); setMyJobsOnly(e.detail.value === 'open' ? false : true) }} value={jobSubSegment}>
          <IonSegmentButton value='open'>Servicios Abiertos</IonSegmentButton>
          <IonSegmentButton value='my'>Mis Servicios</IonSegmentButton>
        </IonSegment>
      </IonToolbar>
      <IonToolbar style={{ padding: '0' }}>
        <IonSearchbar style={{ padding: '0' }} value={searchText} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Procura servicios por nome ou descripción.." />
      </IonToolbar> */}
      {/* <IonToolbar>
        <IonButton expand='block' onClick={() => setShowPopover(!showPopover)}>Filtrar <IonIcon icon={menuOutline} /></IonButton>
        <IonPopover
        isOpen={showPopover}
        onDidDismiss={() => setShowPopover(!showPopover)}
        >
        <IonButton color={aColor}>Todos</IonButton>
        <IonButton color={rColor}>Remoto</IonButton>
        <IonButton color={lColor}>Local</IonButton>
        </IonPopover>
      </IonToolbar> */}
      {/* </IonHeader> */}
      <IonContent color="medium" className="ion-padding">
        {/* {
        myJobsOnly ?
          <IonSegment onIonChange={(e: any) => setJobSegment(e.detail.value)} value={jobSegment}>
            <IonSegmentButton value='all'>Todos</IonSegmentButton>
            <IonSegmentButton value='pending'>Pendientes</IonSegmentButton>
            <IonSegmentButton value='working'>Trabajando</IonSegmentButton>
            <IonSegmentButton value='finished'>Terminados</IonSegmentButton>
          </IonSegment>

          : <></>
      } */}
        {/* <Refresher setUpdateContext={setUpdateUserContext} /> */}

        {/* <p style={{ fontSize: '21px', color: '#000000', fontWeight: 500 }}>Servicios</p> */}

        <div style={{ borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <input style={{ border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar servicio.." />
          <img style={{ color: '#919191' }} src='/assets/search-1.svg' />
        </div>

        <p className='ion-margin-vertical' style={{ fontSize: '15px', color: '#3B3FB6' }}>Servicios encontrados: {myJobs?.length} </p>


        {myJobs?.length === 0
          ? <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
            <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningún servicio acá...</h4>
            <div>
              <p>Si tiene problemas para mostrar los autónomos, verifique lo siguiente:</p>
              <ul>
                <li>Filtros aplicados</li>
                <li>Conexión a Internet</li>
                <li>Conexión al servidor Contadoor</li>
              </ul>
            </div>
          </div>
          : myJobs?.map((job: any, index: any) => (

            <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-text-center ion-margin-top ion-padding-vertical">
              <IonCardContent>
                <p style={{ fontSize: "16px", color: "#2D2D2D", margin: '0', fontWeight: 600 }}>{job.title}</p>
                <IonChip className='ion-text-center ion-margin-vertical' style={{
                  backgroundColor:
                    job.status === 'open' ? '#00CFB4' :
                      job.status === 'private' ? '#ffc409' :
                        job.status === 'working' ? '#ffc409' :
                          job.status === 'waiting-payment' ? '#ffc409' :
                            job.status === 'freelancer-finished' ? '#2fdf75' :
                              job.status === 'client-refused' ? '#ff4961' :
                                job.status === 'client-require-job-cancel' ? '#ffd534' :
                                  job.status === 'moderation' ? '#ffc409' :
                                    job.status === 'job-cancelled' ? '#989aa2' :
                                      job.status === 'job-finished' ? '#989aa2' : '',
                  color: '#F9FAFC',
                  fontSize: '16px',
                  padding: '10px 30px',
                  margin: '16px 0',
                }}>{customBadges(job, user)}</IonChip>

                <p style={{ fontSize: '15px', color: '#969696', marginBottom: '16px' }}>Atualizado el {formatDate(job.updatedAt)}</p>

                <p style={{ fontSize: '15px', color: '#2D2D2D', margin: '20px 0' }}>{job.description.length < 140 ? job.description : job.description.slice(0, 140) + '...'}</p>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '32px 0' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <img height='30px' src='/assets/portfolio.svg' />
                    </div>
                    <div style={{ marginTop: '12px' }}>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio</p>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{
                        job.type === "auditoria" ? "Auditoría" :
                          job.type === "analise" ? "Análisis" :
                            job.type === "pericia" ? "Pericia" :
                              job.type === "fiscalizacao" ? "Inspección" :
                                job.type === "consultoria" ? "Consultoría" : ''
                      }</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <img height='30px' src='/assets/Grupo 5269.svg' />
                    </div>
                    <div style={{ marginTop: '12px' }}>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Plazo deseado</p>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <img height='30px' src='/assets/laptop.svg' />
                    </div>
                    <div style={{ marginTop: '12px' }}>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo</p>
                      <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                    </div>
                  </div>
                </div>

                <div className='ion-margin-vertical' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '16px' }}>
                  {job.status === 'open'
                    ? job.proposed
                      ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                        <img height='15px' src='/assets/check.svg' />
                        <p style={{ fontSize: '14px', margin: 0 }}>Propuesta enviada</p>
                      </div>
                      : <></>
                    : <></>
                  }
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                    <img src='/assets/adjunto-archivo.svg' />
                    <p style={{ fontSize: '14px', margin: 0 }}>{job.attachments?.length} archivo adjunto</p>
                  </div>
                </div>

                <button style={{ marginTop: '16px', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                  <p style={{ margin: '0', fontSize: '17px' }} onClick={() => router.push(`/client/jobs/${job._id}`)}>Ver más</p>
                </button>
              </IonCardContent>
            </IonCard>
          ))
        }

        <IonFab vertical="bottom" horizontal="end" slot="fixed" style={{ marginBottom: '0.5rem', marginRight: '0.5rem' }}>
          <IonFabButton color='secondary' onClick={() => router.push("/client/new-job", 0)}>
            <IonIcon color="light" icon={add} />
          </IonFabButton>
        </IonFab>

      </IonContent>
      <IonFooter color="dark">
        <Tabs currentPath={props.match.url} />
      </IonFooter>
    </IonPage>


    // <IonPage>
    //   <IonHeader >
    //     <MobileHeader title='Mis servicios' backButton={false} />
    //     <IonToolbar color="primary">
    //       <IonSegment onIonChange={(e: any) => setJobSegment(e.detail.value)} value={jobSegment}>
    //         <IonSegmentButton value='all'>Todos</IonSegmentButton>
    //         <IonSegmentButton value='open'>Abiertos</IonSegmentButton>
    //         <IonSegmentButton value='pending'>Pendientes</IonSegmentButton>
    //         <IonSegmentButton value='working'>Trabajando</IonSegmentButton>
    //         <IonSegmentButton value='finished'>Finalizado</IonSegmentButton>
    //       </IonSegment>
    //     </IonToolbar>
    //     <IonToolbar style={{ padding: '0' }}>
    //       <IonSearchbar style={{ padding: '0' }} value={searchText} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Procura servicios por nome ou descripción.." />
    //     </IonToolbar>
    //   </IonHeader>
    //   <IonContent color="medium">
    //     {myJobs?.length === 0
    //       ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //         <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningún servicio acá...</h4>
    //         <div>
    //           <p>Si tiene problemas para mostrar los autónomos, verifique lo siguiente:</p>
    //           <ul>
    //             <li>Filtros aplicados</li>
    //             <li>Conexión a Internet</li>
    //             <li>Conexión com o servidor contadoor</li>
    //           </ul>
    //         </div>
    //       </div>
    //       : myJobs?.map((job: any, index: any) => (
    //         <IonCard className='ion-no-padding' key={index} onClick={() => router.push(`/client/jobs/${job._id}`)}>
    //           <IonRow>
    //             <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
    //               <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{formatDate(job.updatedAt)}</strong></p>
    //             </IonCol>
    //             <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
    //               display: 'flex',
    //               justifyContent: 'center',
    //               alignItems: 'center',
    //               backgroundColor:
    //                 job.status === 'open' ? '#6DECAE40' :
    //                   job.status === 'private' ? '#ffc40940' :
    //                     job.status === 'working' ? '#ffc40940' :
    //                       job.status === 'waiting-payment' ? '#ffc40940' :
    //                         job.status === 'freelancer-finished' ? '#2fdf7540' :
    //                           job.status === 'client-refused' ? '#ff496140' :
    //                             job.status === 'client-require-job-cancel' ? '#ffd53440' :
    //                               job.status === 'moderation' ? '#ffc40940' :
    //                                 job.status === 'job-cancelled' ? '#989aa240' :
    //                                   job.status === 'job-finished' ? '#989aa240' : ''
    //             }}>
    //               {customBadges(job, user)}
    //             </IonCol>
    //           </IonRow>
    //           <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //           <IonCardContent style={{ paddingBottom: '0' }}>
    //             <h1><strong>{job.title}</strong></h1>
    //             <p style={{ fontSize: '0.7em' }}>{
    //               job.type === "auditoria" ? "Auditoría".toUpperCase() :
    //                 job.type === "analise" ? "Análisis".toUpperCase() :
    //                   job.type === "pericia" ? "Pericia".toUpperCase() :
    //                     job.type === "fiscalizacao" ? "Inspección".toUpperCase() :
    //                       job.type === "consultoria" ? "Consultoría".toUpperCase() : ''
    //             } | {job.workPreference === 'remote' ? 'REMOTO' : 'PRESENCIAL'}</p>
    //             <p style={{ fontSize: '0.8em', margin: '1.1em 0' }} >{job.description.length < 30 ? job.description : job.description.slice(0, 30) + '...'}</p>
    //           </IonCardContent>
    //           <IonFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

    //             {/* <IonRow style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start' }}> */}

    //             {job.status === 'open' ? <p style={{ fontSize: '0.7em' }}>{job.proposals.filter((proposal: any) => proposal.status === "pending").length + ` propuesta${job.proposals.length === 1 ? " recebida" : "s recebidas"}`}</p> : null}
    //             {job.status === 'private' ? <p style={{ fontSize: '0.7em' }}>{job.proposals.length > 0 ? "¡El autónomo ha presentado su propuesta!" : "Servicio privado enviado al autónomo. Pendiente de propuesta."}</p> : null}
    //             {job.status === 'waiting-payment' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Aceptaste una propuesta!</span> El servicio comienza después del pago.</p> : null}
    //             {job.status === 'working' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Pago confirmado!</span> El trabajador autónomo debe finalizar el servicio antes de {job.acceptedProposal.createdAt ? formatDate(addDays(job.acceptedProposal.createdAt, job.acceptedProposal.deadline)) : 'a ser definido..'}</p> : null}
    //             {job.status === 'freelancer-finished' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El autónomo terminó el trabajo!</span> Esperando tu confirmacion.</p> : null}
    //             {job.status === 'client-refused' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Rechazaste la finalización del servicio!</span> Esperando nueva finalización por parte del autónomo.</p> : null}
    //             {job.status === 'job-finished' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El servicio esta terminado!</span></p> : null}
    //             {job.status === 'job-cancelled' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El servicio ha sido cancelado!</span></p> : null}
    //             {job.status === 'moderation' ? <p style={{ fontSize: '0.7em', padding: '0 1rem' }}><span style={{ color: '#ED6060' }}>¡Servicio en proceso de disputa!</span> A la espera de la moderación del equipo de Contadoor.</p> : null}
    //             {job.status === 'client-require-job-cancel' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Cancelación del servicio solicitada. </span>Esperando la decisión del autónomo.</p> : null}

    //             {/* </IonRow> */}
    //           </IonFooter>
    //         </IonCard>
    //       ))
    //     }
    //     <IonFab vertical="bottom" horizontal="center" slot="fixed" style={{ marginBottom: '1em' }}>
    //       <IonFabButton color='primary' onClick={() => router.push("/client/new-job", 0)}>
    //         <IonIcon color="light" icon={add} />
    //       </IonFabButton>
    //     </IonFab>
    //   </IonContent>
    //   <IonFooter>
    //     {/* <IonButton expand="full" routerLink="/client/new-job">New Job</IonButton> */}
    //     <Tabs currentPath={props.match.url} />
    //   </IonFooter>
    // </IonPage >
  )
};

export default MyJobsClient;