import { getPlatforms, IonAlert, IonAvatar, IonBackButton, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonText, IonTextarea, IonThumbnail, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import React from 'react';
import api from '../../../services/api'
import { alertCircleOutline, arrowDown, arrowDownCircleOutline, arrowDownOutline, attachOutline, briefcaseOutline, calendar, calendarOutline, cashOutline, checkmarkCircle, checkmarkCircleOutline, checkmarkOutline, chevronBack, chevronDown, close, createOutline, documentTextOutline, laptopOutline, trashOutline } from 'ionicons/icons'


// Components
import Loading from '../../../components/loading';
import BackButton from '../../../components/BackButton';
import localStorage from '../../../services/localStorage';
import { customBadges } from '../../../utils/customBadges';
import { UserContext } from '../../../context/UserContext';
import auth from '../../../services/auth';
import formatDate from '../../../utils/formatDate';
import SucessAlert from '../../../components/Alert/SucessAlert';
import { useHistory } from 'react-router';
import Header from '../../../components/desktop/Header';
import Tabs from '../../../components/Tabs';
import MobileHeader from '../../../components/MobileHeader';

import { addDays } from 'date-fns'


const ShowJob: React.FC = (props: any) => {

  const router = useHistory();
  const platform: any = getPlatforms();

  const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext)

  const [showModal, setShowModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);

  const [cancelProposalConf, setCancelProposalConf] = React.useState(false);

  const [isSucessAlertOpen, setIsSucessAlertOpen] = React.useState(false);

  const job = user ? user.freelancer.jobs.find((job: any) => job._id === props.match.params.jobID) : null

  const proposal = user ? user?.freelancer?.proposals?.find((proposal: any) => proposal.job._id === props.match.params.jobID) : null

  const handleProposal = async (e: any) => {
    e.preventDefault()

    const { description, deadline, price } = e.target;

    // const freelancerID = await localStorage.getItem('token')

    try {
      const config = await auth.getHeaderConfig()
      await api.post(`/proposal/${props.match.params.jobID}`, {
        description: description.value,
        deadline: deadline.value,
        price: price.value,
      }, config)

      setIsSucessAlertOpen(true);
      setUpdateUserContext((previous: any) => !previous);
      setShowModal(false);
    } catch (error) {
      console.log("Erro no handleProposal:", error?.response?.data)
      return;
    }

  }

  async function handleFinishJob(jobStatus: any) {
    try {
      const config = await auth.getHeaderConfig()
      await api.patch(`/job/${job._id}`, { status: jobStatus }, config)
      setUpdateUserContext((previous: any) => !previous);
      router.push('/freelancer/jobs')
    } catch (error) {
      console.log("Erro no handleFinishJob:", error?.response?.data)
      return;
    }
  }

  async function handleEditProposal(e: any) {
    e.preventDefault()

    const { description, deadline, price } = e.target;

    try {
      const config = await auth.getHeaderConfig()
      await api.patch(`/proposal/${proposal._id}`, {
        description: description.value,
        deadline: deadline.value,
        price: price.value,
      }, config)

      // setIsSucessAlertOpen(true);
      setUpdateUserContext((previous: any) => !previous);
      setShowEditModal(false);
      router.push('/freelancer/proposals', { setPending: true })
    } catch (error) {
      console.log("Erro no handleProposal:", error?.response?.data)
      return;
    }
  }

  async function handleDeleteProposal() {
    try {
      const config = await auth.getHeaderConfig()
      await api.delete(`/proposal/${proposal._id}`, config);
      setUpdateUserContext((previous: any) => !previous);
      setShowEditModal(false);

    } catch (error) {
      // setToastMessage("Erro en eliminar servicio, intenta de nuevo..");
      // setToast(true)
      console.log("Erro no handleDeleteJob:", error?.response?.data)
      return;
    }
  }

  if (isUserLoading || !job) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (

    <>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          <div style={{ display: 'flex', gap: '0.5rem', width: '100%', height: '218px', position: 'absolute', top: 0, left: 0, zIndex: 0, backgroundColor: '#7A82EA', padding: '2rem' }} >
            <div style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer', zIndex: 2 }}
              // onClick={() => router.push('/freelancer/jobs')}
              onClick={() => router.goBack()}
            >
              <IonIcon size='large' color='light' icon={chevronBack} />
              <p style={{ margin: '0', fontSize: '14px', color: '#FFFFFF', marginTop: '0.4rem' }}>Volver</p>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1 }} className="mt-1">
            <div style={{ display: 'flex', alignItems: 'center', zIndex: 1 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', padding: '2rem 0', zIndex: 1 }}>
                <p style={{ fontSize: "24px", color: "#FFFFFF", margin: "0" }}>{job.title}</p>
                <IonChip className='ion-text-center' style={{
                  backgroundColor:
                    job.status === 'open' ? '#00CFB4' :
                      job.status === 'private' ? '#ffc409' :
                        job.status === 'working' ? '#ffc409' :
                          job.status === 'waiting-payment' ? '#ffc409' :
                            job.status === 'freelancer-finished' ? '#2fdf75' :
                              job.status === 'client-refused' ? '#ff4961' :
                                job.status === 'client-require-job-cancel' ? '#ffd534' :
                                  job.status === 'moderation' ? '#ffc409' :
                                    job.status === 'job-cancelled' ? '#989aa2' :
                                      job.status === 'job-finished' ? '#989aa2' : '',
                  color: '#F9FAFC',
                  fontSize: '16px',
                  padding: '10px 30px',
                  margin: '0',
                  cursor: 'default',
                }}>{customBadges(job, user)}</IonChip>
              </div>
            </div>

            <div style={{ width: '65%' }}>
              <IonCard style={{ width: "100%", backgroundColor: '#F9FAFC', padding: '2rem' }} className="ion-margin-top">
                <IonCardContent style={{ padding: '2rem' }}>
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <p style={{ fontSize: '14px', color: '#969696', margin: '0' }}>Atualizado el {formatDate(job.updatedAt)}</p>
                    {job.status === 'open'
                      ? job.proposed
                        ? <IonChip className='ion-text-center' style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: '#00CFB420',
                          color: '#616161',
                          fontSize: '16px',
                          padding: '10px 20px',
                          margin: '0',
                          cursor: 'default',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '1rem',
                        }}>
                          <img height='20px' src='/assets/check.svg' />
                          <p style={{ fontSize: '13px', margin: 0 }}>Propuesta enviada</p>
                        </IonChip>

                        : <></>
                      : <></>
                    }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <p style={{ fontSize: '16px', color: '#2D2D2D', margin: '2rem 0' }}><strong>Descripción: </strong>{job.description.length < 140 ? job.description : job.description.slice(0, 140) + '...'}</p>

                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '2rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img width='20px' src='/assets/portfolio.svg' />
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Tipo de servicio:</strong></p>
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{
                            job.type === "auditoria" ? "Auditoría" :
                              job.type === "analise" ? "Análisis" :
                                job.type === "pericia" ? "Pericia" :
                                  job.type === "fiscalizacao" ? "Inspección" :
                                    job.type === "consultoria" ? "Consultoría" : ''
                          }</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img width='20px' src='/assets/Grupo 5269.svg' />
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Plazo deseado:</strong></p>
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img width='20px' src='/assets/laptop.svg' />
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Forma de trabajo:</strong></p>
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                        </div>
                      </div>

                    </div>

                  </div>
                  <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "2rem 0" }}><strong>Archivos adjuntos</strong></p>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                    {
                      job.attachments
                        ? job.attachments.map((attachment: any, index: any) => (
                          <React.Fragment key={index}>
                            <a href={attachment.url} target="_blank" style={{ textDecoration: 'none' }}>
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                                <IonItem lines='none' style={{ '--border-width': '1px', '--border-radius': '10px', '--border-color': '#D4D4D4', width: '190px', '--background': '#F5F5F5' }}>
                                  <p style={{ color: '#000000', margin: 0 }}>{attachment.name}</p>
                                  <IonIcon style={{ height: '20px' }} color='dark' slot="end" icon='/assets/download.svg' />
                                </IonItem>
                              </div>
                            </a>
                          </React.Fragment>
                        ))
                        : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{"No informado"}</p>
                    }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1.5rem' }}>

                    {
                      job?.status === 'open' || job?.status === 'private'
                        ? job.proposed
                          ? <button onClick={() => router.push(`/freelancer/proposals/${proposal?._id}`)} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Ver su propusta</p>
                          </button>
                          : <button onClick={() => setShowModal(true)} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Enviar propuesta</p>
                          </button>
                        : job?.status === 'working'
                          ? <button onClick={() => handleFinishJob("freelancer-finished")} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Finalizar servicio</p>
                          </button>
                          : job?.status === 'client-require-job-cancel'
                            ? <>
                              <button onClick={() => handleFinishJob("job-cancelled")} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                <p style={{ margin: '0', fontSize: '17px' }}>Aceptar cancelamento</p>
                              </button>
                              <button onClick={() => handleFinishJob("moderation")} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                <p style={{ margin: '0', fontSize: '17px' }}>Rechazar cancelamento</p>
                              </button>
                            </>
                            : <></>
                    }

                    <button onClick={() => router.push(`/messages`, { socketId: (user._id + job.client) })} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                      <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                    </button>
                  </div>
                </IonCardContent>
              </IonCard>
            </div>
          </div>
        </main>

        <IonModal isOpen={showModal} swipeToClose={true} onDidDismiss={() => setShowModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              {/* <BackButton /> */}
              <IonButton color='light' size="small" fill="clear" onClick={() => setShowModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Propuesta</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color='medium'>


            <IonCard className='ion-no-padding'>
              <IonRow>
                <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
                  <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{formatDate(job.updatedAt)}</strong></p>
                </IonCol>
                <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor:
                    job.status === 'open' ? '#6DECAE' :
                      job.status === 'private' ? '#ffc409' :
                        job.status === 'working' ? '#ffc409' :
                          job.status === 'waiting-payment' ? '#ffc409' :
                            job.status === 'freelancer-finished' ? '#2fdf75' :
                              job.status === 'client-refused' ? '#ff4961' :
                                job.status === 'client-require-job-cancel' ? '#ffd534' :
                                  job.status === 'moderation' ? '#ffc409' :
                                    job.status === 'job-cancelled' ? '#989aa2' :
                                      job.status === 'job-finished' ? '#989aa2' : ''
                }}>
                  {customBadges(job, user)}
                </IonCol>
              </IonRow>
              <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
              <IonCardContent>
                <h1><strong>{job.title}</strong></h1>
                <p style={{ fontSize: '0.7em' }}>{
                  job.type === "auditoria" ? "Auditoría".toUpperCase() :
                    job.type === "analise" ? "Análisis".toUpperCase() :
                      job.type === "pericia" ? "Pericia".toUpperCase() :
                        job.type === "fiscalizacao" ? "Inspección".toUpperCase() :
                          job.type === "consultoria" ? "Consultoría".toUpperCase() : ''
                }</p>
                <p style={{ fontSize: '0.8em', marginTop: '1.1em' }} >{job?.description?.length < 20 ? job.description : job.description.slice(0, 20) + '...'}</p>
                <IonFooter>
                </IonFooter>
              </IonCardContent>
              <IonFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: '0.7em' }}>Disponible para recibir propuestas</p>
              </IonFooter>
            </IonCard>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
              <IonIcon className="ion-text-center ion-padding-horizontal" icon={chevronDown} />
              <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
            </div>

            <form onSubmit={(e: any) => handleProposal(e)}>
              <IonCard style={{ paddingBottom: '20px', paddingTop: '5px' }} className='ion-no-padding'>
                <IonList>
                  <IonItem>
                    <IonLabel position='floating'>Descripción</IonLabel>
                    <IonTextarea
                      className='ion-no-padding'
                      name='description'
                      required={true}
                      maxlength={100}
                      rows={1}
                    />
                  </IonItem>
                  <IonItem className='ion-align-items-center'>
                    <IonLabel position='floating'>Plazo</IonLabel>
                    <IonInput
                      className='ion-no-padding'
                      name='deadline'
                      type='text'
                      required={true}
                      maxlength={3}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position='floating'>Precio</IonLabel>
                    <IonInput
                      name='price'
                      type='number'
                      required={true}
                    />
                  </IonItem>
                </IonList>
              </IonCard>
              <IonButton type='submit' color='dark' className="ion-margin" expand="block">Enviar propuesta</IonButton>
            </form>
          </IonContent>
        </IonModal>


        <IonModal isOpen={showEditModal} swipeToClose={true} onDidDismiss={() => setShowEditModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonButton color='light' size="small" fill="clear" onClick={() => setShowEditModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Edición de la propuesta</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color='medium'>


            <form onSubmit={(e: any) => handleEditProposal(e)}>
              <IonCard className='ion-no-padding'>
                <IonList>
                  <IonItem>
                    <IonLabel position='floating'>Descripción</IonLabel>
                    <IonTextarea
                      value={proposal?.description}
                      className='ion-no-padding'
                      name='description'
                      required={true}
                      maxlength={100}
                      rows={1}
                    />
                  </IonItem>
                  <IonItem className='ion-align-items-center'>
                    <IonLabel position='floating'>Plazo</IonLabel>
                    <IonInput
                      value={proposal?.deadline}
                      className='ion-no-padding'
                      name='deadline'
                      type='text'
                      required={true}
                      maxlength={3}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position='floating'>Precio</IonLabel>
                    <IonInput
                      value={proposal?.price}
                      name='price'
                      type='number'
                      required={true}
                    />
                  </IonItem>
                </IonList>
                <div className="d-flex justify-content-center align-items-center mx-3 my-3">
                  <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Salvar propuesta</button>
                  <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setShowEditModal(false)}>Cancelar</button>
                </div>
              </IonCard>
            </form>
          </IonContent>
        </IonModal>


        <SucessAlert isOpen={isSucessAlertOpen} setIsOpen={setIsSucessAlertOpen} text="¡Propuesta enviada con éxito! Se le notificará en breve con la respuesta del cliente." />
        <IonAlert
          isOpen={cancelProposalConf}
          onDidDismiss={() => setCancelProposalConf(false)}
          message='¿Seguro que quieres eliminar la propuesta atual?'
          buttons={[{ text: 'Si', handler: handleDeleteProposal }, 'No']}
        />



      </IonContent>
    </>

  )

  else return (
    <IonPage>
      <IonHeader>
        <MobileHeader title='Servicios disponibles' backButton={true} />
      </IonHeader>
      <IonContent color='medium' >

        <div className="d-flex justify-content-center" style={{ height: '14rem', zIndex: 0, backgroundColor: '#7A82EA', padding: '2rem 0' }} >
          <div style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer' }} >

            <div style={{ display: 'flex', width: '100%', position: 'absolute', top: 0, left: 0, zIndex: 0, backgroundColor: '#7A82EA', padding: '1rem' }} >
              <div style={{ display: 'flex', gap: '0.2rem', cursor: 'pointer', zIndex: 2 }}
                // onClick={() => router.push('/freelancer/jobs')}
                onClick={() => router.goBack()}
              >
                <IonIcon color='light' icon={chevronBack} />
                <p style={{ margin: '0', fontSize: '12px', color: '#FFFFFF' }}>Volver</p>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center align-items-center" style={{ zIndex: 1 }}>
              <div
                className="d-flex flex-column justify-content-center align-items-center py-3"
                style={{ zIndex: 1 }}>

                <div className="d-flex algin-items-center justify-content-center">
                  <p className="mb-2 ml-2" style={{ fontSize: "1.2rem", textAlign: "center", color: "#FFFFFF", margin: "0" }}>{job.title}</p>
                </div>

                <IonChip className='ion-text-center' style={{
                  backgroundColor:
                    job.status === 'open' ? '#00CFB4' :
                      job.status === 'private' ? '#ffc409' :
                        job.status === 'working' ? '#ffc409' :
                          job.status === 'waiting-payment' ? '#ffc409' :
                            job.status === 'freelancer-finished' ? '#2fdf75' :
                              job.status === 'client-refused' ? '#ff4961' :
                                job.status === 'client-require-job-cancel' ? '#ffd534' :
                                  job.status === 'moderation' ? '#ffc409' :
                                    job.status === 'job-cancelled' ? '#989aa2' :
                                      job.status === 'job-finished' ? '#989aa2' : '',
                  color: '#F9FAFC',
                  fontSize: '16px',
                  padding: '10px 30px',
                  margin: '0',
                  cursor: 'default',
                }}>{customBadges(job, user)}</IonChip>
              </div>
            </div>




          </div>
        </div>



        <IonCard className="ion-margin" style={{ marginTop: "-3rem" }}>
          <IonCardContent style={{ padding: '2rem' }}>
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>

              <p style={{ fontSize: '14px', color: '#969696', margin: '0' }}>Atualizado el {formatDate(job.updatedAt)}</p>
              {job.status === 'open'
                ? job.proposed
                  ? <IonChip className='ion-text-center' style={{
                    backgroundColor: '#00CFB420',
                    color: '#616161',
                    fontSize: '16px',
                    padding: '10px 20px',
                    marginTop: '1rem',
                    cursor: 'default',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                  }}>
                    <img height='20px' src='/assets/check.svg' />
                    <p style={{ fontSize: '13px', margin: 0 }}>Propuesta enviada</p>
                  </IonChip>

                  : <></>
                : <></>
              }
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: '16px', color: '#2D2D2D', margin: '2rem 0' }}><strong>Descripción: </strong>{job.description.length < 140 ? job.description : job.description.slice(0, 140) + '...'}</p>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '2rem' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    <img width='20px' src='/assets/portfolio.svg' />
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Tipo de servicio:</strong></p>
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{
                      job.type === "auditoria" ? "Auditoría" :
                        job.type === "analise" ? "Análisis" :
                          job.type === "pericia" ? "Pericia" :
                            job.type === "fiscalizacao" ? "Inspección" :
                              job.type === "consultoria" ? "Consultoría" : ''
                    }</p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    <img width='20px' src='/assets/Grupo 5269.svg' />
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Plazo deseado:</strong></p>
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    <img width='20px' src='/assets/laptop.svg' />
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Forma de trabajo:</strong></p>
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                  </div>
                </div>

              </div>

            </div>
            <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "2rem 0" }}><strong>Archivos adjuntos</strong></p>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
              {
                job.attachments
                  ? job.attachments.map((attachment: any, index: any) => (
                    <React.Fragment key={index}>
                      <a href={attachment.url} target="_blank" style={{ textDecoration: 'none' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                          <IonItem lines='none' style={{ '--border-width': '1px', '--border-radius': '10px', '--border-color': '#D4D4D4', width: '190px', '--background': '#F5F5F5' }}>
                            <p style={{ color: '#000000', margin: 0 }}>{attachment.name}</p>
                            <IonIcon style={{ height: '20px' }} color='dark' slot="end" icon='/assets/download.svg' />
                          </IonItem>
                        </div>
                      </a>
                    </React.Fragment>
                  ))
                  : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{"No informado"}</p>
              }
            </div>
            <div className="flex-column" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              {
                job?.status === 'open' || job?.status === 'private'
                  ? job.proposed
                    ? <button onClick={() => router.push(`/freelancer/proposals/${proposal?._id}`)} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                      <p style={{ margin: '0', fontSize: '17px' }}>Ver su propusta</p>
                    </button>
                    : <button onClick={() => setShowModal(true)} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                      <p style={{ margin: '0', fontSize: '17px' }}>Enviar propuesta</p>
                    </button>
                  : job?.status === 'working'
                    ? <button onClick={() => handleFinishJob("freelancer-finished")} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                      <p style={{ margin: '0', fontSize: '17px' }}>Finalizar servicio</p>
                    </button>
                    : job?.status === 'client-require-job-cancel'
                      ? <>
                        <button onClick={() => handleFinishJob("job-cancelled")} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Aceptar cancelamento</p>
                        </button>
                        <button onClick={() => handleFinishJob("moderation")} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Rechazar cancelamento</p>
                        </button>
                      </>
                      : <></>
              }

              <button onClick={() => router.push(`/chat/${user._id + job.client}`)} style={{ marginTop: '0.6rem', borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
              </button>
            </div>
          </IonCardContent>
        </IonCard>






        {/* {proposal
          ? <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
              <IonIcon className="ion-text-center ion-padding-horizontal" icon={chevronDown} />
              <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
            </div>
            <IonCard className='ion-no-padding'>
              <IonRow style={{ display: 'flex', justifyContent: (job.status === 'open' ? 'space-between' : 'center') }}>
                {job.status === 'open' ? <IonButton size='small' fill='clear' onClick={() => setShowEditModal(true)}><IonIcon icon={createOutline} /></IonButton> : <></>}
                <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{formatDate(proposal.updatedAt)}</strong></p>
                {job.status === 'open' ? <IonButton size='small' fill='clear' color='danger' onClick={() => setCancelProposalConf(true)}><IonIcon icon={trashOutline} /></IonButton> : <></>}
              </IonRow>
              <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
              <IonCardContent>
                <IonRow>
                  <IonCol>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.3em' }}>
                      <IonIcon color='dark' icon={documentTextOutline} />
                      <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Descripción</IonText>
                    </div>
                    <p style={{ fontSize: '1em', marginBottom: '0.3em', marginLeft: '0.5em' }}>{proposal.description}</p>
                  </IonCol>
                </IonRow>
                <IonRow style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonCol>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                      <IonIcon color='dark' icon={calendarOutline} />
                      <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Plazo</IonText>
                    </div>
                    <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{proposal.deadline} dias</p>
                  </IonCol>
                  <IonCol>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                      <IonIcon color='dark' icon={cashOutline} />
                      <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Precio</IonText>
                    </div>
                    <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{proposal.price} usd</p>
                  </IonCol>
                </IonRow>
                <IonButton color='dark' size='small' expand='block' className='ion-margin-top' onClick={() => router.push(`/freelancer/proposals/${proposal?._id}`)}>Ver detalles</IonButton>
              </IonCardContent>
            </IonCard>
          </>
          : <></>
        } */}

        <IonModal isOpen={showModal} swipeToClose={true} onDidDismiss={() => setShowModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              {/* <BackButton /> */}
              <IonButton color='light' size="small" fill="clear" onClick={() => setShowModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Propuesta</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color='medium'>


            <IonCard className='ion-no-padding'>
              <IonRow>
                <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
                  <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{formatDate(job.updatedAt)}</strong></p>
                </IonCol>
                <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor:
                    job.status === 'open' ? '#6DECAE' :
                      job.status === 'private' ? '#ffc409' :
                        job.status === 'working' ? '#ffc409' :
                          job.status === 'waiting-payment' ? '#ffc409' :
                            job.status === 'freelancer-finished' ? '#2fdf75' :
                              job.status === 'client-refused' ? '#ff4961' :
                                job.status === 'client-require-job-cancel' ? '#ffd534' :
                                  job.status === 'moderation' ? '#ffc409' :
                                    job.status === 'job-cancelled' ? '#989aa2' :
                                      job.status === 'job-finished' ? '#989aa2' : ''
                }}>
                  {customBadges(job, user)}
                </IonCol>
              </IonRow>
              <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
              <IonCardContent>
                <h1><strong>{job.title}</strong></h1>
                <p style={{ fontSize: '0.7em' }}>{
                  job.type === "auditoria" ? "Auditoría".toUpperCase() :
                    job.type === "analise" ? "Análisis".toUpperCase() :
                      job.type === "pericia" ? "Pericia".toUpperCase() :
                        job.type === "fiscalizacao" ? "Inspección".toUpperCase() :
                          job.type === "consultoria" ? "Consultoría".toUpperCase() : ''
                }</p>
                <p style={{ fontSize: '0.8em', marginTop: '1.1em' }} >{job?.description?.length < 20 ? job.description : job.description.slice(0, 20) + '...'}</p>
                <IonFooter>
                </IonFooter>
              </IonCardContent>
              <IonFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: '0.7em' }}>Disponible para recibir propuestas</p>
              </IonFooter>
            </IonCard>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
              <IonIcon className="ion-text-center ion-padding-horizontal" icon={chevronDown} />
              <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
            </div>

            <form onSubmit={(e: any) => handleProposal(e)}>
              <IonCard style={{ paddingBottom: '20px', paddingTop: '5px' }} className='ion-no-padding'>
                <IonList>
                  <IonItem>
                    <IonLabel position='floating'>Descripción</IonLabel>
                    <IonTextarea
                      className='ion-no-padding'
                      name='description'
                      required={true}
                      maxlength={100}
                      // autoGrow={true}
                      rows={1}
                    />
                  </IonItem>
                  <IonItem className='ion-align-items-center'>
                    <IonLabel position='floating'>Plazo</IonLabel>
                    <IonInput
                      className='ion-no-padding'
                      name='deadline'
                      type='text'
                      required={true}
                      maxlength={3}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position='floating'>Precio</IonLabel>
                    <IonInput
                      name='price'
                      type='number'
                      required={true}
                    />
                  </IonItem>
                </IonList>
              </IonCard>
              <IonButton type='submit' color='dark' className="ion-margin" expand="block">Enviar propuesta</IonButton>
            </form>
          </IonContent>
        </IonModal>


        <IonModal isOpen={showEditModal} swipeToClose={true} onDidDismiss={() => setShowEditModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              {/* <BackButton /> */}
              <IonButton color='light' size="small" fill="clear" onClick={() => setShowEditModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Edición de la propuesta</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color='medium'>


            <form onSubmit={(e: any) => handleEditProposal(e)}>
              <IonCard className='ion-no-padding'>
                <IonList>
                  <IonItem>
                    <IonLabel position='floating'>Descripción</IonLabel>
                    <IonTextarea
                      value={proposal?.description}
                      className='ion-no-padding'
                      name='description'
                      required={true}
                      maxlength={100}
                      // autoGrow={true}
                      rows={1}
                    />
                  </IonItem>
                  <IonItem className='ion-align-items-center'>
                    <IonLabel position='floating'>Plazo</IonLabel>
                    <IonInput
                      value={proposal?.deadline}
                      className='ion-no-padding'
                      name='deadline'
                      type='text'
                      required={true}
                      maxlength={3}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position='floating'>Precio</IonLabel>
                    <IonInput
                      value={proposal?.price}
                      name='price'
                      type='number'
                      required={true}
                    />
                  </IonItem>
                </IonList>
                <div className="d-flex justify-content-center align-items-center mx-3 my-3">
                  <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Salvar propuesta</button>
                  <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setShowEditModal(false)}>Cancelar</button>
                </div>
              </IonCard>
            </form>
          </IonContent>
        </IonModal>
      </IonContent>

      <SucessAlert isOpen={isSucessAlertOpen} setIsOpen={setIsSucessAlertOpen} text="Propuesta enviada con éxito! Se le notificará en breve con la respuesta del cliente." />
      <IonAlert
        isOpen={cancelProposalConf}
        onDidDismiss={() => setCancelProposalConf(false)}
        message='¿Seguro que quieres eliminar la propuesta atual?'
        buttons={[{ text: 'Si', handler: handleDeleteProposal }, 'No']}
      />

      <IonFooter color="dark">
        <Tabs currentPath={props.match.url} />
      </IonFooter>

    </IonPage>
  )
};
export default ShowJob;