import { IonAlert, IonAvatar, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { refreshOutline } from "ionicons/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import auth from "../../../services/auth";
import formatDateHeader from "../../../utils/formatDateHeader";
import Refresher from "../../Refresher/Index";

import './index.css'

const Header: React.FC<any> = ({ currentPath }) => {

  const { user, setUpdateUserContext, lastUpdatedAt, setIsUserLoading } = React.useContext(UserContext);

  const router = useHistory()

  const [logoutConf, setLogoutConf] = React.useState(false);
  const [searchText, setSearchText] = React.useState('');

  async function handleLogout() {
    await auth.onSignOut()
    setUpdateUserContext((previous: any) => !previous);
    return router.push('/landing')
  }
  async function handleHome() {
    return router.push('/')
  }

  if (user?.type === "client") return (
    <>
      {/* <div style={{ width: '350px', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', top: '12.5px', left: 0, right: 0, textAlign: 'center', borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <input style={{ border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px', width: '270px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar servicio.." />
        <img className='logo-image' style={{ color: '#919191' }} src='/assets/search-1.svg' />
      </div> */}
      <header className='desktop-header' style={{ backgroundColor: '#3B3FB6', height: '70px' }}>
        <img style={{ cursor: 'pointer' }} src="/assets/logo_contadoor.svg" alt="Logotipo Contadoor" onClick={() => router.push("/")} />
        <div className="header-options">
          <div className={`header-option ${currentPath === "/client" ? 'active' : ''}`} onClick={() => router.push('/client')}>
            <p style={{ margin: 0 }}>Tablero</p>
          </div>
          <div className={`header-option ${currentPath.includes("/client/jobs") ? 'active' : ''}`} onClick={() => router.push('/client/jobs')}>
            <p style={{ margin: 0 }}>Mis servicios</p>
          </div>
          <div className={`header-option ${currentPath.includes("/client/freelancers") ? 'active' : ''}`} onClick={() => router.push('/client/freelancers')}>
            <p style={{ margin: 0 }}>Freelancers</p>
          </div>

          <div style={{ position: 'relative' }} className='header-option ml-5' onClick={() => router.push("/messages")} >
            <img src="/assets/Grupo 2906.svg" />
            <img style={{ position: 'absolute', top: "-6px", right: "-5px" }} src="/assets/Ellipse 181.svg" />
          </div>
          <div style={{ width: '1px', height: '24px', backgroundColor: '#FFFFFF' }}> </div>
          {/* <div className='header-option'>
            <img src="/assets/bell.svg" />
          </div> */}
          <IonAvatar style={{ border: "2px solid #00F1A9", height: '35px', width: '35px' }} onClick={() => router.push('/profile')}>
            <img src={user?.image} />
          </IonAvatar>
        </div>
      </header>
    </>
  );

  else if (user?.type === "freelancer") return (
    <>
      {/* <div style={{ width: '350px', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', top: '12.5px', left: 0, right: 0, textAlign: 'center', borderRadius: '6px', padding: '10px 20px', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <input style={{ border: 'none', outline: '0', backgroundColor: 'transparent', fontSize: '15px', width: '270px' }} value={searchText} onChange={(e: any) => setSearchText(e.target.value)} placeholder="Buscar servicio.." />
        <img className='logo-image' style={{ color: '#919191' }} src='/assets/search-1.svg' />
      </div> */}
      <header className='desktop-header' style={{ backgroundColor: '#3B3FB6', height: '70px' }}>
        <img style={{ cursor: 'pointer' }} src="/assets/logo_contadoor.svg" alt="Logotipo Contadoor" onClick={() => router.push("/")} />
        <div className="header-options">
          <div className={`header-option ${currentPath === "/freelancer" ? 'active' : ''}`} onClick={() => router.push('/freelancer')}>
            <p style={{ margin: 0 }}>Tablero</p>
          </div>
          <div className={`header-option ${currentPath.includes("/freelancer/jobs") ? 'active' : ''}`} onClick={() => router.push('/freelancer/jobs')}>
            <p style={{ margin: 0 }}>Servicios</p>
          </div>
          <div className={`header-option ${currentPath.includes("/freelancer/proposals") ? 'active' : ''}`} onClick={() => router.push('/freelancer/proposals')}>
            <p style={{ margin: 0 }}>Propuestas</p>
          </div>

          <div style={{ position: 'relative' }} className='header-option ml-5' onClick={() => router.push("/messages")} >
            <img src="/assets/Grupo 2906.svg" />
            <img style={{ position: 'absolute', top: "-6px", right: "-5px" }} src="/assets/Ellipse 181.svg" />
          </div>
          <div style={{ width: '1px', height: '24px', backgroundColor: '#FFFFFF' }}> </div>
          <IonAvatar style={{ border: "2px solid #00F1A9", height: '35px', width: '35px' }} onClick={() => router.push('/profile')}>
            <img src={user?.image} />
          </IonAvatar>
        </div>
      </header>
    </>
  );

  else return (
    <header className='desktop-header' style={{ backgroundColor: '#3B3FB6', height: '70px' }}>
      <img style={{ cursor: 'pointer' }} src="/assets/logo_contadoor.svg" alt="Logotipo Contadoor" onClick={() => router.push("/")} />
      <div className="header-options">
        <div className={`header-option ${currentPath === "/login" ? 'active' : ''}`} onClick={() => router.push('/login')}>
          <p style={{ margin: 0 }}>Entrar</p>
        </div>
        <div className={`header-option ${currentPath === "/signup" ? 'active' : ''}`} onClick={() => router.push('/signup', 'client')}>
          <p style={{ margin: 0 }}>Registrar-se</p>
        </div>
      </div>
    </header>
  )
}

export default Header;
