import React from 'react'

export default function PagueloFacil() {
  const pfClave = window.pfClave

  // pfClave
  let accessTokenApi = "brEyQRSzMm2UwQa5v0NsobRa3U8nH5xT|DIRfhYwNCjjQ8GVFFGgmXs65E";
  let cclw = "2AB0C6393723D5C2A2F7CC70132781DA0A217A9B4490B65CAAF14293044D690F0F0EAF5E52637D8CF5CD246AED3C2F14838A2BB7BECC1D071BB0E1ECCB0914A2";
  pfClave.useAsSandbox(true); //en caso de que desee realizar transacciones para pruebas.

  pfClave
    .openService({
      apiKey: accessTokenApi,
      cclw: cclw
    })
    .then(
      function (merchantSetup: any) {
        startMerchantForm(merchantSetup);
      },
      function (error: any) {
        console.log(error);
      }
    );

  let sdk;
  function startMerchantForm(merchantSetup: any) {
    let paymentInfo = {
      amount: 15.0, //Monto de la compra
      taxAmount: 0.0, //Monto de los impuestos
      description: "descripcion personalizada" //Descripción corta del motivo del pago
    };
    let userInfo = {
      email: "alam@brito.com", //Correo electrónico del usuario que realiza la compra
      phone: "+50761111111" //Teléfono movil del usuario que realiza la compra
    };

    let setup = {
      lang: "es", //Idioma los valores posibles son "es", "en"
      embedded: false, // sí desea que se embebido o muestre un botón.
      container: "container-form", //Elemento html donde se introducirá el formulario de pago de clave
      onError: function (data: any) {
        console.error("onError errors", data);
      },
      onTxSuccess: function (data: any) {
        console.log("onTxSuccess", data);
        window.location.href =
          pfClave.pfHostViews + `/pf/default-receipt/${data?.Oper}`;
      },
      onTxError: function (data: any) {
        console.error("when the onTxError, in other process", data);
      },
      onClose: function () {
        console.log("onClose called");
      }
    };
    sdk = merchantSetup.init(
      merchantSetup.dataMerchant,
      paymentInfo,
      setup,
      userInfo
    );
  }


  return (
    <div id="container-form" style={{ width: "30%" }} />
  )
}
