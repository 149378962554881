import { getPlatforms, IonAvatar, IonButton, IonCard, IonCardContent, IonContent, IonFooter, IonIcon, IonInput, IonItem, IonPage, IonRow } from '@ionic/react';
import { chevronBack, send } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { io } from "socket.io-client";
import MobileHeader from '../../../components/MobileHeader';
import Tabs from '../../../components/Tabs';
import { UserContext } from '../../../context/UserContext';
import api from '../../../services/api';
import formatDateMessage from '../../../utils/formatDateMessage';
import './styles.scss';






// const socket = io("http://localhost:3050")
const socket = io(process.env.REACT_APP_PROD_API || "https://contadoor.herokuapp.com/", { autoConnect: false });

export default function Chat(props: any) {

  const router = useHistory();

  const chatForm = React.useRef<any>(null);
  const messagesEnd = React.useRef<any>(null);

  const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext);
  const [socketId, setSocketId] = useState<any>(props.match.params.socketId)
  const [chat, setChat] = useState<any>([])
  const [userReceiver, setUserReceiver] = useState<any>(false)
  // const [refreshComponent, setRefreshComponent] = useState<any>(false)

  const platform: any = getPlatforms();

  // if (props?.location?.state?.socketId && !messageSelected && !isUserLoading) {
  //   const message = messages?.find((message: any) => message?.socketId === props?.location?.state?.socketId)
  //   if (message) {
  //     setSocketId(message?.socketId);
  //     setUserReceiver(user.type == 'freelancer' ? message?.client : message?.freelancer);
  //     setMessageSelected(message)
  //   }
  // }

  console.log('chat', chat)

  React.useEffect(() => {
    async function fetchMessages() {
      await api.get(`/message/${socketId}`)
      let getChat: any = await api.get(`/message/${socketId}`)
      console.log('getChat', getChat)
      setChat(getChat.data)

      setUserReceiver(user.type == 'freelancer' ? getChat.data?.client : getChat.data?.freelancer)
      // setRefreshComponent((previous: any) => !previous)
    }
    if (socketId && user) fetchMessages()
  }, [
    socketId,
  ])

  useEffect(() => {
    // setRefreshComponent((previous: any) => !previous)
  }, [chat])

  React.useEffect(() => {
    async function joinSocket() {
      try {
        socket.off(socketId);
        socket.disconnect()
        socket.connect()
        socket.emit("createChat", socketId)
        socket.on(socketId, (message: any) => {
          setChat((currentChat: any) => ({
            messages: [...currentChat.messages, message]
          }))
        })
      }
      catch (error) { console.log(error.message) }
    }
    if (socketId && user) joinSocket()
    // setRefreshComponent((previous: any) => !previous)
    return () => {
      socket.off(socketId);
      socket.disconnect()
      setChat(false)
    }
  }, [])

  React.useEffect(() => {
    async function updateMessages() {
      try {
        await api.patch(`/message/${socketId}`, { messages: chat?.messages })
        messagesEnd?.current?.scrollIntoView()
        // setRefreshComponent((previous: any) => !previous)
      } catch (error) { console.log(error.message) }
    }
    if (socketId && user && chat) updateMessages()
  }, [chat])



  async function handleSubmit(e: any) {
    e.preventDefault();
    let message = {
      text: e.target.message.value,
      user: user,
      date: new Date().toString(),
      freelancer: socketId.substr(0, 24),
      client: socketId.substr(24, 48)
    }

    socket.emit(socketId, message)

    chatForm?.current?.reset();
  }

  if (isUserLoading || !user) return <>Loading...</>
  // if (!chat || chat?.length === 0) return <Loading message="Sincronizando mensajes..." isLoading={chat?.length === 0 ? true : false} />

  // else if (platform == "desktop") return <>CAIU NO DESKTOP</>

  // else
  return (
    <IonPage className='chat-mobile-page'>
      <MobileHeader />

      {/* <IonHeader>
            <IonToolbar color='primary'>
               <BackButton />
               <div className='d-flex align-items-center'>
                  <IonAvatar style={{ marginRight: '.8rem' }}>
                     <img style={{ padding: '.3rem', borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} src={"https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                  </IonAvatar>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                     <p className="ion-no-margin"><strong>{userReceiver ? userReceiver : 'Cargando...'}</strong></p>
                  </div>
               </div>
            </IonToolbar>
         </IonHeader> */}

      <IonContent className="chat-mobile-page ion-padding">

        <IonCard className='ion-no-margin ion-no-padding' style={{ width: "100%", margin: 0, backgroundColor: '#FFFFFF', borderRadius: '8px 8px 0 0', borderBottom: '1px solid #3E39BD' }} >
          <IonCardContent style={{ padding: '12px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>

              <div style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer' }} onClick={() => router.push('/messages')}>
                <IonIcon icon={chevronBack} color='primary' style={{ fontSize: '1.75rem' }} />
              </div>

              <IonAvatar style={{ margin: '0 16px', width: '2.75rem', height: '2.75rem' }}>
                <img src={userReceiver?.image} />
              </IonAvatar>
              <div>
                <p className='ion-text-left' style={{ fontSize: '18px', color: '#2D2D2D', fontWeight: 600, margin: 0 }}>{userReceiver?.name}</p>
                {/* <p className='ion-text-left' style={{ fontSize: '16px', color: '#2D2D2D', fontWeight: 600 }}>{"Activo"}</p> */}
              </div>
            </div>
          </IonCardContent>
        </IonCard>
        <IonCard className='ion-no-margin ion-no-padding chat-body' style={{
          width: "100%",
          backgroundColor: '#EAEAEA',
          borderRadius: '0 0 0px 0px',
          overflowY: "scroll"
        }} >
          <IonCardContent


          // style={{ flex: 1 }}

          >
            {chat?.messages && chat?.messages?.map((message: any, index: any) =>
              <div key={index} style={{ display: 'flex', marginBottom: '16px', alignItems: 'flex-end' }}>

                {
                  message?.user?._id == user._id ?
                    <>

                      <div style={{ flex: 1 }}>
                        <IonRow className={`justify-content-end`}>
                          <IonCard className="ion-text-right" key={message} color="primary"
                            style={{ padding: '10px 25px', borderRadius: '35px 35px 0 35px' }}
                          >
                            <p style={{ fontSize: '14px', margin: 0, color: '#ffffff' }}>
                              {message?.text}
                            </p>
                            <p style={{ fontSize: '12px', margin: 0, marginTop: "0.2rem", color: '#ffffff60' }}>
                              {formatDateMessage(message?.date)}
                            </p>
                          </IonCard>
                        </IonRow>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <IonAvatar style={{ marginLeft: '10px', width: '32px', height: '32px' }}>
                          <img src={message?.user?.image} />
                        </IonAvatar>
                      </div>



                    </>

                    :

                    <>

                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <IonAvatar style={{ marginRight: '10px', width: '32px', height: '32px' }}>
                          <img src={message?.user?.image} />
                        </IonAvatar>
                      </div>
                      <div style={{ flex: 1 }}>
                        <IonRow className="justify-content-start">
                          <IonCard key={message} style={{ padding: '10px 25px 5px 25px', borderRadius: '35px 35px 35px 0px' }}
                          >
                            <p style={{ fontSize: '14px', margin: 0, marginTop: "0.2rem", color: '#000000' }}>
                              {message?.text}
                            </p>
                            <p style={{ fontSize: '12px', margin: 0, color: '#00000060' }}>
                              {formatDateMessage(message?.date)}
                            </p>
                          </IonCard>
                        </IonRow>
                      </div>
                    </>
                }
              </div>
            )}

            <div ref={messagesEnd}></div>

          </IonCardContent>
        </IonCard>

        <IonFooter>
          <IonCard style={{ width: "100%", backgroundColor: '#FFFFFF', borderRadius: '0 0 8px 8px', padding: '10px', borderTop: '1px solid #3E39BD' }}>
            <form ref={chatForm} onSubmit={handleSubmit}>
              <IonItem lines="none" style={{ borderRadius: '8px' }}>
                <IonInput required name="message" placeholder="Messaje..."></IonInput>
                <IonButton type="submit" style={{ borderRadius: '50%' }}>
                  <IonIcon slot="end" icon={send} />
                </IonButton>
              </IonItem>
            </form>
          </IonCard>
        </IonFooter>


      </IonContent>
      <IonFooter>
        <Tabs currentPath={props.match.url} />
      </IonFooter>
    </IonPage>
  )
}
