import React from 'react';
import { useHistory } from 'react-router-dom'
import { getPlatforms, IonAlert, IonButton, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTextarea, IonTitle, IonToolbar } from '@ionic/react';
import { addOutline, removeCircleOutline, createOutline, trashBinOutline, trashOutline, attachOutline, documentTextOutline, documentAttach, documentAttachOutline } from 'ionicons/icons';
import auth from '../../../services/auth'




// Components
import BackButton from '../../../components/BackButton';

// Services
import api from '../../../services/api'

// Contexts
import { UserContext } from '../../../context/UserContext';
import Loading from '../../../components/loading';
import Header from '../../../components/desktop/Header';
import { ToastContext } from '../../../context/ToastContext';

const NewJob: React.FC = (myprop: any) => {


  const router = useHistory();
  const platform: any = getPlatforms();

  // Clean-up function
  React.useEffect(() => {
    return () => {
      setTitle('')
      setRequirements([''])
      setAttachments([{ name: '', url: 'https://via.placeholder.com/500x500' }])
      setDescription('')
      setDeadline('')
      setType('')
    }
  }, [router.location])

  const [showAlert, setShowAlert] = React.useState<any>(false);
  const [errmessage, setErrmessage] = React.useState<any>('');

  const [title, setTitle] = React.useState<any>('');
  const [requirements, setRequirements] = React.useState<any>([''])
  const [attachments, setAttachments] = React.useState<any>([{ name: '', url: 'https://via.placeholder.com/500x500' }])
  const [description, setDescription] = React.useState<any>('');
  const [deadline, setDeadline] = React.useState<any>('');
  const [type, setType] = React.useState<any>('');
  const [workPreference, setWorkPreference] = React.useState<any>('presential');

  const [internalLoading, setInternalLoading] = React.useState<any>(false);

  const job = myprop?.location?.state?.job;
  const isCustomJob = myprop?.location?.state?.customJob
  const freelancer = myprop?.location?.state?.freelancer

  const { setUpdateUserContext } = React.useContext(UserContext)
  const { setToast, setToastMessage } = React.useContext(ToastContext);

  React.useEffect(() => {
    async function fetchData() {
      if (job) {
        setTitle(job.title)
        setDescription(job.description)
        setDeadline(job.deadline);
        setRequirements(job.requirements);
        setAttachments(job.attachments.length === 0 ? [{ name: '', url: 'https://via.placeholder.com/500x500' }] : job.attachments);
        setType(job.type)
        setWorkPreference(job.workPreference)
      }
    } fetchData()
  }, [job])

  const handleCreateJob = async (e: any) => {

    e.preventDefault();

    setInternalLoading(true)

    const input = {
      title,
      description,
      deadline,
      type,
      workPreference,
      requirements,
      attachments
    }

    const config = await auth.getHeaderConfig()

    if (isCustomJob && freelancer) {
      try {
        await api.post(`/job/${freelancer._id}/hire`, input, config)
        setInternalLoading(false);
        setToastMessage("Éxito en la creación de este servicio privado!")
      } catch (error) {
        setInternalLoading(false);
        setToastMessage('Error en la creación de este servicio privado.');
        return;
      }

    }
    else if (job) {
      try {
        await api.patch(`/job/${job._id}`, input, config);
        setInternalLoading(false);
        setToastMessage("Éxito en la edición de este servicio!")
      } catch (error) {
        setInternalLoading(false);
        setToastMessage('Error en la edición de este servicio.');
        return;
      }

    } else {
      try {
        await api.post("/job", input, config);
        setInternalLoading(false);
        setToastMessage("Éxito en la creación de este servicio!")
      } catch (error) {
        setInternalLoading(false);
        setToastMessage('Error en la creación de este servicio.');
        return;
      }
    }

    setUpdateUserContext((previous: any) => !previous);

    setToast(true)
    return router.push('/client/jobs')
  }

  if (platform == 'desktop') return (

    <IonContent className="freelancer-job-desktop">

      <Header currentPath={myprop.match.url} />

      <main>
        <IonGrid fixed className="content-wrapper" style={{ width: '100%' }}>

          <IonRow>
            <IonCol size='4'></IonCol>
            <IonCol size='4'>
              {
                freelancer && isCustomJob
                  ? <>
                    <IonCard className='ion-margin-vertical'>
                      <IonRow>
                        <IonCol className='ion-text-center ion-padding'>
                          <p><strong>Estás creando un servicio privado!</strong></p>
                          <p className='m-0'>Solo este autónomo recibirá esta propuesta de servicio.</p>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                    <IonCard className='ion-margin-vertical' onClick={() => router.push(`/client/freelancers/${freelancer._id}`)}>
                      <IonRow>
                        <IonCol>
                          <IonCardContent className="ion-no-padding">
                            <IonRow>
                              <IonCol size='1'>
                                <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                              </IonCol>
                              <IonCol style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <p style={{ fontSize: '1.3em' }}><strong>{freelancer.name}</strong></p>
                                <p style={{ fontSize: '0.8em' }}>{freelancer.email}</p>
                              </IonCol>
                            </IonRow>
                            {/* <IonFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: '0.7em' }}>{products.length} produtos na mostra</p>
              </IonFooter> */}
                          </IonCardContent>
                        </IonCol>
                      </IonRow>
                    </IonCard>
                  </>
                  : <></>
              }

              <IonCard className='ion-margin-vertical'>
                <form id="newjob" onSubmit={(e: any) => handleCreateJob(e)}>
                  <IonList>

                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.8rem', marginLeft: '0.8rem' }}>
                      <IonIcon color='dark' icon={documentTextOutline} />
                      <IonText color='dark' style={{ marginLeft: '0.4rem', fontWeight: 600 }}>Informaciones del servicio</IonText>
                    </div>

                    <IonItem>
                      <IonLabel position='floating'><p><strong>Título</strong></p></IonLabel>
                      <IonTextarea
                        className='ion-no-padding'
                        required={true}
                        // autoGrow={true}
                        rows={1}
                        value={title}
                        onIonChange={(e) => setTitle(e.detail.value!)}
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position='floating'><p><strong>Descripción</strong></p></IonLabel>
                      <IonTextarea
                        className='ion-no-padding'
                        required={true}
                        // autoGrow={true}
                        rows={1}
                        value={description}
                        onIonChange={(e) => setDescription(e.detail.value!)}
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position='floating'><p><strong>Plazo deseable (días)</strong></p></IonLabel>
                      <IonInput
                        className='ion-no-padding'
                        required={true}
                        type='number'
                        step="1"
                        inputMode="numeric"
                        value={deadline}
                        onIonChange={(e) => setDeadline(e.detail.value!)}
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position='floating'><p><strong>Tipo de servicio</strong></p></IonLabel>
                      <IonSelect value={type} onIonChange={(e) => setType(e.detail.value!)}>
                        <IonSelectOption value="auditoria">Auditoría</IonSelectOption>
                        <IonSelectOption value="analise">Análisis</IonSelectOption>
                        <IonSelectOption value="pericia">Pericia</IonSelectOption>
                        <IonSelectOption value="fiscalizacao">Inspección</IonSelectOption>
                        <IonSelectOption value="consultoria">Consultoría</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonItem>
                      <IonLabel position='floating'><p><strong>Forma de trabajo</strong></p></IonLabel>
                      <IonSelect value={workPreference} onIonChange={(e) => setWorkPreference(e.detail.value!)}>
                        <IonSelectOption value="presential">Presencial</IonSelectOption>
                        <IonSelectOption value="remote">Remoto</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                    <IonList>
                      {
                        requirements.length > 1
                          ? requirements.map((requirement: any, index: any) => (
                            <IonItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                              <IonLabel position='floating'><p><strong>Requisito de trabajo  ({index + 1})</strong></p></IonLabel>
                              <IonTextarea autoGrow={true} className='ion-no-padding' name='reqInput' required value={requirement} onIonChange={(e: any) => setRequirements((req: any) => {
                                req[index] = e.detail.value
                                return [...req]
                              })}></IonTextarea>
                              {
                                index < requirements.length - 1
                                  ? <IonIcon icon={trashOutline} color='danger' slot='end' onClick={() => setRequirements((req: any) => {
                                    const temp = req.filter((item: any, indexItem: any) => index !== indexItem)
                                    if (temp.length === 0) return [...temp, '']
                                    else return [...temp]
                                  }
                                  )} />
                                  : <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                                    setRequirements([...requirements, ''])
                                    // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                                  }} />
                              }
                            </IonItem>
                          ))
                          : <IonItem style={{ display: 'flex', alignItems: 'center' }}>
                            <IonLabel position='floating'><p><strong>Requisito de trabajo ({1})</strong></p></IonLabel>
                            <IonTextarea autoGrow={true} className='ion-no-padding' name='reqInput' required value={requirements[0]} onIonChange={(e: any) => setRequirements((req: any) => {
                              req[0] = e.detail.value
                              return [...req]
                            })}></IonTextarea>
                            {
                              <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                                setRequirements([...requirements, ''])
                                // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                              }} />
                            }
                          </IonItem>
                      }
                    </IonList>

                    <br />
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.8rem', marginLeft: '0.8rem' }}>
                      <IonIcon color='dark' icon={documentAttachOutline} />
                      <IonText color='dark' style={{ marginLeft: '0.4rem', fontWeight: 600 }}>Archivos adjuntos</IonText>
                    </div>

                    <IonList>
                      {
                        attachments.length > 1
                          ? attachments.map((attachment: any, index: any) => (
                            <IonItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                              <IonLabel position='floating'><p><strong>Archivo ({index + 1})</strong></p></IonLabel>
                              <IonTextarea autoGrow={true} className='ion-no-padding' name='reqInput' required value={attachment.name} onIonChange={(e: any) => setAttachments((atch: any) => {
                                atch[index].name = e.detail.value
                                return [...atch]
                              })}></IonTextarea>
                              <label slot='end' style={{ margin: 0, height: '1rem' }}><input type="file" style={{ display: 'none' }} /><IonIcon icon={attachOutline}></IonIcon></label>
                              {
                                index < attachments.length - 1
                                  ? <IonIcon icon={trashOutline} color='danger' slot='end' onClick={() => setAttachments((req: any) => {
                                    const temp = req.filter((item: any, indexItem: any) => index !== indexItem)
                                    if (temp.length === 0) return [...temp, '']
                                    else return [...temp]
                                  }
                                  )} />
                                  : <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                                    setAttachments([...attachments, { name: '', url: 'https://via.placeholder.com/500x500' }])
                                    // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                                  }} />
                              }
                            </IonItem>
                          ))
                          : <IonItem style={{ display: 'flex', alignItems: 'center' }}>
                            <IonLabel position='floating'><p><strong>Archivo ({1})</strong></p></IonLabel>
                            <IonTextarea autoGrow={true} className='ion-no-padding' name='reqInput' required value={attachments[0]?.name} onIonChange={(e: any) => setAttachments((atch: any) => {
                              atch[0].name = e.detail.value
                              return [...atch]
                            })}></IonTextarea>
                            <label slot='end' style={{ margin: 0, height: '1rem' }}><input type="file" style={{ display: 'none' }} /><IonIcon icon={attachOutline}></IonIcon></label>
                            {
                              <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                                setAttachments([...attachments, { name: '', url: 'https://via.placeholder.com/500x500' }])
                                // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                              }} />
                            }
                          </IonItem>
                      }
                    </IonList>
                    <IonAlert
                      isOpen={showAlert}
                      onDidDismiss={() => setShowAlert(false)}
                      message={`${errmessage}`}
                      buttons={['OK']}
                    />
                    <br />
                    {job
                      ? <IonButton
                        type='submit'
                        slot='bottom'
                        color='dark'
                        expand='block'
                        className='ion-padding-horizontal'
                      // onClick={() => { handleCreateJob(); setInternalLoading(true) }}
                      >Guardar edición</IonButton>

                      : <IonButton
                        type='submit'
                        slot='bottom'
                        color='dark'
                        expand='block'
                        className='ion-padding-horizontal ion-margin-bottom'
                      // style={{ width: '100%', height: '2.8em', margin: '4px 2px', color: 'white', fontSize: '16px', fontWeight: 500, letterSpacing: '-0.03em' }}
                      // onClick={() => { handleCreateJob(); setInternalLoading(true) }}
                      >Crear servicio</IonButton>
                    }
                  </IonList>
                </form>
              </IonCard>
            </IonCol>
            <IonCol size='4'></IonCol>
          </IonRow>

        </IonGrid>
      </main>
      <Loading isLoading={internalLoading} />
      {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}
    </IonContent >
  )

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="dark">
          <BackButton />
          <IonTitle>{job ? "Edición de servicio" : "Nuevo servicio"} {isCustomJob ? ' privado' : ''}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color='medium'>

        {
          freelancer && isCustomJob
            ? <>
              <IonCard>
                <IonRow>
                  <IonCol className='ion-text-center ion-padding'>
                    <p><strong>¡Estás creando un servicio privado!</strong></p>
                    <p className='m-0'>Solo este autónomo recibirá esta propuesta de servicio.</p>
                  </IonCol>
                </IonRow>
              </IonCard>

              <IonCard onClick={() => router.push(`/client/freelancers/${freelancer._id}`)}>
                <IonRow>
                  <IonCol>
                    <IonCardContent className="ion-no-padding">
                      <IonRow>
                        <IonCol size='3'>
                          <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                        </IonCol>
                        <IonCol style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                          <p style={{ fontSize: '1.3em' }}><strong>{freelancer.name}</strong></p>
                          <p style={{ fontSize: '0.8em' }}>{freelancer.email}</p>
                        </IonCol>
                      </IonRow>
                      {/* <IonFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: '0.7em' }}>{products.length} produtos na mostra</p>
              </IonFooter> */}
                    </IonCardContent>
                  </IonCol>
                </IonRow>
              </IonCard>
            </>
            : <></>
        }

        <IonCard>
          <form id="newjob" onSubmit={(e: any) => handleCreateJob(e)}>
            <IonList>

              <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.8rem', marginLeft: '0.8rem' }}>
                <IonIcon color='dark' icon={documentTextOutline} />
                <IonText color='dark' style={{ marginLeft: '0.4rem', fontWeight: 600 }}>Informaciones del servicio</IonText>
              </div>

              <IonItem>
                <IonLabel position='floating'><p><strong>Título</strong></p></IonLabel>
                <IonTextarea
                  className='ion-no-padding'
                  required={true}
                  // autoGrow={true}
                  rows={1}
                  value={title}
                  onIonChange={(e) => setTitle(e.detail.value!)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position='floating'><p><strong>Descripción</strong></p></IonLabel>
                <IonTextarea
                  className='ion-no-padding'
                  required={true}
                  // autoGrow={true}
                  rows={1}
                  value={description}
                  onIonChange={(e) => setDescription(e.detail.value!)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position='floating'><p><strong>Plazo deseable (días)</strong></p></IonLabel>
                <IonInput
                  className='ion-no-padding'
                  required={true}
                  type='number'
                  step="1"
                  inputMode="numeric"
                  value={deadline}
                  onIonChange={(e) => setDeadline(e.detail.value!)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position='floating'><p><strong>Tipo de servicio</strong></p></IonLabel>
                <IonSelect value={type} onIonChange={(e) => setType(e.detail.value!)}>
                  <IonSelectOption value="auditoria">Auditoría</IonSelectOption>
                  <IonSelectOption value="analise">Análisis</IonSelectOption>
                  <IonSelectOption value="pericia">Pericia</IonSelectOption>
                  <IonSelectOption value="fiscalizacao">Inspección</IonSelectOption>
                  <IonSelectOption value="consultoria">Consultoría</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonItem>
                <IonLabel position='floating'><p><strong>Forma de trabajo</strong></p></IonLabel>
                <IonSelect value={workPreference} onIonChange={(e) => setWorkPreference(e.detail.value!)}>
                  <IonSelectOption value="presential">Presencial</IonSelectOption>
                  <IonSelectOption value="remote">Remoto</IonSelectOption>
                </IonSelect>
              </IonItem>
              <IonList>
                {
                  requirements.length > 1
                    ? requirements.map((requirement: any, index: any) => (
                      <IonItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <IonLabel position='floating'><p><strong>Requisito de trabajo ({index + 1})</strong></p></IonLabel>
                        <IonTextarea autoGrow={true} className='ion-no-padding' name='reqInput' required value={requirement} onIonChange={(e: any) => setRequirements((req: any) => {
                          req[index] = e.detail.value
                          return [...req]
                        })}></IonTextarea>
                        {
                          index < requirements.length - 1
                            ? <IonIcon icon={trashOutline} color='danger' slot='end' onClick={() => setRequirements((req: any) => {
                              const temp = req.filter((item: any, indexItem: any) => index !== indexItem)
                              if (temp.length === 0) return [...temp, '']
                              else return [...temp]
                            }
                            )} />
                            : <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                              setRequirements([...requirements, ''])
                              // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                            }} />
                        }
                      </IonItem>
                    ))
                    : <IonItem style={{ display: 'flex', alignItems: 'center' }}>
                      <IonLabel position='floating'><p><strong>Requisito de trabajo ({1})</strong></p></IonLabel>
                      <IonTextarea autoGrow={true} className='ion-no-padding' name='reqInput' required value={requirements[0]} onIonChange={(e: any) => setRequirements((req: any) => {
                        req[0] = e.detail.value
                        return [...req]
                      })}></IonTextarea>
                      {
                        <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                          setRequirements([...requirements, ''])
                          // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                        }} />
                      }
                    </IonItem>
                }
              </IonList>

              <br />
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.8rem', marginLeft: '0.8rem' }}>
                <IonIcon color='dark' icon={documentAttachOutline} />
                <IonText color='dark' style={{ marginLeft: '0.4rem', fontWeight: 600 }}>Archivos adjuntos</IonText>
              </div>

              <IonList>
                {
                  attachments.length > 1
                    ? attachments.map((attachment: any, index: any) => (
                      <IonItem key={index} style={{ display: 'flex', alignItems: 'center' }}>
                        <IonLabel position='floating'><p><strong>Archivo ({index + 1})</strong></p></IonLabel>
                        <IonTextarea autoGrow={true} className='ion-no-padding' name='reqInput' required value={attachment.name} onIonChange={(e: any) => setAttachments((atch: any) => {
                          atch[index].name = e.detail.value
                          return [...atch]
                        })}></IonTextarea>
                        <label slot='end' style={{ margin: 0, height: '1rem' }}><input type="file" style={{ display: 'none' }} /><IonIcon icon={attachOutline}></IonIcon></label>
                        {
                          index < attachments.length - 1
                            ? <IonIcon icon={trashOutline} color='danger' slot='end' onClick={() => setAttachments((req: any) => {
                              const temp = req.filter((item: any, indexItem: any) => index !== indexItem)
                              if (temp.length === 0) return [...temp, '']
                              else return [...temp]
                            }
                            )} />
                            : <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                              setAttachments([...attachments, { name: '', url: 'https://via.placeholder.com/500x500' }])
                              // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                            }} />
                        }
                      </IonItem>
                    ))
                    : <IonItem style={{ display: 'flex', alignItems: 'center' }}>
                      <IonLabel position='floating'><p><strong>Archivo ({1})</strong></p></IonLabel>
                      <IonTextarea autoGrow={true} className='ion-no-padding' name='reqInput' required value={attachments[0]?.name} onIonChange={(e: any) => setAttachments((atch: any) => {
                        atch[0].name = e.detail.value
                        return [...atch]
                      })}></IonTextarea>
                      <label slot='end' style={{ margin: 0, height: '1rem' }}><input type="file" style={{ display: 'none' }} /><IonIcon icon={attachOutline}></IonIcon></label>
                      {
                        <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                          setAttachments([...attachments, { name: '', url: 'https://via.placeholder.com/500x500' }])
                          // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                        }} />
                      }
                    </IonItem>
                }
              </IonList>
              <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                message={`${errmessage}`}
                buttons={['OK']}
              />
              <br />
              {job
                ? <IonButton
                  type='submit'
                  slot='bottom'
                  color='dark'
                  expand='block'
                  className='ion-padding-horizontal'
                // onClick={() => { handleCreateJob(); setInternalLoading(true) }}
                >Guardar edición</IonButton>

                : <IonButton
                  type='submit'
                  slot='bottom'
                  color='dark'
                  expand='block'
                  className='ion-padding-horizontal ion-margin-bottom'
                // style={{ width: '100%', height: '2.8em', margin: '4px 2px', color: 'white', fontSize: '16px', fontWeight: 500, letterSpacing: '-0.03em' }}
                // onClick={() => { handleCreateJob(); setInternalLoading(true) }}
                >Crear servicio</IonButton>
              }
            </IonList>
          </form>
        </IonCard>
      </IonContent>
      <Loading isLoading={internalLoading} />
      {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}
    </IonPage>
  )
};
export default NewJob;