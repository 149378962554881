import { IonAvatar, IonBadge, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonFooter, IonHeader, IonItem, IonPage, IonRow, IonSegment, IonSegmentButton, IonRefresher, IonPopover, IonRefresherContent, IonText, IonThumbnail, IonTitle, IonToolbar, useIonViewDidEnter, IonButton, IonIcon, IonGrid, IonChip, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import React, { } from 'react';
import { useHistory } from 'react-router-dom'
import { getPlatforms } from '@ionic/react';

// Components
import Loading from '../../../components/loading';
import BackButton from '../../../components/BackButton';
import Tabs from '../../../components/Tabs'

// Services
import api from '../../../services/api'
import { customBadges } from '../../../utils/customBadges';

// Context
import { UserContext } from '../../../context/UserContext';
import Refresher from '../../../components/Refresher/Index'

// Util
import formatDate from '../../../utils/formatDate';

import './index.scss'
import Header from '../../../components/desktop/Header';
import MobileHeader from '../../../components/MobileHeader';


const Withdraw: React.FC = (props: any) => {

  const platform: any = getPlatforms();
  const router = useHistory();

  const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext);
  const [withdrawSegment, setWithdrawSegment] = React.useState<any>("all");

  let withdrawsToShow: any = user ? user.freelancer?.wallet?.withdraws : null

  withdrawsToShow = user ? withdrawSegment === 'all' ? withdrawsToShow : withdrawsToShow?.filter((withdraw: any) => {
    let status = withdraw.status
    return status === withdrawSegment

  }) : null


  if (isUserLoading || !withdrawsToShow) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (

    <IonContent className="freelancer-withdraw-desktop">

      <Header currentPath={props.match.url} />

      <main>
        <IonGrid fixed className="content-wrapper">
          <IonRow className='ion-margin-top'>

            <IonCol size='3'>
              <IonRow>
                <IonCol style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                  <IonRow>
                    <IonCol className='ion-padding'>

                      <div>
                        <small className="mb-2 d-block">Estado de los retiros</small>
                        <select value={withdrawSegment} onChange={(e: any) => setWithdrawSegment(e.target.value!)} className="custom-select custom-select-lg mb-3 w-100">
                          <option value="all">Todos</option>
                          <option value="pending">Pendientes</option>
                          <option value="done">Finalizados</option>
                        </select>
                      </div>
                      <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

                      <div className='text-center mt-2'>
                        <small>Retiros encontrados: {withdrawsToShow?.length}</small>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol size='9'>


              <div style={{ height: '80vh', overflowY: 'auto' }}>


                <IonCard className='ion-padding ion-no-margin'>

                  <table className="table table-striped">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Data</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        withdrawsToShow?.length === 0
                          ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningún retiro acá...</h4>
                          </div>
                          : withdrawsToShow?.map((withdraw: any, index: any) => (


                            <tr>

                              <th scope="row">

                                Atualizado en
                                <strong>
                                  {formatDate(withdraw.updatedAt)}
                                </strong>
                              </th>

                              <td>
                                Valor: <strong>{withdraw.amount}$</strong>
                              </td>

                              <td>

                                <IonCol size="12" className='ion-text-center ion-no-padding ion-no-margin' style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor:
                                    withdraw.status === 'done' ? '#6DECAE40' :

                                      withdraw.status === 'pending' ? '#ffc40940' : null


                                }}>
                                  {customBadges(withdraw, user)}
                                </IonCol>

                              </td>
                            </tr>

                          ))}


                    </tbody>
                  </table>
                </IonCard>






              </div>
            </IonCol>
          </IonRow>
        </IonGrid>



      </main>
    </IonContent >
  )

  else return (
    <IonPage>
      <IonHeader>
        <MobileHeader title='Retiradas' backButton={true} />
        <IonToolbar color='primary'>
          <IonSegment
            onIonChange={
              (e: any) => { setWithdrawSegment(e.detail.value) }}
            value={withdrawSegment}>
            <IonSegmentButton value='all'>Todos</IonSegmentButton>
            <IonSegmentButton value='pending'>Pendentes</IonSegmentButton>
            <IonSegmentButton value='done'>Concluídos</IonSegmentButton>
          </IonSegment>
        </IonToolbar>


      </IonHeader>
      <IonContent color="medium">



        <IonCard className='ion-padding ion-no-margin'>

          <table className="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Data</th>
                <th scope="col">Valor</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>

              {
                withdrawsToShow?.length === 0
                  ? <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningún retiro acá...</h4>
                  </div>
                  : withdrawsToShow?.map((withdraw: any, index: any) => (


                    <tr>

                      <th scope="row">
                        <strong>
                          {formatDate(withdraw.updatedAt)}
                        </strong>
                      </th>

                      <td>
                        Valor: <strong>{withdraw.amount}$</strong>
                      </td>

                      <td>

                        <IonCol size="12" className='ion-text-center ion-no-padding ion-no-margin' style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor:
                            withdraw.status === 'done' ? '#6DECAE40' :

                              withdraw.status === 'pending' ? '#ffc40940' : null


                        }}>
                          {customBadges(withdraw, user)}
                        </IonCol>

                      </td>
                    </tr>

                  ))}


            </tbody>
          </table>
        </IonCard>

      </IonContent>
      <IonFooter color="dark">
        <Tabs currentPath={props.match.url} />
      </IonFooter>
    </IonPage>
  )
};
export default Withdraw;