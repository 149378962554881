import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  getPlatforms,
  IonApp,
  IonRouterOutlet,
  setupConfig,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

// General
import Landing from './pages/landing';
import Signup from './pages/signup';
import Login from './pages/login';
import Profile from './pages/profile';

// Freelancer
import FreelancerJobs from './pages/freelancer/job/index';
import FreelancerShowJob from './pages/freelancer/job/show';
// import MyJobsFreelancer from './pages/freelancer/myJobs';
import FreelancerProposals from './pages/freelancer/proposal/index';
import FreelancerShowProposal from './pages/freelancer/proposal/show';
import FreelancerDashboard from './pages/freelancer/dashboard/index';

// Client
import ClientProposalPayment from './pages/client/payment/index'
import ClientFreelancers from './pages/client/freelancer/index';
import ClientShowFreelancer from './pages/client/freelancer/show';
import ClientJobs from './pages/client/job/index';
import ClientShowJob from './pages/client/job/show'
import ClientNewJob from './pages/client/job/create';
import ClientDashboard from './pages/client/dashboard/index'

// import localStorage from './services/localStorage';

// import { setupConfig } from '@ionic/react'


import './global.scss'
import ScrollToTop from './components/desktop/ScrollToTop';
import Payment from './pages/payment';
import Chat from './pages/messages/chat';
import userEffect from './pages/userEffect';
import Messages from './pages/messages';

// import ClientPrivateRoute from './components/RouteGuard'
import PrivateRoute from './components/RouteGuard'
import { UserContext } from './context/UserContext';
import Loading from './components/loading';
import FreelancerWithdraw from './pages/freelancer/withdraw';
import { ToastProvider } from './context/ToastContext';
import Toast from './components/Toast';
import PagueloFacilPage from './pages/paguelofacil';


const App: React.FC = () => {

  const { user, isUserLoading } = React.useContext(UserContext);

  const platform: any = getPlatforms();

  setupConfig({
    rippleEffect: false,
    mode: 'ios'
  });

  // const logged = localStorage.getItem('token')
  // // console.log('teste')
  // if (logged) { console.log('logged') }

  if (isUserLoading) return <Loading isLoading={isUserLoading} />

  else return (
    <IonApp>
      <IonReactRouter>
        <ScrollToTop />

        <IonRouterOutlet>
          <ToastProvider>
            <Route path="/chat/:socketId" component={Chat} exact />
            <Route path="/messages" component={Messages} exact />
            <Route path="/payment/:jobId" component={Payment} exact />

            {/* Public Pages */}
            <Route path="/test" component={userEffect} exact />
            <Route path="/landing" component={Landing} exact />
            <Route path="/signup" component={Signup} exact />
            <Route path="/login" component={Login} exact />

            {/* Shared Pages */}
            <PrivateRoute path="/profile" component={Profile} exact />

            {/* Client Pages */}
            <PrivateRoute path="/client" component={ClientDashboard} exact />
            <PrivateRoute path="/client/freelancers" component={ClientFreelancers} exact />
            <PrivateRoute path="/client/freelancers/:freelancerID" component={ClientShowFreelancer} exact />
            <PrivateRoute path="/client/new-job" component={ClientNewJob} exact />
            <PrivateRoute path="/client/jobs" component={ClientJobs} exact/>
            <PrivateRoute path="/client/jobs/:jobID" component={ClientShowJob} exact />
            <PrivateRoute path="/client/jobs/:jobID/proposal/:proposalID/pay" component={ClientProposalPayment} exact />

            {/* Freelancer Pages */}
            <PrivateRoute path="/freelancer" component={FreelancerDashboard} exact />
            <PrivateRoute path="/freelancer/jobs" component={FreelancerJobs} exact />
            <PrivateRoute path="/freelancer/jobs/:jobID" component={FreelancerShowJob} exact />
            <PrivateRoute path="/freelancer/proposals" component={FreelancerProposals} exact />
            <PrivateRoute path="/freelancer/proposals/:proposalID" component={FreelancerShowProposal} exact />
            <PrivateRoute path="/freelancer/withdraws" component={FreelancerWithdraw} exact />

            {/* test routes */}
            <Route path="/paguelofacil" component={PagueloFacilPage} exact />

            <Route path="/" component={user?.type === 'client' ? () => <Redirect to="/client" /> : user?.type === 'freelancer' ? () => <Redirect to="/freelancer" /> : () => <Redirect to="/landing" />} exact />

            {platform == 'desktop' ? <Toast position="bottom" color="warning" /> : <Toast />}

          </ToastProvider>
        </IonRouterOutlet>
      </IonReactRouter>

    </IonApp>
  )
};

export default App;
