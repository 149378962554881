// import { format,  } from 'date-fns'
import { isToday } from 'date-fns';
import { formatWithOptions } from 'date-fns/fp';
import { es } from 'date-fns/locale';


export default function formatDateMessage(date: any) {
  
  if (!date) return ''

  const messageDate = new Date(date)

  if (isToday(messageDate)) {
    const formatInputDate = formatWithOptions({ locale: es }, 'hh:mm a')
    return formatInputDate(new Date(messageDate))
  }
  else {
    const formatInputDate = formatWithOptions({ locale: es }, 'dd/MM')
    return formatInputDate(new Date(messageDate))
  }

}