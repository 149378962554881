import React from 'react';
import { IonAvatar, IonAlert, IonBadge, IonButton, IonCard, IonCardContent, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonPage, IonRow, IonText, IonTitle, IonToolbar, getPlatforms, IonList, IonSelect, IonSelectOption, IonCheckbox, IonSegment, IonSegmentButton } from '@ionic/react';
import api from '../../services/api';
import { chevronDown, createOutline, checkmarkOutline, constructOutline, documentTextOutline, homeOutline, personOutline, atOutline, lockClosedOutline, addOutline, phonePortraitOutline, trashOutline, locationOutline, cardOutline, create } from 'ionicons/icons';
import './index.css'
import Toast from '../../components/Toast';

// Services
import auth from "../../services/auth";

// Contexts
import { UserContext } from '../../context/UserContext';
import { useHistory } from 'react-router';

// Components
import BackButton from '../../components/BackButton';
import Tabs from '../../components/Tabs';
import Loading from '../../components/loading';
import Header from '../../components/desktop/Header';
import formatDate from '../../utils/formatDate';
import ReactStars from 'react-stars';
import MobileHeader from '../../components/MobileHeader';
import { ToastContext } from '../../context/ToastContext';

const availableBanks = [
  "ATLAS BANK",
  "BAC INTERNATIONAL BANK",
  "BANCO ALIADO",
  "BANCO AZTECA",
  "BANCO CANAL BANK",
  "BANCO DEL PACIFICO PANAMA, S.A",
  "BANCO DELTA",
  "BANCO FICOHSA PANAMA",
  "BANCO GENERAL",
  "BANCO INTERNACIONAL DE COSTA RICA - BICSA",
  "BANCO LA HIPOTECARIA",
  "BANCO LAFISE, S.A.",
  "BANCO NACIONAL",
  "BANCO PICHINCHA PANAMA",
  "BANCOLOMBIA",
  "BANESCO",
  "BANISI, S.A.",
  "BANISTMO, S.A",
  "BANK OF CHINA",
  "BBP BANK, S.A",
  "BCT BANK",
  "BI - BANK",
  "CACECHI",
  "CAJA DE AHORROS",
  "CAPITAL BANK",
  "CITIBANK N.A",
  "COEDUCO",
  "COOESAN",
  "COOPEDUC",
  "COOPERATIVA CRISTOBAL",
  "COOPERATIVA EDIOACC",
  "COOPERATIVA PROFESIONALES R.L",
  "COOPEVE",
  "COOPRAC R.L",
  "CREDICORP BANK",
  "DAVIVIENDA",
  "ECASESO",
  "GLOBAL BANK",
  "MERCANTIL BANCO",
  "METROBANK",
  "MMG BANK",
  "MULTIBANK",
  "PRIVAL BANK",
  "ST. GEORGES BANK",
  "THE BANK OF NOVA SCOTIA",
  "TOWERBANK INT",
  "UNIBANK",

]

const Profile: React.FC = (props: any) => {

  const router = useHistory();
  const platform: any = getPlatforms();

  const { user, setUser, setUpdateUserContext, isUserLoading } = React.useContext(UserContext)
  const { setToast, setToastMessage } = React.useContext(ToastContext);

  const [isEditing, setIsEditing] = React.useState(false);

  const [passChange, setPassChange] = React.useState(false);

  const [bankData, setBankData] = React.useState(false);

  const [updateProfileModal, setUpdateProfileModal] = React.useState(false);

  const [logoutConf, setLogoutConf] = React.useState(false);

  const [photoModal, setPhotoModal] = React.useState(false);

  const [skills, setSkills] = React.useState<any>(user.freelancer?.skills || ['']);
  const [workPreferences, setWorkPreferences] = React.useState<any>(user.freelancer?.workPreference || { remote: false, presential: false });

  const [profileImage, setProfileImage] = React.useState<any>(null)

  React.useEffect(() => {
    if (user) {
      // setName(user.name)
      // setEmail(user.email)
      // setPhone(user.phone)
      // setAbout(user.freelancer.about)
      // setResume(user.freelancer.resume)
      // setAddress(user.freelancer.address)
      setSkills(user.freelancer.skills)
      setWorkPreferences(user.freelancer.workPreference)
      setProfileImage(user?.image)
    }
  }, [isEditing])


  async function handleEdit(e: any) {
    e.preventDefault()

    const { name, phone, address, about, resume } = e.target;

    let input = {};

    user.type == 'freelancer'
      ? input = {
        name: name.value,
        phone: phone.value,
        freelancer: {
          address: address.value,
          about: about.value,
          resume: resume.value,
          skills: skills,
          workPreference: workPreferences
        }
      }
      : input = {
        name: name.value,
        phone: phone.value,
      }

    try {
      const config = await auth.getHeaderConfig();
      const res = await api.patch("/user/me/update", input, config);
      setUpdateUserContext((previous: any) => !previous);
      setIsEditing(false)
      setUpdateProfileModal(false)
      setToastMessage("¡Datos cambiados con éxito!")
      setToast(true);
    } catch (error) {
      setToastMessage("¡Error de actualizacion de datos!")
      setToast(true);
      return;
    }
  }

  async function handleBankDataChange(e: any) {
    e.preventDefault()

    const { bankName, bankUser, bankNumber, bankType } = e.target;

    let input = {
      bank: {
        name: bankUser.value,
        account: bankNumber.value,
        accountType: bankType.value,
        bank: bankName.value
      },
      updateType: 'bank'
    };

    try {
      const config = await auth.getHeaderConfig();
      const res = await api.patch("/user/me/update", input, config);
      setUpdateUserContext((previous: any) => !previous);
      setBankData(false)
      setToastMessage("¡Datos cambiados con éxito!")
      setToast(true);
    } catch (error) {
      setToastMessage("¡Error al actualizar datos!")
      setToast(true);
      console.log(error?.response?.data);

      return;
    }
  }

  async function handleLogout() {
    try {
      const config = await auth.getHeaderConfig()
      await api.post('/users/me/logout', null, config)

      await auth.onSignOut()

      setUser(false);

      router.push('/landing')
    } catch (error) {
      console.log('Falla al cerrar sesión:', error?.response);
    }
  }

  const handlePassChange = async (e: any) => {

    e.preventDefault()

    const { oldPass, newPass, confirmPass } = e.target;

    if (newPass.value !== confirmPass.value) {
      setToastMessage('¡La confirmación de la nueva contraseña no coincide con la informada!')
      setToast(true);
      return;
    } else if (newPass.value.length < 8) {
      setToastMessage('¡La nueva contraseña debe tener al menos 8 caracteres!')
      setToast(true);
      return;
    } else if (oldPass.value === newPass.value) {
      setToastMessage('¡La nueva contraseña no puede ser idéntica a la actual!')
      setToast(true);
      return;
    }

    const input = {
      oldPassword: oldPass.value,
      password: newPass.value,
    }

    try {
      const config = await auth.getHeaderConfig();
      await api.patch("/user/me/changePassword", input, config);
      setPassChange(false);
      setUpdateUserContext((previous: any) => !previous);
      setToastMessage('Éxito en alterar contraseña.')
      setToast(true);
    } catch (error) {
      setToastMessage('Erro en alterar contraseña.')
      setToast(true);
      setPassChange(false);
    }
  }

  async function handleUpdateProfileImage() {


    try {

      if (profileImage && typeof (profileImage) === 'string') return setPhotoModal(false);

      let formData = new FormData();
      formData.append('file', profileImage);

      const config: any = await auth.getHeaderConfig();
      config.headers['Content-Type'] = 'multipart/form-data'

      await api.patch("/user/me/update", formData, config);

      setPhotoModal(false);
      setUpdateUserContext((previous: any) => !previous);

    } catch (error) {
      console.log('La carga de la imagen falló:', error?.response);
    }
  }

  if (isUserLoading) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (

    <>
      <Header currentPath={props.match.url} />

      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          <div style={{ display: 'flex' }} className="mt-1">
            <div style={{ width: '370px', minWidth: '370px', backgroundColor: 'transparent', borderRadius: '10px', padding: '1rem' }}>
              <IonRow className="mb-3" style={{ overflow: "visible", marginTop: "5rem" }}>
                <IonCol size="12" className="justify-content-start ion-no-padding">
                  <IonButton onClick={() => setUpdateProfileModal(true)} expand="block" color="light" >
                    <IonIcon slot="start" icon='/assets/user.svg' className="mr-2" />
                    Editar Perfil
                  </IonButton>
                  <IonButton onClick={() => setPassChange(true)} expand="block" color="light" className="my-3 ">
                    <IonIcon slot="start" icon='/assets/banco.svg' className="mr-2" />
                    Cambiar contraseña
                  </IonButton>
                  <IonButton onClick={() => setBankData(true)} expand="block" color="light">
                    <IonIcon slot="start" icon='/assets/candado.svg' className="mr-2" />
                    Datos Bancarios
                  </IonButton>

                  <IonButton className="my-3" expand='block' fill='clear' color="danger" onClick={() => setLogoutConf(true)}>
                    Cerrar sesión de Contadoor
                  </IonButton>

                </IonCol>
              </IonRow>
            </div>

            {/*  */}

            <div style={{ width: '100%', backgroundColor: 'trasparent', borderRadius: '10px', padding: '1rem' }}>
              <IonCard className="mb-3" style={{ overflow: "visible", marginTop: "5rem" }}>
                <IonCardContent >
                  <IonRow style={{ position: 'relative', marginBottom: '1rem', alignItems: 'center', marginTop: "-4rem" }}>
                    <p style={{ position: 'absolute', top: 0, right: 0, fontSize: '.9rem', margin: "5rem 1rem", color: "#8D8A8A" }}>{user.createdAt ? "Membro desde de " + formatDate(user.createdAt) : ''}</p>
                    <div className='d-flex' style={{ position: 'absolute', top: 0, left: 0, margin: "5rem 1rem" }}>
                      {
                        user?.type === 'freelancer' ?
                          <>
                            {
                              user?.freelancer?.workPreference?.remote && !user?.freelancer?.workPreference?.presential
                                ? <IonBadge color='primary' style={{ padding: "0.5rem 1rem", borderRadius: "4rem" }}>Remoto</IonBadge> : <></>
                            }

                            {
                              user?.freelancer?.workPreference?.presential && !user?.freelancer?.workPreference?.remote
                                ? <IonBadge color='primary' style={{ padding: "0.5rem 1rem", borderRadius: "4rem" }}>Presencial</IonBadge> : <></>
                            }
                            {
                              user?.freelancer?.workPreference?.presential && user?.freelancer?.workPreference?.remote
                                ? <IonBadge color='primary' style={{ padding: "0.5rem 1rem", borderRadius: "4rem" }}>Presencial y Remoto</IonBadge> : <></>
                            }
                          </>
                          : <></>
                      }
                    </div>
                    <IonCol>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>

                        <div className="desktop-hover" style={{ position: 'relative' }} onClick={() => setPhotoModal(true)}>
                          <div style={{ width: '100px', display: 'inline-block', position: 'relative' }}>
                            <div style={{ display: 'block', paddingBottom: '100%' }}>
                              <img style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%' }} className='ion-no-padding' src={user.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                            </div>
                          </div>
                          <IonIcon icon={create} style={{ position: 'absolute', top: 0, right: 0, fontSize: '1.75rem' }} />
                        </div>

                        <div className="w-100 d-flex flex-column justify-content-center align-items-center ion-padding-horizontal" >
                          <p style={{ fontSize: '1.2rem', marginTop: '16px' }}><strong>{user.name}</strong></p>
                          {/* <p style={{ fontSize: '0.8rem' }}>{user.email}</p> */}
                          {user.type === 'freelancer'
                            ? <>

                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ReactStars
                                  count={5}
                                  value={user?.freelancer?.rating?.value}
                                  size={20}
                                  edit={false}
                                  color2={'#ffd700'}
                                />
                                <p style={{ margin: "0 0 0 5px", fontSize: '15px' }}>{user?.freelancer?.rating?.value}</p>
                              </div>



                              <div className="mt-4 align-self-start">
                                <strong className="d-block mb-2">Sobre mi</strong>
                                <p>{user?.freelancer?.about || "No informado"}</p>
                              </div>
                            </>
                            : <></>
                          }


                          <div className="w-100 mt-4 p-0 align-self-start">

                            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> */}
                            <IonRow>
                              <IonCol size='3'>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem' }}>
                                  <IonIcon className="d-block mb-1"
                                    style={{ height: '32px', width: '32px', backgroundColor: "#F5F5F5", padding: "1rem", borderRadius: "0.5rem" }} icon="/assets/user.svg" />
                                  <div>
                                    <strong>Usuario</strong>
                                    <p>{user?.name || "No informado"}</p>
                                  </div>
                                </div>
                              </IonCol>
                              <IonCol size='3'>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem' }}>
                                  <IonIcon className="d-block mb-1"
                                    style={{ height: '32px', width: '32px', backgroundColor: "#F5F5F5", padding: "1rem", borderRadius: "0.5rem" }} icon="/assets/envelope.svg" />
                                  <div>
                                    <strong>Correo electrónico</strong>
                                    <p>{user?.email || "No informado"}</p>
                                  </div>
                                </div>
                              </IonCol>
                              <IonCol size='3'>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem' }}>
                                  <IonIcon className="d-block mb-1"
                                    style={{ height: '32px', width: '32px', backgroundColor: "#F5F5F5", padding: "1rem", borderRadius: "0.5rem" }} icon="/assets/mobile.svg" />
                                  <div>
                                    <strong>Teléfono</strong>
                                    <p>{user?.phone || "No informado"}</p>
                                  </div>
                                </div>
                              </IonCol>
                              <IonCol size='3'>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '1rem' }}>
                                  <IonIcon className="d-block mb-1"
                                    style={{ height: '32px', width: '32px', backgroundColor: "#F5F5F5", padding: "1rem", borderRadius: "0.5rem" }} icon="/assets/location.svg" />
                                  <div>
                                    <strong>Dirección</strong>
                                    <p>{user.freelancer?.address || "No informado"}</p>
                                  </div>
                                </div>
                              </IonCol>
                            </IonRow>

                            {/* </div> */}

                            {user?.type == "freelancer" ?
                              <IonRow className="w-100 mt-3 p-0 align-self-start">
                                <IonCol className="ion-no-padding">

                                  <h2>
                                    <strong>
                                      Habilidades
                                    </strong>
                                  </h2>

                                  <IonRow className="mt-3">
                                    {skills?.map((skill: any, index: any) => (
                                      <IonCol key={index} size="6" className="ion-no-padding">
                                        <IonItem lines="none" className="ion-no-padding">
                                          <IonIcon slot="start" icon='/assets/habilidades.svg' className="mr-2" />
                                          <IonText >{skill}</IonText>
                                        </IonItem>
                                      </IonCol>
                                    ))}
                                  </IonRow>
                                </IonCol>
                              </IonRow> : <></>
                            }
                          </div>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonCardContent>
              </IonCard>
            </div>
          </div>
        </main>
      </IonContent>

      {/* =========================================== UPDATE PROFILE MODAL ====================================================== */}
      <IonModal isOpen={updateProfileModal} swipeToClose={true} onDidDismiss={() => setUpdateProfileModal(false)}>
        <IonPage>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonButton color='light' size="small" fill="clear" onClick={() => setUpdateProfileModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Edición de Perfil</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            {/* <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}> */}
            <IonCard className='ion-padding'>
              <form onSubmit={handleEdit}>

                <div className="form-group">
                  <IonText color='dark' style={{ fontWeight: 600 }}>Usuario</IonText>
                  <input required type="text" name="name" defaultValue={user?.name || ''} className="form-control" />
                </div>


                <div className="form-group">
                  <IonText color='dark' style={{ fontWeight: 600 }}>Email</IonText>
                  <input disabled type="text" name="email" defaultValue={user?.email || ''} className="form-control" />
                </div>

                <div className="form-group">
                  <IonText color='dark' style={{ fontWeight: 600 }}>Teléfono</IonText>
                  <input type="tel" name="phone" defaultValue={user?.phone || ''} className="form-control" />
                </div>

                {user.type == 'freelancer'
                  ? <>

                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Sobre</IonText>
                      <input type="text" name="about" defaultValue={user?.freelancer?.about || ''} className="form-control" />
                    </div>

                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Resumé</IonText>
                      <input type="text" name="resume" defaultValue={user?.freelancer.resume || ''} className="form-control" />
                    </div>

                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Direción</IonText>
                      <input type="text" name="address" defaultValue={user?.freelancer?.address || ''} className="form-control" />
                    </div>

                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Modalidade de trabalho</IonText>
                      <IonItem lines='none'>
                        <IonCheckbox style={{ '--size': '1.25rem', marginRight: '0.5rem' }} color='dark' checked={workPreferences.remote} onClick={() => setWorkPreferences({ ...workPreferences, remote: !workPreferences.remote })} />
                        <IonLabel style={{ margin: '0 0.1rem', cursor: 'pointer' }}>Remoto</IonLabel>
                      </IonItem>
                      <IonItem lines='none'>
                        <IonCheckbox style={{ '--size': '1.25rem', marginRight: '0.5rem' }} color='dark' checked={workPreferences.presential} onClick={() => setWorkPreferences({ ...workPreferences, presential: !workPreferences.presential })} />
                        <IonLabel style={{ margin: '0 0.1rem', cursor: 'pointer' }}>Presencial</IonLabel>
                      </IonItem>
                    </div>


                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Habilidades</IonText>
                      <IonList>
                        {
                          true
                            ? skills?.length > 1
                              ? skills?.map((skill: any, index: any) => (
                                <IonItem lines='none' key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                  <IonInput style={{ fontSize: '1rem' }} className='form-control pl-2' name='reqInput' type="text" required value={skill} onIonChange={(e: any) => setSkills((currentSkill: any) => {
                                    currentSkill[index] = e.detail.value
                                    return [...currentSkill]
                                  })}></IonInput>
                                  {
                                    index < skills.length - 1
                                      ? <IonIcon icon={trashOutline} color='danger' slot='end' onClick={() => setSkills((currentSkill: any) => {
                                        const temp = currentSkill.filter((item: any, indexItem: any) => index !== indexItem)
                                        if (temp.length === 0) return [...temp, '']
                                        else return [...temp]
                                      }
                                      )} />
                                      : <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                                        setSkills([...skills, ''])
                                        // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                                      }} />
                                  }
                                </IonItem>
                              ))
                              : <IonItem lines='none' style={{ display: 'flex', alignItems: 'center' }}>
                                <IonInput style={{ fontSize: '1rem' }} className='form-control ion-no-padding' name='reqInput' type="text" required value={skills[0]} onIonChange={(e: any) => setSkills((currentSkill: any) => {
                                  currentSkill[0] = e.detail.value
                                  return [...currentSkill]
                                })}></IonInput>
                                {
                                  <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                                    setSkills([...skills, ''])
                                    // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                                  }} />
                                }
                              </IonItem>

                            : user.freelancer?.skills && user.freelancer?.skills?.length > 0
                              ? user.freelancer?.skills?.map((skill: any, index: any) => (
                                <React.Fragment key={index}>
                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                                    <IonIcon color='dark' icon={checkmarkOutline} />
                                    <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>{skill}</p>
                                  </div>
                                </React.Fragment>

                              ))
                              : <p style={{ fontSize: '1em', marginBottom: '1em' }}>No informado</p>
                        }
                      </IonList>
                    </div>

                  </>
                  : <></>
                }
                <div className="d-flex flex-direction-column justify-content-center align-items-center">
                  <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Salvar</button>
                  <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setUpdateProfileModal(false)}>Cancelar</button>
                </div>

              </form>
            </IonCard>
            {/* </div> */}
          </IonContent>
        </IonPage>

      </IonModal>
      {/* ======================================= END UPDATE PROFILE MODAL ================================================= */}

      {/* =========================================== PASS CHANGE MODAL ====================================================== */}

      <IonModal isOpen={passChange} swipeToClose={true} onDidDismiss={() => setPassChange(false)}>
        <IonHeader>
          <IonToolbar color='primary'>
            <IonButton color='light' size="small" fill="clear" onClick={() => setPassChange(false)}>
              <IonIcon slot='start' icon={chevronDown} />
            </IonButton>
            <IonTitle>Edición de Contraseña</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <IonCard className='ion-no-padding'>
            <form onSubmit={handlePassChange}>
              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Introduce la contraseña actual</IonText>
                <input required type="password" name="oldPass" className="form-control" />
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Introduce la nueva contraseña</IonText>
                <input required type="password" name="newPass" className="form-control" />
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Confirma la nueva contraseña</IonText>
                <input required type="password" name="confirmPass" className="form-control" />
              </div>

              <div className="d-flex flex-direction-column justify-content-center align-items-center">
                <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Cambiar</button>
                <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setPassChange(false)}>Cancelar</button>
              </div>
            </form>
          </IonCard>
        </div>

      </IonModal>
      {/* ======================================= END PASS CHANGE MODAL ================================================= */}

      <IonModal isOpen={bankData} swipeToClose={true} onDidDismiss={() => setBankData(false)}>
        <IonHeader>
          <IonToolbar color='primary'>
            <IonButton color='light' size="small" fill="clear" onClick={() => setBankData(false)}>
              <IonIcon slot='start' icon={chevronDown} />
            </IonButton>
            <IonTitle>Datos bancarios</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {/* <IonRow className="ion-align-items-center ion-justify-content-center fullheight ion-padding-horizontal"> */}
          <IonCard className='ion-no-padding'>
            <form onSubmit={handleBankDataChange}>
              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Nombre del banco</IonText>
                {/* <input required type="text" name="bankName" defaultValue={user?.bank?.bank ? user.bank.bank : ''} className="form-control" /> */}
                <input list="banks" type="text" name="bankName" defaultValue={user?.bank?.bank ? user.bank.bank : ''} className="form-control" onChange={(e: any) => e.target.setCustomValidity(availableBanks.includes(e.target.value) ? '' : 'Introduce un banco válido.')} />
                <datalist id="banks">
                  {availableBanks?.map((bank: any, index: any) => (
                    <option key={index} value={bank} />
                  ))}
                </datalist>
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Nombre del responsable de la cuenta</IonText>
                <input required type="text" name="bankUser" defaultValue={user?.bank?.name ? user.bank.name : ''} className="form-control" />
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Número de cuenta</IonText>
                <input required type="number" name="bankNumber" defaultValue={user?.bank?.account ? user.bank.account : ''} className="form-control" />
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Tipo de cuenta</IonText>
                <input required type="text" name="bankType" defaultValue={user?.bank?.accountType ? user.bank.accountType : ''} className="form-control" />
              </div>

              <div className="d-flex flex-direction-column justify-content-center align-items-center">
                <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Guardar</button>
                <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setBankData(false)}>Cancelar</button>
              </div>
            </form>
          </IonCard>
          {/* </IonRow> */}
        </div>

      </IonModal>
      {/* ======================================= END BANKDATA MODAL ================================================= */}

      {/* ======================================== PHOTO MODAL ============================================================ */}
      <IonModal isOpen={photoModal} swipeToClose={true} onDidDismiss={() => { setPhotoModal(false); setProfileImage(user.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg") }}>
        <IonHeader>
          <IonToolbar color='primary'>
            <IonButton color='light' size="small" fill="clear" onClick={() => setPhotoModal(false)}>
              <IonIcon slot='start' icon={chevronDown} />
            </IonButton>
            <IonTitle>Imagen de perfil</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard style={{ width: '100%', height: '100%', backgroundColor: '#F9FAFC' }} className="ion-text-center">
            <IonCardContent style={{ backgroundColor: '#F9FAFC', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', gap: '1.75rem' }}>

              <div style={{ width: '50%', display: 'inline-block', position: 'relative' }}>
                <div style={{ display: 'block', paddingBottom: '100%' }}>
                  {
                    profileImage
                      ? typeof (profileImage) === 'string'
                        ? <img style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%', border: '2px solid #3E39BD' }} className='ion-no-padding' src={user.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                        : <img style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%', border: '2px solid #3E39BD' }} className='ion-no-padding' src={URL.createObjectURL(profileImage)} />
                      : <img style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%', border: '2px solid #3E39BD' }} className='ion-no-padding' src={"https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                  }
                </div>
              </div>

              <IonButton>
                <label className="custom-file-upload">
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    name={'image'}
                    onChange={(e: any) => {
                      setProfileImage(e.target.files[0])
                    }} /> Cargar Imagen
                </label>
              </IonButton>

              <IonFooter>
                <IonRow className='ion-no-padding'>
                  <IonCol>
                    <IonButton expand='block' fill='clear' color='primary' onClick={handleUpdateProfileImage} style={{ fontSize: '1.2rem' }}>
                      Confirmar
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton expand='block' fill='clear' color='danger' onClick={() => setPhotoModal(false)} style={{ fontSize: '1.2rem' }}>
                      Cancelar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonFooter>

            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal >

      {/* ======================================= END PHOTO MODAL ================================================= */}

      < IonAlert
        isOpen={logoutConf}
        onDidDismiss={() => setLogoutConf(false)}
        header='¿Seguro que quieres salir?'
        message='Serás redirigido a la página de inicio de sesión.'
        buttons={[{ text: 'Salir', handler: () => handleLogout() }, 'Cancelar']}
      />
    </>


  )

  else return (
    <IonPage id='page-profile'>
      <IonHeader>
        <MobileHeader title='Perfil' backButton={true} />
        {/* <IonToolbar color='primary'>
          <IonSegment value=''>
            <IonSegmentButton onClick={() => setIsEditing(true)}><IonIcon icon={createOutline} /></IonSegmentButton>
            <IonSegmentButton onClick={() => setPassChange(true)}><IonIcon icon={lockClosedOutline} /></IonSegmentButton>
            <IonSegmentButton onClick={() => setBankData(true)}><IonIcon icon={cardOutline} /></IonSegmentButton>
          </IonSegment>
        </IonToolbar> */}
      </IonHeader>
      <IonContent className="background-default ion-padding">

        <IonCard className="mb-3" style={{ overflow: "visible", marginTop: "5rem" }}>
          <IonCardContent style={{ paddingTop: '0.5rem' }}>
            <IonRow style={{ marginBottom: '1rem', alignItems: 'center', marginTop: "-4rem" }}>
              <IonCol>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>

                  <div style={{ position: 'relative' }} onClick={() => setPhotoModal(true)}>
                    <img style={{ borderRadius: '100px', width: '100px', height: '50%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={user.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                    <IonIcon className="desktop-hover" icon={create} style={{ position: 'absolute', top: 0, right: 0, fontSize: '1.75rem' }} />
                  </div>
                  <div className="w-100 d-flex flex-column justify-content-center align-items-center" >
                    <p style={{ fontSize: '1.2rem', marginTop: '12px' }}><strong>{user.name}</strong></p>
                    {/* <p style={{ fontSize: '0.8rem' }}>{user.email}</p> */}
                    {user.type === 'freelancer'
                      ? <>
                        <ReactStars
                          count={5}
                          value={user?.freelancer?.rating?.value}
                          size={20}
                          edit={false}
                          color1={'#b6b6b6'}
                          color2={'#ffd700'}
                        />

                        <p style={{ fontSize: '.9rem', margin: "0.5rem 0", color: "#8D8A8A" }}>{user.createdAt ? "Membro desde de " + formatDate(user.createdAt) : ''}</p>
                        <div className='d-flex'>

                          {
                            user?.type === 'freelancer' ?
                              <>
                                {
                                  user?.freelancer?.workPreference?.remote && !user?.freelancer?.workPreference?.presential
                                    ? <IonBadge color='primary' style={{ padding: "0.5rem 1rem", borderRadius: "4rem" }}>Remoto</IonBadge> : <></>
                                }

                                {
                                  user?.freelancer?.workPreference?.presential && !user?.freelancer?.workPreference?.remote
                                    ? <IonBadge color='primary' style={{ padding: "0.5rem 1rem", borderRadius: "4rem" }}>Presencial</IonBadge> : <></>
                                }
                                {
                                  user?.freelancer?.workPreference?.presential && user?.freelancer?.workPreference?.remote
                                    ? <IonBadge color='primary' style={{ padding: "0.5rem 1rem", borderRadius: "4rem" }}>Presencial y Remoto</IonBadge> : <></>
                                }
                              </>
                              : <></>
                          }
                        </div>


                        <div className="mt-4 align-self-start">
                          <strong className="d-block mb-2">Sobre mi</strong>
                          <p>{user?.freelancer?.about || "No informado"}</p>
                        </div>
                      </>
                      : <></>
                    }


                    <div className="w-100 mt-4 p-0 align-self-start">
                      <IonRow>
                        <IonCol size="6" className="ion-no-padding d-flex flex-column align-items-center">


                          <IonIcon className="d-block mb-1"
                            style={{ fontSize: "2rem", backgroundColor: "#F5F5F5", padding: "1rem", borderRadius: "0.5rem" }} icon="/assets/user.svg" />

                          <strong>Usuario</strong>
                          <p>{user?.name || "No informado"}</p>
                        </IonCol>

                        <IonCol size="6" className="ion-no-padding d-flex flex-column align-items-center">
                          <IonIcon className="d-block mb-1"
                            style={{ fontSize: "2rem", backgroundColor: "#F5F5F5", padding: "1rem", borderRadius: "0.5rem" }} icon="/assets/envelope.svg" />

                          <strong>Correo electrónico</strong>
                          <p>{user?.email || "No informado"}</p>
                        </IonCol>

                        <IonCol size="6" className="mt-3 ion-no-padding d-flex flex-column align-items-center">
                          <IonIcon className="d-block mb-1"
                            style={{ fontSize: "2rem", backgroundColor: "#F5F5F5", padding: "1rem", borderRadius: "0.5rem" }} icon="/assets/mobile.svg" />
                          <strong>Teléfono</strong>
                          <p>{user?.phone || "No informado"}</p>
                        </IonCol>
                        <IonCol size="6" className="mt-3 ion-no-padding d-flex flex-column align-items-center">
                          <IonIcon className="d-block mb-1"
                            style={{ fontSize: "2rem", backgroundColor: "#F5F5F5", padding: "1rem", borderRadius: "0.5rem" }} icon="/assets/location.svg" />
                          <strong>Dirección</strong>
                          <p>{user.freelancer?.address || "No informado"}</p>
                          <p></p>
                        </IonCol>
                      </IonRow>


                      {user?.type == "freelancer" ?
                        <IonRow className="w-100 mt-3 p-0 align-self-start">
                          <IonCol className="ion-no-padding">

                            <h2>
                              <strong>
                                Habilidades
                              </strong>
                            </h2>

                            <IonRow className="mt-3">
                              {skills?.map((skill: any, index: any) => (
                                <IonCol key={index} size="6" className="ion-no-padding">
                                  <IonItem lines="none" className="ion-no-padding">
                                    <IonIcon slot="start" icon='/assets/habilidades.svg' className="mr-2" />
                                    <IonText >{skill}</IonText>
                                  </IonItem>
                                </IonCol>
                              ))}
                            </IonRow>
                          </IonCol>
                        </IonRow>
                        : <></>
                      }
                    </div>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonCardContent>
        </IonCard>
        <IonRow >
          <IonCol size="12" className="justify-content-start ion-no-padding">
            <IonButton onClick={() => setUpdateProfileModal(true)} expand="block" color="light" >
              <IonIcon slot="start" icon='/assets/user.svg' className="mr-2" />
              Editar Perfil
            </IonButton>
            <IonButton onClick={() => setPassChange(true)} expand="block" color="light" className="my-3 ">
              <IonIcon slot="start" icon='/assets/banco.svg' className="mr-2" />
              Cambiar contraseña
            </IonButton>
            <IonButton onClick={() => setBankData(true)} expand="block" color="light">
              <IonIcon slot="start" icon='/assets/candado.svg' className="mr-2" />
              Datos Bancarios
            </IonButton>

            <IonButton className="my-3" expand='block' fill='clear' color="danger" onClick={() => setLogoutConf(true)}>
              Deslogar de Contadoor
            </IonButton>

          </IonCol>
        </IonRow>
      </IonContent>

      {/* =========================================== UPDATE PROFILE MODAL ====================================================== */}
      <IonModal isOpen={updateProfileModal} swipeToClose={true} onDidDismiss={() => setUpdateProfileModal(false)}>
        <IonPage>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonButton color='light' size="small" fill="clear" onClick={() => setUpdateProfileModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Edición de Perfil</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            {/* <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}> */}
            <IonCard className='ion-padding'>
              <form onSubmit={handleEdit}>

                <div className="form-group">
                  <IonText color='dark' style={{ fontWeight: 600 }}>Usuario</IonText>
                  <input required type="text" name="name" defaultValue={user?.name || ''} className="form-control" />
                </div>


                <div className="form-group">
                  <IonText color='dark' style={{ fontWeight: 600 }}>Email</IonText>
                  <input disabled type="text" name="email" defaultValue={user?.email || ''} className="form-control" />
                </div>

                <div className="form-group">
                  <IonText color='dark' style={{ fontWeight: 600 }}>Teléfono</IonText>
                  <input type="tel" name="phone" defaultValue={user?.phone || ''} className="form-control" />
                </div>

                {user.type == 'freelancer'
                  ? <>

                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Sobre</IonText>
                      <input type="text" name="about" defaultValue={user?.freelancer?.about || ''} className="form-control" />
                    </div>

                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Resumé</IonText>
                      <input type="text" name="resume" defaultValue={user?.freelancer.resume || ''} className="form-control" />
                    </div>

                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Direción</IonText>
                      <input type="text" name="address" defaultValue={user?.freelancer?.address || ''} className="form-control" />
                    </div>

                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Modalidade de trabalho</IonText>
                      <IonItem lines='none'>
                        <IonCheckbox style={{ '--size': '1.25rem', marginRight: '0.5rem' }} color='dark' checked={workPreferences.remote} onClick={() => setWorkPreferences({ ...workPreferences, remote: !workPreferences.remote })} />
                        <IonLabel style={{ margin: '0 0.1rem', cursor: 'pointer' }}>Remoto</IonLabel>
                      </IonItem>
                      <IonItem lines='none'>
                        <IonCheckbox style={{ '--size': '1.25rem', marginRight: '0.5rem' }} color='dark' checked={workPreferences.presential} onClick={() => setWorkPreferences({ ...workPreferences, presential: !workPreferences.presential })} />
                        <IonLabel style={{ margin: '0 0.1rem', cursor: 'pointer' }}>Presencial</IonLabel>
                      </IonItem>
                    </div>


                    <div className="form-group">
                      <IonText color='dark' style={{ fontWeight: 600 }}>Habilidades</IonText>
                      <IonList>
                        {
                          true
                            ? skills?.length > 1
                              ? skills?.map((skill: any, index: any) => (
                                <IonItem lines='none' key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                  <IonInput style={{ fontSize: '1rem' }} className='form-control pl-2' name='reqInput' type="text" required value={skill} onIonChange={(e: any) => setSkills((currentSkill: any) => {
                                    currentSkill[index] = e.detail.value
                                    return [...currentSkill]
                                  })}></IonInput>
                                  {
                                    index < skills.length - 1
                                      ? <IonIcon icon={trashOutline} color='danger' slot='end' onClick={() => setSkills((currentSkill: any) => {
                                        const temp = currentSkill.filter((item: any, indexItem: any) => index !== indexItem)
                                        if (temp.length === 0) return [...temp, '']
                                        else return [...temp]
                                      }
                                      )} />
                                      : <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                                        setSkills([...skills, ''])
                                        // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                                      }} />
                                  }
                                </IonItem>
                              ))
                              : <IonItem lines='none' style={{ display: 'flex', alignItems: 'center' }}>
                                <IonInput style={{ fontSize: '1rem' }} className='form-control ion-no-padding' name='reqInput' type="text" required value={skills[0]} onIonChange={(e: any) => setSkills((currentSkill: any) => {
                                  currentSkill[0] = e.detail.value
                                  return [...currentSkill]
                                })}></IonInput>
                                {
                                  <IonIcon color='dark' icon={addOutline} slot='end' onClick={() => {
                                    setSkills([...skills, ''])
                                    // document.querySelector<any>("ion-list ion-list ion-input:last-child").focus();     
                                  }} />
                                }
                              </IonItem>

                            : user.freelancer?.skills && user.freelancer?.skills?.length > 0
                              ? user.freelancer?.skills?.map((skill: any, index: any) => (
                                <React.Fragment key={index}>
                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                                    <IonIcon color='dark' icon={checkmarkOutline} />
                                    <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>{skill}</p>
                                  </div>
                                </React.Fragment>

                              ))
                              : <p style={{ fontSize: '1em', marginBottom: '1em' }}>No informado</p>
                        }
                      </IonList>
                    </div>

                  </>
                  : <></>
                }
                <div className="d-flex flex-direction-column justify-content-center align-items-center">
                  <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Salvar</button>
                  <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setUpdateProfileModal(false)}>Cancelar</button>
                </div>

              </form>
            </IonCard>
            {/* </div> */}
          </IonContent>
        </IonPage>

      </IonModal>
      {/* ======================================= END UPDATE PROFILE MODAL ================================================= */}

      {/* =========================================== PASS CHANGE MODAL ====================================================== */}

      <IonModal isOpen={passChange} swipeToClose={true} onDidDismiss={() => setPassChange(false)}>
        <IonHeader>
          <IonToolbar color='primary'>
            <IonButton color='light' size="small" fill="clear" onClick={() => setPassChange(false)}>
              <IonIcon slot='start' icon={chevronDown} />
            </IonButton>
            <IonTitle>Edición de Contraseña</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <IonCard className='ion-no-padding'>
            <form onSubmit={handlePassChange}>
              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Introduce la contraseña actual</IonText>
                <input required type="password" name="oldPass" className="form-control" />
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Introduce la nueva contraseña</IonText>
                <input required type="password" name="newPass" className="form-control" />
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Confirma la nueva contraseña</IonText>
                <input required type="password" name="confirmPass" className="form-control" />
              </div>

              <div className="d-flex flex-direction-column justify-content-center align-items-center">
                <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Cambiar</button>
                <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setPassChange(false)}>Cancelar</button>
              </div>
            </form>
          </IonCard>
        </div>

      </IonModal>
      {/* ======================================= END PASS CHANGE MODAL ================================================= */}

      <IonModal isOpen={bankData} swipeToClose={true} onDidDismiss={() => setBankData(false)}>
        <IonHeader>
          <IonToolbar color='primary'>
            <IonButton color='light' size="small" fill="clear" onClick={() => setBankData(false)}>
              <IonIcon slot='start' icon={chevronDown} />
            </IonButton>
            <IonTitle>Datos Bancarios</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          {/* <IonRow className="ion-align-items-center ion-justify-content-center fullheight ion-padding-horizontal"> */}
          <IonCard className='ion-no-padding'>
            <form onSubmit={handleBankDataChange}>
              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Nombre del banco</IonText>
                {/* <input required type="text" name="bankName" defaultValue={user?.bank?.bank ? user.bank.bank : ''} className="form-control" /> */}
                <input list="banks" type="text" name="bankName" defaultValue={user?.bank?.bank ? user.bank.bank : ''} className="form-control" onChange={(e: any) => e.target.setCustomValidity(availableBanks.includes(e.target.value) ? '' : 'Introduce un banco válido.')} />
                <datalist id="banks">
                  {availableBanks?.map((bank: any, index: any) => (
                    <option key={index} value={bank} />
                  ))}
                </datalist>
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Nombre del responsable de la cuenta</IonText>
                <input required type="text" name="bankUser" defaultValue={user?.bank?.name ? user.bank.name : ''} className="form-control" />
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Número de cuenta</IonText>
                <input required type="number" name="bankNumber" defaultValue={user?.bank?.account ? user.bank.account : ''} className="form-control" />
              </div>

              <div className="form-group">
                <IonText color='dark' style={{ fontWeight: 600 }}>Tipo de cuenta</IonText>
                <input required type="text" name="bankType" defaultValue={user?.bank?.accountType ? user.bank.accountType : ''} className="form-control" />
              </div>

              <div className="d-flex flex-direction-column justify-content-center align-items-center">
                <button type="submit" className="m-1 btn btn-block btn-outline-success mt-4">Guardar</button>
                <button type="button" className="m-1 btn btn-block btn-outline-danger mt-4" onClick={() => setBankData(false)}>Cancelar</button>
              </div>
            </form>
          </IonCard>
          {/* </IonRow> */}
        </div>

      </IonModal>
      {/* ======================================= END BANKDATA MODAL ================================================= */}

      {/* ======================================== PHOTO MODAL ============================================================ */}
      <IonModal isOpen={photoModal} swipeToClose={true} onDidDismiss={() => { setPhotoModal(false); setProfileImage(user.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg") }}>
        <IonHeader>
          <IonToolbar color='primary'>
            <IonButton color='light' size="small" fill="clear" onClick={() => setPhotoModal(false)}>
              <IonIcon slot='start' icon={chevronDown} />
            </IonButton>
            <IonTitle>Imagen de perfil</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard style={{ width: '100%', height: '100%', backgroundColor: '#F9FAFC' }} className="ion-text-center">
            <IonCardContent style={{ backgroundColor: '#F9FAFC', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', gap: '1.75rem' }}>

              <div style={{ width: '100%', display: 'inline-block', position: 'relative' }}>
                <div style={{ display: 'block', paddingBottom: '100%' }}>
                  {
                    profileImage
                      ? typeof (profileImage) === 'string'
                        ? <img style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%', border: '2px solid #3E39BD' }} className='ion-no-padding' src={user.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                        : <img style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%', border: '2px solid #3E39BD' }} className='ion-no-padding' src={URL.createObjectURL(profileImage)} />
                      : <img style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', borderRadius: '50%', border: '2px solid #3E39BD' }} className='ion-no-padding' src={"https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                  }
                </div>
              </div>

              <IonButton>
                <label className="custom-file-upload">
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    name={'image'}
                    onChange={(e: any) => {
                      setProfileImage(e.target.files[0])
                    }} /> Cargar Fotografía
                </label>
              </IonButton>

              <IonFooter>
                <IonRow className='ion-no-padding'>
                  <IonCol>
                    <IonButton expand='block' fill='clear' color='primary' onClick={handleUpdateProfileImage} style={{ fontSize: '1.2rem' }}>
                      Confirmar
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton expand='block' fill='clear' color='danger' onClick={() => setPhotoModal(false)} style={{ fontSize: '1.2rem' }}>
                      Cancelar
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonFooter>

            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonModal >

      {/* ======================================= END PHOTO MODAL ================================================= */}

      < IonAlert
        isOpen={logoutConf}
        onDidDismiss={() => setLogoutConf(false)}
        header='¿Seguro que quieres salir?'
        message='Serás redirigido a la página de inicio de sesión.'
        buttons={[{ text: 'Salir', handler: () => handleLogout() }, 'Cancelar']}
      />
      <IonFooter>
        <Tabs currentPath={props.match.url} />
      </IonFooter>
    </IonPage >
  )
};
export default Profile;