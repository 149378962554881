import React from 'react';
import { useHistory } from 'react-router';
import { getPlatforms, IonAlert, IonAvatar, IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonChip, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonRow, IonSegment, IonSegmentButton, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { alertCircle, alertCircleOutline, arrowDownCircleOutline, attachOutline, briefcaseOutline, calendarOutline, callOutline, cashOutline, checkmarkOutline, chevronBack, chevronDown, close, closeCircleOutline, constructOutline, createOutline, desktopOutline, documentTextOutline, homeOutline, laptopOutline, personOutline, star, starHalf, starOutline, trashOutline } from 'ionicons/icons';

// Component
import Loading from '../../../components/loading';
import BackButton from '../../../components/BackButton';

// Services
import api from '../../../services/api'
import auth from '../../../services/auth';

// Context
import { UserContext } from '../../../context/UserContext';
import { ToastContext } from '../../../context/ToastContext';

// Utils
import { customBadges } from '../../../utils/customBadges';
import Tabs from '../../../components/Tabs';
import formatDate from '../../../utils/formatDate';
import SucessAlert from '../../../components/Alert/SucessAlert';
import Header from '../../../components/desktop/Header';
import PaypalButton from '../../../components/PaypalButton';
import ReactStars from 'react-stars';
import MobileHeader from '../../../components/MobileHeader';
import { addDays } from 'date-fns'

const MyJobsClient: React.FC = (props: any) => {

  const router = useHistory();

  const { user, isUserLoading, setUpdateUserContext } = React.useContext(UserContext);
  const { setToast, setToastMessage } = React.useContext(ToastContext);

  const job = user ? user.client?.jobs?.find((job: any) => job._id === props.match.params.jobID) : null
  const proposals = job ? job.proposals?.filter((proposal: any) => proposal.status === "pending") : null

  const platform: any = getPlatforms();

  const [currentProposal, setCurrentProposal] = React.useState<any>({})
  const [showAlert, setShowAlert] = React.useState(false);
  const [operation, setOperation] = React.useState('');

  const [isSucessAlertOpen, setIsSucessAlertOpen] = React.useState(false);
  const [isFailAlertOpen, setIsFailAlertOpen] = React.useState(false);

  const [showProposalModal, setShowProposalModal] = React.useState<any>(false)
  const [showJobModal, setShowJobModal] = React.useState<any>(false)

  const [internalLoading, setInternalLoading] = React.useState<any>(false)

  React.useEffect(()=>{
    let status = props?.location?.search?.split('=')[1]
    
    if (status) {
      status === 'success' ? setIsSucessAlertOpen(true) : setIsFailAlertOpen(true)
      router.replace(props?.location?.pathname)
      status = null
    }
  },[])


  // async function propSetOld(proposal: any) {

  //    return;
  //    try {
  //       const config = await auth.getHeaderConfig()
  //       await api.get(`/job/${job._id}/proposal/${proposal._id}`, config);
  //    }
  //    catch (error) {
  //       console.log("Erro no ClientShowJob:", error?.response?.data)
  //       return;
  //    }
  //    //proposal.new = false;
  // }

  async function handleAcceptProposal() {
    try {
      setInternalLoading(true)
      // const config = await auth.getHeaderConfig()
      await api.post(`/job/${job._id}/proposal/${currentProposal._id}/accept`);
      // await api.patch(`/proposal/${currentProposal._id}`, { status: 'accepted' }, config);
      setUpdateUserContext((previous: any) => !previous);
      setShowProposalModal(false);
      // handlePayProposal();
      setInternalLoading(false)
    } catch (error) {
      console.log("Erro no handleAcceptProposal:", error?.response?.data)
      return;
    }
  }

  async function handleDeclineProposal() {
    try {
      setInternalLoading(true)
      // const config = await auth.getHeaderConfig()
      await api.post(`/job/${job._id}/proposal/${currentProposal._id}/decline`);
      // await api.patch(`/proposal/${currentProposal._id}`, { status: 'declined' }, config);
      setUpdateUserContext((previous: any) => !previous);
      setShowProposalModal(false);
      setInternalLoading(false)
    } catch (error) {
      console.log("Erro no handleDeclineProposal:", error?.response?.data)
      return;
    }
  }

  // async function handlePayProposal(proposal: any) {
  //    router.push(`/client/jobs/${job._id}/proposal/${proposal._id}/pay`, proposal);
  //       router.push(`/payment/${job._id}`, config)
  // }

  async function handlePayProposal() {
    try {
      const config = await auth.getHeaderConfig()
      const { data: payment } = await api.post(`/payment/${job._id}`, null, config)

      if (payment.status === 'success') {
        window.location.href = payment?.data?.res?.data?.url;
      }

    } catch (error) {
      console.log("Erro no handlePay:", error?.response?.data)
      return;
    }
  }

  async function handleAcceptJob() {
    try {
      setInternalLoading(true)
      const config = await auth.getHeaderConfig()
      await api.patch(`/job/${job._id}`, { status: "job-finished" }, config)
      setUpdateUserContext((previous: any) => !previous);;
      setShowProposalModal(false);
      setInternalLoading(false)
    } catch (error) {
      console.log("Erro no handleAcceptJob:", error?.response?.data)
      return;
    }
  }

  async function handleDeclineJob() {
    try {
      setInternalLoading(true)
      const config = await auth.getHeaderConfig()
      await api.patch(`/job/${job._id}`, { status: "client-refused" }, config)
      setUpdateUserContext((previous: any) => !previous);;
      setShowProposalModal(false);
      setInternalLoading(false)
    } catch (error) {
      console.log("Erro no handleDeclineJob:", error?.response?.data)
      return;
    }
  }

  async function handleUpdateJobStatus(status: any) {
    try {
      setInternalLoading(true)
      const config = await auth.getHeaderConfig()
      await api.patch(`/job/${job._id}`, { status: status }, config)
      setUpdateUserContext((previous: any) => !previous);;
      setInternalLoading(false)
      setToast(true)
    } catch (error) {
      console.log("Erro no handleUpdateJobStatus:", error?.response?.data)
      return;
    }
  }

  async function handleDeleteJob() {
    try {
      setInternalLoading(true)
      const config = await auth.getHeaderConfig()
      await api.delete(`/job/${job._id}`, config);
      setToastMessage("Servicio eliminado..");
      setUpdateUserContext((previous: any) => !previous);
      setInternalLoading(false)
      setToast(true)
      return router.push('/client/jobs')
    } catch (error) {
      // setToastMessage("Erro en eliminar servicio, intenta de nuevo..");
      // setToast(true)
      console.log("Erro no handleDeleteJob:", error?.response?.data)
      return;
    }
  }

  if (isUserLoading || !job) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (
    <>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          <div style={{ display: 'flex', gap: '0.5rem', width: '100%', height: '218px', position: 'absolute', top: 0, left: 0, zIndex: 0, backgroundColor: '#7A82EA', padding: '2rem' }} >
            <div style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer', zIndex: 2 }}
              // onClick={() => router.push('/client/jobs')}
              onClick={() => router.goBack()}
            >
              <IonIcon size='large' color='light' icon={chevronBack} />
              <p style={{ margin: '0', fontSize: '14px', color: '#FFFFFF', marginTop: '0.4rem' }}>Volver a servicios</p>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1 }} className="mt-1">
            <div style={{ display: 'flex', alignItems: 'center', zIndex: 1 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', padding: '2rem 0', zIndex: 1 }}>
                <p style={{ fontSize: "24px", color: "#FFFFFF", margin: "0" }}>{job.title}</p>
                <IonChip className='ion-text-center' style={{
                  backgroundColor:
                    job.status === 'open' ? '#00CFB4' :
                      job.status === 'private' ? '#ffc409' :
                        job.status === 'working' ? '#ffc409' :
                          job.status === 'waiting-payment' ? '#ffc409' :
                            job.status === 'freelancer-finished' ? '#2fdf75' :
                              job.status === 'client-refused' ? '#ff4961' :
                                job.status === 'client-require-job-cancel' ? '#ffd534' :
                                  job.status === 'moderation' ? '#ffc409' :
                                    job.status === 'job-cancelled' ? '#989aa2' :
                                      job.status === 'job-finished' ? '#989aa2' : '',
                  color: '#F9FAFC',
                  fontSize: '16px',
                  padding: '10px 30px',
                  margin: '0',
                  cursor: 'default',
                }}>{customBadges(job, user)}</IonChip>
              </div>
            </div>

            <div style={{ width: '65%' }}>
              <IonCard style={{ width: "100%", backgroundColor: '#F9FAFC', padding: '2rem' }} className="ion-margin-top">
                <IonCardContent style={{ padding: '2rem' }}>
                  <div style={{ position: 'relative', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <p style={{ fontSize: '14px', color: '#969696', margin: '0' }}>Atualizado el {formatDate(job.updatedAt)}</p>
                    {job.status === 'open'
                      ? job.proposed
                        ? <IonChip className='ion-text-center' style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: '#00CFB420',
                          color: '#616161',
                          fontSize: '16px',
                          padding: '10px 20px',
                          margin: '0',
                          cursor: 'default',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '1rem',
                        }}>
                          <img height='20px' src='/assets/check.svg' />
                          <p style={{ fontSize: '13px', margin: 0 }}>Propuesta enviada</p>
                        </IonChip>

                        : <></>
                      : <></>
                    }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <p style={{ fontSize: '16px', color: '#2D2D2D', margin: '2rem 0' }}><strong>Descripción: </strong>{job.description.length < 140 ? job.description : job.description.slice(0, 140) + '...'}</p>

                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '2rem' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img width='20px' src='/assets/portfolio.svg' />
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Tipo de servicio:</strong></p>
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{
                            job.type === "auditoria" ? "Auditoría" :
                              job.type === "analise" ? "Análisis" :
                                job.type === "pericia" ? "Pericia" :
                                  job.type === "fiscalizacao" ? "Inspección" :
                                    job.type === "consultoria" ? "Consultoría" : ''
                          }</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img width='20px' src='/assets/Grupo 5269.svg' />
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Plazo deseado:</strong></p>
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img width='20px' src='/assets/laptop.svg' />
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Forma de trabajo:</strong></p>
                          <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                        </div>
                      </div>

                    </div>

                  </div>
                  <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "2rem 0" }}><strong>Archivos adjuntos</strong></p>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
                    {
                      job.attachments
                        ? job.attachments.map((attachment: any, index: any) => (
                          <React.Fragment key={index}>
                            <a href={attachment.url} target="_blank" style={{ textDecoration: 'none' }}>
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                                <IonItem lines='none' style={{ '--border-width': '1px', '--border-radius': '10px', '--border-color': '#D4D4D4', width: '190px', '--background': '#F5F5F5' }}>
                                  <p style={{ color: '#000000', margin: 0 }}>{attachment.name}</p>
                                  <IonIcon style={{ height: '20px' }} color='dark' slot="end" icon='/assets/download.svg' />
                                </IonItem>
                              </div>
                            </a>
                          </React.Fragment>
                        ))
                        : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{"No informado"}</p>
                    }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1.5rem' }}>

                    {
                      job?.status === 'open' || job?.status === 'private'
                        ? <>
                          <button onClick={() => { router.push('/client/new-job', { job: job }); setShowJobModal(false); }} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Editar servicio</p>
                          </button>
                          <button onClick={() => { setOperation('delete'); setShowAlert(true) }} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Eliminar servicio</p>
                          </button>
                        </>
                        : job?.status === 'waiting-payment'
                          ? <>
                            <button onClick={() => handlePayProposal()} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                              <p style={{ margin: '0', fontSize: '17px' }}>Pagar</p>
                              {/* <PaypalButton path={props.match.params.jobID} /> */}
                            </button>
                            <button onClick={() => { setOperation('cancelar'); setShowAlert(true) }} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                              <p style={{ margin: '0', fontSize: '17px' }}>Solicitar cancelación</p>
                            </button>
                            <button onClick={() => router.push(`/messages`, { socketId: (job?.acceptedProposal?.freelancer?._id + user._id) })} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                              <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                            </button>
                          </>
                          : job?.status === 'working'
                            ? <>
                              <button onClick={() => { setOperation('cancelar'); setShowAlert(true) }} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                <p style={{ margin: '0', fontSize: '17px' }}>Solicitar cancelación</p>
                              </button>
                              <button onClick={() => router.push(`/messages`, { socketId: (job?.acceptedProposal?.freelancer?._id + user._id) })} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                              </button>
                            </>
                            : job?.status === 'freelancer-finished'
                              ? <>
                                <button onClick={() => handleAcceptJob()} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                  <p style={{ margin: '0', fontSize: '17px' }}>Aceptar finalización</p>
                                </button>
                                <button onClick={() => handleDeclineJob()} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                  <p style={{ margin: '0', fontSize: '17px' }}>Rechazar finalización</p>
                                </button>
                                <button onClick={() => router.push(`/messages`, { socketId: (job?.acceptedProposal?.freelancer?._id + user._id) })} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                  <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                                </button>
                              </>
                              : job?.status === 'job-finished'
                                ? <>
                                  <button onClick={() => router.push('/client/new-job', { customJob: true, freelancer: job.acceptedProposal.freelancer })} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                    <p style={{ margin: '0', fontSize: '17px' }}>Iniciar otro servicio</p>
                                  </button>
                                  <button onClick={() => router.push(`/messages`, { socketId: (job?.acceptedProposal?.freelancer?._id + user._id) })} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                    <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                                  </button>
                                </>
                                : job?.status === 'job-cancelled'
                                  ? <>
                                    <button onClick={() => router.push('/client/new-job', { customJob: true, freelancer: job.acceptedProposal.freelancer })} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                      <p style={{ margin: '0', fontSize: '17px' }}>Iniciar otro servicio</p>
                                    </button>
                                    <button onClick={() => router.push(`/messages`, { socketId: (job?.acceptedProposal?.freelancer?._id + user._id) })} style={{ marginTop: '4rem', borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                      <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                                    </button>
                                  </>
                                  : <></>
                    }
                  </div>
                </IonCardContent>
              </IonCard>
            </div>
          </div>


          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1, marginBottom: '4rem' }} className="mt-1">
            <div style={{ width: '65%' }}>
              {
                job?.status === 'open' || job?.status === 'private'
                  ? <>
                    <p style={{ margin: '1.5rem 0', color: '#000000', fontSize: '20px' }}>Propuestas recebidas</p>
                    {proposals.map((proposal: any, index: any) => (
                      <IonCard key={index} style={{ position: 'relative', width: "100%", backgroundColor: '#F9FAFC' }} className="ion-margin-vertical ion-no-padding">
                        <IonCardContent style={{ padding: '2rem' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                              <IonAvatar style={{ marginRight: '10px', width: '32px', height: '32px' }}>
                                <img src={proposal.freelancer.image || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFvTcn5FrkzwRL1mBlRgMpNE7mIelcbKJiWg&usqp=CAU"} />
                              </IonAvatar>
                              <p style={{ margin: 0, fontSize: '18px' }}>{proposal.freelancer.name}</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                              <button onClick={() => router.push(`/client/freelancers/${proposal?.freelancer?._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '5px 15px', height: '25px' }}>
                                <p style={{ margin: '0', fontSize: '12px' }}>Ver autónomo</p>
                              </button>
                              <button onClick={() => { setCurrentProposal(proposal); setOperation('acceptProposal'); setShowAlert(true) }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '5px 15px', height: '25px' }}>
                                <p style={{ margin: '0', fontSize: '12px' }}>Aceptar</p>
                              </button>
                              <button onClick={() => { setCurrentProposal(proposal); setOperation('declineProposal'); setShowAlert(true) }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '5px 15px', height: '25px' }}>
                                <p style={{ margin: '0', fontSize: '12px' }}>Rechazar</p>
                              </button>
                            </div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/portfolio.svg' />
                                <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Precio:</p>
                                <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{proposal.price} usd</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/Grupo 5269.svg' />
                                <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Plazo:</p>
                                <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{proposal.deadline} dias</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/laptop.svg' />
                                <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Descripción:</p>
                                <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{proposal.description.length < 50 ? proposal.description : proposal.description.slice(0, 50) + '...'}</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                              <p style={{ fontSize: '14px', color: "#8D8A8A" }}>Atualizado en {proposal.updatedAt ? formatDate(proposal.updatedAt) : ''}</p>
                            </div>
                          </div>
                        </IonCardContent>
                      </IonCard>
                    ))}
                  </>
                  : <>
                    <p style={{ margin: '1.5rem 0', color: '#000000', fontSize: '20px' }}>Propuesta acceptada</p>
                    <IonCard style={{ position: 'relative', width: "100%", backgroundColor: '#F9FAFC' }} className="ion-margin-vertical ion-no-padding">
                      <IonCardContent style={{ padding: '2rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
                          <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                            <IonAvatar style={{ marginRight: '10px', width: '32px', height: '32px' }}>
                              <img src={job.acceptedProposal.freelancer.image || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFvTcn5FrkzwRL1mBlRgMpNE7mIelcbKJiWg&usqp=CAU"} />
                            </IonAvatar>
                            <p style={{ margin: 0, fontSize: '18px' }}>{job?.acceptedProposal?.freelancer?.name}</p>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                            <button onClick={() => router.push(`/client/freelancers/${job?.acceptedProposal?.freelancer?._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '5px 15px', height: '25px' }}>
                              <p style={{ margin: '0', fontSize: '12px' }}>Ver autónomo</p>
                            </button>
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                              <img height='15px' src='/assets/portfolio.svg' />
                              <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Precio:</p>
                              <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{job?.acceptedProposal?.price} usd</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                              <img height='15px' src='/assets/Grupo 5269.svg' />
                              <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Plazo:</p>
                              <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{job?.acceptedProposal?.deadline} dias</p>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                              <img height='15px' src='/assets/laptop.svg' />
                              <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Descripción:</p>
                              <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{job?.acceptedProposal?.description.length < 50 ? job?.acceptedProposal?.description : job?.acceptedProposal?.description.slice(0, 50) + '...'}</p>
                            </div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                            <p style={{ fontSize: '14px', color: "#8D8A8A" }}>Atualizado en {job?.acceptedProposal?.updatedAt ? formatDate(job?.acceptedProposal?.updatedAt) : ''}</p>
                          </div>
                        </div>
                      </IonCardContent>
                    </IonCard>
                  </>
              }
            </div>
          </div>
        </main>

        <IonModal isOpen={showProposalModal} swipeToClose={true} onDidDismiss={() => setShowProposalModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonButton color='light' size="small" fill="clear" onClick={() => setShowProposalModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Detalles del Autónomo</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="medium">
            <IonCard className='ion-no-padding'>
              <IonCardContent style={{ paddingBottom: '0' }}>
                <IonRow style={{ marginBottom: '1em' }}>
                  <IonCol size='2.5' className='ion-no-padding'>
                    <img style={{ borderRadius: '50%', width: '100%', height: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={currentProposal?.freelancer?.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                  </IonCol>
                  <IonCol style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: '0.4em' }}>
                    <IonText color='dark' style={{ fontSize: '1.3em' }}><strong>{currentProposal?.freelancer?.name}</strong></IonText>
                    <IonText style={{ fontSize: '0.8em' }}>{currentProposal?.freelancer?.email}</IonText>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonButton size='small' color='dark' className='mb-3' expand='block' onClick={(e) => { e.stopPropagation(); router.push('/client/new-job', { customJob: true, freelancer: currentProposal.freelancer }); setShowProposalModal(false); }}>Contratar</IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton size='small' color='dark' className='mb-3' expand='block' onClick={() => { router.push(`/messages`, { socketId: (currentProposal.freelancer._id + user._id) }); setShowProposalModal(false); }}>Abre el chat</IonButton>
                  </IonCol>
                </IonRow>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                  <IonIcon color='dark' icon={personOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Sobre el</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.about ? currentProposal.freelancer.freelancer.about : "No informado"}</p>


                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                  <IonIcon color='dark' icon={documentTextOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Resumen</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.resume ? currentProposal.freelancer.freelancer.resume : "No informado"}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
                  <IonIcon color='dark' icon={calendarOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Fecha de registro</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.createdAt ? formatDate(currentProposal.freelancer.createdAt) : "No informado"}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                  <IonIcon color='dark' icon={homeOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Dirección</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.address ? currentProposal.freelancer.freelancer.address : "No informado"}</p>


                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                  <IonIcon color='dark' icon={constructOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Habilidades</IonText>
                </div>
                {
                  currentProposal?.freelancer?.freelancer?.skills && currentProposal?.freelancer?.freelancer?.skills?.length > 0
                    ? currentProposal.freelancer.freelancer.skills.map((skill: any, index: any) => (
                      <React.Fragment key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                          <IonIcon color='dark' icon={checkmarkOutline} />
                          <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>{skill}</p>
                        </div>
                      </React.Fragment>
                    ))
                    : <p style={{ fontSize: '1em', marginBottom: '1em' }}>No informado</p>
                }

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
                  <IonIcon color='dark' icon={laptopOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Forma preferida de trabajo</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.workPreference?.remote && currentProposal?.freelancer?.freelancer?.workPreference?.presential
                  ? "Remoto / Presencial"
                  : currentProposal?.freelancer?.freelancer?.workPreference?.presential
                    ? "Presencial"
                    : currentProposal?.freelancer?.freelancer?.workPreference?.presential
                      ? "Remoto"
                      : "No informado"}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
                  <IonIcon color='dark' icon={starOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Evaluación</IonText>
                </div>
                <div style={{ fontSize: '1em', marginBottom: '1em' }}>
                  <ReactStars
                    count={5}
                    value={currentProposal?.freelancer?.freelancer?.rating?.value}
                    size={24}
                    edit={false}
                    color1={'#b6b6b6'}
                    color2={'#ffd700'}
                  />
                </div>
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>



        <IonModal isOpen={showJobModal} swipeToClose={true} onDidDismiss={() => setShowJobModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonButton color='light' size="small" fill="clear" onClick={() => setShowJobModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Detalles del Servicio</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="medium">
            <IonCard className='ion-no-padding'>
              <IonRow style={{ display: 'flex', justifyContent: job.status === 'open' || job?.status === 'private' ? 'space-between' : 'center' }}>
                {job.status === 'open' || job?.status === 'private' ? <IonButton size='small' fill='clear' onClick={() => { router.push('/client/new-job', { job: job }); setShowJobModal(false); }}><IonIcon icon={createOutline} /></IonButton> : <></>}
                <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.updatedAt ? formatDate(job.updatedAt) : ''}</strong></p>
                {job.status === 'open' || job?.status === 'private' ? <IonButton size='small' fill='clear' color='danger' onClick={() => { setOperation('delete'); setShowAlert(true) }}><IonIcon icon={trashOutline} /></IonButton> : <></>}
              </IonRow>
              <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
              <IonCardContent>
                <h1 className="ion-no-padding"><strong>{job.title}</strong></h1>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={briefcaseOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Tipo de servicio</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{
                  job.type === "auditoria" ? "Auditoría" :
                    job.type === "analise" ? "Análisis" :
                      job.type === "pericia" ? "Pericia" :
                        job.type === "fiscalizacao" ? "Inspección" :
                          job.type === "consultoria" ? "Consultoría" : ''
                }</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={laptopOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Forma de trabajo</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{job.workPreference === 'remote' ? 'Remoto' : 'Presencial'}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={documentTextOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Descripción</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{job.description ? job.description : "No informado"}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={calendarOutline} />
                  <IonText color='dark' style={{ fontSize: '1em', marginLeft: '0.4em' }}>{job.acceptedProposal ? 'Plazo' : 'Plazo Desejable'}</IonText>
                </div>
                {/* {job.acceptedProposal ?
                           <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{job.acceptedProposal.createdAt ? formatDate(addDays(job.acceptedProposal.createdAt, job.acceptedProposal.deadline)) : 'A ser definido..'}</p>
                           : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{job.deadline} dias</p>} */}

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={alertCircleOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Requisitos de trabajo</IonText>
                </div>
                {
                  job.requirements
                    ? job.requirements.map((requirement: any, index: any) => (
                      <React.Fragment key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginLeft: '0.5em' }}>
                          <IonIcon icon={checkmarkOutline} />
                          <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>{requirement}</p>
                        </div>
                      </React.Fragment>

                    ))
                    : <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{"No informado"}</p>
                }

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={attachOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Archivos adjuntos</IonText>
                </div>
                {
                  job.attachments
                    ? job.attachments.map((attachment: any, index: any) => (
                      <React.Fragment key={index}>
                        <a href={attachment.url} target="_blank" style={{ textDecoration: 'none' }}>
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginLeft: '0.5em' }}>
                            <IonItem lines='none' style={{ '--border-width': '1px', '--border-radius': '8px', '--border-color': '#072266', width: '100%' }}>
                              <IonLabel color='dark'>{attachment.name}</IonLabel>
                              <IonIcon color='dark' slot="end" icon={arrowDownCircleOutline} />
                            </IonItem>
                          </div>
                        </a>
                      </React.Fragment>

                    ))
                    : <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{"No informado"}</p>
                }
              </IonCardContent>
            </IonCard>
          </IonContent>
        </IonModal>


        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Atención'}
          message={
            operation === 'cancelar' ? '¿Seguro que quieres solicitar la cancelación del servicio?'
              : operation === 'delete' ? '¿Seguro que quieres eliminar el servicio?'
                : operation === 'declineProposal' ? '¿Seguro que quieres rechazar esta propuesta?'
                  : operation === 'acceptProposal' ? '¿Seguro que quieres aceptar esta propuesta?'
                    : ''
          }
          buttons={[
            {
              text: 'Si',
              handler: async () => {
                operation === 'cancelar' ? await handleUpdateJobStatus('client-require-job-cancel')
                  : operation === 'delete' ? await handleDeleteJob()
                    : operation === 'declineProposal' ? await handleDeclineProposal()
                      : operation === 'acceptProposal' ? await handleAcceptProposal()
                        : console.log('');
              }
            },
            {
              text: 'No',
              role: 'cancel',
            },

          ]}
        />


        <SucessAlert success={true} isOpen={isSucessAlertOpen} setIsOpen={setIsSucessAlertOpen} text="Pago realizado. El autónomo será notificado y empezará a trabajar." />
        <SucessAlert success={false} isOpen={isFailAlertOpen} setIsOpen={setIsFailAlertOpen} text="Pago denegado. Tente realizar o pago novamente." />

        <Loading isLoading={internalLoading} />



      </IonContent>
    </>























    // <IonContent className="freelancer-job-desktop">

    //    <Header currentPath={props.match.url} />

    //    <main>
    //       <IonGrid fixed className="content-wrapper">
    //          <IonCard className='ion-no-padding'>
    //             <IonRow>
    //                <IonCol className='ion-text-center ion-no-padding ion-no-margin'>
    //                   <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.updatedAt ? formatDate(job.updatedAt) : ''}</strong></p>
    //                </IonCol>
    //                <IonCol className='ion-text-center ion-no-padding ion-no-margin' style={{
    //                   display: 'flex',
    //                   justifyContent: 'center',
    //                   alignItems: 'center',
    //                   backgroundColor:
    //                      job.status === 'open' ? '#6DECAE40' :
    //                         job.status === 'private' ? '#ffc40940' :
    //                            job.status === 'working' ? '#ffc40940' :
    //                               job.status === 'waiting-payment' ? '#ffc40940' :
    //                                  job.status === 'freelancer-finished' ? '#2fdf7540' :
    //                                     job.status === 'client-refused' ? '#ff496140' :
    //                                        job.status === 'client-require-job-cancel' ? '#ffd53440' :
    //                                           job.status === 'moderation' ? '#ffc40940' :
    //                                              job.status === 'job-cancelled' ? '#989aa240' :
    //                                                 job.status === 'job-finished' ? '#989aa240' : ''
    //                }}>
    //                   {customBadges(job, user)}
    //                </IonCol>
    //             </IonRow>
    //             <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

    //             <IonCardContent style={{ paddingRight: '0' }}>
    //                <IonRow>
    //                   <IonCol size='9'>
    //                      <h1 className="ion-no-padding ion-padding-bottom" ><strong>{job.title}</strong></h1>
    //                      <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{job.description ? job.description : "No informado"}</p>
    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={briefcaseOutline} />
    //                         <IonText color='dark' style={{ fontSize: '1em', marginLeft: '0.4em' }}>Tipo de servicio</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{
    //                         job.type === "auditoria" ? "Auditoría" :
    //                            job.type === "analise" ? "Análisis" :
    //                               job.type === "pericia" ? "Pericia" :
    //                                  job.type === "fiscalizacao" ? "Inspección" :
    //                                     job.type === "consultoria" ? "Consultoría" : ''
    //                      }</p>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={laptopOutline} />
    //                         <IonText color='dark' style={{ fontSize: '1em', marginLeft: '0.4em' }}>Forma de trabajo</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{job.workPreference === 'remote' ? 'Remoto' : 'Presencial'}</p>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={calendarOutline} />
    //                         <IonText color='dark' style={{ fontSize: '1em', marginLeft: '0.4em' }}>{job.acceptedProposal ? 'Plazo' : 'Plazo Desejable'}</IonText>
    //                      </div>
    //                      {job.acceptedProposal ?
    //                         <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{job.acceptedProposal.createdAt ? formatDate(addDays(job.acceptedProposal.createdAt, job.acceptedProposal.deadline)) : 'A ser definido..'}</p>
    //                         : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{job.deadline} dias</p>}

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={alertCircleOutline} />
    //                         <IonText color='dark' style={{ fontSize: '1em', marginLeft: '0.4em' }}>Requisitos de trabajo</IonText>
    //                      </div>
    //                      {
    //                         job.requirements
    //                            ? job.requirements.map((requirement: any, index: any) => (
    //                               <React.Fragment key={index}>
    //                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                                     <IonIcon icon={checkmarkOutline} />
    //                                     <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>{requirement}</p>
    //                                  </div>
    //                               </React.Fragment>

    //                            ))
    //                            : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{"No informado"}</p>
    //                      }

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={attachOutline} />
    //                         <IonText color='dark' style={{ fontSize: '1em', marginLeft: '0.4em' }}>Archivos adjuntos</IonText>
    //                      </div>
    //                      {
    //                         job.attachments
    //                            ? job.attachments.map((attachment: any, index: any) => (
    //                               <React.Fragment key={index}>
    //                                  <a href={attachment.url} target="_blank" style={{ textDecoration: 'none' }}>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                                        <IonItem lines='none' style={{ '--border-width': '1px', '--border-radius': '8px', '--border-color': '#072266', width: '300px' }}>
    //                                           <IonLabel color='dark'>{attachment.name}</IonLabel>
    //                                           <IonIcon color='dark' slot="end" icon={arrowDownCircleOutline} />
    //                                        </IonItem>
    //                                     </div>
    //                                  </a>
    //                               </React.Fragment>

    //                            ))
    //                            : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{"No informado"}</p>
    //                      }
    //                   </IonCol>
    //                   {
    //                      job.status !== 'client-require-job-cancel' ?
    //                         <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                            {
    //                               job?.status === 'open' || job?.status === 'private'
    //                                  ? <>
    //                                     <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { router.push('/client/new-job', { job: job }); setShowJobModal(false); }}>
    //                                        Editar servicio
    //                                     </IonButton>
    //                                     <IonButton color='danger' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setOperation('delete'); setShowAlert(true) }}>
    //                                        Eliminar servicio
    //                                     </IonButton>
    //                                  </>
    //                                  : job?.status === 'waiting-payment'
    //                                     ? <>
    //                                        <PaypalButton path={props.match.params.jobID} />
    //                                        <IonButton color='danger' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setOperation('cancelar'); setShowAlert(true) }}>
    //                                           Solicitar cancelación de servicio
    //                                        </IonButton>
    //                                     </>
    //                                     : job?.status === 'working'
    //                                        ? <>
    //                                           <IonButton color='danger' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setOperation('cancelar'); setShowAlert(true) }}>
    //                                              Solicitar cancelación de servicio
    //                                           </IonButton>
    //                                        </>
    //                                        : job?.status === 'freelancer-finished'
    //                                           ? <>
    //                                              <IonButton color='success' fill='outline' className='ion-padding-horizontal ion-margin-vertical' onClick={() => handleAcceptJob()}>
    //                                                 Aceptar finalización
    //                                              </IonButton>
    //                                              <IonButton color='danger' fill='outline' className='ion-padding-horizontal ion-margin-vertical' onClick={() => handleDeclineJob()}>
    //                                                 Rechazar finalización
    //                                              </IonButton>
    //                                           </>
    //                                           : job?.status === 'job-finished'
    //                                              ? <>
    //                                                 <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push('/client/new-job', { customJob: true, freelancer: job.acceptedProposal.freelancer })}>
    //                                                    Iniciar otro servicio
    //                                                 </IonButton>
    //                                              </>
    //                                              : job?.status === 'job-cancelled'
    //                                                 ? <>
    //                                                    <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push('/client/new-job', { customJob: true, freelancer: job.acceptedProposal.freelancer })}>
    //                                                       Iniciar otro servicio
    //                                                    </IonButton>
    //                                                 </>
    //                                                 : <></>

    //                            }

    //                         </IonCol>
    //                         : <></>
    //                   }

    //                </IonRow>
    //                <IonRow>
    //                   <IonCol>
    //                      <IonFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>

    //                         {job.status === 'open' ? <p style={{ fontSize: '0.7em' }}>{job.proposals.filter((proposal: any) => proposal.status === "pending").length + ` propuesta${job.proposals.length === 1 ? " recebida" : "s recebidas"}`}</p> : null}
    //                         {job.status === 'private' ? <p style={{ fontSize: '0.7em' }}>{job.proposals.length > 0 ? "¡El autónomo ha presentado su propuesta!" : "Servicio privado enviado al autónomo. Pendiente de propuesta."}</p> : null}
    //                         {job.status === 'waiting-payment' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Aceptaste una propuesta!</span> El servicio comienza después del pago.</p> : null}
    //                         {job.status === 'working' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Pago confirmado!</span> El trabajador autónomo debe finalizar el servicio hasta {job.acceptedProposal.createdAt ? formatDate(addDays(job.acceptedProposal.createdAt, job.acceptedProposal.deadline)) : 'a ser definido..'}</p> : null}
    //                         {job.status === 'freelancer-finished' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El autónomo terminó el trabajo!</span> Esperando tu confirmacion.</p> : null}
    //                         {job.status === 'client-refused' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Rechazaste la finalización del servicio!</span> Esperando nueva finalización por parte del autónomo.</p> : null}
    //                         {job.status === 'job-finished' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El servicio esta terminado!</span></p> : null}
    //                         {job.status === 'job-cancelled' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡El servicio ha sido cancelado!</span></p> : null}
    //                         {job.status === 'moderation' ? <p style={{ fontSize: '0.7em', padding: '0 1rem' }}><span style={{ color: '#ED6060' }}>¡Servicio en proceso de disputa!</span> A la espera de la moderación del equipo de Contadoor.</p> : null}
    //                         {job.status === 'client-require-job-cancel' ? <p style={{ fontSize: '0.7em' }}><span style={{ color: '#ED6060' }}>¡Cancelación del servicio solicitada. </span>Esperando la decisión del autónomo.</p> : null}

    //                      </IonFooter>
    //                   </IonCol>
    //                </IonRow>
    //             </IonCardContent>
    //          </IonCard>

    //          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
    //             <IonIcon className="ion-text-center ion-padding-horizontal" icon={chevronDown} />
    //             <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
    //          </div>

    //          {job?.status === 'open'
    //             ? <>
    //                {proposals.length === 0
    //                   ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem' }}>
    //                      <h4 className="ion-text-center ion-padding-horizontal ion-align-self-center">Ningúna propuesta ahi..</h4>
    //                      <div>
    //                         <p>Si tiene problemas para mostrar las propuestas, verifique lo siguiente:</p>
    //                         <ul>
    //                            <li>Filtros aplicados</li>
    //                            <li>Conexión a Internet</li>
    //                            <li>Conexión al servidor Contadoor</li>
    //                         </ul>
    //                      </div>
    //                   </div>
    //                   : <>
    //                      {proposals.map((proposal: any, index: any) => (
    //                         <IonCard className='ion-no-padding ion-card-desktop' key={index}>

    //                            <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
    //                               <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{proposal.updatedAt ? formatDate(proposal.updatedAt) : ''}</strong></p>
    //                            </IonRow>
    //                            <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

    //                            <IonCardContent>
    //                               <IonRow>
    //                                  <IonCol size='9'>
    //                                     <IonRow style={{ display: 'flex', alignItems: 'center', margin: '0.4rem' }}>

    //                                        <IonCol>
    //                                           <IonRow style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                                              <IonCol size='3'>
    //                                                 <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={proposal.freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                                              </IonCol>
    //                                              <IonCol>
    //                                                 <p style={{ fontSize: '1em' }}><strong>{proposal.freelancer.name}</strong></p>
    //                                                 <p style={{ fontSize: '0.8em' }}>{proposal.freelancer.email}</p>
    //                                              </IonCol>
    //                                           </IonRow>
    //                                        </IonCol>

    //                                        <IonCol>
    //                                           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                              <IonIcon color='dark' icon={calendarOutline} />
    //                                              <IonText color='dark' style={{ fontSize: '0.8rem', marginLeft: '0.4rem', fontWeight: 600 }}>Plazo</IonText>
    //                                           </div>
    //                                           <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{proposal.deadline} dias</p>
    //                                        </IonCol>

    //                                        <IonCol>
    //                                           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                              <IonIcon color='dark' icon={cashOutline} />
    //                                              <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Precio</IonText>
    //                                           </div>
    //                                           <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{proposal.price} usd</p>
    //                                        </IonCol>

    //                                        <IonCol size="12">
    //                                           <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem', marginTop: '0.3rem' }}>
    //                                              <IonIcon color='dark' icon={documentTextOutline} />
    //                                              <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Descripción</IonText>
    //                                           </div>
    //                                           <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{proposal.description.length < 200 ? proposal.description : proposal.description.slice(0, 200) + '...'}</p>
    //                                        </IonCol>

    //                                     </IonRow>
    //                                  </IonCol>

    //                                  <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                                     <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(proposal); setShowProposalModal(true); }}>
    //                                        Ver detalles
    //                                     </IonButton>
    //                                     <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(proposal); setOperation('acceptProposal'); setShowAlert(true) }}>
    //                                        Aceitar propuesta
    //                                     </IonButton>
    //                                     <IonButton color='danger' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(proposal); setOperation('declineProposal'); setShowAlert(true) }}>
    //                                        Rechazar propuesta
    //                                     </IonButton>
    //                                  </IonCol>

    //                               </IonRow>
    //                            </IonCardContent>
    //                         </IonCard>
    //                      ))}
    //                   </>
    //                }
    //             </>
    //             : <></>
    //          }

    //          {
    //             job?.status === 'private'
    //                ? <>
    //                   {proposals.length === 0
    //                      ? <>
    //                         <h5 className="ion-text-center  ion-padding">
    //                            Esperando el autónomo.
    //                         </h5>
    //                      </>
    //                      : <>
    //                         {proposals.map((proposal: any, index: any) => (
    //                            <IonCard className='ion-no-padding ion-card-desktop' key={index}>

    //                               <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
    //                                  <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{proposal.updatedAt ? formatDate(proposal.updatedAt) : ''}</strong></p>
    //                               </IonRow>
    //                               <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

    //                               <IonCardContent>
    //                                  <IonRow>
    //                                     <IonCol size='9'>
    //                                        <IonRow style={{ display: 'flex', alignItems: 'center', margin: '0.4rem' }}>

    //                                           <IonCol>
    //                                              <IonRow style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                                                 <IonCol size='3'>
    //                                                    <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={proposal.freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                                                 </IonCol>
    //                                                 <IonCol>
    //                                                    <p style={{ fontSize: '1em' }}><strong>{proposal.freelancer.name}</strong></p>
    //                                                    <p style={{ fontSize: '0.8em' }}>{proposal.freelancer.email}</p>
    //                                                 </IonCol>
    //                                              </IonRow>
    //                                           </IonCol>

    //                                           <IonCol>
    //                                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                                 <IonIcon color='dark' icon={calendarOutline} />
    //                                                 <IonText color='dark' style={{ fontSize: '0.8rem', marginLeft: '0.4rem', fontWeight: 600 }}>Plazo</IonText>
    //                                              </div>
    //                                              <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{proposal.deadline} dias</p>
    //                                           </IonCol>

    //                                           <IonCol>
    //                                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                                 <IonIcon color='dark' icon={cashOutline} />
    //                                                 <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Precio</IonText>
    //                                              </div>
    //                                              <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{proposal.price} usd</p>
    //                                           </IonCol>

    //                                           <IonCol size="12">
    //                                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem', marginTop: '0.3rem' }}>
    //                                                 <IonIcon color='dark' icon={documentTextOutline} />
    //                                                 <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Descripción</IonText>
    //                                              </div>
    //                                              <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{proposal.description.length < 200 ? proposal.description : proposal.description.slice(0, 200) + '...'}</p>
    //                                           </IonCol>

    //                                        </IonRow>
    //                                     </IonCol>

    //                                     <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                                        <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(proposal); setShowProposalModal(true); }}>
    //                                           Ver detalles
    //                                        </IonButton>
    //                                        <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(proposal); setOperation('acceptProposal'); setShowAlert(true) }}>
    //                                           Aceitar propuesta
    //                                        </IonButton>
    //                                        <IonButton color='danger' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(proposal); setOperation('declineProposal'); setShowAlert(true) }}>
    //                                           Rechazar propuesta
    //                                        </IonButton>
    //                                     </IonCol>

    //                                  </IonRow>
    //                               </IonCardContent>
    //                            </IonCard>
    //                         ))}
    //                      </>
    //                   }
    //                </>
    //                : <></>
    //          }


    //          {
    //             job?.status === 'waiting-payment'
    //                ? <>
    //                   <IonCard className='ion-no-padding ion-card-desktop'>
    //                      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
    //                         <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.acceptedProposal.updatedAt ? formatDate(job.acceptedProposal.updatedAt) : ''}</strong></p>
    //                      </IonRow>
    //                      <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //                      <IonCardContent>
    //                         <IonRow>
    //                            <IonCol size='9'>
    //                               <IonRow style={{ display: 'flex', alignItems: 'center', margin: '0.4rem' }}>

    //                                  <IonCol>
    //                                     <div className='d-flex'>
    //                                        <IonAvatar style={{ marginRight: '1rem' }}>
    //                                           <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={job.acceptedProposal.freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                                        </IonAvatar>
    //                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    //                                           <p style={{ fontSize: '1.2rem' }}><strong>{job.acceptedProposal.freelancer.name}</strong></p>
    //                                           <p style={{ fontSize: '0.8rem' }}>{job.acceptedProposal.freelancer.email}</p>
    //                                        </div>
    //                                     </div>
    //                                  </IonCol>

    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={calendarOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8rem', marginLeft: '0.4rem', fontWeight: 600 }}>Plazo</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.deadline} dias</p>
    //                                  </IonCol>
    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={cashOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Precio</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.price} usd</p>
    //                                  </IonCol>


    //                                  <IonCol size="12">
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem', marginTop: '0.3rem' }}>
    //                                        <IonIcon color='dark' icon={documentTextOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Descripción</IonText>
    //                                     </div>
    //                             <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{job.acceptedProposal.description}</p>
    //                                  </IonCol>

    //                               </IonRow>
    //                            </IonCol>

    //                            <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                               <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(job.acceptedProposal); setShowProposalModal(true); }}>
    //                                  Ver detalles
    //                               </IonButton>
    //                               <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/messagess`, { socketId: (job.acceptedProposal.freelancer._id + user._id) })}>
    //                                  Abre el chat
    //                               </IonButton>
    //                            </IonCol>

    //                         </IonRow>
    //                      </IonCardContent>
    //                   </IonCard>
    //                </>
    //                : <></>
    //          }

    //          {
    //             job?.status === 'working' ?
    //                <>
    //                   <IonCard className='ion-no-padding ion-card-desktop'>
    //                      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
    //                         <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Propuesta aceptada en <strong>{job.acceptedProposal ? formatDate(job.acceptedProposal?.updatedAt) : 'a ser definida..'}</strong></p>
    //                      </IonRow>
    //                      <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //                      <IonCardContent>
    //                         <IonRow>
    //                            <IonCol size='9'>
    //                               <IonRow style={{ display: 'flex', alignItems: 'center', margin: '0.4rem' }}>

    //                                  <IonCol>
    //                                     <div className='d-flex'>
    //                                        <IonAvatar style={{ marginRight: '1rem' }}>
    //                                           <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={job.acceptedProposal.freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                                        </IonAvatar>
    //                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    //                                           <p style={{ fontSize: '1.2rem' }}><strong>{job.acceptedProposal.freelancer.name}</strong></p>
    //                                           <p style={{ fontSize: '0.8rem' }}>{job.acceptedProposal.freelancer.email}</p>
    //                                        </div>
    //                                     </div>
    //                                  </IonCol>

    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={calendarOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8rem', marginLeft: '0.4rem', fontWeight: 600 }}>Plazo</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.deadline} dias</p>
    //                                  </IonCol>
    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={cashOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Precio</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.price} usd</p>
    //                                  </IonCol>


    //                                  <IonCol size="12">
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem', marginTop: '0.3rem' }}>
    //                                        <IonIcon color='dark' icon={documentTextOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Descripción</IonText>
    //                                     </div>
    //                             <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{job.acceptedProposal.description}</p>
    //                                  </IonCol>

    //                               </IonRow>
    //                            </IonCol>

    //                            <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                               <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(job.acceptedProposal); setShowProposalModal(true); }}>
    //                                  Ver detalles
    //                               </IonButton>
    //                               <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/messages`, { socketId: (job.acceptedProposal.freelancer._id + user._id) })}>
    //                                  Abre el chat
    //                               </IonButton>
    //                            </IonCol>

    //                         </IonRow>
    //                      </IonCardContent>
    //                   </IonCard>
    //                   <IonFooter className="ion-padding-horizontal">
    //                      <IonRow className="ion-margin-bottom">
    //                         <IonCol size="12" className='ion-no-padding'>
    //                     <IonButton expand='block' fill='outline' color="danger" onClick={() => { setOperation('cancelar'); setShowAlert(true) }}><strong>Solicitar cancelación de servicio</strong></IonButton>
    //                         </IonCol>
    //                      </IonRow>
    //                   </IonFooter>
    //                </>
    //                : <></>
    //          }

    //          {
    //             job?.status === 'freelancer-finished' ?
    //                <>
    //                   <IonCard className='ion-no-padding ion-card-desktop'>
    //                      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
    //                         <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.acceptedProposal ? formatDate(job.acceptedProposal?.updatedAt) : 'a ser definida..'}</strong></p>
    //                      </IonRow>
    //                      <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //                      <IonCardContent>
    //                         <IonRow>
    //                            <IonCol size='9'>
    //                               <IonRow style={{ display: 'flex', alignItems: 'center', margin: '0.4rem' }}>

    //                                  <IonCol>
    //                                     <div className='d-flex'>
    //                                        <IonAvatar style={{ marginRight: '1rem' }}>
    //                                           <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={job.acceptedProposal.freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                                        </IonAvatar>
    //                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    //                                           <p style={{ fontSize: '1.2rem' }}><strong>{job.acceptedProposal.freelancer.name}</strong></p>
    //                                           <p style={{ fontSize: '0.8rem' }}>{job.acceptedProposal.freelancer.email}</p>
    //                                        </div>
    //                                     </div>
    //                                  </IonCol>

    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={calendarOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8rem', marginLeft: '0.4rem', fontWeight: 600 }}>Plazo</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.deadline} dias</p>
    //                                  </IonCol>
    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={cashOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Precio</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.price} usd</p>
    //                                  </IonCol>


    //                                  <IonCol size="12">
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem', marginTop: '0.3rem' }}>
    //                                        <IonIcon color='dark' icon={documentTextOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Descripción</IonText>
    //                                     </div>
    //                  <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{job.acceptedProposal.description}</p>
    //                                  </IonCol>

    //                               </IonRow>
    //                            </IonCol>

    //                            <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                               <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(job.acceptedProposal); setShowProposalModal(true); }}>
    //                                  Ver detalles
    //                               </IonButton>
    //                               <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/messages`, { socketId: (job.acceptedProposal.freelancer._id + user._id) })}>
    //                                  Abre el chat
    //                               </IonButton>
    //                            </IonCol>

    //                         </IonRow>
    //                      </IonCardContent>
    //                   </IonCard>
    //                   <IonFooter className="ion-padding-horizontal">
    //                      <IonRow className="ion-margin-bottom">
    //                         <IonCol size="12" className='ion-no-padding'>
    //                            <IonButton size="large" expand='block' color='primary' onClick={() => handleAcceptJob()} >Aceptar entrega</IonButton>
    //                            <IonButton expand='block' fill="clear" color="danger" onClick={() => handleDeclineJob()}>Rechazar entrega</IonButton>
    //                         </IonCol>
    //                      </IonRow>
    //                   </IonFooter>
    //                </>

    //                : <></>
    //          }

    //          {
    //             job?.status === 'job-finished' ?
    //                <>
    //                   <IonCard className='ion-no-padding ion-card-desktop'>
    //                      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
    //                         <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.acceptedProposal ? formatDate(job.acceptedProposal?.updatedAt) : 'a ser definida..'}</strong></p>
    //                      </IonRow>
    //                      <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //                      <IonCardContent>
    //                         <IonRow>
    //                            <IonCol size='9'>
    //                               <IonRow style={{ display: 'flex', alignItems: 'center', margin: '0.4rem' }}>

    //                                  <IonCol>
    //                                     <div className='d-flex'>
    //                                        <IonAvatar style={{ marginRight: '1rem' }}>
    //                                           <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={job.acceptedProposal.freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                                        </IonAvatar>
    //                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    //                                           <p style={{ fontSize: '1.2rem' }}><strong>{job.acceptedProposal.freelancer.name}</strong></p>
    //                                           <p style={{ fontSize: '0.8rem' }}>{job.acceptedProposal.freelancer.email}</p>
    //                                        </div>
    //                                     </div>
    //                                  </IonCol>

    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={calendarOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8rem', marginLeft: '0.4rem', fontWeight: 600 }}>Plazo</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.deadline} dias</p>
    //                                  </IonCol>
    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={cashOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Precio</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.price} usd</p>
    //                                  </IonCol>


    //                                  <IonCol size="12">
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem', marginTop: '0.3rem' }}>
    //                                        <IonIcon color='dark' icon={documentTextOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Descripción</IonText>
    //                                     </div>
    //                           <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{job.acceptedProposal.description}</p>
    //                                  </IonCol>

    //                               </IonRow>
    //                            </IonCol>

    //                            <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                               <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(job.acceptedProposal); setShowProposalModal(true); }}>
    //                                  Ver detalhls
    //                               </IonButton>
    //                               <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/messages`, { socketId: (job.acceptedProposal.freelancer._id + user._id) })}>
    //                                  Abre el chat
    //                               </IonButton>
    //                            </IonCol>

    //                         </IonRow>
    //                      </IonCardContent>
    //                   </IonCard>
    //                </>
    //                : <></>
    //          }

    //          {
    //             job?.status === 'job-cancelled' ?
    //                <>
    //                   <IonCard className='ion-no-padding ion-card-desktop'>
    //                      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
    //                         <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.acceptedProposal ? formatDate(job.acceptedProposal?.updatedAt) : 'a ser definida..'}</strong></p>
    //                      </IonRow>
    //                      <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //                      <IonCardContent>
    //                         <IonRow>
    //                            <IonCol size='9'>
    //                               <IonRow style={{ display: 'flex', alignItems: 'center', margin: '0.4rem' }}>

    //                                  <IonCol>
    //                                     <div className='d-flex'>
    //                                        <IonAvatar style={{ marginRight: '1rem' }}>
    //                                           <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={job.acceptedProposal.freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                                        </IonAvatar>
    //                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    //                                           <p style={{ fontSize: '1.2rem' }}><strong>{job.acceptedProposal.freelancer.name}</strong></p>
    //                                           <p style={{ fontSize: '0.8rem' }}>{job.acceptedProposal.freelancer.email}</p>
    //                                        </div>
    //                                     </div>
    //                                  </IonCol>

    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={calendarOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8rem', marginLeft: '0.4rem', fontWeight: 600 }}>Plazo</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.deadline} dias</p>
    //                                  </IonCol>
    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={cashOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Precio</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.price} usd</p>
    //                                  </IonCol>


    //                                  <IonCol size="12">
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem', marginTop: '0.3rem' }}>
    //                                        <IonIcon color='dark' icon={documentTextOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Descripción</IonText>
    //                                     </div>
    //                     <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{job.acceptedProposal.description}</p>
    //                                  </IonCol>

    //                               </IonRow>
    //                            </IonCol>

    //                            <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                               <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(job.acceptedProposal); setShowProposalModal(true); }}>
    //                                  Ver detalles
    //                               </IonButton>
    //                               <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/messages`, { socketId: (job.acceptedProposal.freelancer._id + user._id) })}>
    //                                  Abre el chat
    //                               </IonButton>
    //                            </IonCol>

    //                         </IonRow>
    //                      </IonCardContent>
    //                   </IonCard>
    //                </>
    //                : <></>
    //          }

    //          {
    //             job?.status === 'moderation' ?
    //                <>
    //                   <IonCard className='ion-no-padding ion-card-desktop'>
    //                      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
    //                         <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.acceptedProposal ? formatDate(job.acceptedProposal?.updatedAt) : 'a ser definida..'}</strong></p>
    //                      </IonRow>
    //                      <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //                      <IonCardContent>
    //                         <IonRow>
    //                            <IonCol size='9'>
    //                               <IonRow style={{ display: 'flex', alignItems: 'center', margin: '0.4rem' }}>

    //                                  <IonCol>
    //                                     <div className='d-flex'>
    //                                        <IonAvatar style={{ marginRight: '1rem' }}>
    //                                           <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={job.acceptedProposal.freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                                        </IonAvatar>
    //                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    //                                           <p style={{ fontSize: '1.2rem' }}><strong>{job.acceptedProposal.freelancer.name}</strong></p>
    //                                           <p style={{ fontSize: '0.8rem' }}>{job.acceptedProposal.freelancer.email}</p>
    //                                        </div>
    //                                     </div>
    //                                  </IonCol>

    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={calendarOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8rem', marginLeft: '0.4rem', fontWeight: 600 }}>Plazo</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.deadline} dias</p>
    //                                  </IonCol>
    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={cashOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Precio</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.price} usd</p>
    //                                  </IonCol>


    //                                  <IonCol size="12">
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem', marginTop: '0.3rem' }}>
    //                                        <IonIcon color='dark' icon={documentTextOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Descripción</IonText>
    //                                     </div>
    //                                   <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{job.acceptedProposal.description}</p>
    //                                  </IonCol>

    //                               </IonRow>
    //                            </IonCol>

    //                            <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                               <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(job.acceptedProposal); setShowProposalModal(true); }}>
    //                                  Ver detalles
    //                               </IonButton>
    //                               <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/messages`, { socketId: (job.acceptedProposal.freelancer._id + user._id) })}>
    //                                  Abre el chat
    //                               </IonButton>
    //                            </IonCol>

    //                         </IonRow>
    //                      </IonCardContent>
    //                   </IonCard>
    //                </>
    //                : <></>
    //          }
    //          {
    //             job.status === 'client-require-job-cancel' ?
    //                <>
    //                   <IonCard className='ion-no-padding ion-card-desktop'>
    //                      <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
    //                         <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.acceptedProposal ? formatDate(job.acceptedProposal?.updatedAt) : 'a ser definida..'}</strong></p>
    //                      </IonRow>
    //                      <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //                      <IonCardContent>
    //                         <IonRow>
    //                            <IonCol size='9'>
    //                               <IonRow style={{ display: 'flex', alignItems: 'center', margin: '0.4rem' }}>

    //                                  <IonCol>
    //                                     <div className='d-flex'>
    //                                        <IonAvatar style={{ marginRight: '1rem' }}>
    //                                           <img style={{ borderRadius: '50%', width: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={job.acceptedProposal.freelancer.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                                        </IonAvatar>
    //                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
    //                                           <p style={{ fontSize: '1.2rem' }}><strong>{job.acceptedProposal.freelancer.name}</strong></p>
    //                                           <p style={{ fontSize: '0.8rem' }}>{job.acceptedProposal.freelancer.email}</p>
    //                                        </div>
    //                                     </div>
    //                                  </IonCol>

    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={calendarOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8rem', marginLeft: '0.4rem', fontWeight: 600 }}>Plazo</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.deadline} dias</p>
    //                                  </IonCol>
    //                                  <IonCol>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem' }}>
    //                                        <IonIcon color='dark' icon={cashOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Precio</IonText>
    //                                     </div>
    //                                     <p style={{ fontSize: '1em', marginLeft: '0rem' }}>{job.acceptedProposal.price} usd</p>
    //                                  </IonCol>


    //                                  <IonCol size="12">
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4rem', marginTop: '0.3rem' }}>
    //                                        <IonIcon color='dark' icon={documentTextOutline} />
    //                                        <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4rem', fontWeight: 600 }}>Descripción</IonText>
    //                                     </div>
    //                               <p style={{ fontSize: '1em', marginBottom: '0.3em' }}>{job.acceptedProposal.description}</p>
    //                                  </IonCol>

    //                               </IonRow>
    //                            </IonCol>

    //                            <IonCol size="3" style={{ borderLeft: '1px solid #E0E0E0' }}>
    //                               <IonButton color='dark' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => { setCurrentProposal(job.acceptedProposal); setShowProposalModal(true); }}>
    //                                  Ver detalles
    //                               </IonButton>
    //                               <IonButton color='success' fill='outline' expand='block' className='ion-padding-horizontal ion-margin-vertical' onClick={() => router.push(`/messages`, { socketId: (job.acceptedProposal.freelancer._id + user._id) })}>
    //                                  Abre el chat
    //                               </IonButton>
    //                            </IonCol>

    //                         </IonRow>
    //                      </IonCardContent>
    //                   </IonCard>
    //                </>
    //                : <></>
    //          }

    //          <IonModal isOpen={showProposalModal} swipeToClose={true} onDidDismiss={() => setShowProposalModal(false)}>
    //             <IonHeader>
    //                <IonToolbar color='primary'>
    //                   <IonButton color='light' size="small" fill="clear" onClick={() => setShowProposalModal(false)}>
    //                      <IonIcon slot='start' icon={chevronDown} />
    //                   </IonButton>
    //                   <IonTitle>Detalles del Autónomo</IonTitle>
    //                </IonToolbar>
    //             </IonHeader>
    //             <IonContent color="medium">
    //                <IonCard className='ion-no-padding'>
    //                   <IonCardContent style={{ paddingBottom: '0' }}>
    //                      <IonRow style={{ marginBottom: '1em' }}>
    //                         <IonCol size='2.5' className='ion-no-padding'>
    //                            <img style={{ borderRadius: '50%', width: '100%', height: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={currentProposal?.freelancer?.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
    //                         </IonCol>
    //                         <IonCol style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: '0.4em' }}>
    //                            <IonText color='dark' style={{ fontSize: '1.3em' }}><strong>{currentProposal?.freelancer?.name}</strong></IonText>
    //                            <IonText style={{ fontSize: '0.8em' }}>{currentProposal?.freelancer?.email}</IonText>
    //                         </IonCol>
    //                      </IonRow>

    //                      <IonRow>
    //                         <IonCol>
    //                            <IonButton size='small' color='dark' className='mb-3' expand='block' onClick={(e) => { e.stopPropagation(); router.push('/client/new-job', { customJob: true, freelancer: currentProposal.freelancer }); setShowProposalModal(false); }}>Contratar</IonButton>
    //                         </IonCol>
    //                         <IonCol>
    //                            <IonButton size='small' color='dark' className='mb-3' expand='block' onClick={() => { router.push(`/messages`, { socketId: (currentProposal.freelancer._id + user._id) }); setShowProposalModal(false); }}>Abre el chat</IonButton>
    //                         </IonCol>
    //                      </IonRow>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                         <IonIcon color='dark' icon={personOutline} />
    //                         <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Sobre el</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.about ? currentProposal.freelancer.freelancer.about : "No informado"}</p>


    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                         <IonIcon color='dark' icon={documentTextOutline} />
    //                         <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Resumen</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.resume ? currentProposal.freelancer.freelancer.resume : "No informado"}</p>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
    //                         <IonIcon color='dark' icon={calendarOutline} />
    //                         <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Fecha de registro</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.createdAt ? formatDate(currentProposal.freelancer.createdAt) : "No informado"}</p>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                         <IonIcon color='dark' icon={homeOutline} />
    //                         <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Dirección</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.address ? currentProposal.freelancer.freelancer.address : "No informado"}</p>


    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                         <IonIcon color='dark' icon={constructOutline} />
    //                         <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Habilidades</IonText>
    //                      </div>
    //                      {
    //                         currentProposal?.freelancer?.freelancer?.skills && currentProposal?.freelancer?.freelancer?.skills?.length > 0
    //                            ? currentProposal.freelancer.freelancer.skills.map((skill: any, index: any) => (
    //                               <React.Fragment key={index}>
    //                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
    //                                     <IonIcon color='dark' icon={checkmarkOutline} />
    //                                     <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>{skill}</p>
    //                                  </div>
    //                               </React.Fragment>
    //                            ))
    //                            : <p style={{ fontSize: '1em', marginBottom: '1em' }}>No informado</p>
    //                      }

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
    //                         <IonIcon color='dark' icon={laptopOutline} />
    //                         <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Forma preferida de trabajo</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.workPreference?.remote && currentProposal?.freelancer?.freelancer?.workPreference?.presential
    //                         ? "Remoto / Presencial"
    //                         : currentProposal?.freelancer?.freelancer?.workPreference?.presential
    //                            ? "Presencial"
    //                            : currentProposal?.freelancer?.freelancer?.workPreference?.presential
    //                               ? "Remoto"
    //                               : "No informado"}</p>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
    //                         <IonIcon color='dark' icon={starOutline} />
    //                         <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Evaluación</IonText>
    //                      </div>
    //                      <div style={{ fontSize: '1em', marginBottom: '1em' }}>
    //                         <ReactStars
    //                            count={5}
    //                            value={currentProposal?.freelancer?.freelancer?.rating?.value}
    //                            size={24}
    //                            edit={false}
    //                            color1={'#b6b6b6'}
    //                            color2={'#ffd700'}
    //                         />
    //                      </div>
    //                   </IonCardContent>
    //                </IonCard>
    //             </IonContent>
    //          </IonModal>



    //          <IonModal isOpen={showJobModal} swipeToClose={true} onDidDismiss={() => setShowJobModal(false)}>
    //             <IonHeader>
    //                <IonToolbar color='primary'>
    //                   <IonButton color='light' size="small" fill="clear" onClick={() => setShowJobModal(false)}>
    //                      <IonIcon slot='start' icon={chevronDown} />
    //                   </IonButton>
    //                   <IonTitle>Detalles del Servicio</IonTitle>
    //                </IonToolbar>
    //             </IonHeader>
    //             <IonContent color="medium">
    //                <IonCard className='ion-no-padding'>
    //                   <IonRow style={{ display: 'flex', justifyContent: job.status === 'open' || job?.status === 'private' ? 'space-between' : 'center' }}>
    //                      {job.status === 'open' || job?.status === 'private' ? <IonButton size='small' fill='clear' onClick={() => { router.push('/client/new-job', { job: job }); setShowJobModal(false); }}><IonIcon icon={createOutline} /></IonButton> : <></>}
    //                      <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.updatedAt ? formatDate(job.updatedAt) : ''}</strong></p>
    //                      {job.status === 'open' || job?.status === 'private' ? <IonButton size='small' fill='clear' color='danger' onClick={() => { setOperation('delete'); setShowAlert(true) }}><IonIcon icon={trashOutline} /></IonButton> : <></>}
    //                   </IonRow>
    //                   <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
    //                   <IonCardContent>
    //                      <h1 className="ion-no-padding"><strong>{job.title}</strong></h1>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={briefcaseOutline} />
    //                         <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Tipo de servicio</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{
    //                         job.type === "auditoria" ? "Auditoría" :
    //                            job.type === "analise" ? "Análisis" :
    //                               job.type === "pericia" ? "Pericia" :
    //                                  job.type === "fiscalizacao" ? "Inspección" :
    //                                     job.type === "consultoria" ? "Consultoría" : ''
    //                      }</p>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={laptopOutline} />
    //                         <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Forma de trabajo</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{job.workPreference === 'remote' ? 'Remoto' : 'Presencial'}</p>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={documentTextOutline} />
    //                         <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Descripción</IonText>
    //                      </div>
    //                      <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{job.description ? job.description : "No informado"}</p>

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={calendarOutline} />
    //                         <IonText color='dark' style={{ fontSize: '1em', marginLeft: '0.4em' }}>{job.acceptedProposal ? 'Plazo' : 'Plazo Desejable'}</IonText>
    //                      </div>
    //                      {job.acceptedProposal ?
    //                         <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{job.acceptedProposal.createdAt ? formatDate(addDays(job.acceptedProposal.createdAt, job.acceptedProposal.deadline)) : 'A ser definido..'}</p>
    //                         : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{job.deadline} dias</p>}

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={alertCircleOutline} />
    //                         <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Requisitos de trabajo</IonText>
    //                      </div>
    //                      {
    //                         job.requirements
    //                            ? job.requirements.map((requirement: any, index: any) => (
    //                               <React.Fragment key={index}>
    //                                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginLeft: '0.5em' }}>
    //                                     <IonIcon icon={checkmarkOutline} />
    //                                     <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>{requirement}</p>
    //                                  </div>
    //                               </React.Fragment>

    //                            ))
    //                            : <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{"No informado"}</p>
    //                      }

    //                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
    //                         <IonIcon color='dark' icon={attachOutline} />
    //                         <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Archivos adjuntos</IonText>
    //                      </div>
    //                      {
    //                         job.attachments
    //                            ? job.attachments.map((attachment: any, index: any) => (
    //                               <React.Fragment key={index}>
    //                                  <a href={attachment.url} target="_blank" style={{ textDecoration: 'none' }}>
    //                                     <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginLeft: '0.5em' }}>
    //                                        <IonItem lines='none' style={{ '--border-width': '1px', '--border-radius': '8px', '--border-color': '#072266', width: '100%' }}>
    //                                           <IonLabel color='dark'>{attachment.name}</IonLabel>
    //                                           <IonIcon color='dark' slot="end" icon={arrowDownCircleOutline} />
    //                                        </IonItem>
    //                                     </div>
    //                                  </a>
    //                               </React.Fragment>

    //                            ))
    //                            : <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{"No informado"}</p>
    //                      }
    //                   </IonCardContent>
    //                </IonCard>
    //             </IonContent>
    //          </IonModal>


    //          <IonAlert
    //             isOpen={showAlert}
    //             onDidDismiss={() => setShowAlert(false)}
    //             header={'Atención'}
    //             message={
    //                operation === 'cancelar' ? '¿Seguro que quieres solicitar la cancelación del servicio?'
    //                   : operation === 'delete' ? '¿Seguro que quieres eliminar el servicio?'
    //                      : operation === 'declineProposal' ? '¿Seguro que quieres rechazar esta propuesta?'
    //                         : operation === 'acceptProposal' ? '¿Seguro que quieres aceptar esta propuesta?'
    //                            : ''
    //             }
    //             buttons={[
    //                {
    //                   text: 'Si',
    //                   handler: async () => {
    //                      operation === 'cancelar' ? await handleUpdateJobStatus('client-require-job-cancel')
    //                         : operation === 'delete' ? await handleDeleteJob()
    //                            : operation === 'declineProposal' ? await handleDeclineProposal()
    //                               : operation === 'acceptProposal' ? await handleAcceptProposal()
    //                                  : console.log('');
    //                   }
    //                },
    //                {
    //                   text: 'No',
    //                   role: 'cancel',
    //                },

    //             ]}
    //          />


    //          <SucessAlert isOpen={isSucessAlertOpen} setIsOpen={setIsSucessAlertOpen} text="Pago realizado. El autónomo será notificado y empezará a trabajar." />

    //          <Loading isLoading={internalLoading} />

    //       </IonGrid>
    //    </main>


    // </IonContent >
  )

  else return (
    <IonPage>
      <IonHeader>
        <MobileHeader title='Servicios disponibles' backButton={true} />
      </IonHeader>
      <IonContent color='medium' >
        <div className="d-flex justify-content-center" style={{ height: '14rem', zIndex: 0, backgroundColor: '#7A82EA', padding: '1rem 0' }} >
          <div style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer' }} >
            <div style={{ display: 'flex', width: '100%', position: 'absolute', top: 0, left: 0, zIndex: 0, backgroundColor: '#7A82EA', padding: '1rem' }} >
              <div style={{ display: 'flex', gap: '0.2rem', cursor: 'pointer', zIndex: 2 }}
                // onClick={() => router.push('/client/jobs')}
                onClick={() => router.goBack()}
              >
                <IonIcon color='light' icon={chevronBack} />
                <p style={{ margin: '0', fontSize: '12px', color: '#FFFFFF' }}>Volver</p>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ zIndex: 1 }}>
              <div
                className="d-flex flex-column justify-content-center align-items-center py-3"
                style={{ zIndex: 1 }}>
                <div className="d-flex algin-items-center justify-content-center">
                  <p className="mb-2 ml-2" style={{ fontSize: "1.2rem", textAlign: "center", color: "#FFFFFF", margin: "0" }}>{job.title}</p>
                </div>
                <IonChip className='ion-text-center' style={{
                  backgroundColor:
                    job.status === 'open' ? '#00CFB4' :
                      job.status === 'private' ? '#ffc409' :
                        job.status === 'working' ? '#ffc409' :
                          job.status === 'waiting-payment' ? '#ffc409' :
                            job.status === 'freelancer-finished' ? '#2fdf75' :
                              job.status === 'client-refused' ? '#ff4961' :
                                job.status === 'client-require-job-cancel' ? '#ffd534' :
                                  job.status === 'moderation' ? '#ffc409' :
                                    job.status === 'job-cancelled' ? '#989aa2' :
                                      job.status === 'job-finished' ? '#989aa2' : '',
                  color: '#F9FAFC',
                  fontSize: '16px',
                  padding: '10px 30px',
                  margin: '0',
                  cursor: 'default',
                }}>{customBadges(job, user)}</IonChip>
              </div>
            </div>
          </div>
        </div>
        <IonCard className="ion-margin" style={{ marginTop: "-3rem" }}>
          <IonCardContent style={{ padding: '2rem' }}>
            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>

              <p style={{ fontSize: '14px', color: '#969696', margin: '0' }}>Atualizado el {formatDate(job.updatedAt)}</p>
              {job.status === 'open'
                ? job.proposed
                  ? <IonChip className='ion-text-center' style={{
                    backgroundColor: '#00CFB420',
                    color: '#616161',
                    fontSize: '16px',
                    padding: '10px 20px',
                    marginTop: '1rem',
                    cursor: 'default',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                  }}>
                    <img height='20px' src='/assets/check.svg' />
                    <p style={{ fontSize: '13px', margin: 0 }}>Propuesta enviada</p>
                  </IonChip>

                  : <></>
                : <></>
              }
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <p style={{ fontSize: '16px', color: '#2D2D2D', margin: '2rem 0' }}><strong>Descripción: </strong>{job.description.length < 140 ? job.description : job.description.slice(0, 140) + '...'}</p>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '2rem' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    <img width='20px' src='/assets/portfolio.svg' />
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Tipo de servicio:</strong></p>
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{
                      job.type === "auditoria" ? "Auditoría" :
                        job.type === "analise" ? "Análisis" :
                          job.type === "pericia" ? "Pericia" :
                            job.type === "fiscalizacao" ? "Inspección" :
                              job.type === "consultoria" ? "Consultoría" : ''
                    }</p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    <img width='20px' src='/assets/Grupo 5269.svg' />
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Plazo deseado:</strong></p>
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                    <img width='20px' src='/assets/laptop.svg' />
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}><strong>Forma de trabajo:</strong></p>
                    <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                  </div>
                </div>

              </div>

            </div>
            <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "1.5rem 0" }}><strong>Archivos adjuntos</strong></p>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: '1rem' }}>
              {
                job.attachments
                  ? job.attachments.map((attachment: any, index: any) => (
                    <React.Fragment key={index}>
                      <a href={attachment.url} target="_blank" style={{ textDecoration: 'none' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                          <IonItem lines='none' style={{ '--border-width': '1px', '--border-radius': '10px', '--border-color': '#D4D4D4', width: '190px', '--background': '#F5F5F5' }}>
                            <p style={{ color: '#000000', margin: 0 }}>{attachment.name}</p>
                            <IonIcon style={{ height: '20px' }} color='dark' slot="end" icon='/assets/download.svg' />
                          </IonItem>
                        </div>
                      </a>
                    </React.Fragment>
                  ))
                  : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{"No informado"}</p>
              }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem', marginTop: '3rem' }}>

              {
                job?.status === 'open' || job?.status === 'private'
                  ? <>
                    <button onClick={() => { router.push('/client/new-job', { job: job }); setShowJobModal(false); }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                      <p style={{ margin: '0', fontSize: '17px' }}>Editar servicio</p>
                    </button>
                    <button onClick={() => { setOperation('delete'); setShowAlert(true) }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                      <p style={{ margin: '0', fontSize: '17px' }}>Eliminar servicio</p>
                    </button>
                  </>
                  : job?.status === 'waiting-payment'
                    ? <>
                      <button onClick={() => handlePayProposal()} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                        <p style={{ margin: '0', fontSize: '17px' }}>Pagar</p>
                        {/* <PaypalButton path={props.match.params.jobID} /> */}
                      </button>
                      <button onClick={() => { setOperation('cancelar'); setShowAlert(true) }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                        <p style={{ margin: '0', fontSize: '17px' }}>Solicitar cancelación</p>
                      </button>
                      <button onClick={() => router.push(`/chat/${job?.acceptedProposal?.freelancer?._id + user._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                        <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                      </button>
                    </>
                    : job?.status === 'working'
                      ? <>
                        <button onClick={() => { setOperation('cancelar'); setShowAlert(true) }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Solicitar cancelación</p>
                        </button>
                        <button onClick={() => router.push(`/chat/${job?.acceptedProposal?.freelancer?._id + user._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                          <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                        </button>
                      </>
                      : job?.status === 'freelancer-finished'
                        ? <>
                          <button onClick={() => handleAcceptJob()} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Aceptar finalización</p>
                          </button>
                          <button onClick={() => handleDeclineJob()} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Rechazar finalización</p>
                          </button>
                          <button onClick={() => router.push(`/chat/${job?.acceptedProposal?.freelancer?._id + user._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                            <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                          </button>
                        </>
                        : job?.status === 'job-finished'
                          ? <>
                            <button onClick={() => router.push('/client/new-job', { customJob: true, freelancer: job.acceptedProposal.freelancer })} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                              <p style={{ margin: '0', fontSize: '17px' }}>Iniciar otro servicio</p>
                            </button>
                            <button onClick={() => router.push(`/chat/${job?.acceptedProposal?.freelancer?._id + user._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                              <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                            </button>
                          </>
                          : job?.status === 'job-cancelled'
                            ? <>
                              <button onClick={() => router.push('/client/new-job', { customJob: true, freelancer: job.acceptedProposal.freelancer })} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                <p style={{ margin: '0', fontSize: '17px' }}>Iniciar otro servicio</p>
                              </button>
                              <button onClick={() => router.push(`/chat/${job?.acceptedProposal?.freelancer?._id + user._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '17px', width: '227px', height: '47px' }}>
                                <p style={{ margin: '0', fontSize: '17px' }}>Abre el chat</p>
                              </button>
                            </>
                            : <></>
              }
            </div>
          </IonCardContent>
        </IonCard>

        <div className="ion-margin" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1, marginBottom: '4rem' }}>
          {
            job?.status === 'open' || job?.status === 'private'
              ? <>
                <p style={{ margin: '1.5rem 0', color: '#000000', fontSize: '20px' }}>Propuestas recebidas</p>
                {proposals.map((proposal: any, index: any) => (
                  <IonCard key={index} style={{ position: 'relative', width: "100%", backgroundColor: '#F9FAFC' }} className="ion-margin-vertical ion-no-padding">
                    <IonCardContent style={{ padding: '2rem' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 }}>
                        <IonAvatar style={{ marginRight: '10px', width: '40px', height: '40px' }}>
                          <img src={proposal.freelancer.image || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFvTcn5FrkzwRL1mBlRgMpNE7mIelcbKJiWg&usqp=CAU"} />
                        </IonAvatar>
                        <p style={{ margin: 0, fontSize: '18px' }}>{proposal.freelancer.name}</p>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginTop: '1.5rem', gap: '1rem', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img height='15px' src='/assets/portfolio.svg' />
                          <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Precio:</p>
                          <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{proposal.price} usd</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img height='15px' src='/assets/Grupo 5269.svg' />
                          <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Plazo:</p>
                          <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{proposal.deadline} dias</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <img height='15px' src='/assets/laptop.svg' />
                          <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Descripción:</p>
                          <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{proposal.description.length < 50 ? proposal.description : proposal.description.slice(0, 50) + '...'}</p>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem', width: '100%', marginTop: '1rem' }}>
                          <button onClick={() => router.push(`/client/freelancers/${proposal?.freelancer?._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '5px 15px', height: '25px' }}>
                            <p style={{ margin: '0', fontSize: '12px' }}>Ver autónomo</p>
                          </button>
                          <button onClick={() => { setCurrentProposal(proposal); setOperation('acceptProposal'); setShowAlert(true) }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '5px 15px', height: '25px' }}>
                            <p style={{ margin: '0', fontSize: '12px' }}>Aceptar</p>
                          </button>
                          <button onClick={() => { setCurrentProposal(proposal); setOperation('declineProposal'); setShowAlert(true) }} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '5px 15px', height: '25px' }}>
                            <p style={{ margin: '0', fontSize: '12px' }}>Rechazar</p>
                          </button>
                        </div>

                      </div>

                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem', marginTop: '2rem' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                          <p style={{ fontSize: '14px', color: "#8D8A8A" }}>Atualizado en {proposal.updatedAt ? formatDate(proposal.updatedAt) : ''}</p>
                        </div>
                      </div>

                    </IonCardContent>
                  </IonCard>
                ))}
              </>
              : <>
                <p style={{ margin: '1.5rem 0', color: '#000000', fontSize: '20px' }}>Propuesta acceptada</p>
                <IonCard style={{ position: 'relative', width: "100%", backgroundColor: '#F9FAFC' }} className="ion-margin-vertical ion-no-padding">
                  <IonCardContent style={{ padding: '2rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
                      <IonAvatar style={{ width: '50px', height: '50px' }}>
                        <img src={job.acceptedProposal.freelancer.image || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFvTcn5FrkzwRL1mBlRgMpNE7mIelcbKJiWg&usqp=CAU"} />
                      </IonAvatar>
                      <p style={{ margin: 0, fontSize: '18px' }}>{job?.acceptedProposal?.freelancer?.name}</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', marginTop: '1.5rem', gap: '1rem', width: '100%' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <img height='15px' src='/assets/portfolio.svg' />
                        <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Precio:</p>
                        <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{job?.acceptedProposal?.price} usd</p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <img height='15px' src='/assets/Grupo 5269.svg' />
                        <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Plazo:</p>
                        <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{job?.acceptedProposal?.deadline} dias</p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <img height='15px' src='/assets/laptop.svg' />
                        <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>Descripción:</p>
                        <p style={{ fontSize: "15px", color: '#2D2D2D', margin: "0" }}>{job?.acceptedProposal?.description.length < 50 ? job?.acceptedProposal?.description : job?.acceptedProposal?.description.slice(0, 50) + '...'}</p>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem', width: '100%', marginTop: '1rem' }}>
                        <button onClick={() => router.push(`/client/freelancers/${job?.acceptedProposal?.freelancer?._id}`)} style={{ borderRadius: '28px', color: 'white', backgroundColor: '#3B3FB6', padding: '5px 15px', height: '25px' }}>
                          <p style={{ margin: '0', fontSize: '12px' }}>Ver autónomo</p>
                        </button>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5rem', marginTop: '2rem' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                        <p style={{ fontSize: '14px', color: "#8D8A8A" }}>Atualizado en {job?.acceptedProposal?.updatedAt ? formatDate(job?.acceptedProposal?.updatedAt) : ''}</p>
                      </div>
                    </div>
                  </IonCardContent>
                </IonCard>
              </>
          }
        </div>



        {/* PROPOSAL MODAL */}
        <IonModal isOpen={showProposalModal} swipeToClose={true} onDidDismiss={() => setShowProposalModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonButton color='light' size="small" fill="clear" onClick={() => setShowProposalModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Detalles do Autónomo</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="medium">

            {/* <IonCard className='ion-no-padding'>
                     <IonRow style={{ display: 'flex', justifyContent: job.status === 'open' ? 'space-between' : 'center' }}>
                        {job.status === 'open' ? <IonButton size='small' fill='clear' onClick={() => { setOperation('acceptProposal'); setShowAlert(true) }}><IonIcon icon={checkmarkOutline} /></IonButton> : <></>}
                        <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{currentProposal?.updatedAt ? formatDate(currentProposal?.updatedAt) : ''}</strong></p>
                        {job.status === 'open' ? <IonButton size='small' fill='clear' color='danger' onClick={() => { setOperation('declineProposal'); setShowAlert(true) }}><IonIcon icon={closeCircleOutline} /></IonButton> : <></>}
                     </IonRow>
                     <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>

                     <IonCardContent>
                        <IonRow style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                           <IonCol>
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.3em' }}>
                                 <IonIcon color='dark' icon={personOutline} />
                                 <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Freelancer</IonText>
                              </div>
                              <p style={{ fontSize: '1em', marginBottom: '0.3em', marginLeft: '0.5em' }}>{currentProposal?.freelancer?.name}</p>
                           </IonCol>
                        </IonRow>
                        <IonRow>
                           <IonCol>
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                                 <IonIcon color='dark' icon={documentTextOutline} />
                                 <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Descripción</IonText>
                              </div>
                              <p style={{ fontSize: '1em', marginBottom: '0.3em', marginLeft: '0.5em' }}>{currentProposal?.description}</p>
                           </IonCol>
                        </IonRow>
                        <IonRow style={{ display: 'flex', justifyContent: 'space-between' }}>
                           <IonCol>
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                                 <IonIcon color='dark' icon={calendarOutline} />
                                 <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Plazo</IonText>
                              </div>
                              <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{currentProposal?.deadline} dias</p>
                           </IonCol>
                           <IonCol>
                              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                                 <IonIcon color='dark' icon={cashOutline} />
                                 <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Precio</IonText>
                              </div>
                              <p style={{ fontSize: '1em', marginLeft: '0.5em' }}>{currentProposal?.price} usd</p>
                           </IonCol>
                        </IonRow>
                     </IonCardContent>
                  </IonCard>

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
                     <IonIcon className="ion-text-center ion-padding-horizontal" icon={chevronDown} />
                     <div style={{ width: '50%', height: '1px', background: '#D2D1D6' }}></div>
                  </div> */}

            <IonCard className='ion-no-padding'>
              <IonCardContent style={{ paddingBottom: '0' }}>
                <IonRow style={{ marginBottom: '1em' }}>
                  <IonCol size='2.5' className='ion-no-padding'>
                    <img style={{ borderRadius: '50%', width: '100%', height: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} className='ion-no-padding' src={currentProposal?.freelancer?.image || "https://ionicframework.com/docs/demos/api/avatar/avatar.svg"} />
                  </IonCol>
                  <IonCol style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: '0.4em' }}>
                    <IonText color='dark' style={{ fontSize: '1.3em' }}><strong>{currentProposal?.freelancer?.name}</strong></IonText>
                    <IonText style={{ fontSize: '0.8em' }}>{currentProposal?.freelancer?.email}</IonText>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonButton size='small' color='dark' className='mb-3' expand='block' onClick={(e) => { e.stopPropagation(); router.push('/client/new-job', { customJob: true, freelancer: currentProposal.freelancer }); setShowProposalModal(false); }}>Contratar</IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton size='small' color='dark' className='mb-3' expand='block' onClick={() => { router.push(`/messages`, { socketId: (currentProposal.freelancer._id + user._id) }); setShowProposalModal(false); }}>Abre el chat</IonButton>
                  </IonCol>
                </IonRow>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                  <IonIcon color='dark' icon={personOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Sobre el</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.about ? currentProposal.freelancer.freelancer.about : "No informado"}</p>


                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                  <IonIcon color='dark' icon={documentTextOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Resumen</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.resume ? currentProposal.freelancer.freelancer.resume : "No informado"}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
                  <IonIcon color='dark' icon={calendarOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Fecha de registro</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.createdAt ? formatDate(currentProposal.freelancer.createdAt) : "No informado"}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                  <IonIcon color='dark' icon={homeOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Dirección</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.address ? currentProposal.freelancer.freelancer.address : "No informado"}</p>


                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                  <IonIcon color='dark' icon={constructOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Habilidades</IonText>
                </div>
                {
                  currentProposal?.freelancer?.freelancer?.skills && currentProposal?.freelancer?.freelancer?.skills?.length > 0
                    ? currentProposal.freelancer.freelancer.skills.map((skill: any, index: any) => (
                      <React.Fragment key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em' }}>
                          <IonIcon color='dark' icon={checkmarkOutline} />
                          <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>{skill}</p>
                        </div>
                      </React.Fragment>
                    ))
                    : <p style={{ fontSize: '1em', marginBottom: '1em' }}>No informado</p>
                }

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
                  <IonIcon color='dark' icon={laptopOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Forma preferida de trabajo</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1em' }}>{currentProposal?.freelancer?.freelancer?.workPreference?.remote && currentProposal?.freelancer?.freelancer?.workPreference?.presential
                  ? "Remoto / Presencial"
                  : currentProposal?.freelancer?.freelancer?.workPreference?.presential
                    ? "Presencial"
                    : currentProposal?.freelancer?.freelancer?.workPreference?.presential
                      ? "Remoto"
                      : "No informado"}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '0.8em' }}>
                  <IonIcon color='dark' icon={starOutline} />
                  <IonText color='dark' style={{ marginLeft: '0.4em', fontWeight: 600 }}>Evaluación</IonText>
                </div>
                <div style={{ fontSize: '1em', marginBottom: '1em' }}>
                  <ReactStars
                    count={5}
                    value={currentProposal?.freelancer?.freelancer?.rating?.value}
                    size={24}
                    edit={false}
                    color1={'#b6b6b6'}
                    color2={'#ffd700'}
                  />
                </div>
              </IonCardContent>
            </IonCard>



            {/* {currentProposal.status === 'accepted' ? <></> :
                     <IonFooter className="ion-padding-horizontal">
                        <IonRow>
                           <IonCol size="12" className='ion-no-padding'>
                              <IonButton size="large" expand='block' color='primary' onClick={handleAcceptProposal}>Aceptar</IonButton>
                           </IonCol>
                        </IonRow>
                        <IonRow >
                           <IonCol size="12" className='ion-no-padding ion-text-center'>
                              <IonButton fill="clear" color="danger" onClick={handleDeclineProposal}><IonIcon color='danger' icon={close} />Rechazar</IonButton>
                           </IonCol>
                        </IonRow>
                     </IonFooter>
                  } */}

          </IonContent>
        </IonModal>



        {/* JOB MODAL */}
        <IonModal isOpen={showJobModal} swipeToClose={true} onDidDismiss={() => setShowJobModal(false)}>
          <IonHeader>
            <IonToolbar color='primary'>
              <IonButton color='light' size="small" fill="clear" onClick={() => setShowJobModal(false)}>
                <IonIcon slot='start' icon={chevronDown} />
              </IonButton>
              <IonTitle>Detalles del servicio</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent color="medium">
            <IonCard className='ion-no-padding'>
              <IonRow style={{ display: 'flex', justifyContent: job.status === 'open' || job?.status === 'private' ? 'space-between' : 'center' }}>
                {job.status === 'open' || job?.status === 'private' ? <IonButton size='small' fill='clear' onClick={() => { router.push('/client/new-job', { job: job }); setShowJobModal(false); }}><IonIcon icon={createOutline} /></IonButton> : <></>}
                <p style={{ fontSize: '.8em', margin: '.5rem 0' }}>Atualizado en <strong>{job.updatedAt ? formatDate(job.updatedAt) : ''}</strong></p>
                {job.status === 'open' || job?.status === 'private' ? <IonButton size='small' fill='clear' color='danger' onClick={() => { setOperation('delete'); setShowAlert(true) }}><IonIcon icon={trashOutline} /></IonButton> : <></>}
              </IonRow>
              <div style={{ width: '100%', height: '1px', background: '#D2D1D6' }}></div>
              {/* <IonCardHeader>
                        <IonRow className='ion-align-items-center'>
                           <IonBadge style={{ marginRight: '.8em' }} color="primary">Remoto</IonBadge>
                           <IonBadge color="secondary">Auditoría</IonBadge>
                        </IonRow>
                     </IonCardHeader> */}
              <IonCardContent>
                <h1 className="ion-no-padding"><strong>{job.title}</strong></h1>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={briefcaseOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Tipo de servicio</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{
                  job.type === "auditoria" ? "Auditoría" :
                    job.type === "analise" ? "Análisis" :
                      job.type === "pericia" ? "Pericia" :
                        job.type === "fiscalizacao" ? "Inspección" :
                          job.type === "consultoria" ? "Consultoría" : ''
                }</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={laptopOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Forma de trabajo</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{job.workPreference === 'remote' ? 'Remoto' : 'Presencial'}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={documentTextOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Descripción</IonText>
                </div>
                <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{job.description ? job.description : "No informado"}</p>

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={calendarOutline} />
                  <IonText color='dark' style={{ fontSize: '1em', marginLeft: '0.4em' }}>{job.acceptedProposal ? 'Plazo' : 'Plazo Desejable'}</IonText>
                </div>
                {/* {job.acceptedProposal ?
                           <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{job.acceptedProposal.createdAt ? formatDate(addDays(job.acceptedProposal.createdAt, job.acceptedProposal.deadline)) : 'A ser definido..'}</p>
                           : <p style={{ fontSize: '1em', marginBottom: '1.1em' }}>{job.deadline} dias</p>} */}

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={alertCircleOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Requisitos de trabajo</IonText>
                </div>
                {
                  job.requirements
                    ? job.requirements.map((requirement: any, index: any) => (
                      <React.Fragment key={index}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginLeft: '0.5em' }}>
                          <IonIcon icon={checkmarkOutline} />
                          <p style={{ fontSize: '1em', marginLeft: '0.4em' }}>{requirement}</p>
                        </div>
                      </React.Fragment>

                    ))
                    : <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{"No informado"}</p>
                }

                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginTop: '1.1em' }}>
                  <IonIcon color='dark' icon={attachOutline} />
                  <IonText color='dark' style={{ fontSize: '0.8em', marginLeft: '0.4em', fontWeight: 600 }}>Archivos adjuntos</IonText>
                </div>
                {
                  job.attachments
                    ? job.attachments.map((attachment: any, index: any) => (
                      <React.Fragment key={index}>
                        <a href={attachment.url} target="_blank" style={{ textDecoration: 'none' }}>
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.4em', marginLeft: '0.5em' }}>
                            <IonItem lines='none' style={{ '--border-width': '1px', '--border-radius': '8px', '--border-color': '#072266', width: '100%' }}>
                              <IonLabel color='dark'>{attachment.name}</IonLabel>
                              <IonIcon color='dark' slot="end" icon={arrowDownCircleOutline} />
                            </IonItem>
                          </div>
                        </a>
                      </React.Fragment>

                    ))
                    : <p style={{ fontSize: '1em', marginBottom: '1.1em', marginLeft: '0.5em' }}>{"No informado"}</p>
                }
              </IonCardContent>
            </IonCard>
            {/* <IonRow >
                     <IonCol className='ion-padding-horizontal ion-text-center'>
                        <IonButton expand='block' onClick={() => { router.push('/client/new-job', job); setShowJobModal(false); }}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><IonIcon icon={createOutline} />Editar Servicio</div></IonButton>
                        <IonButton fill='clear' color='danger' onClick={() => { handleDeleteJob(); setShowJobModal(false); router.push('/client/jobs') }}>Eliminar Servicio</IonButton>
                     </IonCol>
                  </IonRow> */}
          </IonContent>
        </IonModal>


        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={'Atención'}
          message={
            operation === 'cancelar' ? '¿Seguro que quieres solicitar la cancelación del servicio?'
              : operation === 'delete' ? '¿Seguro que quieres eliminar el servicio?'
                : operation === 'declineProposal' ? '¿Seguro que quieres rechazar esta propuesta?'
                  : operation === 'acceptProposal' ? '¿Seguro que quieres aceptar esta propuesta?'
                    : ''
          }
          buttons={[
            {
              text: 'Si',
              handler: async () => {
                operation === 'cancelar' ? await handleUpdateJobStatus('client-require-job-cancel')
                  : operation === 'delete' ? await handleDeleteJob()
                    : operation === 'declineProposal' ? await handleDeclineProposal()
                      : operation === 'acceptProposal' ? await handleAcceptProposal()
                        : console.log('');
              }
            },
            {
              text: 'No',
              role: 'cancel',
            },

          ]}
        />


        {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}

        <SucessAlert isOpen={isSucessAlertOpen} setIsOpen={setIsSucessAlertOpen} text="Pago realizado. El autónomo será notificado y empezará a trabajar." />

        <Loading isLoading={internalLoading} />

      </IonContent >
      <IonFooter>
        <Tabs currentPath={props.match.url} />
      </IonFooter>
    </IonPage >
  )
};
export default MyJobsClient;