import React, { } from 'react';
import { IonBadge, IonChip, IonCol, IonLabel, IonRow, IonText } from '@ionic/react';

export function customBadges(job: any, user: any) {
    const myBadge = {
        content: '',
        color: ''
    }
    if (job.status == 'open') {
        myBadge.content = 'Abierto'
        myBadge.color = 'secondary'
    }
    else if (job.status == 'private') {
        myBadge.content = 'Privado'
        myBadge.color = 'warning'
    }
    else if (job.status == 'working') {
        myBadge.content = 'Trabajando'
        myBadge.color = 'warning'
    }
    else if (job.status == 'waiting-payment') {
        myBadge.content = 'En espera de pago'
        myBadge.color = 'warning'
    } else if (job.status == 'freelancer-finished') {
        myBadge.content = 'Servicio entregado'
        myBadge.color = 'success'
    } else if (job.status == 'client-refused') {
        myBadge.content = 'Entrega rechazada'
        myBadge.color = 'danger'
    }
    else if (job.status == 'client-require-job-cancel') {
        myBadge.content = 'Cancelación solicitada'
        myBadge.color = 'warning'
    }
    else if (job.status == 'moderation') {
        myBadge.content = 'En espera de moderación'
        myBadge.color = 'warning'
    }
    else if (job.status == 'job-cancelled') {
        myBadge.content = 'Cancelado'
        myBadge.color = 'medium'
    }
    else if (job.status == 'job-finished') {
        myBadge.content = 'Terminado'
        myBadge.color = 'medium'
    }
    else if (job.status == 'done') {
        myBadge.content = 'Terminado'
        myBadge.color = 'medium'
    }
    else if (job.status == 'pending') {
        myBadge.content = 'Pendiente'
        myBadge.color = 'warning'
    }

    return (
        // <IonChip className='ion-text-center ion-no-margin ion-no-padding' style={{padding: '0 .7em'}} color={myBadge.color}>{myBadge.content}</IonChip>

        <IonText style={{ color: '#F9FAFC' }}>{myBadge.content}</IonText>


    )
}

