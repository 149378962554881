import React from 'react'
import { IonPage, IonHeader, IonToolbar, IonLabel, IonTitle, IonContent, IonFooter, IonRow, IonCol, IonText, IonCard, getPlatforms, IonGrid, IonCardContent, IonBadge, IonCardHeader, IonCardTitle, IonChip, IonAvatar } from '@ionic/react';
import Tabs from '../../../components/Tabs'
import { UserContext } from '../../../context/UserContext';
import formatDate from '../../../utils/formatDate';
import Loading from '../../../components/loading';
import Header from '../../../components/desktop/Header';
import { useHistory } from 'react-router-dom'
import { customBadges } from '../../../utils/customBadges';
import MobileHeader from '../../../components/MobileHeader';

const ClientDashboard: React.FC = (props: any) => {
  const { user, isUserLoading } = React.useContext(UserContext);
  const currentDate = new Date()

  const platform: any = getPlatforms();
  const router = useHistory()

  const myJobs = user.client?.jobs || null

  if (isUserLoading || !user) return <Loading isLoading={isUserLoading} />

  else if (platform == 'desktop') return (

    <>
      <Header currentPath={props.match.url} />
      <IonContent color="medium" className="ion-padding desktop-dashboard">
        <main>
          {/* <IonGrid fixed className="content-wrapper"> */}
          <div style={{ display: 'flex' }} className="mt-1">
            <div style={{ width: '370px', minWidth: '370px', backgroundColor: 'transparent', borderRadius: '10px', padding: '1rem' }}>

              <IonRow className="ion-no-padding">
                <IonCard style={{ width: "100%", backgroundColor: '#F9FAFC', border: '1px solid #E3E3E3' }} className="ion-margin-none ion-padding">
                  {/* <IonCardContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}> */}
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IonAvatar style={{ border: "2px solid #00F1A9", marginRight: '10px', width: '70px', height: '70px' }}>
                        <img src={user.image} />
                      </IonAvatar>
                      <div>
                        <p style={{ margin: 0, fontSize: '12px', color: '#969696' }}>Buenos dias</p>
                        <p style={{ margin: 0, fontSize: '18px' }}>{user.name}</p>
                      </div>
                    </div>
                    <button style={{ borderRadius: '18px', color: '#00CFB4', backgroundColor: 'transparent', padding: '10px', fontSize: '13px', width: '76px', textDecoration: 'underline' }}>
                      <p style={{ margin: "0", fontSize: '13px' }} onClick={() => router.push('/profile')}>Ver perfil</p>
                    </button>
                  </div>
                  {/* </IonCardContent> */}
                </IonCard>
              </IonRow>

              <IonRow className="ion-no-padding ion-margin-vertical">
                <IonCard style={{ width: "100%", backgroundColor: '#F9FAFC', border: '1px solid #E3E3E3' }} className="ion-text-center ion-margin-none ion-padding">
                  <IonCardContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    <div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src='/assets/contrato.svg' />
                        <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0 10px" }}>Mis Servicios</p>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', marginTop: '10px' }}>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Abiertos: {myJobs.filter((job: any) => job.status === 'open').length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Privados: {myJobs.filter((job: any) => job.status === 'private').length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>A pagar: {myJobs.filter((job: any) => job.status === 'waiting-payment').length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>En ejecución: {myJobs.filter((job: any) => job.status === 'working').length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Entregados: {myJobs.filter((job: any) => job.status === 'freelancer-finished').length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Finalizados: {myJobs.filter((job: any) => job.status === 'finished').length}</p>
                        <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Cancelados: {myJobs.filter((job: any) => job.status === 'job-cancelled').length}</p>
                      </div>
                    </div>
                  </IonCardContent>
                </IonCard>
              </IonRow>
            </div>

            {/*  */}

            <div style={{ width: '100%', backgroundColor: 'trasparent', borderRadius: '10px', padding: '1rem', marginBottom: '4rem' }}>
              <div className='ion-margin-vertical' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <IonChip style={{ backgroundColor: '#FFFFFF', color: '#969696', cursor: 'default' }}>{formatDate(currentDate)}</IonChip>
                {/* <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '133px', backgroundColor: '#00CFB4', borderRadius: '6px', color: '#F9FAFC', padding: '10px' }}>
                  <p style={{ margin: "0", fontSize: '16px' }}>Ordenar por</p>
                  <img src="/assets/Group 8435.svg" />
                </button> */}
              </div>

              {
                myJobs.length > 0
                  ? <>
                    <p className='ion-margin-vertical' style={{ fontSize: '24px' }}>Mis Servicios</p>
                    {myJobs.map((job: any, index: any) => (
                      <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC', cursor: 'pointer' }} className="ion-margin-vertical ion-no-padding" onClick={() => router.push(`/client/jobs/${job._id}`)}>
                        <IonCardContent style={{ padding: '2rem' }}>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                            <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0" }}>{job.title}</p>
                            <IonChip className='ion-text-center' style={{
                              backgroundColor:
                                job.status === 'open' ? '#00CFB4' :
                                  job.status === 'private' ? '#ffc409' :
                                    job.status === 'working' ? '#ffc409' :
                                      job.status === 'waiting-payment' ? '#ffc409' :
                                        job.status === 'freelancer-finished' ? '#2fdf75' :
                                          job.status === 'client-refused' ? '#ff4961' :
                                            job.status === 'client-require-job-cancel' ? '#ffd534' :
                                              job.status === 'moderation' ? '#ffc409' :
                                                job.status === 'job-cancelled' ? '#989aa2' :
                                                  job.status === 'job-finished' ? '#989aa2' : '',
                              color: '#F9FAFC',
                              fontSize: '16px',
                              padding: '10px 30px',
                              margin: '0',
                            }}>{customBadges(job, user)}</IonChip>
                          </div>

                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '16px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2rem' }}>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/portfolio.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{
                                  job.type === "auditoria" ? "Auditoría" :
                                    job.type === "analise" ? "Análisis" :
                                      job.type === "pericia" ? "Pericia" :
                                        job.type === "fiscalizacao" ? "Inspección" :
                                          job.type === "consultoria" ? "Consultoría" : ''
                                }</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/Grupo 5269.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Plazo deseado:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                <img height='15px' src='/assets/laptop.svg' />
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo:</p>
                                <p style={{ fontSize: "16px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                              <img src='/assets/adjunto-archivo.svg' />
                              <p style={{ fontSize: "16px", color: '#616161', margin: "0" }}>{job.attachments?.length} archivo adjunto</p>
                            </div>
                          </div>


                        </IonCardContent>
                      </IonCard>
                    ))}
                  </>
                  : <></>
              }
            </div>
          </div>
          {/* </IonGrid> */}
        </main>

        {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}


      </IonContent>
    </>

    // <IonContent className="freelancer-job-desktop">

    //   <Header currentPath={props.match.url} />


    //   <main>
    //     <IonGrid fixed className="content-wrapper">


    //       <IonRow className="mt-3">
    //         <IonCol size="4" style={{ backgroundColor: 'white', borderRadius: '10px', padding: '1rem' }}>


    //           <IonRow>


    //             <IonCol size="12" style={{ paddingLeft: '1rem' }}>
    //               <h2 className="my-2">Servicios</h2>

    //               <IonText className="d-block">{user.client?.jobs?.filter((job: any) => job.status === 'open').length} servicio(s) abiertos </IonText>
    //               <IonText className="d-block">{user.client?.jobs?.filter((job: any) => job.status === 'working').length} servicio(s) en curso</IonText>
    //               <IonText className="d-block">{user.client?.jobs?.filter((job: any) => job.status === 'finished').length} servicio(s) terminados</IonText>
    //             </IonCol>

    //           </IonRow>
    //         </IonCol>
    //         <IonCol size="8" style={{ paddingLeft: '1rem' }}>
    //           <IonRow>
    //             <IonCol size="12" style={{ margin: '0' }}>
    //               <div style={{ height: '80vh', overflowY: 'auto' }}>

    //                 <h2 style={{ fontSize: '1.6em', fontWeight: 'bold' }}>Mis proyectos</h2>

    //                 {user.client?.jobs?.length === 0 ?
    //                   <h2 style={{ color: 'black', fontSize: '1.2rem', marginTop: '.3rem', fontWeight: 600 }}>Aún no has publicado ningún proyecto. ¿Qué tal crear uno ahora? Empieza <IonText color='primary' onClick={() => router.push("/client/new-job", 0)}>aqui!</IonText></h2> : <></>
    //                 }

    //                 {user.client?.jobs?.map((job: any, index: any) => (


    //                   <IonCard
    //                     onClick={() => router.push(`/client/jobs/${job._id}`)}
    //                     className='ion-no-padding ion-no-margin ion-card-desktop'
    //                     style={{ backgroundColor: 'white', marginTop: '1.2rem' }}
    //                     key={index}
    //                   >
    //                     <IonCardContent style={{ margin: '0' }}>
    //                       <IonRow style={{ margin: '0' }}>
    //                         <IonCol size="8" style={{ margin: '0' }}>
    //                           <h2 style={{ color: 'black', fontSize: '1.2rem', marginTop: '.3rem', fontWeight: 600 }}>{job.title}</h2>

    //                         </IonCol>

    //                         <IonCol size="4" className="d-flex align-items-center justify-content-center flex-column">
    //                           <IonBadge className='ion-text-center mt-2 ion-no-padding ion-no-margin' style={{
    //                             display: 'flex',
    //                             justifyContent: 'center',
    //                             alignItems: 'center',
    //                             padding: '.3rem 1rem',
    //                             backgroundColor:
    //                               job.status === 'open' ? '#6DECAE40' :
    //                                 job.status === 'private' ? '#ffc40940' :
    //                                   job.status === 'working' ? '#ffc40940' :
    //                                     job.status === 'waiting-payment' ? '#ffc40940' :
    //                                       job.status === 'freelancer-finished' ? '#2fdf7540' :
    //                                         job.status === 'client-refused' ? '#ff496140' :
    //                                           job.status === 'client-require-job-cancel' ? '#ffd53440' :
    //                                             job.status === 'moderation' ? '#ffc40940' :
    //                                               job.status === 'job-cancelled' ? '#989aa240' :
    //                                                 job.status === 'job-finished' ? '#989aa240' : ''
    //                           }}>
    //                             {customBadges(job, user)}
    //                           </IonBadge>
    //                         </IonCol>
    //                       </IonRow>
    //                     </IonCardContent>
    //                     <IonFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //                     </IonFooter>
    //                   </IonCard>
    //                 ))}
    //               </div>
    //             </IonCol>
    //           </IonRow>
    //         </IonCol>
    //       </IonRow>

    //     </IonGrid>
    //   </main>

    // </IonContent >
  )

  else return (
    <IonPage>
      <MobileHeader />
      <IonContent color="medium" className="ion-padding mobile-dashboard">

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IonAvatar style={{ border: "2px solid #00F1A9", marginRight: '10px', width: '70px', height: '70px' }}>
              <img src={user?.image} />
            </IonAvatar>
            <div>
              <p style={{ margin: 0, fontSize: '18px' }}>{user?.name}</p>
            </div>
          </div>
          <button style={{ borderRadius: '18px', color: 'white', backgroundColor: '#00CFB4', padding: '10px', fontSize: '13px', width: '76px' }}>
            <p style={{ margin: "0", fontSize: '13px' }} onClick={() => router.push('/profile')}>Ver perfil</p>
          </button>
        </div>

        {/* <div className='ion-margin-vertical'>
          <p style={{ margin: "0", fontSize: '15px' }}>Propuestas enviadas esta semana: 10</p>
          <p style={{ margin: "0", fontSize: '15px' }}>Servicios en los que trabajo: 2</p>
        </div> */}

        <IonRow className="ion-no-padding ion-margin-vertical">
          <IonCard style={{ width: "100%", backgroundColor: '#F9FAFC', border: '1px solid #E3E3E3' }} className="ion-text-center ion-margin-none ion-padding">
            <IonCardContent style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img src='/assets/contrato.svg' />
                  <p style={{ fontSize: "21px", color: "#2D2D2D", margin: "0 10px" }}>Sobre mis servicios</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px', marginTop: '10px' }}>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Abiertos: {myJobs.filter((job: any) => job.status === 'open').length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Privados: {myJobs.filter((job: any) => job.status === 'private').length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>A pagar: {myJobs.filter((job: any) => job.status === 'waiting-payment').length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>En ejecución: {myJobs.filter((job: any) => job.status === 'working').length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Entregados: {myJobs.filter((job: any) => job.status === 'freelancer-finished').length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Finalizados: {myJobs.filter((job: any) => job.status === 'finished').length}</p>
                  <p style={{ fontSize: "16px", color: "#2D2D2D" }}>Cancelados: {myJobs.filter((job: any) => job.status === 'job-cancelled').length}</p>
                </div>
              </div>
            </IonCardContent>
          </IonCard>
        </IonRow>

        <div className='ion-margin-vertical' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <IonChip style={{ backgroundColor: '#FFFFFF', color: '#969696' }}>{formatDate(currentDate)}</IonChip>
          {/* {
            myJobs.length > 0
              ? <button style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '133px', backgroundColor: '#00CFB4', borderRadius: '6px', color: '#F9FAFC', padding: '10px' }}>
                <p style={{ margin: "0", fontSize: '16px' }}>Ordenar por</p>
                <img src="/assets/Group 8435.svg" />
              </button>
              : <></>
          } */}
        </div>

        {
          myJobs.length > 0
            ? <>
              <p className='ion-margin-vertical' style={{ fontSize: '24px' }}>Mis Servicios</p>
              {myJobs.map((job: any, index: any) => (
                <IonCard key={index} style={{ width: "100%", backgroundColor: '#F9FAFC' }} className="ion-text-center ion-margin-top ion-padding-vertical" onClick={() => router.push(`/client/jobs/${job._id}`)}>
                  <IonCardContent>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <p style={{ fontSize: "17px", color: "#2D2D2D", margin: "0", fontWeight: 600 }}>{job.title}</p>
                    </div>
                    <IonChip className='ion-text-center ion-margin-vertical' style={{
                      backgroundColor:
                        job.status === 'open' ? '#00CFB4' :
                          job.status === 'private' ? '#ffc409' :
                            job.status === 'working' ? '#ffc409' :
                              job.status === 'waiting-payment' ? '#ffc409' :
                                job.status === 'freelancer-finished' ? '#2fdf75' :
                                  job.status === 'client-refused' ? '#ff4961' :
                                    job.status === 'client-require-job-cancel' ? '#ffd534' :
                                      job.status === 'moderation' ? '#ffc409' :
                                        job.status === 'job-cancelled' ? '#989aa2' :
                                          job.status === 'job-finished' ? '#989aa2' : '',
                      color: '#F9FAFC',
                      fontSize: '16px',
                      padding: '10px 30px',
                      margin: '16px 0',
                    }}>{customBadges(job, user)}</IonChip>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '32px', marginTop: '16px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/portfolio.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Tipo de servicio</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{
                            job.type === "auditoria" ? "Auditoría" :
                              job.type === "analise" ? "Análisis" :
                                job.type === "pericia" ? "Pericia" :
                                  job.type === "fiscalizacao" ? "Inspección" :
                                    job.type === "consultoria" ? "Consultoría" : ''
                          }</p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/Grupo 5269.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Plazo deseado</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.deadline} días</p>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <img height='30px' src='/assets/laptop.svg' />
                        </div>
                        <div style={{ marginTop: '12px' }}>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>Forma de trabajo</p>
                          <p style={{ fontSize: "13px", color: '#2D2D2D', margin: "0" }}>{job.workPreference === 'remote' ? 'Remota' : 'Presente'}</p>
                        </div>
                      </div>
                    </div>

                    <div className='ion-margin-top' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                      <img src='/assets/adjunto-archivo.svg' />
                      <p>{job.attachments?.length} archivo adjunto</p>
                    </div>

                  </IonCardContent>
                </IonCard>
              ))}
            </>
            : <></>
        }
      </IonContent >
      <IonFooter>
        <Tabs currentPath={props.match.url} />
      </IonFooter>

      {/* <Toast setToast={setToast} toast={toast} message={toastMessage} /> */}

    </IonPage >

    // <IonPage>
    //   <IonHeader>
    //     <MobileHeader title='Dashboard' backButton={false} />
    //   </IonHeader>
    //   <IonContent color="medium" className="ion-padding">
    //     <IonRow>
    //       <IonCol>
    //         {/* <IonText style={{ color: "#B9B9B9" }}>{formatDate(currentDate)}</IonText> */}
    //         <IonText style={{ fontSize: '1.6em', display: 'block', fontWeight: 'bold' }}>{user.name}</IonText>
    //       </IonCol>
    //     </IonRow>

    //     <IonRow>
    //       <IonCol><h2>Servicios</h2></IonCol>
    //     </IonRow>
    //     <IonRow className="ion-text-center">
    //       <IonCol>
    //         <IonCard className="ion-no-margin">
    //           <h3>{user.client?.jobs?.length}</h3>
    //           <p>Mis proyectos</p>
    //         </IonCard>
    //       </IonCol>
    //     </IonRow>
    //     <IonRow>
    //       <IonCol>
    //         {user.client?.jobs?.map((job: any, index: any) => (


    //           <IonCard
    //             onClick={() => router.push(`/client/jobs/${job._id}`)}
    //             className='ion-no-padding ion-no-margin ion-card-desktop'
    //             style={{ backgroundColor: 'white', marginTop: '1.2rem' }}
    //             key={index}
    //           >
    //             <IonCardContent style={{ margin: '0' }}>
    //               <IonRow style={{ margin: '0' }}>
    //                 <IonCol size="8" style={{ margin: '0' }}>

    //                   <h2 style={{ color: 'black', fontSize: '1.2rem', marginTop: '.3rem', fontWeight: 600 }}>{job.title}</h2>

    //                 </IonCol>

    //                 <IonCol size="4" className="d-flex align-items-center justify-content-center flex-column">
    //                   <IonBadge className='ion-text-center mt-2 ion-no-padding ion-no-margin' style={{
    //                     display: 'flex',
    //                     justifyContent: 'center',
    //                     alignItems: 'center',
    //                     padding: '.3rem 1rem',
    //                     backgroundColor:
    //                       job.status === 'open' ? '#6DECAE40' :
    //                         job.status === 'private' ? '#ffc40940' :
    //                           job.status === 'working' ? '#ffc40940' :
    //                             job.status === 'waiting-payment' ? '#ffc40940' :
    //                               job.status === 'freelancer-finished' ? '#2fdf7540' :
    //                                 job.status === 'client-refused' ? '#ff496140' :
    //                                   job.status === 'client-require-job-cancel' ? '#ffd53440' :
    //                                     job.status === 'moderation' ? '#ffc40940' :
    //                                       job.status === 'job-cancelled' ? '#989aa240' :
    //                                         job.status === 'job-finished' ? '#989aa240' : ''
    //                   }}>
    //                     {customBadges(job, user)}
    //                   </IonBadge>

    //                 </IonCol>
    //               </IonRow>
    //             </IonCardContent>
    //             <IonFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //             </IonFooter>
    //           </IonCard>
    //         ))}
    //       </IonCol>
    //     </IonRow>
    //   </IonContent >
    //   <IonFooter>
    //     <Tabs currentPath={props.match.url} />
    //   </IonFooter>
    // </IonPage >
  )
};

export default ClientDashboard;