import React, { useEffect } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import api from "../services/api"




export default function Payment(props: any) {

   const [job, setJob] = React.useState<any>(false)


   useEffect(() => {
      async function fetchJob() {
         let job: any = await api.get(`/job/${props.match.params.jobId}`)
         setJob(job.data)
      }
      fetchJob()
   }, [])

   async function updatePaymentStatus(data: any) {
      const payment: any = await api.post(`/payment/${props.match.params.jobId}`, data)
      return payment
   }

   return (
      <>
         {
            !job ? <p>Cargando...</p>

               :
               <>
                  <p>Valor: {job.acceptedProposal.price}</p>
                  <PayPalButton
                     createOrder={(data: any, actions: any) => {
                        return actions.order.create({
                           purchase_units: [{
                              amount: {
                                 currency_code: "USD",
                                 value: job.acceptedProposal.price
                              }
                           }],
                           // application_context: {
                           //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                           // }
                        });
                     }}
                     onApprove={(data: any, actions: any) => {
                        // Capture the funds from the transaction
                        return actions.order.capture().then(function (details: any) {
                           // Show a success message to your buyer
                           alert("Transaction completed by " + details.payer.name.given_name);

                           // console.log('response data', data, 'details')
                           // OPTIONAL: Call your server to save the transaction

                           return updatePaymentStatus(data)

                        });
                     }}

                     options={{
                        clientId: "AdsuA8nz_nlIJ5Pkw4wWVoYPu9kEfdZCOVIiL1Tnsv-jg-6YnsUJruDr1w1w3f-3CEkXtMkNf7ri5L6y"
                     }}
                  />
               </>
         }
      </>
   )

}