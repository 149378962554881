import { IonAlert } from "@ionic/react";
import React from "react";


const SucessAlert: React.FC<any> = ({ isOpen, setIsOpen, text, success }) => {


  return (
    //   <div>
    //      <img style={{width: '70%'}} src="images/success_check.svg"/>
    //       <h3>{text}</h3>
    //   </div>

    <IonAlert
      isOpen={isOpen}
      onDidDismiss={() => setIsOpen(false)}
      cssClass="custom-alert ion-no-padding"
      message={success ? `<div><img width='70%' src="images/success_check.svg"/><p>${text}</p></div>` : `<div><img width='70%' src="images/warning_icon.svg"/><p>${text}</p></div>`}
      buttons={['OK']}
    />
  )
}

export default SucessAlert;
