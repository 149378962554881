import { IonButton, IonCard, IonRow, IonCol, IonPage, IonFooter, IonContent, IonText, getPlatforms, IonGrid, IonAvatar, IonCardContent } from '@ionic/react';
import React from 'react';



import PagueloFacil from '../../components/PagueloFacil';



const PagueloFacilPage: React.FC = (props: any) => {

  return (
    <IonPage className="page-landing">

      <IonContent>
        <h2>Works</h2>
        <PagueloFacil />
      </IonContent>



    </IonPage>
  )
};
export default PagueloFacilPage;