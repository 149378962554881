import React, { useEffect, useState } from 'react'

export default function UserEffect() {

   const [number, setNumber] = useState<any>(1)


   React.useEffect(() => {
      // async function setChatId() {
      // setNumber(2)

      // console.log('inside useEffect')
      // console.log(number)

      // setState()


      // } setChatId()
      console.log('timeout', number)
   }, [number]);


   setTimeout(async () => {
      await setNumber(number + 1)

   }, 1000)


   // function setState() {
   //    setNumber(2)


   //    console.log('inside outside function')
   //    console.log(number)
   // }


   return (
      <div>
         <h2>Teste</h2>
      </div>
   )
}
