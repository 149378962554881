import React from 'react';
import { createContext } from 'react'

export const ToastContext = createContext<any>({})

export function ToastProvider(props: any) {

  const [toast, setToast] = React.useState<any>(false);
  const [toastMessage, setToastMessage] = React.useState<any>(false);

  return (
    <ToastContext.Provider value={{
      toast,
      toastMessage,
      setToast,
      setToastMessage,
    }}>
      {props.children}
    </ToastContext.Provider>
  )
}